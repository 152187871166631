import { gql } from 'apollo-angular';

export const ROLE_INFO_FRAGMENT = gql`
  fragment RoleInfo on Role {
    role_id
    group_id
    name
    description
    permissions_ids
    created_at
    updated_at
  }
`;
