import { gql } from 'apollo-angular';
import { ARCHIVED_ARTICLE_INFO_FRAGMENT, ENTITY_ARTICLE_INFO_FRAGMENT, ORDER_ARTICLE_INFO_FRAGMENT, ENTITY_ARTICLES_BY_STOCK_FRAGMENT, ORDER_ARTICLES_BY_STOCK_FRAGMENT } from './fragments/_article';

export const ARTICLES_BY_ORDER_QUERY = gql`
  query articlesByOrder {
    articlesByOrder
  }
`;

export const ARTICLES_BY_ENTITY_QUERY = gql`
  query articlesByEntity {
    articlesByEntity
  }
`;

export const AVAILABLE_ARTICLE_QUANTITY_FOR_REFERENCE_QUERY = gql`
  query availableArticleQuantityForReference($where: ResourceWhereUniqueInput!) {
    availableArticleQuantityForReference(where: $where) {
      availableResourceQuantity
      availableArticles {
        ...EntityArticleInfo
      }
      availableOrderedResourceQuantity
      lockedArticles {
        ...EntityArticleInfo
      }
      lockedResourceQuantity
      availableOrderedArticles {
        ...OrderArticleInfo
      }
      lockedOrderedResourceQuantity
      lockedOrderedArticles {
        ...OrderArticleInfo
      }
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
  ${ORDER_ARTICLE_INFO_FRAGMENT}
`;

export const ARCHIVED_ARTICLES_QUERY = gql`
  query archivedArticles($where: TimeFrameInput) {
    archivedArticles(where: $where)
  }
`;

export const ARCHIVED_ARTICLES_BY_BUSINESS_QUERY = gql`
  query archivedArticlesByBusiness($where: StockBusinessWhereUniqueInput!) {
    archivedArticlesByBusiness(where: $where)
  }
`;

export const ARCHIVED_ARTICLES_BY_SHIPMENT_QUERY = gql`
  query archivedArticlesByShipment($where: ShipmentWhereUniqueInput!) {
    archivedArticlesByShipment(where: $where)
  }
`;

export const ARTICLES_HISTORY_QUERY = gql`
  query articlesHistory($where: ArticleInstanceWhereInput!) {
    articlesHistory(where: $where)
  }
`;

export const ENTITY_ARTICLES_BY_STOCK_QUERY = gql`
  query entityArticlesByStock($where: ResourceByStockWhereUniqueInput!) {
    entityArticlesByStock(where: $where) {
      ...EntityArticlesByStockInfo
    }
  }
  ${ENTITY_ARTICLES_BY_STOCK_FRAGMENT}
`;

export const ORDER_ARTICLES_BY_STOCK_QUERY = gql`
  query orderArticlesByStock($where: ResourceByStockWhereUniqueInput!) {
    orderArticlesByStock(where: $where) {
      ...OrderArticlesByStockInfo
    }
  }
  ${ORDER_ARTICLES_BY_STOCK_FRAGMENT}
`;

export const REASSIGN_ENTITY_ARTICLES_MUTATION = gql`
  mutation reassignEntityArticles($where: [EntityArticleInput!]! $data: ReassignArticlesInput!) {
    reassignEntityArticles(where: $where, data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const UPDATE_ENTITY_ARTICLE_MUTATION = gql`
  mutation updateEntityArticle($where: EntityArticleWhereUniqueInput! $data:EntityArticleUpdateInput!){
    updateEntityArticle(where: $where, data: $data){
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const CREATE_ARTICLE_IN_ENTITY_MUTATION = gql`
  mutation createArticleInEntity($where: StockStorageEntityWhereUniqueInput! $data: EntityArticleCreateInput!) {
    createArticleInEntity(where: $where, data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const ADD_ARTICLES_TO_ENTITY_MUTATION = gql`
  mutation addArticlesToEntity($data: AddArticlesToEntityInput!) {
    addArticlesToEntity(data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const REMOVE_ARTICLE_FROM_ENTITY_MUTATION = gql`
  mutation removeArticleFromEntity($data: EntityArticleInput!) {
    removeArticleFromEntity(data: $data)
  }
`;

export const ARCHIVE_ENTITY_ARTICLES_MUTATION = gql`
  mutation archiveEntityArticles($data: ArchiveArticlesInput!) {
    archiveEntityArticles(data: $data) {
      ...ArchivedArticleInfo
    }
  }
  ${ARCHIVED_ARTICLE_INFO_FRAGMENT}
`;

export const UPDATE_ENTITY_ARTICLES_BY_STOCK_MUTATION = gql`
  mutation updateEntityArticlesByStock(
    $data: EntityArticlesByStockUpdateInput!
    $where: ResourceDetailWhereUniqueInput!
  ) {
    updateEntityArticlesByStock(
      data: $data
      where: $where
    ) {
      ...EntityArticlesByStockInfo
    }
  }
  ${ENTITY_ARTICLES_BY_STOCK_FRAGMENT}
`;

export const ARTICLES_BY_ORDER_SUBSCRIPTION = gql`
  subscription articlesByOrderSubscription {
    articlesByOrderSubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;

export const ARTICLES_BY_ENTITY_SUBSCRIPTION = gql`
  subscription articlesByEntitySubscription {
    articlesByEntitySubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;

export const ARCHIVED_ARTICLE_SUBSCRIPTION = gql`
  subscription archivedArticleSubscription {
    archivedArticleSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const RESOURCE_QUANTITY_BY_STOCK_SUBSCRIPTION = gql`
  subscription resourceQuantityByStockSubscription {
    resourceQuantityByStockSubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;

export const RESOURCE_ORDERED_QUANTITY_BY_STOCK_SUBSCRIPTION = gql`
  subscription resourceOrderedQuantityByStockSubscription {
    resourceOrderedQuantityByStockSubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;
