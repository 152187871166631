import { gql } from 'apollo-angular';

import { CITADEL_SYSTEM_INFO_FRAGMENT } from './fragments/_citadelSystem';

export const CITADEL_SYSTEMS_QUERY = gql`
  query citadelSystems($where: CitadelInfrastructureWhereUniqueInput!) {
    citadelSystems(where: $where)
  }
`;

export const CREATE_CITADEL_SYSTEMS_MUTATION = gql`
  mutation createCitadelSystems($data: CitadelSystemsCreateInput!) {
    createCitadelSystems(data: $data) {
      ...CitadelSystemInfo
    }
  }
  ${CITADEL_SYSTEM_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_SYSTEM_MUTATION = gql`
  mutation updateCitadelSystem(
    $where: CitadelSystemWhereUniqueInput!
    $data: CitadelSystemUpdateInput!
  ) {
    updateCitadelSystem(where: $where, data: $data) {
      ...CitadelSystemInfo
    }
  }
  ${CITADEL_SYSTEM_INFO_FRAGMENT}
`;

export const ARCHIVE_CITADEL_SYSTEM_MUTATION = gql`
  mutation archiveCitadelSystem($where: CitadelSystemWhereUniqueInput!) {
    archiveCitadelSystem(where: $where) {
      system_id
    }
  }
`;

export const DELETE_CITADEL_SYSTEM_MUTATION = gql`
  mutation deleteCitadelSystem($where: CitadelSystemWhereUniqueInput!) {
    deleteCitadelSystem(where: $where) {
      system_id
    }
  }
`;

export const CITADEL_SYSTEM_SUBSCRIPTION = gql`
  subscription citadelSystemSubscription($where: ContextWhereInput!) {
    citadelSystemSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;
