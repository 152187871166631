import { Deserializable, flatMap } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { AssetModel } from './assetModel.model';
import { CitadelVariableModel } from './citadelVariableModel.model';
import { ParameterSet } from './parameterSet.model';

export class AssetCategory implements Deserializable {
  public id: string;
  public name: string;
  public parameters_sets_ids: string[];
  public typeName = "AssetCategory";
  constructor(private citadelCoreService: CitadelCoreService) { }
  deserialize(input: any): this {
    const { __typename, parameters_sets_ids, category_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = category_id;
    this.parameters_sets_ids = (parameters_sets_ids || []);
    return this;
  }

  get whereUniqueValue(): any {
    return {
      category_id: this.id
    }
  }

  get createValue() {
    const { id, typeName, citadelCoreService, ..._this } = this;
    return {
      ..._this
    }
  }

  get updateValue() {
    const { id, typeName, citadelCoreService, ..._this } = this;
    return {
      ..._this
    }
  }

  get searchTerm(): string {
    return this.name
  }

  get label(): string {
    return this.name
  }

  get models(): AssetModel[] {
    return this.citadelCoreService.getAssetModels.filter(model => model.category_id === this.id);
  }

  get parameterSets(): ParameterSet[] {
    return this.citadelCoreService.getParameterSets.filter(paramSet => this.parameters_sets_ids.includes(paramSet.id));
  }

  get variableModels(): CitadelVariableModel[] {
    return flatMap(this.parameterSets, set => set.variableModels);
  }
}