import { StockInventoryService } from '../services/stock-inventory.service';
import { Deserializable } from '@sparte/utils';
import { AddressLabel } from './shipment.model';

export class StockBusiness implements Deserializable {
  public reference: string;
  public designation: string;
  public addresses: AddressLabel[];
  public network_ids: string[];
  public from_sap: boolean;
  public created_at: Date;
  private updated_at: Date;
  public readonly typeName = 'StockBusiness';

  constructor(private stockInventoryService: StockInventoryService) { }

  deserialize(input: any): this {
    const { __typename, addresses, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.addresses = (addresses || []).map(address => new AddressLabel().deserialize(address));
    if (!this.network_ids) this.network_ids = [];
    return this;
  }

  get id(): string {
    return this.reference;
  }

  get whereUniqueValue() {
    return {
      reference: this.reference
    }
  }

  get createValue() {
    const {
      typeName,
      from_sap,
      network_ids,
      created_at,
      updated_at,
      stockInventoryService,
      ..._this
    } = this;
    return _this;
  }

  get updateValue() {
    const {
      reference,
      typeName,
      from_sap,
      created_at,
      updated_at,
      stockInventoryService,
      ..._this
    } = this;
    return _this;
  }

  get label(): string {
    return this.designation;
  }

  get fullLabel(): string {
    return `${this.reference} - ${this.designation}`;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.reference}`.toLowerCase();
  }
}