import { gql } from 'apollo-angular';

import { CITADEL_STOCK_INFO_FRAGMENT } from './fragments/_citadelStock';

export const CITADEL_STOCKS_QUERY = gql`
  query citadelStocks {
    citadelStocks
  }
`;

export const CREATE_CITADEL_STOCK_MUTATION = gql`
  mutation createCitadelStock(
    $data: CitadelStockCreateInput!
  ) {
    createCitadelStock(data: $data) {
      ...CitadelStockInfo
    }
  }
  ${CITADEL_STOCK_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_STOCK_MUTATION = gql`
  mutation updateCitadelStock(
    $where: CitadelStockWhereUniqueInput!
    $data: CitadelStockUpdateInput!
  ) {
    updateCitadelStock(where: $where, data: $data) {
      ...CitadelStockInfo
    }
  }
  ${CITADEL_STOCK_INFO_FRAGMENT}
`;

export const DELETE_CITADEL_STOCK_MUTATION = gql`
  mutation deleteCitadelStock(
    $where: CitadelStockWhereUniqueInput!
  ) {
    deleteCitadelStock(where: $where) {
      stock_id
    }
  }
`;

export const CITADEL_STOCK_SUBSCRIPTION = gql`
  subscription citadelStockSubscription {
    citadelStockSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
