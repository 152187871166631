import { gql } from "apollo-angular";

export const RESOURCE_INFO_FRAGMENT = gql`
  fragment ResourceInfo on Resource {
    resource_id
    reference
    catalog_id
    designation
    description
    unit
    created_at
    updated_at
  }
`;