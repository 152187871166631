import { Resource } from '@sparte/citadel-core';
import { Deserializable } from "@sparte/utils";
import { StockInventoryService } from "../services/stock-inventory.service";

export abstract class ArticleInstance implements Deserializable {
  public id: string;
  public order_id: string;
  public article_id: string;
  public resource_id: string;
  public business_ref: string;
  public network_id: string;
  public shipment_ref: string;
  public designation: string;
  public long_text: string;
  public post: string;
  public provider: string;
  public original_value: number;
  public original_quantity: number;
  public quantity: number;
  public created_at: Date;
  public updated_at: Date;
  typeName: string;

  constructor(protected stockInventoryService: StockInventoryService) { }
  deserialize(input: any): this {
    throw new Error("Method not implemented.");
  }

  get searchTerm(): string {
    return;
  }

  get label(): string {
    return;
  }

  get description(): string{
    return this.long_text;
  }

  get createValue(): any {
    return;
  }

  get whereUniqueValue(): any {
    return;
  }

  get updateValue(): any {
    return;
  }

  get resource(): Resource {
    return this.stockInventoryService.getResource(this.resource_id);
  }

  get isInPriorityTransfer(): boolean {
    return false;
  }
}