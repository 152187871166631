import { gql } from 'apollo-angular';

export const ASSET_CATEGORY_INFO_FRAGMENT = gql`
  fragment AssetCategoryInfo on AssetCategory {
    category_id
    name
    icon
    parameters_sets_ids
  }
`;
