import {
  ASK_RESET_PASSWORD_MUTATION,
  LOGIN_MUTATION, LOGIN_PIN_MUTATION,
  RENEW_TOKEN_MUTATION,
  RESET_PASSWORD_MUTATION,
  SIGNUP_MUTATION,
  UDPATE_PASSWORD_MUTATION,
  UPDATE_PIN_MUTATION,
  VERIFY_EMAIL_MUTATION
} from "../queries/sparte/auth";
import { ApiService, Auth } from "./api.service";
import { IApiStore } from "./auth.store";

const AUTH_TOKEN = 'sparte-auth-token';

export class SparteAuth implements Auth {
  public authStore: IApiStore;
  constructor(private apiService: ApiService) { }

  public setApiStore = (authStore: IApiStore) => {
    this.authStore = authStore;
  }

  public login = (mail: string, password: string, keepLoggedIn: boolean): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.apiService.mutation(LOGIN_MUTATION, { mail, password }).subscribe({
        next: (res) => {
          this.localLogin(res.data['login'], keepLoggedIn);
          resolve();
        },
        error: reject
      });
    });
  }

  public loginPin = (mail: string, pin: string, keepLoggedIn: boolean): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.apiService.mutation(LOGIN_PIN_MUTATION, { mail, pin }).subscribe({
        next: (res) => {
          this.localLogin(res.data['loginPin'], keepLoggedIn);
          resolve();
        },
        error: reject
      });
    });
  }

  public signup = (mail: string, password: string, pin: string, first_name: string, last_name: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.mutation(SIGNUP_MUTATION, { mail, password, pin, first_name, last_name }).subscribe({
        next: (res) => {
          resolve(res.data['signup']);
        },
        error: reject
      });
    });
  }

  public verify = (mail: string, code: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.apiService.mutation(VERIFY_EMAIL_MUTATION, { mail, code }).subscribe({
        next: (res) => {
          this.localLogin(res.data['verifyEmail'], true);
          resolve()
        },
        error: reject
      });
    });
  }

  public updatePassword = (password: string, newPassword: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.mutation(UDPATE_PASSWORD_MUTATION, { password, newPassword }).subscribe({
        next: (res) => {
          resolve(res.data['updatePassword']);
        },
        error: reject
      });
    });
  }

  public updatePin = (password: string, newPin: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.mutation(UPDATE_PIN_MUTATION, { password, newPin }).subscribe({
        next: (res) => {
          resolve(res.data['updatePin']);
        },
        error: reject
      });
    });
  }

  public askResetPassword = (mail: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apiService.mutation(ASK_RESET_PASSWORD_MUTATION, { mail }).subscribe({
        next: (res) => {
          resolve(res.data['askResetPassword']);
        },
        error: reject
      });
    });
  }

  public resetPassword = (mail: string, code: string, newPassword: string, newPin: string) => {
    return new Promise<void>((resolve, reject) => {
      this.apiService.mutation(RESET_PASSWORD_MUTATION, { mail, code, newPassword, newPin }).subscribe({
        next: (res) => {
          this.localLogin(res.data['resetPassword'], true);
          resolve();
        },
        error: reject
      });
    });
  }

  public localLogin = (authResult: any, keepLoggedIn: boolean): void => {
    const { token, user } = authResult;
    if (keepLoggedIn) {
      localStorage.setItem(AUTH_TOKEN, token);
    }
    this.authStore.setAccessToken(token);
    this.apiService.setApolloClientAuthLink();
    this.setUser(user);
  }

  public setUser = (user: any): void => {
    this.authStore.setCurrentUser({
      id: user.user_id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.mail,
      permissions: user.permissions
    });
    this.apiService.setAuthenticated(true);
  }

  public async renewTokens(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = localStorage.getItem(AUTH_TOKEN);
      if (!token) {
        resolve(false);
        return;
      }
      this.authStore.setAccessToken(token);
      this.apiService.setApolloClientAuthLink();
      this.apiService.mutation(RENEW_TOKEN_MUTATION).subscribe({
        next: (res) => {
          const { token, user } = res.data['renewToken'];
          localStorage.setItem(AUTH_TOKEN, token);
          this.setUser(user);
          resolve(true);
        },
        error: reject
      });
    });
  }

  public logout = (callback?: Function) => {
    // Remove tokens and expiry time
    localStorage.removeItem(AUTH_TOKEN);
    this.authStore.unsetCurrentUser();
    this.authStore.unsetAccessToken();
    this.apiService.setAuthenticated(false);
    // Go back to the home route
    if (callback) callback();
  }
}