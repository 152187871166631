import { gql } from 'apollo-angular';

export const ORDER_ARTICLE_INFO_FRAGMENT = gql`
  fragment OrderArticleInfo on OrderArticle {
    order_id
    article_id
    resource_id
    business_ref
    network_id
    shipment_ref
    designation
    long_text
    original_value
    post
    provider
    original_quantity
    quantity
    created_at
    updated_at
  }
`;

export const ENTITY_ARTICLE_INFO_FRAGMENT = gql`
  fragment EntityArticleInfo on EntityArticle {
    entity_id
    instance_id
    article_id
    resource_id
    business_ref
    network_id
    shipment_ref
    order_id
    designation
    long_text
    original_value
    post
    provider
    original_quantity
    quantity
    created_at
    updated_at
  }
`;

export const ARCHIVED_ARTICLE_INFO_FRAGMENT = gql`
  fragment ArchivedArticleInfo on ArchivedArticle {
    time_id
    instance_id
    article_id
    resource_id
    reference
    business_des
    business_ref
    shipment_ref
    network_des
    order_ref
    designation
    long_text
    post
    provider
    original_value
    original_quantity
    archived_at
  }
`;

export const ENTITY_ARTICLES_BY_STOCK_FRAGMENT = gql`
  fragment EntityArticlesByStockInfo on EntityArticlesByStock {
    stock_id
    instance_id
    resource_id
    business_ref
    network_id
    quantity
    value
  }
`;

export const ORDER_ARTICLES_BY_STOCK_FRAGMENT = gql`
  fragment OrderArticlesByStockInfo on OrderArticlesByStock {
    stock_id
    article_id
    resource_id
    business_ref
    network_id
    quantity
    value
  }
`;