import { UiService } from '@sparte/ui';
import { SparteFile } from './file.model';
import { Injectable, EventEmitter } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { FileApi } from './file.api';
import { makeObservable, when } from 'mobx';
import { action, observable } from 'mobx-angular';

export interface FileSpecs {
  makeUrl?: boolean;
  makeThumbnail?: boolean;
  sizeX?: number;
  sizeY?: number;
  thumbSizeX?: number;
  thumbSizeY?: number;
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private sparteFiles: Map<string, SparteFile>;
  private fetching: string[];
  @observable private isFetching: boolean = false;
  public newFileEvent: EventEmitter<void>;
  constructor(
    private fileApi: FileApi,
    private apiService: ApiService,
    private uiService: UiService
  ) {
    makeObservable(this);
    this.sparteFiles = new Map<string, SparteFile>();
    this.fetching = [];
    this.newFileEvent = new EventEmitter();
  }

  @action private setIsFetching = (value: boolean) => {
    this.isFetching = value;
  };

  isOwner(userId: string): boolean {
    if (this.apiService.currentUser) {
      return this.apiService.currentUser.id === userId;
    } else return false;
  }

  public getFile = async (fileId: string, batch?: boolean): Promise<SparteFile> => {
    if (!this.sparteFiles.has(fileId)) {
      await this.fetchFile(fileId, batch);
    }
    return this.sparteFiles.get(fileId);
  };

  addSparteFile(data: any, callback?: Function) {
    if (data) {
      if (this.sparteFiles.has(data.file_id)) {
        this.sparteFiles.get(data.file_id).deserialize(data);
      } else {
        this.sparteFiles.set(data.file_id, new SparteFile(this).deserialize(data));
      }
      this.newFileEvent.emit();
      if (callback) callback(this.sparteFiles.get(data.file_id));
    }
  }

  createSparteFile(data: File, args: any, callback?: Function) {
    if (data) {
      this.fileApi.uploadSparteFile(data, args).subscribe((sparteFilesRes) => {
        this.addSparteFile(sparteFilesRes, callback);
      });
    }
  }

  renameSparteFile(fileId: string, name: string) {
    if (fileId) {
      this.fileApi.renameSparteFile(fileId, name).subscribe((fileRes) => {
        this.sparteFiles.get(fileRes.id).deserialize(fileRes);
      });
    }
  }

  deleteSparteFile(fileId: string, callback?: Function) {
    if (fileId) {
      this.fileApi.deleteSparteFile(fileId).subscribe((file) => {
        this.sparteFiles.delete(file.file_id);
        if (callback) callback(file.file_id);
      });
    } else {
      if (callback) callback();
    }
  }

  deleteBatchFiles(filesIds: string[], callback?: Function) {
    if (filesIds ? filesIds.length > 0 : false) {
      const arrayIds = Object.assign([], filesIds);
      filesIds.forEach((fileId) => {
        this.fileApi.deleteSparteFile(fileId).subscribe((file) => {
          this.sparteFiles.delete(file.id);
          arrayIds.splice(arrayIds.indexOf(file.id), 1);
          if (arrayIds.length === 0 && callback) {
            callback(filesIds);
          }
        });
      });
    } else {
      if (callback) callback();
    }
  }

  fetchBatchFiles(filesIds: string[], callback?: Function) {
    const filesToFetch = filesIds.filter(
      (fileId) =>
        !this.fetching.includes(fileId) && !this.sparteFiles.has(fileId)
    );
    this.fetching.concat(filesToFetch);
    this.fileApi.getBatchFiles(filesToFetch).subscribe((sparteFileRes) => {
      if (sparteFileRes) {
        sparteFileRes.forEach((sparteFile) => {
          this.addSparteFile(sparteFile);
          this.fetching.splice(this.fetching.indexOf(sparteFile.id), 1);
        });
        if (callback) callback();
      } else {
        if (callback) callback();
      }
    });
  }

  async fetchFile(fileId, batch = true): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.fetching.includes(fileId)) {
        this.fetching.push(fileId);
        if (batch) {
          setTimeout(() => {
            if (this.fetching.length === 1) {
              this.fileApi.getSparteFile(fileId).subscribe((sparteFile) => {
                if (sparteFile) {
                  this.addSparteFile(sparteFile, resolve);
                  this.fetching.splice(this.fetching.indexOf(sparteFile.id), 1);
                } else {
                  this.fetching.splice(this.fetching.indexOf(fileId), 1);
                }
              });
            } else if (!this.isFetching && this.fetching.length > 1) {
              this.setIsFetching(true);
              const toFetch = this.fetching.splice(0, this.fetching.length);
              this.fetchBatchFiles(toFetch, () => {
                this.setIsFetching(false);
                resolve();
              });
            } else if (this.isFetching) {
              when(() => !this.isFetching, () => {
                resolve();
              });
            }
          }, 100);
        }
        else {
          this.fileApi.getSparteFile(fileId).subscribe((sparteFile) => {
            if (sparteFile) {
              this.fetching.splice(this.fetching.indexOf(sparteFile.id), 1);
              this.addSparteFile(sparteFile, resolve);
            } else {
              this.fetching.splice(this.fetching.indexOf(fileId), 1);
              resolve();
            }
          });
        }
      }
    });
  }

  /**
   * Ouvre le dialogue pour afficher la gallerie d'images
   * @param {SparteFile[]} fileArray: images a afficher.
   * @param {Number} index: index de l'image a afficher en premier
   * @param {boolean} showImageTitle: si on doit afficher le titre des images
   * @param {String} panelClass: nom de la classe utilisée pour afficher le dialogue
   */
  display(
    fileArray: SparteFile[],
    index = 0,
    showImageTitle = false,
    panelClass = 'gallery-dialog-container'
  ): void {
    this.uiService.display(fileArray, index, showImageTitle, panelClass);
  }
}
