import { EventEmitter, Injectable } from '@angular/core';

export enum PrintModeEnum {
  EntityLabel = "EntityLabel",
  Report = "Report",
  RowBarcodes = "RowBarcodes",
  BinBarcodes = "BinBarcodes",
  ShipmentSlip = "ShipmentSlip",
  SelectedArticles = "SelectedArticles"
}

export enum PrintFormatEnum {
  A3 = "A3",
  A4 = "A4"
}

interface BarcodeData {
  barcode: string;
  businessDes?: string;
  networkDes?: string;
  articleListData?: ArticleList[];
}

export interface BinData {
  binTag: string;
  barcode: string;
  levelTag: string;
}

export interface BinBarcodeData {
  binTag: string;
  binName: string;
  barcode: string;
  storageName: string;
}

export interface PrintRowData {
  binsTag: string;
  rowsTags: string[];
  binDatas: BinData[];
}

interface ArticleData {
  post: string;
  quantity: string;
  label: string;
}

export interface ArticleList {
  mainLabel: string;
  articles: ArticleData[];
}

export interface ShipmentData {
  articles: any,// EntityArticle[];
  entityBarcode: string;
  weight: number;
  dimensions: string;
  address: any,//Address;
  description: string;
  client: string;
  createdBy: string;
  comment: string;
  destination_user: any,//ShipmentUser;
  business_ref: string;
  business_des: string;
  added_articles: any,//ShipmentArticle[];

}

export interface PrintInterface {
  mode: PrintModeEnum;
  barcodeData?: BarcodeData;
  archivedOrder?: any,//ArchivedOrder;
  rowsData?: PrintRowData[];
  binsData?: BinBarcodeData[];
  printFormat?: PrintFormatEnum;
  shipmentData?: ShipmentData;
}

@Injectable({
  providedIn: 'root'
})
export class DataPrintService {
  public printEvent: EventEmitter<PrintInterface>;

  constructor() {
    this.printEvent = new EventEmitter<PrintInterface>();
  }
}
