import { gql } from 'apollo-angular';

import { CITADEL_ENVIRONMENT_INFO_FRAGMENT } from './fragments/_citadelEnvironment';

export const CITADEL_ENVIRONMENTS_QUERY = gql`
  query citadelEnvironments {
    citadelEnvironments
  }
`;

export const CREATE_CITADEL_ENVIRONMENT_MUTATION = gql`
  mutation createCitadelEnvironment($data: CitadelEnvironmentCreateInput!) {
    createCitadelEnvironment(data: $data) {
      ...CitadelEnvironmentInfo
    }
  }
  ${CITADEL_ENVIRONMENT_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_ENVIRONMENT_MUTATION = gql`
  mutation updateCitadelEnvironment(
    $where: CitadelEnvironmentWhereUniqueInput!
    $data: CitadelEnvironmentUpdateInput!
  ) {
    updateCitadelEnvironment(where: $where, data: $data) {
      ...CitadelEnvironmentInfo
    }
  }
  ${CITADEL_ENVIRONMENT_INFO_FRAGMENT}
`;
export const DELETE_CITADEL_ENVIRONMENT_MUTATION = gql`
  mutation deleteCitadelEnvironment(
    $where: CitadelEnvironmentWhereUniqueInput!
  ) {
    deleteCitadelEnvironment(where: $where) {
      environment_id
    }
  }
`;

export const CITADEL_ENVIRONMENT_SUBSCRIPTION = gql`
  subscription citadelEnvironmentSubscription {
    citadelEnvironmentSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;