import { SparteFile } from '@sparte/api';
import { Deserializable, flatMap } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { CitadelVariableModel } from './citadelVariableModel.model';
import { ParameterSet } from './parameterSet.model';
export class AssetModel implements Deserializable {
  public id: string;
  public category_id: string;
  public name: string;
  public distribution: boolean;
  public desc_file_id: string;
  public descFile: SparteFile;
  public parameters_sets_ids: string[];
  public typeName = "AssetModel";
  constructor(private citadelCoreService: CitadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, model_id, parameters_sets_ids, possible_parent_models_ids, ..._input } = input;
    Object.assign(this, _input);
    this.id = model_id;
    this.parameters_sets_ids = parameters_sets_ids || [];
    return this;
  }

  get label(): string {
    return this.name;
  }

  get searchTerm(): string {
    return this.name
  }

  get whereUniqueValue(): any {
    return {
      model_id: this.id
    }
  }

  get createValue() {
    const { id, typeName, citadelCoreService, descFile, ..._this } = this;
    _this.desc_file_id = descFile ? descFile.id : null;
    return {
      ..._this
    }
  }

  get updateValue() {
    const { id, typeName, citadelCoreService, descFile, ..._this } = this;
    _this.desc_file_id = descFile ? descFile.id : null;
    return {
      ..._this
    }
  }

  get parameterSets(): ParameterSet[] {
    return this.citadelCoreService.getParameterSets.filter(paramSet => this.parameters_sets_ids.includes(paramSet.id));
  }

  get variableModels(): CitadelVariableModel[] {
    return flatMap(this.parameterSets, set => set.variableModels);
  }
}