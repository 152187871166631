import { datePrettyfier, Deserializable } from '@sparte/utils';
import moment from 'moment';

export enum TaskEventTypeEnum {
  Alert = "Alert",
  Danger = "Danger",
  StartCycle = "StartCycle",
  EndCycle = "EndCycle"
}

export enum CycleTypeEnum {
  Production = "Production",
  Maintenance = "Maintenance",
  Reglage = "Reglage",
  Transfert = "Transfert",
  Preparation = "Preparation",
  Retard = "Retard"
}

export class TaskEvent implements Deserializable {
  public history_ts: Date;
  public info: string;
  public event_type: TaskEventTypeEnum;
  public cycleType: CycleTypeEnum;
  public event_of: string;
  public state: string;
  searchTerm: string;
  label: string;
  typeName: string = "TaskEvent";

  deserialize(input: any): this {
    const { history_ts, time_diff, ..._input } = input;
    Object.assign(this, _input);
    if (history_ts) this.history_ts = new Date(history_ts);
    else if (time_diff) {
      const currentTime = new Date(Date.now());
      this.history_ts = moment(currentTime).add(time_diff, 'hours').toDate();
    }
    return this;
  }

  get updateValue(): any {
    return {
      info: this.info,
      event_type: this.event_type
    }
  }

  get prettyCycleType(): string {
    return `${this.event_type === TaskEventTypeEnum.StartCycle ? 'Début de' : 'Fin de'} ${CycleTypeEnum[this.cycleType]}`;
  }

  get prettyHour(): string {
    return datePrettyfier(this.history_ts, "HH:mm");
  }
}