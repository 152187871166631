import { quantityPrettyfier } from '@sparte/utils';

import { StockInventoryService } from '../services/stock-inventory.service';
import { HistoryTypeEnum, OperationTypeEnum } from './../utils/stock-interfaces';
import { ArticleHistory } from './articleHistory.model';
import { ArticleInstance } from './articleInstance.model';
import { OrderArticle } from './orderArticle.model';
import { StockBusiness } from './stockBusiness.model';
import { StockNetwork } from './stockNetwork.model';
import { StockStorageEntity } from './stockStorageEntity.model';

export class EntityArticle extends ArticleInstance {
  public entity_id: string;
  public order_ref: string;
  public quantitySet: number;
  public previous_id: string;
  public readonly typeName = "EntityArticle";

  constructor(stockInventoryService: StockInventoryService) {
    super(stockInventoryService);
  }

  deserialize(input: any): this {
    const { __typename, instance_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.quantitySet = this.quantity;
    this.id = instance_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    return this;
  }

  deserializeOrderArticle(article: OrderArticle, quantity: number): this {
    const { id, created_at, updated_at, ..._article } = article;
    Object.assign(this, _article);
    this.article_id = id;
    this.quantity = quantity;
    this.quantitySet = quantity;
    return this;
  }

  transferEntityArticle(article: EntityArticle, setQuantity: number): this {
    const { quantity, id, previous_id, ..._input } = article;
    Object.assign(this, _input);
    this.quantity = setQuantity;
    this.quantitySet = setQuantity;
    this.previous_id = id;
    return this;
  }

  cloneEntityArticle(article: EntityArticle): this {
    const {
      stockInventoryService,
      previous_id,
      ..._article
    } = article;
    Object.assign(this, _article);
    return this;
  }

  get createValue() {
    return {
      designation: this.designation,
      quantity: this.quantity,
      business_ref: this.business_ref,
      network_id: this.network_id,
      provider: this.provider,
      resource_id: this.resource_id,
      original_value: this.original_value,
      original_quantity: this.original_quantity,
      order_ref: this.order_ref
    }
  }

  get updateValue() {
    return {
      quantity: this.quantity,
      value: this.original_value
    };
  }

  get updateArticleValue() {
    return {
      resource_id: this.resource_id,
      quantity: this.quantity
    };
  }

  get instanceId() {
    return {
      instance_id: this.id
    }
  }

  get whereUniqueValue() {
    return {
      instance_id: this.id,
      entity_id: this.entity_id
    }
  }

  get selectValue() {
    return {
      instance_id: this.id,
      entity_id: this.entity_id,
      quantity: this.quantitySet
    }
  }

  get snapshotValue() {
    const {
      id,
      stockInventoryService,
      typeName,
      ..._this
    } = this;
    return {
      instance_id: id,
      ..._this
    }
  }

  get hasUpdatedQuantity(): boolean {
    return this.quantitySet !== this.quantity;
  }

  get reference(): string {
    if (!this.resource_id) return 'N/A';
    return this.resource?.reference;
  }

  get searchTerm(): string {
    return `${this.orderRef} ${this.designation} ${this.reference} ${this.business_ref} ${this.businessDes} ${this.networkDes} ${this.provider} ${this.shipment_ref}`.toLowerCase();
  }

  get label(): string {
    if (!this.resource_id) return this.designation;
    return this.resource?.label;
  }

  get ref(): string {
    if (!this.resource_id) return this.designation;
    if (!this.resource.reference) {
      return this.resource.designation
    } else {
      return this.resource.reference
    }
  }

  get prettyQuantity(): string {
    return quantityPrettyfier(this.quantity);
  }

  get orderRef(): string {
    return this.order_ref;
  }

  get value(): number {
    if (!this.original_quantity) return;
    return this.original_value / this.original_quantity * this.quantity;
  }

  get business(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_ref);
  }

  get network(): StockNetwork {
    return this.stockInventoryService.getStockNetwork(this.network_id);
  }

  get businessDes(): string {
    return this.business ? this.business.designation : "NA";
  }

  get networkDes(): string {
    return this.network ? this.network.designation : "NA";
  }

  get entity(): StockStorageEntity {
    return this.stockInventoryService.getStockStorageEntity(this.entity_id);
  }

  get barcode(): string {
    return this.entity?.barcode;
  }

  get isTrash(): boolean {
    return this.entity?.trash;
  }

  get isReceived(): boolean {
    return this.entity?.received;
  }

  get transfer(): boolean {
    const operations = this.stockInventoryService.getStockOperationsForEntity(this.entity_id);
    return operations.some(operation => operation.operation_type === OperationTypeEnum.TRANSFER);
  }

  get withdraw(): boolean {
    const operations = this.stockInventoryService.getStockOperationsForEntity(this.entity_id);
    return operations.some(operation => operation.operation_type === OperationTypeEnum.WITHDRAWAL && operation.article_instance_id === this.id);
  }

  get storageLabel(): string {
    return this.entity?.storageLabel;
  }

  get history(): ArticleHistory[] {
    return this.stockInventoryService.getArticleHistory(this.id);
  }

  get getHistory(): ArticleHistory[] {
    return this.history.sort((opA, opB) => (opA.action_ts > opB.action_ts ? -1 : 1));
  }

  get isInPriorityTransfer(): boolean {
    if (!this.entity_id) return false;
    return this.stockInventoryService.getStockOperationsForEntity(this.entity_id).some(operation => operation.hpriority);
  }

  addHistory(history_type: HistoryTypeEnum, quantity: number, information: string, comment?: string) {
    const history = new ArticleHistory(this.stockInventoryService.defaultStorageLabel).deserialize({ history_type, quantity, information, comment }, true);
    this.history.push(history);
  }

  //XLSX REPORT VARIABLES
  get prettyReportQuantity(): string {
    return this.prettyQuantity;
  }

  get prettyReportOriginalQuantity(): string {
    return quantityPrettyfier(this.original_quantity);
  }
}