import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { makeObservable } from 'mobx';
import { action, computed, observable } from 'mobx-angular';

export interface SparteRoute {
  url: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class SparteRoutingService {
  @observable public currentRoute: SparteRoute[];
  @observable private currentUrl: string;
  public callbackRoutes: string[];
  constructor(
    private router: Router,
    private zone: NgZone
  ) {
    makeObservable(this);
    this.currentRoute = [];
    this.callbackRoutes = [];
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = [];
        this.currentUrl = event.urlAfterRedirects;
      }
    })
  }

  @computed get canGoBack(): boolean {
    return this.currentRoute.length > 0;
  }

  @computed get fullRouteNames(): string[] {
    if (this.currentRoute.length > 0) {
      return this.currentRoute.map(route => route.name);
    }
  }

  @computed get urlArray(): string[] {
    if (this.currentUrl) {
      return this.currentUrl.split("/").filter(url => url !== "");
    }
  }

  getConcatUrlArrayByIndex(index: number): string {
    let fullUrl = "";
    if (this.currentUrl ? index < this.urlArray.length : false) {
      for (let i = 0; i < (index + 2); i++) {
        fullUrl += `/${this.urlArray[i]}`;
      }
    }
    return fullUrl;
  }

  @action setRoute(names: string[]) {
    setTimeout(() => {
      if (this.currentUrl ? this.urlArray.length - 1 === names.length : false) {
        this.currentRoute.push({
          name: "origin",
          url: this.getConcatUrlArrayByIndex(-1)
        })
        for (let i = 0; i < names.length; i++) {
          this.currentRoute.push({
            name: names[i],
            url: this.getConcatUrlArrayByIndex(i)
          });
        }
      }
    });
  }

  @action setCustomRoute(newRoute: SparteRoute[]) {
    setTimeout(() => {
      this.currentRoute = newRoute;
    });
  }

  navigateToRoute(route: SparteRoute) {
    this.zone.run(() => {
      this.router.navigateByUrl(route.url);
    });
  }

  @action goBack(callback?: Function) {
    this.currentRoute.pop();
    this.router.navigateByUrl(this.currentRoute[this.currentRoute.length - 1].url);
    if (callback && this.currentRoute.length === 1 && this.callbackRoutes.includes(this.currentRoute[0].url)) {
      setTimeout(() => {
        callback();
      })
    }
  }

}
