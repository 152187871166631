import { gql } from 'apollo-angular';

export const CITADEL_TASK_INFO_FRAGMENT = gql`
  fragment CitadelTaskInfo on CitadelTask {
    task_id
    owners_ids
    parent_task_id
    context_asset_id
    name
    completion_time
    # in_resources {
    #   node_id
    #   resource_id
    #   quantity
    # }
    # out_resources {
    #   node_id
    #   resource_id
    #   quantity
    # }
    assets_ids
    events {
      history_ts
      info
      event_type
    }
    x
    y
    created_by
    created_at
    updated_at
  }
`;
