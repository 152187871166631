import { gql } from 'apollo-angular';

import { USER_NODE_INFO_FRAGMENT } from './fragments/_userNode';

export const USER_NODES_QUERY = gql`
  query getUserNodes($where: UserNodeWhereInput!) {
    getUserNodes(where: $where)
  }
`;

export const CREATE_USER_NODE_MUTATION = gql`
  mutation createUserNode($data: UserNodeCreateInput!) {
    createUserNode(data: $data) {
      ...UserNodeInfo
    }
  }
  ${USER_NODE_INFO_FRAGMENT}
`;

export const UPDATE_USER_NODE_MUTATION = gql`
  mutation updateUserNode(
    $where: UserNodeWhereUniqueInput!
    $data: UserNodeUpdateInput!
  ) {
    updateUserNode(where: $where, data: $data) {
      ...UserNodeInfo
    }
  }
  ${USER_NODE_INFO_FRAGMENT}
`;

export const DELETE_USER_NODE_MUTATION = gql`
  mutation deleteUserNode($where: UserNodeWhereUniqueInput!) {
    deleteUserNode(where: $where) {
      node_id
      user_id
    }
  }
`;

export const USER_NODE_SUBSCRIPTION = gql`
  subscription userNodeSubscription($where: UserNodeWhereInput!) {
    userNodeSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;