import { gql } from 'apollo-angular';


export const SPQDC_EVENT_INFO_FRAGMENT = gql`
  fragment SPQDCEventInfo on SPQDCEvent {
    spqdc_id
    post
    context_id
    event_date
    category
    message
    type
  }
`;