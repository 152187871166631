import { gql } from 'apollo-angular';

import { STOCK_LOGISTIC_FLOW_INFO_FRAGMENT } from './fragments/_stockLogisticFlow';

export const STOCK_LOGISTIC_FLOWS_QUERY = gql`
  query stockLogisticFlows {
    stockLogisticFlows
  }
`;

export const CREATE_STOCK_LOGISTIC_FLOW_MUTATION = gql`
  mutation createStockLogisticFlow($data: StockLogisticFlowCreateInput!) {
    createStockLogisticFlow(data: $data) {
      ...StockLogisticFlowInfo
    }
  }
  ${STOCK_LOGISTIC_FLOW_INFO_FRAGMENT}
`;

export const UPDATE_STOCK_LOGISTIC_FLOW_MUTATION = gql`
  mutation updateStockLogisticFlow(
    $where: StockLogisticFlowWhereUniqueInput!
    $data: StockLogisticFlowUpdateInput!
  ) {
    updateStockLogisticFlow(where: $where, data: $data) {
      ...StockLogisticFlowInfo
    }
  }
  ${STOCK_LOGISTIC_FLOW_INFO_FRAGMENT}
`;

export const PAUSE_STOCK_LOGISTIC_FLOW_MUTATION = gql`
  mutation pauseStockLogisticFlow(
    $where: StockLogisticFlowWhereUniqueInput!
    $data: StockLogisticFlowPauseInput!
  ) {
    pauseStockLogisticFlow(where: $where, data: $data) {
      ...StockLogisticFlowInfo
    }
  }
  ${STOCK_LOGISTIC_FLOW_INFO_FRAGMENT}
`;

export const DELETE_STOCK_LOGISTIC_FLOW_MUTATION = gql`
  mutation deleteStockLogisticFlow(
    $where: StockLogisticFlowWhereUniqueInput!
  ) {
    deleteStockLogisticFlow(where: $where) {
      flow_id
    }
  }
`;

export const STOCK_LOGISTIC_FLOW_SUBSCRIPTION = gql`
  subscription stockLogisticFlowSubscription {
    stockLogisticFlowSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
