import { gql } from 'apollo-angular';
import { FILE_INFO_FRAGMENT } from './fragments/_file';

export const CREATE_FILE_MUTATION = gql`
  mutation createFile(
    $file: Upload!
    $makeUrl: Boolean
    $makeThumbnail: Boolean
    $sizeX: Int
    $sizeY: Int
    $thumbSizeX: Int
    $thumbSizeY: Int
    $user_id: String
  ) {
    createFile(
      file: $file
      makeUrl: $makeUrl
      makeThumbnail: $makeThumbnail
      sizeX: $sizeX
      sizeY: $sizeY
      thumbSizeX: $thumbSizeX
      thumbSizeY: $thumbSizeY
      user_id: $user_id
    ) {
      ...FileInfo
    }
  }
  ${FILE_INFO_FRAGMENT}
`;

export const RENAME_FILE_MUTATION = gql`
  mutation renameFile(
    $where: FileWhereUniqueInput!
    $data: FileUpdateNameInput!
  ) {
    renameFile(where: $where, data: $data) {
      ...FileInfo
    }
  }
  ${FILE_INFO_FRAGMENT}
`;

export const DELETE_FILE_MUTATION = gql`
  mutation deleteFile($where: FileWhereUniqueInput!) {
    deleteFile(where: $where) {
      file_id
    }
  }
`;

export const SPARTE_FILE_QUERY = gql`
  query file($where: FileWhereUniqueInput!) {
    file(where: $where) {
      ...FileInfo
    }
  }
  ${FILE_INFO_FRAGMENT}
`;

export const SPARTE_BATCH_FILES_QUERY = gql`
  query batchFiles($where: FileWhereInput!) {
    batchFiles(where: $where)
  }
`;
