import { gql } from 'apollo-angular';

import { SHARE_PROJECT_INFO_FRAGMENT } from './fragments/_shareProject';

export const SHARE_PROJECTS_QUERY = gql`
  query shareProjects {
    shareProjects
  }
`;

export const SHARE_PROJECT_QUERY = gql`
  query shareProject($where: ShareProjectWhereUniqueInput!) {
    shareProject(where: $where)
  }
`;

export const CREATE_SHARE_PROJECT_MUTATION = gql`
  mutation createShareProject($data: ShareProjectCreateInput!) {
    createShareProject(data: $data) {
      ...ShareProjectInfo
    }
  }
  ${SHARE_PROJECT_INFO_FRAGMENT}
`;

export const UPDATE_SHARE_PROJECT_MUTATION = gql`
  mutation updateShareProject(
    $where: ShareProjectWhereUniqueInput!
    $data: ShareProjectUpdateInput!
  ) {
    updateShareProject(where: $where, data: $data) {
      ...ShareProjectInfo
    }
  }
  ${SHARE_PROJECT_INFO_FRAGMENT}
`;

export const DELETE_SHARE_PROJECT_MUTATION = gql`
  mutation deleteShareProject($where: ShareProjectWhereUniqueInput!) {
    deleteShareProject(where: $where) {
      project_id
    }
  }
`;
