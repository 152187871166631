import { gql } from 'apollo-angular';
import { CITADEL_VARIABLE_MODEL_INFO_FRAGMENT } from './_citadelVariableModel';

export const PARAMETER_SET_INFO_FRAGMENT = gql`
  fragment ParameterSetInfo on ParameterSet {
    set_id
    name
    variable_models {
      ...CitadelVariableModelInfo
    }
  }
  ${CITADEL_VARIABLE_MODEL_INFO_FRAGMENT}
`;
