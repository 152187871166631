import { gql } from 'apollo-angular';
import { FAVORITE_BUSINESS_BY_USER_INFO_FRAGMENT } from './fragments/_favoriteBusiness';



export const FAVORITE_BUSINESS_BY_USER_QUERY = gql`
  query favoriteBusinessByUser {
    favoriteBusinessByUser
  }
`;

export const CREATE_FAVORITE_BUSINESS_BY_USER_MUTATION = gql`
  mutation createFavoriteBusinessByUser($data: FavoriteBusinessByUserCreateInput!) {
    createFavoriteBusinessByUser(data: $data) {
      ...FavoriteBusinessByUserInfo
    }
  }
  ${FAVORITE_BUSINESS_BY_USER_INFO_FRAGMENT}
`;

export const DELETE_FAVORITE_BUSINESS_BY_USER_MUTATION = gql`
  mutation deleteFavoriteBusinessByUser($data: FavoriteBusinessByUserUpdateInput!) {
    deleteFavoriteBusinessByUser(data: $data) {
      ...FavoriteBusinessByUserInfo
    }
  }
  ${FAVORITE_BUSINESS_BY_USER_INFO_FRAGMENT}
`;