import { gql } from 'apollo-angular';

import { ROLE_INFO_FRAGMENT } from './fragments/_role';

export const ROLES_QUERY = gql`
  query roles($where: UserGroupWhereInput) {
    roles(where: $where)
  }
`;

export const CREATE_ROLE_MUTATION = gql`
  mutation createRole($data: RoleCreateInput!) {
    createRole(data: $data) {
      ...RoleInfo
    }
  }
  ${ROLE_INFO_FRAGMENT}
`;

export const UPDATE_ROLE_MUTATION = gql`
  mutation updateRole($where: RoleWhereUniqueInput!, $data: RoleUpdateInput!) {
    updateRole(where: $where, data: $data) {
      ...RoleInfo
    }
  }
  ${ROLE_INFO_FRAGMENT}
`;

export const DELETE_ROLE_MUTATION = gql`
  mutation deleteRole($where: RoleWhereUniqueInput!) {
    deleteRole(where: $where) {
      role_id
    }
  }
`;