<section class="main-section"
         *mobxAutorun="{ dontDetach: true }">
  <h1 *ngIf="showImageTitle"
      class="image-title">{{ imageTitle }}</h1>
  <div [@slideAnimation]="currentIndex"
       class="gallery-file-div"
       [ngClass]="fileClass">
    <ng-template ngFor
                 [ngForOf]="fileList"
                 let-slide
                 let-i="index">
      <div class="slide slide-animation nonDraggableFile"
           (swiperight)="prevFile()"
           (swipeleft)="nextFile()"
           *ngIf="isFileSelected(i) && isImage(slide)"
           [ngStyle]="{ 'background-image': 'url(' + slide.url + ')' }">
      </div>
      <div class="wrong-format"
           *ngIf="isFileSelected(i) && !isPdf(slide) && !isImage(slide)"> Aperçu non disponible. </div>
    </ng-template>
    <!-- <ng2-pdfjs-viewer class="pdf-scroll-container"
                      *ngIf="isCurrentPdf"
                      [ngStyle]="{'pointer-events': isDrawingActive ? 'none' : 'auto' }"
                      [openFile]="false"
                      [download]="false"
                      [print]="false"
                      [fullScreen]="false"
                      [viewBookmark]="false"
                      [find]="false"
                      [pdfSrc]="fileList[0].url">
    </ng2-pdfjs-viewer> -->
    <!-- <svg #drawingCanvas
         [ngStyle]="{'pointer-events': isDrawingActive ? 'auto' : 'none' }"
         class="drawing-canvas"></svg>
    <div *ngIf="isCurrentPdf"
         class="pdf-toolbar"
         fxLayout="row"
         fxLayoutAlign=" center"
         fxLayoutGap="8px">
      <mat-slide-toggle tabindex="-1"
                        class="drawing-toggle"
                        [(ngModel)]="drawEnabled"> Dessin </mat-slide-toggle>
      <button mat-icon-button
              *ngIf="isDrawingActive"
              tabindex="-1"
              class="drawing-buttons"
              (click)="undo()">
        <mat-icon>undo</mat-icon>
      </button>
      <button mat-icon-button
              *ngIf="isDrawingActive"
              tabindex="-1"
              class="drawing-buttons"
              (click)="reset()">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div> -->
    <button mat-icon-button
            tabindex="-1"
            class="close-button"
            (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="multiFiles"
            tabindex="-1"
            class="arrow-buttons arrow-left"
            (click)="prevFile()">
      <mat-icon class="button-icons">navigate_before</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="multiFiles"
            tabindex="-1"
            class="arrow-buttons arrow-right"
            (click)="nextFile()">
      <mat-icon class="button-icons">navigate_next</mat-icon>
    </button>
  </div>
  <section class="gallery-toolbar"
           *ngIf="multiFiles"
           fxShow
           fxHide.xs>
    <cdk-virtual-scroll-viewport orientation="horizontal"
                                 class="ps virtual-content perfect-scroll"
                                 *ngIf="fileList"
                                 [itemSize]="200"
                                 [perfectScrollbar]="config"
                                 #galleryScroll>
      <div class="scroll-content-div"
           #scrollContent>
        <div *cdkVirtualFor="let thumbnail of fileList; index as i;"
             class="thumbnail-row"
             fxFlex="1 1 200px"
             (click)="setCurrentIndex(i)"
             [attr.id]="thumbnail.id"
             (mouseenter)="setHoveredFile(i)"
             (mouseleave)="setHoveredFile()"
             [class.mat-elevation-z9]="isFileHovered(i)"
             [class.mat-elevation-z12]="isFileSelected(i)"
             [ngClass]="{'dark-thumb': !isFileSelected(i)}">
          <mat-icon class="pdf-icon"
                    *ngIf="isPdf(thumbnail)">picture_as_pdf</mat-icon>
          <img class="thumbnail-file nonDraggableFile"
               *ngIf="isImage(thumbnail)"
               draggable="false"
               (dragstart)="false;"
               [src]="thumbnail.thumbnail_url || thumbnail.url">
          <mat-icon class="pdf-icon"
                    *ngIf="!isPdf(thumbnail) && !isImage(thumbnail)">insert_drive_file</mat-icon>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <perfect-scrollbar></perfect-scrollbar>
  </section>
  <section class="gallery-toolbar-min"
           *ngIf="multiFiles"
           fxHide
           fxShow.xs>
    <perfect-scrollbar class="perfect-scroll-min"
                       #scrollElementMin
                       [config]="config">
      <div #scrollContentMin
           fxLayout="row">
        <div class="dot"
             fxFlex="1 1 24px"
             *ngFor="let dot of fileList; let i = index;"
             (click)="setCurrentIndex(i);">
          <span [ngClass]="{'active':isFileSelected(i)}"
                [attr.id]="dot.id"></span>
        </div>
      </div>
    </perfect-scrollbar>
  </section>
</section>