import { CitadelStock } from '@sparte/citadel-core';
import { Deserializable } from '@sparte/utils';

import { StockInventoryService } from '../services/stock-inventory.service';
import { StockBusiness } from './stockBusiness.model';
import { StockStorageEntity } from './stockStorageEntity.model';

export class NetworksByBusiness implements Deserializable {
  public business_ref: string;
  public network_ids: string[];
  searchTerm: string;
  label: string;
  typeName: string = "NetworksByBusiness";
  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    if (!this.network_ids) this.network_ids = [];
    return this;
  }

  get updateValue(): any {
    return {
      business_ref: this.business_ref,
      network_ids: this.network_ids
    }
  }

  get isValid(): boolean {
    return !!this.business_ref && this.network_ids.length > 0;
  }
}

export class StockLogisticFlow implements Deserializable {
  public id: string;
  public name: string;
  public targets: NetworksByBusiness[];
  public hpriority: boolean;
  public destination_id: string;
  public paused: boolean;
  private created_at: Date;
  private updated_at: Date;
  readonly typeName = "StockLogisticFlow";
  public x: number;
  public y: number;
  public defaultFlow: boolean;
  public shipmentFlow: boolean;
  public pickupFlow: boolean;
  public awaitingUpdate: boolean;
  public previousDestId: string;
  private snapshot: string;
  constructor(private stockInventoryService: StockInventoryService) { }

  deserialize(input: any): this {
    const { __typename, flow_id, targets, created_at, updated_at, businesses_ids, networks_ids, ..._input } = input;
    Object.assign(this, _input);
    this.id = flow_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.targets = (targets || []).map(target => new NetworksByBusiness().deserialize(target));
    this.defaultFlow = (this.id === 'default');
    this.shipmentFlow = (this.id === 'shipment');
    this.pickupFlow = (this.id === 'pickup');
    this.previousDestId = this.destination_id;
    this.awaitingUpdate = false;
    this.snapshotObject();
    return this;
  }

  private snapshotObject() {
    this.snapshot = JSON.stringify(this.snapshotValue);
  }

  applySnapshot() {
    if (this.id ? !this.id.startsWith('temp') : false) {
      this.deserialize({ flow_id: this.id, ...JSON.parse(this.snapshot) });
    }
  }

  get label(): string {
    if (this.defaultFlow) return 'DEFAUT';
    if (this.shipmentFlow) return 'EXPEDITION';
    if (this.pickupFlow) return 'PICKUP';
    return this.name ?? `${this.targets.length} AFFAIRE${this.targets.length > 1 ? 'S' : ''}`;
  }

  get fullLabel(): string {
    if (this.defaultFlow) return 'DEFAUT';
    if (this.shipmentFlow) return 'EXPEDITION';
    if (this.pickupFlow) return 'PICKUP';
    return this.businessDes;
  }

  get searchTerm(): string {
    return "";
  }

  get snapshotValue() {
    const {
      id,
      typeName,
      targets,
      snapshot,
      stockInventoryService,
      ..._this
    } = this;
    return {
      ..._this,
      targets: targets.map(target => target.updateValue)
    };
  }

  get updateValue() {
    const {
      id,
      typeName,
      paused,
      created_at,
      updated_at,
      targets,
      awaitingUpdate,
      previousDestId,
      defaultFlow,
      shipmentFlow,
      pickupFlow,
      snapshot,
      x,
      y,
      stockInventoryService,
      ..._this
    } = this;
    return {
      ..._this,
      targets: targets.map(target => target.updateValue)
    };
  }

  get businesses(): StockBusiness[] {
    return this.targets.map(target => this.stockInventoryService.getStockBusiness(target.business_ref)).filter(biz => !!biz);
  }

  get businessDes(): string {
    return this.businesses.length > 1 ? `${this.businesses[0].label} (+${this.businesses.length - 1})` : this.businesses.map(business => business.label).join("");
  }

  get destination(): CitadelStock {
    return this.stockInventoryService.getCitadelStock(this.destination_id);
  }

  get entities(): StockStorageEntity[] {
    if (this.shipmentFlow) {
      //TODO: exclude pickup flow
      return this.stockInventoryService.getStockStorageEntities.filter(entity => !!entity.shipment_ref);
    }
    if (this.pickupFlow) {
      return;
    }
    if (this.defaultFlow) {
      return this.stockInventoryService.getStockStorageEntities.reduce((acc, entity) => {
        if (!entity.shipment_ref && !this.stockInventoryService.getStockLogisticFlows.some(flow =>
          flow.targets.some(target => target.business_ref === entity.business_ref && (target.network_ids.includes('any') || target.network_ids.includes(entity.network_id))))
        ) acc.push(entity);
        return acc;
      }, []);
    }
    return this.stockInventoryService.getStockStorageEntities.filter(entity => this.targets.some(target => target.business_ref === entity.business_ref && (target.network_ids.includes('any') || target.network_ids.includes(entity.network_id))));
  }

  get isValid(): boolean {
    return !!this.destination_id && this.defaultFlow || this.shipmentFlow || this.pickupFlow || (this.targets.length > 0 && this.targets.every(target => target.network_ids.length > 0));
  }
}