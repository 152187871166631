import { ComponentContainer } from './component-container';
import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormInfoDirective } from './form-info.directive';

@Component({
  selector: 'sparte-update-form-container',
  templateUrl: './update-form-container.component.html',
  styleUrls: ['./update-form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateFormContainerComponent implements AfterViewInit {
  public updateModel: any;
  public formTitle: string;
  public createModel: boolean;
  public canDelete: boolean;
  public saveCondition: Function;
  public cancelFunction?: Function;
  public closeFunction?: Function;
  @ViewChild(FormInfoDirective, { static: true }) sparteFormInfo!: FormInfoDirective;

  private compContainer: ComponentContainer

  constructor(
    public dialogRef: MatDialogRef<UpdateFormContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public componentContainer: ComponentContainer,
    private cdRef: ChangeDetectorRef
  ) {
    if (componentContainer) {
      this.compContainer = componentContainer;
      this.updateModel = componentContainer.data.updateModel;
      this.formTitle = componentContainer.data.formTitle;
      this.createModel = componentContainer.data.createModel;
      this.canDelete = componentContainer.data.canDelete;
      this.saveCondition = componentContainer.data.saveCondition;
      if (componentContainer.data.cancelFunction) {
        this.cancelFunction = componentContainer.data.cancelFunction;
      }
      if (componentContainer.data.closeFunction) {
        this.closeFunction = componentContainer.data.closeFunction;
      }
    }
  }

  ngAfterViewInit(): void {
    const viewContainerRef = this.sparteFormInfo.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(this.compContainer.component);
    componentRef.instance.data = {
      updateModel: this.updateModel,
      createModel: this.createModel
    };
    this.cdRef.detectChanges();
  }

  closeDialog() {
    if (this.closeFunction) {
      this.closeFunction(() => {
        this.dialogRef.close(this.updateModel);
      });
    }
    else {
      this.dialogRef.close(this.updateModel);
    }
  }

  cancelDialog() {
    if (this.cancelFunction) {
      this.cancelFunction();
    }
    this.dialogRef.close();
  }

  deleteDialog() {
    this.dialogRef.close({
      deleteObject: true
    });
  }
}
