import { gql } from 'apollo-angular';

import { ASSET_MODEL_INFO_FRAGMENT } from './fragments/_assetModel';

export const ASSET_MODELS_QUERY = gql`
  query assetModels {
    assetModels
  }
`;

export const CREATE_ASSET_MODEL_MUTATION = gql`
  mutation createAssetModel($data: AssetModelCreateInput!) {
    createAssetModel(data: $data) {
      ...AssetModelInfo
    }
  }
  ${ASSET_MODEL_INFO_FRAGMENT}
`;

export const UPDATE_ASSET_MODEL_MUTATION = gql`
  mutation updateAssetModel(
    $where: AssetModelWhereUniqueInput!
    $data: AssetModelUpdateInput!
  ) {
    updateAssetModel(where: $where, data: $data) {
      ...AssetModelInfo
    }
  }
  ${ASSET_MODEL_INFO_FRAGMENT}
`;

export const DELETE_ASSET_MODEL_MUTATION = gql`
  mutation deleteAssetModel($where: AssetModelWhereUniqueInput!) {
    deleteAssetModel(where: $where) {
      model_id
    }
  }
`;

export const ASSET_MODEL_SUBSCRIPTION = gql`
  subscription assetModelSubscription {
    assetModelSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
