import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BinBarcodeData, PrintFormatEnum } from '../services/data-print.service';

@Component({
  selector: 'sparte-print-storage-barcodes',
  templateUrl: './print-storage-barcodes.component.html',
  styleUrls: ['./print-storage-barcodes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintStorageBarcodesComponent implements OnInit {
  @Input() binBarcodes: BinBarcodeData[];
  @Input() printFormat: PrintFormatEnum;

  constructor() {
  }

  ngOnInit(): void { }

  getColor(): string {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
}
