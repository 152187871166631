import { gql } from 'apollo-angular';

import { STOCK_BUSINESS_INFO_FRAGMENT } from './fragments/_stockBusiness';

export const STOCK_BUSINESSES_QUERY = gql`
  query stockBusinesses {
    stockBusinesses
  }
`;

export const CREATE_STOCK_BUSINESS_MUTATION = gql`
  mutation createStockBusiness($data: StockBusinessCreateInput!) {
    createStockBusiness(data: $data) {
      ...StockBusinessInfo
    }
  }
  ${STOCK_BUSINESS_INFO_FRAGMENT}
`;

export const UPDATE_STOCK_BUSINESS_MUTATION = gql`
  mutation updateStockBusiness(
    $where: StockBusinessWhereUniqueInput!
    $data: StockBusinessUpdateInput!
  ) {
    updateStockBusiness(where: $where, data: $data) {
      ...StockBusinessInfo
    }
  }
  ${STOCK_BUSINESS_INFO_FRAGMENT}
`;
export const DELETE_STOCK_BUSINESS_MUTATION = gql`
  mutation deleteStockBusiness(
    $where: StockBusinessWhereUniqueInput!
  ) {
    deleteStockBusiness(where: $where) {
      reference
    }
  }
`;

export const STOCK_BUSINESS_SUBSCRIPTION = gql`
  subscription stockBusinessSubscription {
    stockBusinessSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;