import { gql } from 'apollo-angular';
import { STOCK_REQUEST_INFO_FRAGMENT } from './fragments/_stockRequest';

export const STOCK_REQUESTS_QUERY = gql`
  query stockRequests {
    stockRequests
  }
`;

export const CREATE_STOCK_REQUEST_MUTATION = gql`
  mutation createStockRequest($data: StockRequestCreateInput!) {
    createStockRequest(data: $data) {
      ...StockRequestInfo
    }
  }
  ${STOCK_REQUEST_INFO_FRAGMENT}
`;

export const UPDATE_STOCK_REQUEST_MUTATION = gql`
  mutation updateStockRequest(
    $where: StockRequestWhereUniqueInput!
    $data: StockRequestUpdateInput!
  ) {
    updateStockRequest(where: $where, data: $data) {
      ...StockRequestInfo
    }
  }
  ${STOCK_REQUEST_INFO_FRAGMENT}
`;

export const CANCEL_REQUEST_MUTATION = gql`
  mutation cancelStockRequest($where: StockRequestWhereUniqueInput!) {
    cancelStockRequest(where: $where) {
      request_id
    }
  }
`;

export const DELETE_STOCK_REQUEST_MUTATION = gql`
  mutation deleteStockRequest($where: StockRequestWhereUniqueInput!) {
    deleteStockRequest(where: $where) {
      request_id
    }
  }
`;

export const UPDATE_PURCHASE_ORDER_MUTATION = gql`
  mutation updatePurchaseOrder(
    $where: StockRequestWhereUniqueInput!
    $data: PurchaseOrderUpdateInput!
  ) {
    updatePurchaseOrder(where: $where, data: $data) {
      ...StockRequestInfo
    }
  }
  ${STOCK_REQUEST_INFO_FRAGMENT}
`;

export const ARCHIVE_PURCHASE_ORDER_MUTATION = gql`
  mutation archivePurchaseOrder(
    $where: PurchaseOrderWhereUniqueInput!
  ) {
    archivePurchaseOrder(where: $where) {
      request_id
    }
  }
`;

export const STOCK_REQUEST_SUBSCRIPTION = gql`
  subscription stockRequestSubscription {
    stockRequestSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;