import { gql } from 'apollo-angular';

export const USER_NODE_INFO_FRAGMENT = gql`
  fragment UserNodeInfo on UserNode {
    node_id
    user_id
    group_id
    x
    y
  }
`;
