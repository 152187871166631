import { gql } from 'apollo-angular';

import { ASSET_ZONE_INFO_FRAGMENT } from './fragments/_assetZone';

export const ASSET_ZONES_QUERY = gql`
  query assetZones {
    assetZones
  }
`;

export const CREATE_ASSET_ZONE_MUTATION = gql`
  mutation createAssetZone($data: AssetZoneCreateInput!) {
    createAssetZone(data: $data) {
      ...AssetZoneInfo
    }
  }
  ${ASSET_ZONE_INFO_FRAGMENT}
`;

export const UPDATE_ASSET_ZONE_MUTATION = gql`
  mutation updateAssetZone(
    $where: AssetZoneWhereUniqueInput!
    $data: AssetZoneUpdateInput!
  ) {
    updateAssetZone(where: $where, data: $data) {
      ...AssetZoneInfo
    }
  }
  ${ASSET_ZONE_INFO_FRAGMENT}
`;

export const DELETE_ASSET_ZONE_MUTATION = gql`
  mutation deleteAssetZone($where: AssetZoneWhereUniqueInput!) {
    deleteAssetZone(where: $where) {
      zone_id
    }
  }
`;

export const ASSET_ZONE_SUBSCRIPTION = gql`
  subscription assetZoneSubscription {
    assetZoneSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
