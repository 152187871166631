import { gql } from 'apollo-angular';
import { ENTITY_ARTICLE_INFO_FRAGMENT } from './fragments/_article';

import { STOCK_STORAGE_ENTITY_INFO_FRAGMENT } from './fragments/_stockStorageEntity';

export const STOCK_STORAGE_ENTITIES_QUERY = gql`
  query stockStorageEntities {
    stockStorageEntities
  }
`;

export const ENTITES_LAST_HISTORY_QUERY = gql`
  query entitiesLastHistory {
    entitiesLastHistory
  }
`;

export const ENTITY_HISTORY_QUERY = gql`
  query getEntityHistory($where: StockStorageEntityWhereUniqueInput!) {
    getEntityHistory(where: $where)
  }
`;

export const CREATE_STOCK_STORAGE_ENTITY_FROM_RECEPTION_MUTATION = gql`
  mutation createStockStorageEntityFromReception($data: StockStorageEntityFromReceptionCreateInput!) {
    createStockStorageEntityFromReception(data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const CREATE_STOCK_STORAGE_ENTITY_WITH_BARCODE_MUTATION = gql`
  mutation createStockStorageEntityWithBarcode($data: StockStorageEntityWithBarcodeCreateInput!) {
    createStockStorageEntityWithBarcode(data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const LOCK_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation lockStockStorageEntity(
    $where: StockStorageEntityWhereUniqueInput!
  ) {
    lockStockStorageEntity(where: $where) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const RECEIVE_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation receiveStockStorageEntity(
    $where: StockStorageEntityWhereUniqueInput!
    $data: ReceiveEntityInput
  ) {
    receiveStockStorageEntity(where: $where, data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const TRANSFER_ENTITY_TO_ENTITY_MUTATION = gql`
  mutation transferEntityToEntity(
    $where: StockStorageEntityWhereUniqueInput!
    $data: TransferToEntityInput!
  ) {
    transferEntityToEntity(where: $where, data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const TRANSFER_ENTITY_TO_STORAGE_MUTATION = gql`
  mutation transferEntityToStorage(
    $where: StockStorageEntityWhereUniqueInput!
    $data: TransferToStorageInput!
  ) {
    transferEntityToStorage(where: $where, data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const PACKAGE_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation packageStockStorageEntity(
    $where: StockStorageEntityWhereUniqueInput!
    $data: PackageEntityInput!
  ) {
    packageStockStorageEntity(where: $where, data: $data) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const UNPACK_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation unpackStockStorageEntity($where: StockStorageEntityWhereUniqueInput!) {
    unpackStockStorageEntity(where: $where) {
      ...StockStorageEntityInfo
    }
  }
  ${STOCK_STORAGE_ENTITY_INFO_FRAGMENT}
`;

export const TRANSFER_ARTICLES_IN_ENTITY_MUTATION = gql`
  mutation transferArticlesInEntity(
    $where: StockStorageEntityWhereUniqueInput!
    $data: TransferToEntityInput!
  ) {
    transferArticlesInEntity(where: $where, data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const TRANSFER_ARTICLE_TO_ENTITY_MUTATION = gql`
  mutation transferArticleToEntity(
    $where: StockStorageEntityWhereUniqueInput!
    $data: EntityArticleInput!
  ) {
    transferArticleToEntity(where: $where, data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const DELETE_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation deleteStockStorageEntity($where: StockStorageEntityWhereUniqueInput!) {
    deleteStockStorageEntity(where: $where) {
      entity_id
    }
  }
`;

export const STOCK_STORAGE_ENTITY_SUBSCRIPTION = gql`
  subscription stockStorageEntitySubscription {
    stockStorageEntitySubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const STOCK_STORAGE_ENTITY_HISTORY_SUBSCRIPTION = gql`
  subscription stockStorageEntityHistorySubscription {
    stockStorageEntityHistorySubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
