import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, DropdownOptions, initFlowbite } from 'flowbite';
import { when } from 'mobx';

/**
 * @input value: string;
 * @input viewValue: string;
*/
export type SparteFormDropdownItem = {
  value: string,
  viewValue: string,
  selected?: boolean,
}

@Component({
  selector: 'sparte-form-dropdown',
  templateUrl: './sparte-form-dropdown.component.html',
  styleUrls: ['./sparte-form-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparteFormDropdownComponent implements AfterViewInit {
  public _id: string = uuidv4();
  @ViewChild('dropdownButton') public dropdownButton;
  @ViewChild('dropdownMenu') public dropdownMenu;

  /**
   * @description
   * @default []
   * @example
   * <sparte-form-dropdown [items]="items">
   * </sparte-form-dropdown>
   * @input items: SparteFormDropdownItemList[] = [];
   */
  @Input() items: SparteFormDropdownItem[] = [];

  @Input() placeholder: string = 'Sélectionner une valeur';

  /**
   * @description return an array of selected items values
   * allow multiple selection of items in the dropdown
   * @default false
   * @example
   * <sparte-form-dropdown multiSelect>
   * </sparte-form-dropdown>
   * @input multiSelect: boolean = false;
   */
  @Input() set multiSelect(param) { this._multiSelect = true; }

  /**
   * @description
   * @default 'bottom'
   * @example
   * <sparte-form-dropdown [placement]="'bottom'">
   * </sparte-form-dropdown>
   * @input placement: "top" | "right" | "bottom" | "left" = 'bottom';
   * @see https://flowbite.com/docs/components/dropdowns/#placement
   */
  @Input() placement: "top" | "right" | "bottom" | "left" = 'bottom';

  /**
   * @description custom width of the dropdown
   * @default 'w-[22rem]'
   * @example
   * <sparte-form-dropdown [width]="'w-[22rem]'">
   * </sparte-form-dropdown>
   * @input width: string = 'w-[22rem]';
   * @see https://tailwindcss.com/docs/width
   */
  @Input() width: string = 'w-[22rem]';

  @Output() selectedItems: EventEmitter<string[] | string> = new EventEmitter<string[] | string>();

  public _multiSelect = false;
  public dropdown: Dropdown;
  public selectedItemsList: string[] = [];
  public _placeholder: string

  constructor(
    private cdref: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
    initFlowbite();
    const $triggerEl = this.dropdownButton;
    const $targetEl = this.dropdownMenu;
    const options: DropdownOptions = {
      placement: this.placement,
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 10,
      delay: 300,
    }

    this.dropdown = new Dropdown($targetEl.nativeElement, $triggerEl.nativeElement, options);
    this.cdref.detectChanges();

    this._placeholder = this.placeholder;

    when(() => this.items.length > 0, () => {
      this.selectedItemsList = this.items.filter((item) => item.selected).map((item) => item.value);
      this._placeholder = this.selectedItemsList.length > 0 ? `${this.selectedItemsList.length} élément(s) sélectionné(s)` : this.placeholder;
      this.cdref.detectChanges();
    })

  }

  handleSelect(item: SparteFormDropdownItem) {
    if (this._multiSelect) {
      if (this.selectedItemsList.includes(item.value)) this.selectedItemsList = this.selectedItemsList.filter((value) => value !== item.value);
      else this.selectedItemsList.push(item.value);
      this._placeholder = this.selectedItemsList.length > 0 ? `${this.selectedItemsList.length} élément(s) sélectionné(s)` : this.placeholder;
      this.selectedItems.emit(this.selectedItemsList);
    } else {
      this._placeholder = item.viewValue;
      this.selectedItems.emit(item.value);
      this.dropdown?.hide();
    }

  }

  handleClick(item: SparteFormDropdownItem) {
    if (this._multiSelect) return;
    this.handleSelect(item);
  }

  trackByFn(index: number, item: any): number {
    return index;
  }
}
