import { gql } from 'apollo-angular';

export const CITADEL_SYSTEM_INFO_FRAGMENT = gql`
  fragment CitadelSystemInfo on CitadelSystem {
    system_id
    infrastructure_id
    environment_id
    system_model
    parent_system_id
    name
    created_at
    updated_at
  }
`;
