import { gql } from 'apollo-angular';

import { CARTO_PROCESS_INFO_FRAGMENT } from './fragments/_cartoProcess';

export const CARTO_PROCESSES_QUERY = gql`
  query cartoProcesses {
    processes
  }
`;

export const CARTO_PROCESS_SUBSCRIPTION = gql`
  subscription cartoProcessSubscription {
    processSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_CARTO_PROCESS_MUTATION = gql`
  mutation createCartoProcess($data: ProcessCreateInput!) {
    createProcess(data: $data) {
      ...CartoProcessInfo
    }
  }
  ${CARTO_PROCESS_INFO_FRAGMENT}
`;

export const UPDATE_CARTO_PROCESS_MUTATION = gql`
  mutation updateCartoProcess(
    $where: ProcessWhereUniqueInput!
    $data: ProcessUpdateInput!
  ) {
    updateProcess(where: $where, data: $data) {
      ...CartoProcessInfo
    }
  }
  ${CARTO_PROCESS_INFO_FRAGMENT}
`;

export const DELETE_CARTO_PROCESS_MUTATION = gql`
  mutation deleteCartoProcess(
    $where: ProcessWhereUniqueInput!
  ) {
    deleteProcess(where: $where) {
      process_id
    }
  }
`;