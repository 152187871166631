import { gql } from 'apollo-angular';

import { USER_GROUP_INFO_FRAGMENT } from './fragments/_userGroup';

export const USER_GROUPS_QUERY = gql`
  query userGroups {
    userGroups
  }
`;

export const CREATE_USER_GROUP_MUTATION = gql`
  mutation createUserGroup($data: UserGroupCreateInput!) {
    createUserGroup(data: $data) {
      ...UserGroupInfo
    }
  }
  ${USER_GROUP_INFO_FRAGMENT}
`;

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation updateUserGroup(
    $where: UserGroupWhereUniqueInput!
    $data: UserGroupUpdateInput!
  ) {
    updateUserGroup(where: $where, data: $data) {
      ...UserGroupInfo
    }
  }
  ${USER_GROUP_INFO_FRAGMENT}
`;

export const DELETE_USER_GROUP_MUTATION = gql`
  mutation deleteUserGroup($where: UserGroupWhereUniqueInput!) {
    deleteUserGroup(where: $where) {
      group_id
    }
  }
`;

export const USER_GROUP_SUBSCRIPTION = gql`
  subscription userGroupSubscription {
    userGroupSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;