import { gql } from "apollo-angular";

export const RESOURCE_CATALOG_INFO_FRAGMENT = gql`
  fragment ResourceCatalogInfo on ResourceCatalog {
    catalog_id
    library_id
    designation
    description
    created_at
    updated_at
  }
`;