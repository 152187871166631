import { StockInventoryService } from '../services/stock-inventory.service';
import { quantityPrettyfier } from '@sparte/utils';
import { StockBusiness } from './stockBusiness.model';
import { StockNetwork } from './stockNetwork.model';
import { StockStorageEntity } from './stockStorageEntity.model';
import { ArticleInstance } from './articleInstance.model';

export class OrderArticle extends ArticleInstance {
  public readonly typeName = "StockOrderArticle";
  public da_date: Date;
  public order_date: Date;
  public delivery_date: Date;
  public quantitySet: number;
  constructor(stockInventoryService: StockInventoryService) {
    super(stockInventoryService);
  }

  deserialize(input: any): this {
    const { __typename, da_date, order_date, delivery_date, original_value, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = this.article_id;
    this.original_value = Number(original_value?.toFixed(2));
    if (da_date) this.da_date = new Date(da_date);
    if (order_date) this.order_date = new Date(order_date);
    if (delivery_date) this.delivery_date = new Date(delivery_date);
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.quantitySet = this.quantity;
    return this;
  }

  get updateValue() {
    return;
  }

  get snapshotValue() {
    const {
      id,
      stockInventoryService,
      typeName,
      ..._this
    } = this;
    return {
      article_id: id,
      ..._this
    }
  }

  get selectValue() {
    return {
      article_id: this.id,
      order_id: this.order_id,
      quantity: this.quantitySet
    }
  }

  get receptionValue() {
    return {
      article_id: this.article_id,
      order_id: this.order_id,
      shipment_ref: this.shipment_ref,
      quantity: this.quantitySet
    }
  }

  get label(): string {
    return this.designation;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.post} ${this.orderRef} ${this.business_ref} ${this.businessDes} ${this.networkDes} ${this.reference} ${this.shipment_ref}`.toLowerCase();
  }

  get entities(): StockStorageEntity[] {
    return this.stockInventoryService.getAllStockStorageEntities.filter(entity => entity.articles.some(article => article.article_id === this.id));
  }

  get orderRef(): string {
    return this.stockInventoryService.getStockOrder(this.order_id)?.reference;
  }

  get order_ref(): string {
    return this.orderRef;
  }

  get storageLabel(): string {
    return "";
  }

  get reference(): string {
    return this.resource?.reference;
  }

  get prettyRemainingQuantity(): string {
    return quantityPrettyfier(this.quantity);
  }

  get prettyQuantity(): string {
    return this.prettyRemainingQuantity;
  }

  get remainingValue(): number {
    return this.original_value / (this.original_quantity || 1) * this.quantity;
  }

  get business(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_ref);
  }

  get network(): StockNetwork {
    return this.stockInventoryService.getStockNetwork(this.network_id);
  }

  get businessDes(): string {
    return this.business ? this.business.designation : "NA";
  }

  get networkDes(): string {
    return this.network ? this.network.designation : "NA";
  }

  //XLSX REPORT VARIABLES
  get prettyReportQuantity(): string {
    return this.prettyRemainingQuantity;
  }

  get prettyReportOriginalQuantity(): string {
    return this.prettyQuantity;
  }

  get hasUpdatedQuantity(): boolean {
    return this.quantitySet !== this.quantity;
  }
}