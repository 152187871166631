import { gql } from 'apollo-angular';

export const ACCOUNTING_ENTRY_INFO_FRAGMENT = gql`
  fragment AccountingEntryInfo on AccountingEntry {
    entry_id
    entry_ref
    request_id
    business_from
    business_to
    status
    articles {
      resource_id
      instance_id
      quantity
      designation
      original_value
      original_quantity
    }
    created_at
    updated_at
  }
`;
