import { gql } from 'apollo-angular';

import { CITADEL_ITEM_INFO_FRAGMENT } from './fragments/_citadelItem';

export const CITADEL_ITEMS_QUERY = gql`
  query citadelItems {
    citadelItems
  }
`;

export const CREATE_CITADEL_ITEM_MUTATION = gql`
  mutation createCitadelItem($data: CitadelItemCreateInput!) {
    createCitadelItem(data: $data) {
      ...CitadelItemInfo
    }
  }
  ${CITADEL_ITEM_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_ITEM_MUTATION = gql`
  mutation updateCitadelItem(
    $where: CitadelItemWhereUniqueInput!
    $data: CitadelItemUpdateInput!
  ) {
    updateCitadelItem(where: $where, data: $data) {
      ...CitadelItemInfo
    }
  }
  ${CITADEL_ITEM_INFO_FRAGMENT}
`;

export const DELETE_CITADEL_ITEM_MUTATION = gql`
  mutation deleteCitadelItem($where: CitadelItemWhereUniqueInput!) {
    deleteCitadelItem(where: $where) {
      asset_id
    }
  }
`;

export const CITADEL_ITEM_SUBSCRIPTION = gql`
  subscription citadelItemSubscription {
    citadelItemSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
