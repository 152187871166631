import { gql } from 'apollo-angular';
import { RESOURCE_INFO_FRAGMENT } from './fragments/_resource';

export const RESOURCES_QUERY = gql`
  query resources {
    resources
  }
`;

export const CREATE_RESOURCE_MUTATION = gql`
  mutation createResource($data: ResourceCreateInput!) {
    createResource(data: $data) {
      ...ResourceInfo
    }
  }
  ${RESOURCE_INFO_FRAGMENT}
`;

export const UPDATE_RESOURCE_MUTATION = gql`
  mutation updateResource(
    $where: ResourceWhereUniqueInput!
    $data: ResourceUpdateInput!
  ) {
    updateResource(where: $where, data: $data) {
      ...ResourceInfo
    }
  }
  ${RESOURCE_INFO_FRAGMENT}
`;

export const CHANGE_RESOURCES_CATALOG_MUTATION = gql`
  mutation changeResourcesCatalog(
    $where: ResourceWhereInput!
    $data: ResourceCatalogWhereUniqueInput!
  ) {
    changeResourcesCatalog(where: $where, data: $data) {
      ...ResourceInfo
    }
  }
  ${RESOURCE_INFO_FRAGMENT}
`;

export const DELETE_RESOURCE_MUTATION = gql`
  mutation deleteResource($where: ResourceWhereUniqueInput!) {
    deleteResource(where: $where) {
      resource_id
    }
  }
`;

export const RESOURCE_SUBSCRIPTION = gql`
  subscription resourceSubscription {
    resourceSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
