<button [id]="id"
        #dropdownDefaultButton
        (data-dropdown-toggle)="getDropdownID()"
        class="focus:outline-none font-medium rounded-full text-base tracking-widest px-4 py-2.5 text-center inline-flex items-center min-w-fit gap-2 h-14"
        [ngClass]="customClasses"
        type="button">
  <mat-icon *ngIf="prefixIcon"> {{prefixIcon}} </mat-icon>
  <span class="uppercase">{{title}}</span>
  <mat-icon *ngIf="!allowClear && showChevron"> expand_more </mat-icon>
  <mat-icon *ngIf="allowClear"
            (click)="clearSelection($event)"> close </mat-icon>
</button>
<!-- Dropdown menu -->
<div [id]="id + '-dropdown'"
     #dropdown
     class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-md border border-[#dbe0e7] w-fit ">
  <ul class="py-2 text-base text-gray-700 overflow-hidden"
      aria-labelledby="dropdownDefaultButton">
    <perfect-scrollbar class="max-h-80">
      <li *ngFor="let item of items; trackBy: trackByFn"
          (click)="onItemSelect(item)"
          class="hover:bg-[#dbe0e7] text-sparteBlue flex font-normal items-center px-4 py-2 cursor-pointer">
        <span>{{item.label}}</span>
      </li>
    </perfect-scrollbar>
  </ul>
</div>