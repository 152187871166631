import { gql } from 'apollo-angular';
import { EVENT_ACTION_INFO_FRAGMENT } from './fragments/_eventAction';

export const EVENT_ACTIONS_BY_POSTS_QUERY = gql`
  query eventActionsByPosts($where: PostWhereInput!) {
    eventActionsByPosts(where: $where)
  }
`;

export const CREATE_EVENT_ACTION_MUTATION = gql`
  mutation createEventAction($data: EventActionCreateInput!) {
    createEventAction(data: $data) {
      ...EventActionInfo
    }
  }
  ${EVENT_ACTION_INFO_FRAGMENT}
`;

export const UPDATE_EVENT_ACTION_MUTATION = gql`
  mutation updateEventAction(
    $where: EventActionWhereUniqueInput!
    $data: EventActionUpdateInput!
  ) {
    updateEventAction(where: $where, data: $data) {
      ...EventActionInfo
    }
  }
  ${EVENT_ACTION_INFO_FRAGMENT}
`;

export const DELETE_EVENT_ACTION_MUTATION = gql`
  mutation deleteEventAction($where: EventActionWhereUniqueInput!) {
    deleteEventAction(where: $where) {
      time_id
    }
  }
`;

export const EVENT_ACTION_SUBSCRIPTION = gql`
  subscription eventActionSubscription($where: PostWhereInput!) {
    eventActionSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;
