import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrentRouteService } from '../services/current-route.service';

@Component({
  selector: 'sparte-current-route',
  templateUrl: './current-route.component.html',
  styleUrls: ['./current-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentRouteComponent implements OnInit, OnDestroy {
  private routeChangeSub: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private currentRouteService: CurrentRouteService
  ) { }

  ngOnInit(): void {

    this.routeChangeSub = this.currentRouteService.routeChange.subscribe(route => {
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.routeChangeSub?.unsubscribe();
  }

  get currentRoute() {
    return this.currentRouteService.currentRouteName;
  }

  get icon(): string {
    return this.currentRouteService.icon;
  }

  get outlined(): boolean {
    return this.currentRouteService.outlined;
  }
}
