
import { gql } from 'apollo-angular';

export const CARTO_PROCESS_INFO_FRAGMENT = gql`
  fragment CartoProcessInfo on Process {
    process_id
    label
    inputs{
      id
      label
      type
    }
    outputs{
      id
      label
      type
    }
    location
    duration
  }
`;
