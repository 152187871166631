import { Component, Input } from '@angular/core';
import { CitadelUser } from '@sparte/citadel-core';
import { ShipmentData } from '@sparte/ui';
import { StockUsersService } from '../../services/stock-users.service';
@Component({
  selector: 'sparte-print-shipment-slip',
  templateUrl: './print-shipment-slip.component.html',
  styleUrls: ['./print-shipment-slip.component.scss'],
})
export class PrintShipmentSlipComponent {
  public today: string = new Date().toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' });
  @Input() shipmentData: ShipmentData;
  constructor(private userService: StockUsersService) { }

  get userNameAndEmail() {
    const user: CitadelUser = this.userService.getCitadelUser(this.shipmentData.createdBy);
    if (!user) return '';
    return `${user.label} (${user.mail})`;
  }
}
