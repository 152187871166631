import { Deserializable } from '@sparte/utils';

export class RunnerStat implements Deserializable {
  public user_id: string;
  public stat_ts: Date;
  public operation_type: string;
  typeName = "RunnerStat";
  searchTerm: string;
  label: string;
  updateValue: any;

  deserialize(input: any): this {
    const { __typename, stat_ts, ..._input } = input;
    Object.assign(this, _input);
    if (stat_ts) this.stat_ts = new Date(stat_ts);
    return this;
  }
}