import { EventEmitter, Injectable, Output } from '@angular/core';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';

@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {
  @observable public currentRoute: string;
  @observable public currentRouteName: string;
  @observable public icon: string;
  @observable public outlined: boolean;
  routeChange: EventEmitter<string>;
  routeDataInitialized: EventEmitter<void>;

  constructor() {
    makeObservable(this);
    this.routeChange = new EventEmitter<string>();
    this.routeDataInitialized = new EventEmitter<void>();
  }

  @action setCurrentRoute(route: string, name: string, icon = 'space_dashboard', outlined = true) {
    this.currentRoute = route;
    this.currentRouteName = name;
    this.icon = icon;
    this.outlined = outlined;
    this.routeChange.emit(this.currentRoute);
  }
}
