import { Debounce } from 'lodash-decorators';
import { EventEmitter, Injectable } from '@angular/core';
import { CurrentRouteService, SparteModulesService } from '@sparte/ui';
import { SubscriptionEvent } from '@sparte/utils';
import { Point } from 'electron';
import { makeObservable } from 'mobx';
import { action, computed, observable } from 'mobx-angular';
import { Subscription } from 'rxjs';

import { AssetCategory } from '../models/assetCategory.model';
import { AssetModel } from '../models/assetModel.model';
import { AssetZone } from '../models/assetZone.model';
import { CitadelAsset } from '../models/citadelAsset.model';
import { CitadelInfrastructure } from '../models/citadelInfrastructure.model';
import { CitadelItem } from '../models/citadelItem.model';
import { CitadelStock } from '../models/citadelStock.model';
import { CitadelTask } from '../models/citadelTask.model';
import { ItemGroup } from '../models/itemGroup.model';
import { ParameterSet } from '../models/parameterSet.model';
import { Resource } from '../models/resource.model';
import { ResourceByStock, ResourceQuantity } from '../models/resourceByStock.model';
import { ResourceCatalog } from '../models/resourceCatalog.model';
import { StockNode } from '../models/stockNode.model';
import { CitadelStorage } from '../models/citadelStorage.model';
import { TaskEvent } from '../models/taskEvent.model';
import { CitadelCoreApiService } from './citadel-core-api.service';
import { ImportConfig, ResourceLibrary, ResourceLink } from '../models';

interface ContextChangeInterface {
  eventType: string;
  data: any;
}

@Injectable()
export abstract class CitadelCoreService {
  @observable public selectedInfrastructure: CitadelInfrastructure;
  @observable protected readyModules: string[] = [];
  @observable protected readyRoute: string;
  public subscriptionRefresh: EventEmitter<SubscriptionEvent>;
  protected citadelInfrastructures: Map<string, CitadelInfrastructure>;
  protected citadelItems: Map<string, CitadelItem>;
  protected citadelStocks: Map<string, CitadelStock>;
  protected assetZones: Map<string, AssetZone>;
  protected assetCategories: Map<string, AssetCategory>;
  protected assetModels: Map<string, AssetModel>;
  protected parameterSets: Map<string, ParameterSet>;
  protected citadelTasks: Map<string, CitadelTask>;
  protected stockNodes: Map<string, StockNode>;
  protected resourceCatalogs: Map<string, ResourceCatalog>;
  protected resourceLibraries: Map<string, ResourceLibrary>;
  protected resources: Map<string, Resource>;
  protected resourcesByReference: Map<string, Resource>;
  protected resourcesByStock: Map<string, ResourceByStock>;
  protected resourceQuantitiesByStock: Map<string, Map<string, ResourceQuantity>>;
  protected stockResourcesMap: Map<string, Set<string>>;
  protected citadelStorages: Map<string, CitadelStorage>;
  protected itemGroups: Map<string, ItemGroup>;
  protected importConfigsByDataset: Map<string, Map<string, ImportConfig>>;
  protected resourceLinks: Map<string, ResourceLink>;
  @observable public storeReady: boolean = false;
  public historyTime: Date;
  protected storeSubscriptions: Map<string, Subscription>;
  contextChange: EventEmitter<ContextChangeInterface>;
  protected citadelConnected: boolean = false;
  protected fetchedObjects: string[] = [];

  constructor(
    protected sparteModulesService: SparteModulesService,
    protected currentRouteService: CurrentRouteService,
    protected citadelCoreApi: CitadelCoreApiService
  ) {
    makeObservable(this);
    this.subscriptionRefresh = new EventEmitter();
    this.contextChange = new EventEmitter();
    this.citadelInfrastructures = new Map<string, CitadelInfrastructure>();
    this.citadelItems = new Map<string, CitadelItem>();
    this.citadelStocks = new Map<string, CitadelStock>();
    this.assetZones = new Map<string, AssetZone>();
    this.assetModels = new Map<string, AssetModel>();
    this.assetCategories = new Map<string, AssetCategory>();
    this.parameterSets = new Map<string, ParameterSet>();
    this.citadelTasks = new Map<string, CitadelTask>();
    this.stockNodes = new Map<string, StockNode>();
    this.resourceCatalogs = new Map<string, ResourceCatalog>();
    this.resourceLibraries = new Map<string, ResourceLibrary>();
    this.resources = new Map<string, Resource>();
    this.resourcesByReference = new Map<string, Resource>();
    this.resourcesByStock = new Map<string, ResourceByStock>();
    this.stockResourcesMap = new Map<string, Set<string>>();
    this.citadelStorages = new Map<string, CitadelStorage>();
    this.itemGroups = new Map<string, ItemGroup>();
    this.resourceQuantitiesByStock = new Map<string, Map<string, ResourceQuantity>>();
    this.importConfigsByDataset = new Map<string, Map<string, ImportConfig>>();
    this.resourceLinks = new Map<string, ResourceLink>();
    this.storeSubscriptions = new Map<string, Subscription>();

    this.sparteModulesService.moduleChangeEvent.subscribe(module => {
      if (!module) {
        this.readyModules = [];
        return;
      };
      if (this.readyModules.includes(module.name)) return;
      this.updateReadyModules(module.name);
    });
    this.currentRouteService.routeChange.subscribe(() => {
      this.setReadyRoute('');
      this.fetchCurrentRouteData();
    });
  }

  @action protected updateReadyModules(moduleName: string) {
    this.readyModules = [...this.readyModules, moduleName];
  }

  @action protected setReadyRoute(routeName: string) {
    this.readyRoute = routeName;
    this.currentRouteService.routeDataInitialized.emit();
  }

  @computed get currentRouteReady(): boolean {
    return this.readyRoute === this.currentRouteService.currentRoute;
  }

  @computed get fullRoute(): string {
    return `${this.sparteModulesService.currentModule?.link}/${this.currentRouteService.currentRoute}`;
  }

  moduleReady(module: string): boolean {
    return this.readyModules.includes(module);
  }

  routeReady(route: string): boolean {
    return this.readyRoute === route;
  }

  public refreshStoreData(): void {
    this.clearData();
    this.fetchCurrentRouteData();
  }

  protected fetchCurrentRouteData(): void {
    throw new Error('Method not implemented.');
  }

  protected clearData() {
    throw new Error('Method not implemented.');
  }

  @action setInfrastructure(infrastructure: CitadelInfrastructure) {
    this.selectedInfrastructure = infrastructure;
    this.contextChange.emit({
      eventType: 'infrastructure',
      data: infrastructure
    });
  }

  public get getCitadelAssets(): CitadelAsset[] {
    return [...this.getCitadelItems, ...this.getCitadelInfrastructures];
  }

  public getCitadelAsset(assetId: string): CitadelAsset {
    return this.citadelInfrastructures.get(assetId) ?? this.citadelItems.get(assetId);
  }

  public get getCitadelInfrastructures(): CitadelInfrastructure[] {
    return [...this.citadelInfrastructures.values()];
  }

  public getCitadelInfrastructure(infrastructureId: string): CitadelInfrastructure {
    return this.citadelInfrastructures.get(infrastructureId);
  }

  public get getCitadelItems(): CitadelItem[] {
    return [...this.citadelItems.values()];
  }

  public getCitadelItem(itemId: string): CitadelItem {
    return this.citadelItems.get(itemId);
  }

  public get getItemGroups(): ItemGroup[] {
    return [...this.itemGroups.values()];
  }

  public getItemGroup(groupId: string): ItemGroup {
    return this.itemGroups.get(groupId);
  }

  public get getCitadelStocks(): CitadelStock[] {
    return [...this.citadelStocks.values()];
  }

  public getCitadelStock(stockId: string): CitadelStock {
    return this.citadelStocks.get(stockId);
  }

  public get getAssetZones(): AssetZone[] {
    return [...this.assetZones.values()];
  }

  public getAssetZone(zoneId: string): AssetZone {
    return this.assetZones.get(zoneId);
  }

  public get getAssetCategories(): AssetCategory[] {
    return [...this.assetCategories.values()];
  }

  public getAssetCategory(categoryId: string): AssetCategory {
    return this.assetCategories.get(categoryId);
  }

  public get getAssetModels(): AssetModel[] {
    return [...this.assetModels.values()];
  }

  public getAssetModel(modelId: string): AssetModel {
    return this.assetModels.get(modelId);
  }

  public get getParameterSets(): ParameterSet[] {
    return [...this.parameterSets.values()];
  }

  public getParameterSet(parameterSetId: string): ParameterSet {
    return this.parameterSets.get(parameterSetId);
  }

  public get getCitadelStorages(): CitadelStorage[] {
    return [...this.citadelStorages.values()];
  }

  public getCitadelStorage(storageId: string): CitadelStorage {
    return this.citadelStorages.get(storageId);
  }

  public getCitadelStorageByBarcode(barcode: string): CitadelStorage {
    return [...this.citadelStorages.values()].find(storage => storage.barcode === barcode);
  }

  /**
   * returns all tasks
   */
  public get getCitadelTasks(): CitadelTask[] {
    return [...this.citadelTasks.values()];
  }

  /**
   * Get Task from tasks map
   * @param {string} taskId id of task to get
   * @returns {CitadelTask} task
   */
  public getCitadelTask(taskId: string): CitadelTask {
    return this.citadelTasks.get(taskId);
  }


  /**
   * returns all StockNodes
   */
  public get getStockNodes(): StockNode[] {
    return [...this.stockNodes.values()];
  }

  /**
   * Get StockNode from StockNodes map
   * @param {string} nodeId id of StockNode to get
   * @returns {StockNode} StockNode
   */
  public getStockNode(nodeId: string): StockNode {
    return this.stockNodes.get(nodeId);
  }

  public get getResourceCatalogs(): ResourceCatalog[] {
    return [...this.resourceCatalogs.values()];
  }

  public getResourceCatalog(catalogId: string): ResourceCatalog {
    return this.resourceCatalogs.get(catalogId);
  }

  public get getResourceLibraries(): ResourceLibrary[] {
    return [...this.resourceLibraries.values()];
  }

  public getResourceLibrary(libraryId: string): ResourceLibrary {
    return this.resourceLibraries.get(libraryId);
  }

  public get getResources(): Resource[] {
    return [...this.resources.values()];
  }

  public getResourcesByStock(stock_id: string): ResourceByStock[] {
    return [...(this.stockResourcesMap.get(stock_id) || [])].reduce((acc, resourceId) => {
      if (!this.resourcesByStock.has(resourceId)) return acc;
      acc.push(this.resourcesByStock.get(resourceId))
      return acc;
    }, []);
  }

  public getResourceQuantity(stock_id: string, resource_id: string): ResourceQuantity {
    return this.resourceQuantitiesByStock.get(stock_id)?.get(resource_id);
  }

  public getResourcesQuantitiesByStock(stock_id: string): ResourceQuantity[] {
    return [...(this.resourceQuantitiesByStock.get(stock_id)?.values() || [])];
  }

  public getResource(resource_id: string): Resource {
    return this.resources.get(resource_id);
  }

  public getResourceByReference(reference: string): Resource {
    return this.resourcesByReference.get(reference);
  }

  public getContextIds(taskId: string): string[] {
    const taskIds = this.getCitadelTasks.filter(task => task.parent_task_id === taskId).map(task => task.id);
    const stockIds = this.getStockNodes.filter(stockNode => stockNode.citadelStock ? stockNode.task_id === taskId : false).map(stockNode => stockNode.citadelStock.id);
    return [...taskIds, ...stockIds];
  }

  /**
   * returns all ResourceLinks
   */
  public get getResourceLinks(): ResourceLink[] {
    return [...this.resourceLinks.values()];
  }

  /**
   * Get ResourceLink from links map
   * @param {string} linkId id of link to get
   * @returns {ResourceLink} link
   */
  public getResourceLink(linkId: string): ResourceLink {
    return this.resourceLinks.get(linkId);
  }

  /**
   * add infrastructure to store
   * @param {any} citadelInfrastructure infrastructure to add
   * @param {function} callback function to call when it is done
   */
  protected addCitadelInfrastructure(citadelInfrastructure: any, callback?: Function) {
    if (!this.citadelInfrastructures.has(citadelInfrastructure.asset_id)) {
      this.citadelInfrastructures.set(citadelInfrastructure.asset_id, new CitadelInfrastructure(this).deserialize(citadelInfrastructure));
    }
    else {
      this.citadelInfrastructures.get(citadelInfrastructure.asset_id).deserialize(citadelInfrastructure);
    }
    if (callback) callback(this.citadelInfrastructures.get(citadelInfrastructure.asset_id));
  }

  /**
   * remove citadel infrastructure from map
   * @param {string} infrastructureId infrastructure id
   * @param {function} callback function to call when it is done
   */
  protected removeCitadelInfrastructure(infrastructureId, callback?: Function): void {
    this.citadelInfrastructures.delete(infrastructureId);
    if (callback) callback('deleted');
  }

  /**
   * fetch infrastructures for given infrastructure id
   * @returns {Promise<CitadelInfrastructure[]>} CitadelInfrastructures in infrastructure id
   */
  protected async fetchCitadelInfrastructures(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('CitadelInfrastructures')) return resolve();
      this.citadelCoreApi.getCitadelInfrastructures().subscribe({
        next: citadelInfrastructureRes => {
          citadelInfrastructureRes.forEach(citadelInfrastructure => {
            this.addCitadelInfrastructure(citadelInfrastructure);
          });
          this.fetchedObjects.push('CitadelInfrastructures');
          if (!this.storeSubscriptions.has('CitadelInfrastructure')) {
            const infrastructureSub = this.citadelCoreApi.citadelInfrastructureSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addCitadelInfrastructure(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeCitadelInfrastructure(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'CitadelInfrastructure' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('CitadelInfrastructure', infrastructureSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create citadelInfrastructure in database
   * @param {any} newInfrastructure infrastructure to create
   * @returns {Promise<CitadelInfrastructure>} created CitadelInfrastructure
   */
  public async createCitadelInfrastructure(newInfrastructure: any): Promise<CitadelInfrastructure> {
    return new Promise<CitadelInfrastructure>((resolve, reject) => {
      this.citadelCoreApi.createCitadelInfrastructure(newInfrastructure).subscribe({
        next: citadelInfrastructureRes => {
          this.addCitadelInfrastructure(citadelInfrastructureRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelInfrastructure in database
   * @param {CitadelInfrastructure} infrastructure infrastructure to update
   * @returns {Promise<CitadelInfrastructure>} updated CitadelInfrastructure
   */
  public async updateCitadelInfrastructure(infrastructure: CitadelInfrastructure): Promise<CitadelInfrastructure> {
    return new Promise<CitadelInfrastructure>((resolve, reject) => {
      this.citadelCoreApi.updateCitadelInfrastructure(infrastructure).subscribe({
        next: citadelInfrastructureRes => {
          this.addCitadelInfrastructure(citadelInfrastructureRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete citadelInfrastructure in database
   * @param {CitadelInfrastructure} citadelInfrastructure infrastructure to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteCitadelInfrastructure(citadelInfrastructure: CitadelInfrastructure): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteCitadelInfrastructure(citadelInfrastructure).subscribe({
        next: citadelInfrastructureIdRes => {
          this.removeCitadelInfrastructure(citadelInfrastructureIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * add item to store
   * @param {any} citadelItem item to add
   * @param {function} callback function to call when it is done
   */
  protected addCitadelItem(citadelItem: any, callback?: Function) {
    if (!this.citadelItems.has(citadelItem.asset_id)) {
      this.citadelItems.set(citadelItem.asset_id, new CitadelItem(this).deserialize(citadelItem));
    }
    else {
      this.citadelItems.get(citadelItem.asset_id).deserialize(citadelItem);
    }
    if (callback) callback(this.citadelItems.get(citadelItem.asset_id));
  }

  /**
   * remove citadel item from map
   * @param {string} itemId item id
   * @param {function} callback function to call when it is done
   */
  protected removeCitadelItem(itemId, callback?: Function): void {
    this.citadelItems.delete(itemId);
    if (callback) callback('deleted');
  }

  /**
   * fetch items for given item id
   * @returns {Promise<CitadelItem[]>} CitadelItems in item id
   */
  protected async fetchCitadelItems(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('CitadelItems')) return resolve();
      this.citadelCoreApi.getCitadelItems().subscribe({
        next: citadelItemRes => {
          citadelItemRes.forEach(citadelItem => {
            this.addCitadelItem(citadelItem);
          });
          this.fetchedObjects.push('CitadelItems');
          if (!this.storeSubscriptions.has('CitadelItem')) {
            const itemSub = this.citadelCoreApi.citadelItemSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addCitadelItem(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeCitadelItem(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'CitadelItem' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('CitadelItem', itemSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create citadelItem in database
   * @param {any} newItem item to create
   * @returns {Promise<CitadelItem>} created CitadelItem
   */
  public async createCitadelItem(newItem: CitadelItem): Promise<CitadelItem> {
    return new Promise<CitadelItem>((resolve, reject) => {
      this.citadelCoreApi.createCitadelItem(newItem).subscribe({
        next: citadelItemRes => {
          this.addCitadelItem(citadelItemRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelItem in database
   * @param {CitadelItem} item item to update
   * @returns {Promise<CitadelItem>} updated CitadelItem
   */
  public async updateCitadelItem(item: CitadelItem): Promise<CitadelItem> {
    return new Promise<CitadelItem>((resolve, reject) => {
      this.citadelCoreApi.updateCitadelItem(item).subscribe({
        next: citadelItemRes => {
          this.addCitadelItem(citadelItemRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete citadelItem in database
   * @param {CitadelItem} citadelItem item to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteCitadelItem(citadelItem: CitadelItem): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteCitadelItem(citadelItem).subscribe({
        next: citadelItemIdRes => {
          this.removeCitadelItem(citadelItemIdRes, resolve)
        },
        error: reject
      });
    });
  }

  // ITEM GROUPS

  /**
   * add item group to store
   * @param {any} itemGroup item group to add
   * @param {function} callback function to call when it is done
   */
  protected addItemGroup(itemGroup: any, callback?: Function) {
    if (!this.itemGroups.has(itemGroup.group_id)) {
      this.itemGroups.set(itemGroup.group_id, new ItemGroup().deserialize(itemGroup));
    }
    else {
      this.itemGroups.get(itemGroup.group_id).deserialize(itemGroup);
    }
    if (callback) callback(this.itemGroups.get(itemGroup.group_id));
  }

  /**
   * remove item group from map
   * @param {string} itemGroupId item group id
   * @param {function} callback function to call when it is done
   */
  protected removeItemGroup(itemGroupId, callback?: Function): void {
    this.itemGroups.delete(itemGroupId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch Item Groups from database
   * @returns {Promise<void>} resolves when done
   */
  protected async fetchItemGroups(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('ItemGroups')) return resolve();
      this.citadelCoreApi.getItemGroups().subscribe({
        next: itemGroupRes => {
          itemGroupRes.forEach(itemGroup => {
            this.addItemGroup(itemGroup);
          });
          this.fetchedObjects.push('ItemGroups');
          if (!this.storeSubscriptions.has('ItemGroup')) {
            const itemGroupSub = this.citadelCoreApi.itemGroupSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addItemGroup(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeItemGroup(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ItemGroup' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ItemGroup', itemGroupSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }


  /**
   * Create Item Group in database
   * @param {ItemGroup} itemGroup item group to create
   * @returns {Promise<ItemGroup>} created ItemGroup
   */
  public async createItemGroup(itemGroup: ItemGroup): Promise<ItemGroup> {
    return new Promise<ItemGroup>((resolve, reject) => {
      this.citadelCoreApi.createItemGroup(itemGroup).subscribe({
        next: itemGroupRes => {
          this.addItemGroup(itemGroupRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Update Item Group in database
   * @param {ItemGroup} itemGroup item group to update
   * @returns {Promise<ItemGroup>} updated ItemGroup
   */
  public async updateItemGroup(itemGroup: ItemGroup): Promise<ItemGroup> {
    return new Promise<ItemGroup>((resolve, reject) => {
      this.citadelCoreApi.updateItemGroup(itemGroup).subscribe({
        next: itemGroupRes => {
          this.addItemGroup(itemGroupRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Delete Item Group in database
   * @param {ItemGroup} itemGroup item group to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteItemGroup(itemGroup: ItemGroup): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteItemGroup(itemGroup).subscribe({
        next: itemGroupIdRes => {
          this.removeItemGroup(itemGroupIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * add stock to store
   * @param {any} citadelStock stock to add
   * @param {function} callback function to call when it is done
   */
  protected addCitadelStock(citadelStock: any, callback?: Function) {
    if (!this.citadelStocks.has(citadelStock.stock_id)) {
      this.citadelStocks.set(citadelStock.stock_id, new CitadelStock().deserialize(citadelStock));
    }
    else {
      this.citadelStocks.get(citadelStock.stock_id).deserialize(citadelStock);
    }
    if (callback) callback(this.citadelStocks.get(citadelStock.stock_id));
  }

  /**
   * remove citadel stock from map
   * @param {string} stockId stock id
   * @param {function} callback function to call when it is done
   */
  protected removeCitadelStock(stockId, callback?: Function): void {
    this.citadelStocks.delete(stockId);
    if (callback) callback('deleted');
  }

  /**
   * fetch stocks for given stock id
   * @returns {Promise<CitadelStock[]>} CitadelStocks in stock id
   */
  protected async fetchCitadelStocks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('CitadelStocks')) return resolve();
      this.citadelCoreApi.getCitadelStocks().subscribe({
        next: citadelStockRes => {
          citadelStockRes.forEach(citadelStock => {
            this.addCitadelStock(citadelStock);
          });
          this.fetchedObjects.push('CitadelStocks');
          if (!this.storeSubscriptions.has('CitadelStock')) {
            const stockSub = this.citadelCoreApi.citadelStockSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addCitadelStock(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeCitadelStock(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'CitadelStock' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('CitadelStock', stockSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create citadelStock in database
   * @param {any} newStock stock to create
   * @returns {Promise<CitadelStock>} created CitadelStock
   */
  public async createCitadelStock(newStock: any): Promise<CitadelStock> {
    return new Promise<CitadelStock>((resolve, reject) => {
      this.citadelCoreApi.createCitadelStock(newStock).subscribe({
        next: citadelStockRes => {
          this.addCitadelStock(citadelStockRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelStock in database
   * @param {CitadelStock} stock stock to update
   * @returns {Promise<CitadelStock>} updated CitadelStock
   */
  public async updateCitadelStock(stock: CitadelStock): Promise<CitadelStock> {
    return new Promise<CitadelStock>((resolve, reject) => {
      this.citadelCoreApi.updateCitadelStock(stock).subscribe({
        next: citadelStockRes => {
          this.addCitadelStock(citadelStockRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete citadelStock in database
   * @param {CitadelStock} citadelStock stock to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteCitadelStock(citadelStock: CitadelStock): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteCitadelStock(citadelStock).subscribe({
        next: citadelStockIdRes => {
          this.removeCitadelStock(citadelStockIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * add zone to store
   * @param {any} assetZone zone to add
   * @param {function} callback function to call when it is done
   */
  protected addAssetZone(assetZone: any, callback?: Function) {
    if (!this.assetZones.has(assetZone.zone_id)) {
      this.assetZones.set(assetZone.zone_id, new AssetZone(this).deserialize(assetZone));
    }
    else {
      this.assetZones.get(assetZone.zone_id).deserialize(assetZone);
    }
    if (callback) callback(this.assetZones.get(assetZone.zone_id));
  }

  /**
   * remove zone from map
   * @param {string} zoneId zone id
   * @param {function} callback function to call when it is done
   */
  protected removeAssetZone(zoneId, callback?: Function): void {
    this.assetZones.delete(zoneId);
    if (callback) callback('deleted');
  }

  /**
   * fetch zones
   * @returns {Promise<AssetZone[]>} AssetZones
   */
  protected async fetchAssetZones(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('AssetZones')) return resolve();
      this.citadelCoreApi.getAssetZones().subscribe({
        next: assetZoneRes => {
          assetZoneRes.forEach(assetZone => {
            this.addAssetZone(assetZone);
          });
          this.fetchedObjects.push('AssetZones');
          if (!this.storeSubscriptions.has('AssetZone')) {
            const zoneSub = this.citadelCoreApi.assetZoneSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addAssetZone(JSON.parse(objectJson));
                    break;
                  case "DELETED":
                    this.removeAssetZone(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'AssetZone' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('AssetZone', zoneSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create assetZone in database
   * @param {any} newZone zone to create
   * @returns {Promise<AssetZone>} created AssetZone
   */
  public async createAssetZone(newZone: AssetZone): Promise<AssetZone> {
    return new Promise<AssetZone>((resolve, reject) => {
      this.citadelCoreApi.createAssetZone(newZone).subscribe({
        next: assetZoneRes => {
          this.addAssetZone(assetZoneRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update assetZone in database
   * @param {AssetZone} assetZone zone to update
   * @returns {Promise<AssetZone>} updated AssetZone
   */
  public async updateAssetZone(assetZone: AssetZone): Promise<AssetZone> {
    return new Promise<AssetZone>((resolve, reject) => {
      this.citadelCoreApi.updateAssetZone(assetZone).subscribe({
        next: assetZoneRes => {
          this.addAssetZone(assetZoneRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete assetZone in database
   * @param {AssetZone} assetZone zone to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteAssetZone(assetZone: AssetZone): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteAssetZone(assetZone).subscribe({
        next: assetZoneIdRes => {
          this.removeAssetZone(assetZoneIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * add category to store
   * @param {any} assetCategory category to add
   * @param {function} callback function to call when it is done
   */
  protected addAssetCategory(assetCategory: any, callback?: Function) {
    if (!this.assetCategories.has(assetCategory.category_id)) {
      this.assetCategories.set(assetCategory.category_id, new AssetCategory(this).deserialize(assetCategory));
    }
    else {
      this.assetCategories.get(assetCategory.category_id).deserialize(assetCategory);
    }
    if (callback) callback(this.assetCategories.get(assetCategory.category_id));
  }

  /**
   * remove asset category from map
   * @param {string} categoryId category id
   * @param {function} callback function to call when it is done
   */
  protected removeAssetCategory(categoryId, callback?: Function): void {
    this.assetCategories.delete(categoryId);
    if (callback) callback('deleted');
  }

  /**
   * fetch categories
   * @returns {Promise<void>} AssetCategories
   */
  protected async fetchAssetCategories(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('AssetCategories')) return resolve();
      this.citadelCoreApi.getAssetCategories().subscribe({
        next: assetCategoryRes => {
          assetCategoryRes.forEach(assetCategory => {
            this.addAssetCategory(assetCategory);
          });
          this.fetchedObjects.push('AssetCategories');
          if (!this.storeSubscriptions.has('AssetCategory')) {
            const categorySub = this.citadelCoreApi.assetCategorysubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addAssetCategory(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeAssetCategory(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'AssetCategory' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('AssetCategory', categorySub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create assetCategory in database
   * @param {AssetCategory} newCategory category to create
   * @returns {Promise<AssetCategory>} created AssetCategory
   */
  public async createAssetCategory(newCategory: AssetCategory): Promise<AssetCategory> {
    return new Promise<AssetCategory>((resolve, reject) => {
      this.citadelCoreApi.createAssetCategory(newCategory).subscribe({
        next: assetCategoryRes => {
          this.addAssetCategory(assetCategoryRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update assetCategory in database
   * @param {AssetCategory} category category to update
   * @returns {Promise<AssetCategory>} updated AssetCategory
   */
  public async updateAssetCategory(category: AssetCategory): Promise<AssetCategory> {
    return new Promise<AssetCategory>((resolve, reject) => {
      this.citadelCoreApi.updateAssetCategory(category).subscribe({
        next: assetCategoryRes => {
          this.addAssetCategory(assetCategoryRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete assetCategory in database
   * @param {AssetCategory} assetCategory category to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteAssetCategory(assetCategory: AssetCategory): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteAssetCategory(assetCategory).subscribe({
        next: assetCategoryIdRes => {
          this.removeAssetCategory(assetCategoryIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * add zone to store
   * @param {any} assetZone zone to add
   * @param {function} callback function to call when it is done
   */
  protected addAssetModel(assetModel: any, callback?: Function) {
    if (!this.assetModels.has(assetModel.model_id)) {
      this.assetModels.set(assetModel.model_id, new AssetModel(this).deserialize(assetModel));
    }
    else {
      this.assetModels.get(assetModel.model_id).deserialize(assetModel);
    }
    if (callback) callback(this.assetModels.get(assetModel.model_id));
  }

  /**
   * remove model from map
   * @param {string} modelId model id
   * @param {function} callback function to call when it is done
   */
  protected removeAssetModel(modelId, callback?: Function): void {
    this.assetModels.delete(modelId);
    if (callback) callback('deleted');
  }

  /**
   * fetch models
   * @returns {Promise<void>} AssetModels
   */
  protected async fetchAssetModels(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('AssetModels')) return resolve();
      this.citadelCoreApi.getAssetModels().subscribe({
        next: assetModelRes => {
          assetModelRes.forEach(assetModel => {
            this.addAssetModel(assetModel);
          });
          this.fetchedObjects.push('AssetModels');
          if (!this.storeSubscriptions.has('AssetModel')) {
            const modelSub = this.citadelCoreApi.assetModelSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addAssetModel(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeAssetModel(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'AssetModel' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('AssetModel', modelSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create assetModel in database
   * @param {any} newModel model to create
   * @returns {Promise<AssetModel>} created AssetModel
   */
  public async createAssetModel(newModel: AssetModel): Promise<AssetModel> {
    return new Promise<AssetModel>((resolve, reject) => {
      this.citadelCoreApi.createAssetModel(newModel).subscribe({
        next: assetModelRes => {
          this.addAssetModel(assetModelRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update assetModel in database
   * @param {AssetModel} assetModel model to update
   * @returns {Promise<AssetModel>} updated AssetModel
   */
  public async updateAssetModel(assetModel: AssetModel): Promise<AssetModel> {
    return new Promise<AssetModel>((resolve, reject) => {
      this.citadelCoreApi.updateAssetModel(assetModel).subscribe({
        next: assetModelRes => {
          this.addAssetModel(assetModelRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete assetModel in database
   * @param {AssetModel} assetModel model to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteAssetModel(assetModel: AssetModel): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteAssetModel(assetModel).subscribe({
        next: assetModelIdRes => {
          this.removeAssetModel(assetModelIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * fetch parameter sets
   * @returns {Promise<void>} ParameterSets
   */
  protected async fetchParameterSets(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('ParameterSets')) return resolve();
      this.citadelCoreApi.getParameterSets().subscribe({
        next: parameterSetRes => {
          parameterSetRes.forEach(parameterSet => {
            this.addParameterSet(parameterSet);
          });
          this.fetchedObjects.push('ParameterSets');
          if (!this.storeSubscriptions.has('ParameterSet')) {
            const parameterSetSub = this.citadelCoreApi.parameterSetSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addParameterSet(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeParameterSet(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ParameterSet' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ParameterSet', parameterSetSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * add parameterset to store
   * @param {any} parameterSet parameterset to add
   * @param {function} callback function to call when it is done
   */
  protected addParameterSet(parameterSet: any, callback?: Function) {
    if (this.parameterSets.has(parameterSet.set_id)) {
      this.parameterSets.get(parameterSet.set_id).deserialize(parameterSet);
    }
    else {
      this.parameterSets.set(parameterSet.set_id, new ParameterSet(this).deserialize(parameterSet));
    }
    if (callback) callback(this.parameterSets.get(parameterSet.set_id));
  }

  /**
   * Removes a parameter set with the given ID.
   * @param setId The ID of the parameter set to remove.
   * @param callback An optional callback function to call after the parameter set is removed.
   */
  protected removeParameterSet(setId, callback?: Function): void {
    if (!this.parameterSets.has(setId)) return;
    this.parameterSets.delete(setId);
    if (callback) callback('deleted');
  }

  /**
   * create parameterSet in database
   * @param {any} newParameterSet parameterSet to create
   * @returns {Promise<ParameterSet>} created ParameterSet
   */
  public createParameterSet(parameterSet: any): Promise<ParameterSet> {
    return new Promise<ParameterSet>((resolve, reject) => {
      this.citadelCoreApi.createParameterSet(parameterSet).subscribe({
        next: parameterSetRes => {
          this.addParameterSet(parameterSetRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * update parameterSet in database
   * @param {ParameterSet} parameterset parameterset to update
   * @returns {Promise<ParameterSet>} updated ParameterSet
   */
  public updateParameterSet(parameterSet: ParameterSet): Promise<ParameterSet> {
    return new Promise<ParameterSet>((resolve, reject) => {
      this.citadelCoreApi.updateParameterSet(parameterSet).subscribe({
        next: parameterSetRes => {
          this.addParameterSet(parameterSetRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * delete parameterSet in database
   * @param {ParameterSet} parameterSet parameterset to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public deleteParameterSet(parameterSetId: string, callback?: Function) {
    this.citadelCoreApi.deleteParameterSet(parameterSetId).subscribe(parameterSetIdRes => {
      this.removeParameterSet(parameterSetIdRes, callback);
    });
  }

  /**
   * add task to store
   * @param {any} citadelTask task to add
   * @param {function} callback function to call when it is done
   */
  protected addCitadelTask(citadelTask: any, callback?: Function) {
    if (!this.citadelTasks.has(citadelTask.task_id)) {
      this.citadelTasks.set(citadelTask.task_id, new CitadelTask(this).deserialize(citadelTask));
    }
    else {
      this.citadelTasks.get(citadelTask.task_id).deserialize(citadelTask);
    }
    if (callback) callback(this.citadelTasks.get(citadelTask.task_id));
  }

  /**
   * remove citadel task from map
   * @param {string} taskId task id
   * @param {function} callback function to call when it is done
   */
  protected removeCitadelTask(taskId, callback?: Function): void {
    this.citadelTasks.delete(taskId);
    if (callback) callback('deleted');
  }

  /**
   * fetch tasks for given task id
   * @param {string} parent_task_id id to fetch children tasks
   * @returns {Promise<CitadelTask[]>} CitadelTasks in task id
   */
  public async fetchCitadelTasks(parent_task_id: string): Promise<CitadelTask[]> {
    return new Promise<CitadelTask[]>((resolve, reject) => {
      this.citadelCoreApi.getCitadelTasks(parent_task_id).subscribe({
        next: citadelTaskRes => {
          let citadelTasks = [];
          citadelTaskRes.forEach(citadelTask => {
            this.addCitadelTask(citadelTask, addedTask => {
              citadelTasks.push(addedTask);
            });
          });
          resolve(citadelTasks);
        },
        error: reject
      });
    });
  }

  /**
   * create citadelTask in database
   * @param {CitadelTask} newTask task to create
   * @returns {Promise<CitadelTask>} created CitadelTask
   */
  public async createCitadelTask(newTask: CitadelTask): Promise<CitadelTask> {
    return new Promise<CitadelTask>((resolve, reject) => {
      this.citadelCoreApi.createCitadelTask(newTask).subscribe({
        next: citadelTaskRes => {
          this.addCitadelTask(citadelTaskRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelTask in database
   * @param {CitadelTask} task task to update
   * @returns {Promise<CitadelTask>} updated CitadelTask
   */
  public async updateCitadelTask(task: CitadelTask): Promise<CitadelTask> {
    return new Promise<CitadelTask>((resolve, reject) => {
      this.citadelCoreApi.updateCitadelTask(task).subscribe({
        next: citadelTaskRes => {
          this.addCitadelTask(citadelTaskRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelTask position
   * @param {CitadelTask} citadelTask task to update
   * @param {Point} position
   * @returns {Promise<CitadelTask>} updated CitadelTask
   */
  public async updateCitadelTaskPosition(citadelTask: CitadelTask, position: Point): Promise<CitadelTask> {
    return new Promise<CitadelTask>((resolve, reject) => {
      this.citadelCoreApi.updateCitadelTaskPosition(citadelTask, position).subscribe({
        next: citadelTaskRes => {
          this.addCitadelTask(citadelTaskRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * simulates an event for a task
   * @param {CitadelTask} citadelTask task to update
   * @param {TaskEvent} event to simulate
   * @returns {Promise<CitadelTask>} updated CitadelTask
   */
  public async simulateTaskEvent(citadelTask: CitadelTask, event: TaskEvent): Promise<CitadelTask> {
    return new Promise<CitadelTask>((resolve, reject) => {
      this.citadelCoreApi.simulateTaskEvent(citadelTask, event).subscribe({
        next: citadelTaskRes => {
          this.addCitadelTask(citadelTaskRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete citadelTask in database
   * @param {CitadelTask} citadelTask task to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteCitadelTask(citadelTask: CitadelTask): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteCitadelTask(citadelTask).subscribe({
        next: citadelTaskIdRes => {
          this.removeCitadelTask(citadelTaskIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * fetch resource links for given task id
   * @param {string} task_id id to fetch children links
   * @returns {Promise<ResourceLink[]>} ResourceLinks in task id
   */
  public async fetchResourceLinks(task_id: string): Promise<ResourceLink[]> {
    return new Promise<ResourceLink[]>((resolve, reject) => {
      this.citadelCoreApi.getResourceLinks(task_id).subscribe({
        next: resourceLinksRes => {
          let resourceLinks = [];
          resourceLinksRes.forEach(resourceLink => {
            this.addResourceLink(resourceLink, addedLink => {
              resourceLinks.push(addedLink);
            });
          });
          resolve(resourceLinks);
        },
        error: reject
      });
    });
  }

  /**
   * add resourceLink to store
   * @param {any} resourceLink resourceLink to add
   * @param {function} callback function to call when it is done
   */
  protected addResourceLink(resourceLink: any, callback?: Function) {
    if (!this.resourceLinks.has(resourceLink.link_id)) {
      this.resourceLinks.set(resourceLink.link_id, new ResourceLink().deserialize(resourceLink));
    }
    else {
      this.resourceLinks.get(resourceLink.link_id).deserialize(resourceLink);
    }
    if (callback) callback(this.resourceLinks.get(resourceLink.link_id));
  }

  /**
   * remove citadel resourceLink from map
   * @param {string} resourcelinkId resourceLink id
   * @param {function} callback function to call when it is done
   */
  protected removeResourceLink(resourcelinkId, callback?: Function): void {
    this.resourceLinks.delete(resourcelinkId);
    if (callback) callback('deleted');
  }

  /**
   * create resourceLink in database
   * @param {ResourceLink} newResourceLink resourceLink to create
   * @returns {Promise<ResourceLink>} created ResourceLink
   */
  public async createResourceLink(newResourceLink: ResourceLink): Promise<ResourceLink> {
    return new Promise<ResourceLink>((resolve, reject) => {
      this.citadelCoreApi.createResourceLink(newResourceLink).subscribe({
        next: resourceLinkRes => {
          this.addResourceLink(resourceLinkRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete resourceLink in database
   * @param {ResourceLink} link id of resourceLink to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteResourceLink(link: ResourceLink): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteResourceLink(link).subscribe({
        next: resourceLinkIdRes => {
          this.removeResourceLink(resourceLinkIdRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
   * fetch stock nodes for given task id
   * @param {string} task_id id to fetch children stock nodes
   * @returns {Promise<StockNode[]>} StockNodes in task id
   */
  public async fetchStockNodes(task_id: string): Promise<StockNode[]> {
    return new Promise<StockNode[]>((resolve, reject) => {
      this.citadelCoreApi.getStockNodes(task_id).subscribe({
        next: stockNodesRes => {
          let stockNodes = [];
          stockNodesRes.forEach(stockNode => {
            this.addStockNode(stockNode, addedStockNode => {
              stockNodes.push(addedStockNode);
            });
          });
          resolve(stockNodes);
        },
        error: reject
      });
    });
  }

  /**
   * add stockNode to store
   * @param {any} stockNode stockNode to add
   * @param {function} callback function to call when it is done
   */
  protected addStockNode(stockNode: any, callback?: Function) {
    if (!this.stockNodes.has(stockNode.node_id)) {
      this.stockNodes.set(stockNode.node_id, new StockNode(this).deserialize(stockNode));
    }
    else {
      this.stockNodes.get(stockNode.node_id).deserialize(stockNode);
    }
    if (callback) callback(this.stockNodes.get(stockNode.node_id));
  }

  /**
   * remove stockNode from map
   * @param {string} stockNodeId stockNode id
   * @param {function} callback function to call when it is done
   */
  protected removeStockNode(stockNodeId, callback?: Function): void {
    this.stockNodes.delete(stockNodeId);
    if (callback) callback('deleted');
  }

  /**
   * create stockNode in database
   * @param {any} newStockNode stockNode to create
   * @returns {Promise<StockNode>} created StockNode
   */
  public async createStockNode(newStockNode: any): Promise<StockNode> {
    return new Promise<StockNode>((resolve, reject) => {
      this.citadelCoreApi.createStockNode(newStockNode).subscribe({
        next: stockNodeRes => {
          this.addStockNode(stockNodeRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockNode in database
   * @param {StockNode} stockNode stockNode to update
   * @returns {Promise<StockNode>} updated StockNode
   */
  public async updateStockNode(stockNode: StockNode): Promise<StockNode> {
    return new Promise<StockNode>((resolve, reject) => {
      this.citadelCoreApi.updateStockNode(stockNode).subscribe({
        next: stockNodeRes => {
          this.addStockNode(stockNodeRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockNode position
   * @param {StockNode} stockNode stockNode to update
   * @param {Point} position
   * @returns {Promise<StockNode>} updated StockNode
   */
  public async updateStockNodePosition(stockNode: StockNode, position: Point) {
    return new Promise<StockNode>((resolve, reject) => {
      this.citadelCoreApi.updateStockNodePosition(stockNode, position).subscribe({
        next: stockNodeRes => {
          this.addStockNode(stockNodeRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockNode in database
   * @param {StockNode} stockNode stockNode to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteStockNode(stockNode: StockNode): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteStockNode(stockNode).subscribe({
        next: stockNodeIdRes => {
          this.removeStockNode(stockNodeIdRes, resolve)
        },
        error: reject
      });
    });
  }

  // RESOURCE CATALOGS METHODS

  /**
   * Add resource catalog to store
   * @param {any} resourceCatalog resource catalog to add
   * @param {function} callback function to call when it is done
   */
  protected addResourceCatalog(resourceCatalog: any, callback?: Function) {
    if (!this.resourceCatalogs.has(resourceCatalog.catalog_id)) {
      this.resourceCatalogs.set(resourceCatalog.catalog_id, new ResourceCatalog().deserialize(resourceCatalog));
    }
    else {
      this.resourceCatalogs.get(resourceCatalog.catalog_id).deserialize(resourceCatalog);
    }
    if (callback) callback(this.resourceCatalogs.get(resourceCatalog.catalog_id));
  }

  /**
   * remove resource catalog from map
   * @param {string} catalog_id resource catalog id
   * @param {function} callback function to call when it is done
   */
  protected removeResourceCatalog(catalog_id, callback?: Function): void {
    this.resourceCatalogs.delete(catalog_id);
    if (callback) callback('deleted');
  }

  /**
   * fetch resource catalogs
   * @returns {Promise<void>} Returns when function ended
   */
  protected async fetchResourceCatalogs(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('ResourceCatalogs')) return resolve();
      this.citadelCoreApi.getResourceCatalogs().subscribe({
        next: resourceCatalogsRes => {
          resourceCatalogsRes.forEach(resourceCatalog => {
            this.addResourceCatalog(resourceCatalog);
          });
          this.fetchedObjects.push('ResourceCatalogs');
          if (!this.storeSubscriptions.has('ResourceCatalog')) {
            const catalogSub = this.citadelCoreApi.resourceCatalogSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResourceCatalog(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResourceCatalog(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ResourceCatalog' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ResourceCatalog', catalogSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create resource catalog in database
   * @param {ResourceCatalog} newResourceCatalog resource catalog to create
   * @returns {Promise<ResourceCatalog>} created ResourceCatalog
   */
  public async createResourceCatalog(newResourceCatalog: ResourceCatalog): Promise<ResourceCatalog> {
    return new Promise<ResourceCatalog>((resolve, reject) => {
      this.citadelCoreApi.createResourceCatalog(newResourceCatalog).subscribe({
        next: resourceCatalogRes => {
          this.addResourceCatalog(resourceCatalogRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update resource catalog in database
   * @param {ResourceCatalog} resourceCatalog resource catalog to update
   * @returns {Promise<ResourceCatalog>} updated ResourceCatalog
   */
  public async updateResourceCatalog(resourceCatalog: ResourceCatalog): Promise<ResourceCatalog> {
    return new Promise<ResourceCatalog>((resolve, reject) => {
      this.citadelCoreApi.updateResourceCatalog(resourceCatalog).subscribe({
        next: resourceCatalogRes => {
          this.addResourceCatalog(resourceCatalogRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete resource catalog in database
   * @param {ResourceCatalog} resourceCatalog resource catalog to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteResourceCatalog(resourceCatalog: ResourceCatalog): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteResourceCatalog(resourceCatalog).subscribe({
        next: resourceCatalogIdRes => {
          this.removeResourceCatalog(resourceCatalogIdRes, resolve)
        },
        error: reject
      });
    });
  }

  // RESOURCE LIBRARIES METHODS

  /**
   * Add resource library to store
   * @param {any} resourceLibrary resource library to add
   * @param {function} callback function to call when it is done
   */
  protected addResourceLibrary(resourceLibrary: any, callback?: Function) {
    if (!this.resourceLibraries.has(resourceLibrary.library_id)) {
      this.resourceLibraries.set(resourceLibrary.library_id, new ResourceLibrary().deserialize(resourceLibrary));
    }
    else {
      this.resourceLibraries.get(resourceLibrary.library_id).deserialize(resourceLibrary);
    }
    if (callback) callback(this.resourceLibraries.get(resourceLibrary.library_id));
  }

  /**
   * remove resource library from map
   * @param {string} library_id resource library id
   * @param {function} callback function to call when it is done
   */
  protected removeResourceLibrary(library_id, callback?: Function): void {
    this.resourceLibraries.delete(library_id);
    if (callback) callback('deleted');
  }

  /**
   * fetch resource libraries
   * @returns {Promise<void>} Returns when function ended
   */
  protected async fetchResourceLibraries(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('ResourceLibraries')) return resolve();
      this.citadelCoreApi.getResourceLibraries().subscribe({
        next: resourceLibrariesRes => {
          resourceLibrariesRes.forEach(resourceLibrary => {
            this.addResourceLibrary(resourceLibrary);
          });
          this.fetchedObjects.push('ResourceLibraries');
          if (!this.storeSubscriptions.has('ResourceLibrary')) {
            const librarySub = this.citadelCoreApi.resourceLibrarySubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResourceLibrary(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResourceLibrary(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ResourceLibrary' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ResourceLibrary', librarySub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create resource library in database
   * @param {ResourceLibrary} newResourceLibrary resource library to create
   * @returns {Promise<ResourceLibrary>} created ResourceLibrary
   */
  public async createResourceLibrary(newResourceLibrary: ResourceLibrary): Promise<ResourceLibrary> {
    return new Promise<ResourceLibrary>((resolve, reject) => {
      this.citadelCoreApi.createResourceLibrary(newResourceLibrary).subscribe({
        next: resourceLibraryRes => {
          this.addResourceLibrary(resourceLibraryRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update resource library in database
   * @param {ResourceLibrary} resourceLibrary resource library to update
   * @returns {Promise<ResourceLibrary>} updated ResourceLibrary
   */
  public async updateResourceLibrary(resourceLibrary: ResourceLibrary): Promise<ResourceLibrary> {
    return new Promise<ResourceLibrary>((resolve, reject) => {
      this.citadelCoreApi.updateResourceLibrary(resourceLibrary).subscribe({
        next: resourceLibraryRes => {
          this.addResourceLibrary(resourceLibraryRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete resource library in database
   * @param {ResourceLibrary} resourceLibrary resource library to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteResourceLibrary(resourceLibrary: ResourceLibrary): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteResourceLibrary(resourceLibrary).subscribe({
        next: resourceLibraryIdRes => {
          this.removeResourceLibrary(resourceLibraryIdRes, resolve)
        },
        error: reject
      });
    });
  }


  // RESOURCES METHODS

  /**
  * add resource to store
  * @param {any} resource resource to add
  * @param {function} callback function to call when it is done
  */
  protected addResource(resource: any, callback?: Function) {
    if (!this.resources.has(resource.resource_id)) {
      this.resources.set(resource.resource_id, new Resource().deserialize(resource));
    }
    else {
      this.resources.get(resource.resource_id).deserialize(resource);
    }
    this.resourcesByReference.set(resource.reference, this.resources.get(resource.resource_id));
    if (callback) callback(this.resources.get(resource.resource_id));
  }

  /**
   * remove citadel resource from map
   * @param {string} resource_id resource id
   * @param {function} callback function to call when it is done
   */
  protected removeResource(resource_id, callback?: Function): void {
    const resource = this.resources.get(resource_id);
    this.resourcesByReference.delete(resource.reference);
    this.resources.delete(resource_id);
    if (callback) callback('deleted');
  }

  /**
   * fetch resources
   * @returns {Promise<void>} Resources
   */
  protected async fetchResources(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('Resources')) return resolve();
      this.citadelCoreApi.getResources().subscribe({
        next: resourcesRes => {
          resourcesRes.forEach(resource => {
            this.addResource(resource);
          });
          this.fetchedObjects.push('Resources');
          if (!this.storeSubscriptions.has('Resource')) {
            const resourceSub = this.citadelCoreApi.resourceSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResource(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResource(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'Resource' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('Resource', resourceSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create resource in database
   * @param {any} newResource resource to create
   * @returns {Promise<Resource>} created Resource
   */
  public async createResource(newResource: any): Promise<Resource> {
    return new Promise<Resource>((resolve, reject) => {
      this.citadelCoreApi.createResource(newResource).subscribe({
        next: resourceRes => {
          this.addResource(resourceRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update resource in database
   * @param {Resource} resource resource to update
   * @returns {Promise<Resource>} updated Resource
   */
  public async updateResource(resource: Resource): Promise<Resource> {
    return new Promise<Resource>((resolve, reject) => {
      this.citadelCoreApi.updateResource(resource).subscribe({
        next: resourceRes => {
          this.addResource(resourceRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Move resources to another catalog
   * @param {string[]} resources_ids resources to update
   * @param {string} catalog_id destination catalog
   * @returns {Promise<void>} returns when function ended
   */
  public async changeResourcesCatalog(resources_ids: string[], catalog_id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.citadelCoreApi.changeResourcesCatalog(resources_ids, catalog_id).subscribe({
        next: resourceRes => {
          resourceRes.forEach(resource => {
            this.addResource(resource);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * delete resource in database
   * @param {string} resourceId id of resource to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteResource(resourceId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteResource(resourceId).subscribe({
        next: resourceIdRes => {
          this.removeResource(resourceIdRes, resolve)
        },
        error: reject
      });
    });
  }


  /**
   * Add Storage to store
   * @param {any} storage Storage to add
   * @param {function} callback function to call when it is done
   */
  protected addStorage(storage: any, callback?: Function) {
    if (!this.citadelStorages.has(storage.storage_id)) {
      this.citadelStorages.set(storage.storage_id, new CitadelStorage(this).deserialize(storage));
    }
    else {
      this.citadelStorages.get(storage.storage_id).deserialize(storage);
    }
    if (callback) callback(this.citadelStorages.get(storage.storage_id));
  }

  /**
   * Remove Storage from map
   * @param {string} storageId Storage id
   * @param {function} callback function to call when it is done
   */
  protected removeStorage(storageId, callback?: Function): void {
    this.citadelStorages.delete(storageId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch Storages from database
   * @returns {Promise<void>} Storages
   */
  protected async fetchStorages(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('Storages')) return resolve();
      this.citadelCoreApi.getStorages().subscribe({
        next: storageRes => {
          storageRes.forEach(storage => {
            this.addStorage(storage);
          });
          this.fetchedObjects.push('Storages');
          if (!this.storeSubscriptions.has('Storage')) {
            const storageSub = this.citadelCoreApi.storageSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStorage(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStorage(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'Storage' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('Storage', storageSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * Create Storage in database
   * @param {CitadelStorage} newStorage Storage to create
   * @returns {Promise<CitadelStorage>} created Storage
   */
  public async createStorage(newStorage: CitadelStorage): Promise<CitadelStorage> {
    return new Promise<CitadelStorage>((resolve, reject) => {
      this.citadelCoreApi.createStorage(newStorage).subscribe({
        next: storageRes => {
          this.addStorage(storageRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Update Storage in database
   * @param {CitadelStorage} storage Storage to update
   * @returns {Promise<CitadelStorage>} updated Storage
   */
  public async updateStorage(storage: CitadelStorage): Promise<CitadelStorage> {
    return new Promise<CitadelStorage>((resolve, reject) => {
      this.citadelCoreApi.updateStorage(storage).subscribe({
        next: storageRes => {
          this.addStorage(storageRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Update Storages stocks
   * @param {CitadelStorage[]} storages Storages to update
   * @returns {Promise<CitadelStorage[]>} updated Storages
   */
  public async updateStoragesStocks(storages: CitadelStorage[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.citadelCoreApi.updateStorageStockIds(storages).subscribe({
        next: storagesRes => {
          storagesRes.forEach(storageRes => {
            this.addStorage(storageRes);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * Delete Storage in database
   * @param {CitadelStorage} storage Storage to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteStorage(storage: CitadelStorage): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteStorage(storage).subscribe({
        next: storageIdRes => {
          this.removeStorage(storageIdRes, resolve)
        },
        error: reject
      });
    });
  }

  //  ResourcesByStock

  /**
 * add resourceByStock to store
 * @param {any} resourceByStock resourceByStock to add
 * @param {function} callback function to call when it is done
 */
  protected addResourceByStock(resourceByStock: any, callback?: Function) {
    if (!this.stockResourcesMap.has(resourceByStock.stock_id)) this.stockResourcesMap.set(resourceByStock.stock_id, new Set());
    this.stockResourcesMap.get(resourceByStock.stock_id).add(resourceByStock.resource_id);
    if (!this.resourcesByStock.has(resourceByStock.resource_id)) {
      this.resourcesByStock.set(resourceByStock.resource_id, new ResourceByStock(this).deserialize(resourceByStock));
    }
    else {
      this.resourcesByStock.get(resourceByStock.resource_id).deserialize(resourceByStock);
    }
    if (callback) callback(this.resourcesByStock.get(resourceByStock.resource_id));
  }


  /**
   * remove citadel resourceByStock from map
   * @param {string} resourceByStockId resourceByStock id
   * @param {function} callback function to call when it is done
   */
  protected removeResourceByStock(resourceByStockId: string, callback?: Function): void {
    const resourceByStock = this.resourcesByStock.get(resourceByStockId);
    this.resourcesByStock.delete(resourceByStockId);
    this.stockResourcesMap.get(resourceByStock.stock_id)?.delete(resourceByStockId);
    if (callback) callback('deleted');
  }

  /**
   * fetch resourceByStocks
   * @returns {Promise<ResourceByStock[]>} ResourceByStocks
   */
  public async fetchResourcesByStock(stock_id: string): Promise<ResourceByStock[]> {
    return new Promise<ResourceByStock[]>((resolve, reject) => {
      this.citadelCoreApi.getResourcesByStock(stock_id).subscribe({
        next: resourcesByStockRes => {
          let resourcesByStock = [];
          resourcesByStockRes.forEach(resourceByStock => {
            this.addResourceByStock(resourceByStock, addedResourceByStock => {
              resourcesByStock.push(addedResourceByStock);
            });
          });

          if (!this.storeSubscriptions.has('ResourceByStock')) {
            const resourceByStockSub = this.citadelCoreApi.resourceByStockSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResourceByStock(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResourceByStock(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ResourceByStock' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ResourceByStock', resourceByStockSub);
          }
          resolve(resourcesByStock);
        },
        error: reject
      });
    });
  }

  /**
   * create resourceByStock in database
   * @param {any} newResourceByStock resourceByStock to create
   * @returns {Promise<ResourceByStock>} created ResourceByStock
   */
  public async createResourceByStock(newResourceByStock: any): Promise<ResourceByStock> {
    return new Promise<ResourceByStock>((resolve, reject) => {
      this.citadelCoreApi.createResourceByStock(newResourceByStock).subscribe({
        next: resourceByStockRes => {
          this.addResourceByStock(resourceByStockRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update resourceByStock in database
   * @param {ResourceByStock} resourceByStock resourceByStock to update
   * @returns {Promise<ResourceByStock>} updated ResourceByStock
   */
  public async updateResourceByStock(resourceByStock: ResourceByStock): Promise<ResourceByStock> {
    return new Promise<ResourceByStock>((resolve, reject) => {
      this.citadelCoreApi.updateResourceByStock(resourceByStock).subscribe({
        next: resourceByStockRes => {
          this.addResourceByStock(resourceByStockRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete resourceByStock in database
   * @param {string} resourceByStockId id of resourceByStock to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteResourceByStock(resourceByStock: ResourceByStock): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteResourceByStock(resourceByStock).subscribe({
        next: resourceByStockRes => {
          this.removeResourceByStock(resourceByStockRes, resolve)
        },
        error: reject
      });
    });
  }

  /**
  * add resource quantity to store
  * @param {any} resourceQuantity resource quantity to add
  * @param {function} callback function to call when it is done
  */
  protected addResourceQuantity(resourceQuantity: any, callback?: Function) {
    const { stock_id, resource_id } = resourceQuantity;
    if (!this.resourceQuantitiesByStock.has(stock_id)) {
      this.resourceQuantitiesByStock.set(stock_id, new Map());
    }
    if (!this.resourceQuantitiesByStock.get(stock_id).has(resource_id)) {
      this.resourceQuantitiesByStock.get(stock_id).set(resource_id, new ResourceQuantity(this).deserialize(resourceQuantity));
    }
    else {
      this.resourceQuantitiesByStock.get(stock_id).get(resource_id).deserialize(resourceQuantity);
    }
    if (callback) callback(this.resourceQuantitiesByStock.get(stock_id).get(resource_id));
  }

  /**
   * remove resource quantity from map
   * @param {string} resource_id resource quantity to remove
   * @param {string} stock_id parent id
   * @param {function} callback function to call when it is done
   */
  protected removeResourceQuantity(resource_id: string, stock_id: string, callback?: Function): void {
    const resourceQuantity = this.resourceQuantitiesByStock.get(stock_id)?.get(resource_id);
    if (resourceQuantity) this.resourceQuantitiesByStock.get(stock_id)?.delete(resource_id);
    if (callback) callback('deleted');
  }


  /**
   * fetch resource quantity by stock
   * @param {string} stock_id stock id
   * @returns {Promise<ResourceQuantity[]>} ResourceQuantities
   */
  public fetchResourceQuantitiesByStock(stock_id: string): Promise<ResourceQuantity[]> {
    return new Promise<ResourceQuantity[]>((resolve, reject) => {
      this.resourceQuantitiesByStock.set(stock_id, new Map());
      this.citadelCoreApi.getResourceQuantityByStock(stock_id).subscribe({
        next: resourceQuantitiesRes => {
          let resourceQuantities = [];
          resourceQuantitiesRes.forEach(resourceQuantity => {
            this.addResourceQuantity(resourceQuantity, addedResourceQuantity => {
              resourceQuantities.push(addedResourceQuantity);
            });
          });
          if (!this.storeSubscriptions.has('ResourceQuantityByStock')) {
            const resourceQtitySub = this.citadelCoreApi.resourceQuantityByStockSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, parentId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResourceQuantity(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResourceQuantity(objectId, parentId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ResourceQuantityByStock' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ResourceQuantityByStock', resourceQtitySub);
            const resourceOrderedQtitySub = this.citadelCoreApi.resourceOrderedQuantityByStockSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, parentId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addResourceQuantity(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeResourceQuantity(objectId, parentId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'ResourceQuantityByStock' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ResourceOrderedQuantityByStock', resourceOrderedQtitySub);
          }
          resolve(resourceQuantities);
        },
        error: reject
      });
    });
  }

  /**
   * Adds an import configuration to the service's internal map of import configurations.
   * If an import configuration with the same dataset and config_id already exists, it will be updated.
   * @param importConfig - The import configuration to add or update.
   * @param callback - An optional callback function to be called with the added/updated import configuration.
   */
  protected addImportConfig(importConfig: any, callback?: Function) {
    if (!this.importConfigsByDataset.has(importConfig.dataset)) {
      this.importConfigsByDataset.set(importConfig.dataset, new Map());
    }
    if (!this.importConfigsByDataset.get(importConfig.dataset).has(importConfig.config_id)) {
      this.importConfigsByDataset.get(importConfig.dataset).set(importConfig.config_id, new ImportConfig().deserialize(importConfig));
    }
    else {
      this.importConfigsByDataset.get(importConfig.dataset).get(importConfig.config_id).deserialize(importConfig);
    }
    if (callback) callback(this.importConfigsByDataset.get(importConfig.dataset).get(importConfig.config_id));
  }

  /**
   * Removes an import configuration for a given dataset.
   * @param config_id The ID of the import configuration to remove.
   * @param dataset The dataset for which to remove the import configuration.
   * @param callback An optional callback function to call after the import configuration is removed.
   */
  protected removeImportConfig(config_id: string, dataset: string, callback?: Function): void {
    const importConfig = this.importConfigsByDataset.get(dataset)?.get(config_id);
    if (importConfig) this.importConfigsByDataset.get(dataset)?.delete(config_id);
    if (callback) callback('deleted');
  }

  /**
   * Fetches the import configurations for a given dataset.
   * @param dataset The name of the dataset to fetch import configurations for.
   * @returns A promise that resolves with an array of ImportConfig objects.
   */
  public fetchImportConfigsForDataset(dataset: string): Promise<ImportConfig[]> {
    return new Promise<ImportConfig[]>((resolve, reject) => {
      this.citadelCoreApi.getImportConfigsForDataset(dataset).subscribe({
        next: importConfigsRes => {
          let importConfigs = [];
          importConfigsRes.forEach(importConfig => {
            this.addImportConfig(importConfig, addedImportConfig => {
              importConfigs.push(addedImportConfig);
            });
          });
          resolve(importConfigs);
        },
        error: reject
      });
    });
  }

  /**
   * Creates a new import configuration.
   * @param importConfig The import configuration to create.
   * @returns A promise that resolves with the created import configuration.
   */
  public createImportConfig(importConfig: ImportConfig): Promise<ImportConfig> {
    return new Promise<ImportConfig>((resolve, reject) => {
      this.citadelCoreApi.createImportConfig(importConfig).subscribe({
        next: importConfigRes => {
          this.addImportConfig(importConfigRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Updates an existing import configuration.
   * @param importConfig The import configuration to update.
   * @returns A promise that resolves with the updated import configuration.
   */
  public updateImportConfig(importConfig: ImportConfig): Promise<ImportConfig> {
    return new Promise<ImportConfig>((resolve, reject) => {
      this.citadelCoreApi.updateImportConfig(importConfig).subscribe({
        next: importConfigRes => {
          this.addImportConfig(importConfigRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Deletes an import configuration.
   * @param importConfig The import configuration to delete.
   * @returns A promise that resolves with the ID of the deleted import configuration.
   */
  public deleteImportConfig(importConfig: ImportConfig): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.citadelCoreApi.deleteImportConfig(importConfig).subscribe({
        next: importConfigIdRes => {
          this.removeImportConfig(importConfigIdRes, importConfig.dataset, resolve)
        },
        error: reject
      });
    });
  }
}
