import { gql } from 'apollo-angular';
import { RESOURCE_CATALOG_INFO_FRAGMENT } from './fragments/_resourceCatalog';

export const RESOURCE_CATALOGS_QUERY = gql`
  query resourceCatalogs {
    resourceCatalogs
  }
`;

export const CREATE_RESOURCE_CATALOG_MUTATION = gql`
  mutation createResourceCatalog($data: ResourceCatalogCreateInput!) {
    createResourceCatalog(data: $data) {
      ...ResourceCatalogInfo
    }
  }
  ${RESOURCE_CATALOG_INFO_FRAGMENT}
`;

export const UPDATE_RESOURCE_CATALOG_MUTATION = gql`
  mutation updateResourceCatalog(
    $where: ResourceCatalogWhereUniqueInput!
    $data: ResourceCatalogUpdateInput!
  ) {
    updateResourceCatalog(where: $where, data: $data) {
      ...ResourceCatalogInfo
    }
  }
  ${RESOURCE_CATALOG_INFO_FRAGMENT}
`;

export const DELETE_RESOURCE_CATALOG_MUTATION = gql`
  mutation deleteResourceCatalog($where: ResourceCatalogWhereUniqueInput!) {
    deleteResourceCatalog(where: $where) {
      catalog_id
    }
  }
`;

export const RESOURCE_CATALOG_SUBSCRIPTION = gql`
  subscription resourceCatalogSubscription {
    resourceCatalogSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
