import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AvatarByUser, UserService } from '@sparte/citadel-core';
import { AuthComponent } from '../auth/auth.component';
import { ApiService } from '@sparte/api';

@Component({
  selector: 'sparte-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnexionComponent implements OnInit {
  profiles: string[];
  @Input() onProfileSelected: Function;
  avatarByUsers: AvatarByUser[] = []

  constructor(
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private authComponent: AuthComponent
  ) { }

  async ngOnInit(): Promise<void> {
    this.profiles = this.apiService.profiles;
    this.avatarByUsers = await this.userService.getUsersAvatars(this.profiles);
    this.cdRef.detectChanges();
  }

  selectProfile(profile: AvatarByUser): void {
    this.onProfileSelected(profile);
  }

  addProfile() {
    this.authComponent.setFormMode('LOGIN');
  }

  async deleteProfile(event: MouseEvent, profile: AvatarByUser): Promise<void> {
    event?.stopPropagation();
    this.apiService.removeProfile(profile.mail);
    this.profiles = [...this.apiService.profiles];
    if (this.profiles.length === 0) {
      this.authComponent.setFormMode('LOGIN');
      return;
    };
    this.avatarByUsers = await this.userService.getUsersAvatars(this.profiles);
    this.cdRef.detectChanges();
  }
}
