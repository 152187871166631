import { Injectable } from '@angular/core';
import {
  ApiService,
  ARCHIVE_CITADEL_USER_MUTATION,
  CITADEL_PERMISSIONS_QUERY,
  CITADEL_USERS_QUERY,
  CREATE_CITADEL_USER_MUTATION,
  CREATE_ROLE_MUTATION,
  CREATE_USER_GROUP_MUTATION,
  DELETE_ROLE_MUTATION,
  DELETE_USER_GROUP_MUTATION,
  ROLES_QUERY,
  UPDATE_CITADEL_USER_MUTATION,
  UPDATE_ROLE_MUTATION,
  UPDATE_USER_GROUP_MUTATION,
  USER_GROUPS_QUERY,
  USER_NODES_QUERY,
  CREATE_USER_NODE_MUTATION,
  UPDATE_USER_NODE_MUTATION,
  DELETE_USER_NODE_MUTATION,
  CITADEL_USER_SUBSCRIPTION,
  USER_GROUP_SUBSCRIPTION,
  USER_NODE_SUBSCRIPTION,
  GET_USERS_AVATARS_QUERY,
  CHECK_USER_PIN,
  UPDATE_SELF_AVATAR_MUTATION
} from '@sparte/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CitadelUser } from '../models/citadelUser.model';
import { Role } from '../models/role.model';
import { UserGroup } from '../models/userGroup.model';
import { UserNode } from '../models/userNode.model';


@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private api: ApiService) { }

  checkUserPin(mail: string): Observable<boolean> {
    return this.api.watchQuery(CHECK_USER_PIN, { mail })
      .pipe(
        map((result: any) => {
          return result.data['checkUserPin']
        })
      )
  }

  //CITADEL USER

  citadelUserSubscription(): Observable<any> {
    return this.api.subscription(CITADEL_USER_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          if (result?.data) {
            return result.data['citadelUserSubscription'];
          }
          else return;
        })
      )
  }

  getCitadelUsers(): Observable<any> {
    return this.api.watchQuery(CITADEL_USERS_QUERY)
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['citadelUsers']);
        })
      )
  }

  getUsersAvatars(mail_in: string[]): Observable<any[]> {
    return this.api.watchQuery(GET_USERS_AVATARS_QUERY, { where: { mail_in } })
      .pipe(
        map((result: any) => {
          return result.data['getUsersAvatars']
        })
      )
  }

  createCitadelUser(citadelUser: CitadelUser): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_USER_MUTATION, {
        data: citadelUser.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCitadelUser']
        })
      );
  }

  updateCitadelUser(citadelUser: CitadelUser): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_USER_MUTATION, {
        where: {
          user_id: citadelUser.id
        },
        data: citadelUser.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelUser'];
        })
      );
  }

  updateSelfAvatar(avatar_id: string): Observable<any> {
    return this.api
      .mutation(UPDATE_SELF_AVATAR_MUTATION, {
        data: { avatar_id }
      })
      .pipe(
        map((result: any) => {
          return result.data['updateSelfAvatar'];
        })
      );
  }

  archiveCitadelUser(citadelUserId: string): Observable<any> {
    return this.api
      .mutation(ARCHIVE_CITADEL_USER_MUTATION, {
        where: {
          user_id: citadelUserId
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['archiveCitadelUser'].user_id;
        })
      );
  }

  // deleteCitadelUser(citadelUserId: string): Observable<string> {
  //   return this.api
  //     .mutation(DELETE_CITADEL_USER_MUTATION, { where: { id: citadelUserId } }).pipe(
  //       map((result: any) => {
  //         return result.data['deleteCitadelUser'].id
  //       })
  //     );
  // }

  // USER NODES
  userNodeSubscription(group_id_in: string[]): Observable<any> {
    return this.api.subscription(USER_NODE_SUBSCRIPTION, { where: { group_id_in } })
      .pipe(
        map((result: any) => {
          if (result ? result.data : false) {
            return result.data['userNodeSubscription'];
          }
          else return;
        })
      )
  }

  getUserNodes(group_id_in: string[]): Observable<any> {
    return this.api.watchQuery(USER_NODES_QUERY, { where: { group_id_in } })
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['getUserNodes']);
        })
      )
  }

  createUserNode(userNode: UserNode): Observable<any> {
    return this.api
      .mutation(CREATE_USER_NODE_MUTATION, {
        data: userNode.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createUserNode']
        })
      );
  }

  updateUserNode(userNode: UserNode): Observable<any> {
    return this.api
      .mutation(UPDATE_USER_NODE_MUTATION, {
        where: userNode.whereUniqueValue,
        data: userNode.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateUserNode'];
        })
      );
  }

  deleteUserNode(userNode: UserNode): Observable<string> {
    return this.api
      .mutation(DELETE_USER_NODE_MUTATION, { where: userNode.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteUserNode'].node_id
        })
      );
  }

  //USER GROUP

  userGroupSubscription(): Observable<any> {
    return this.api.subscription(USER_GROUP_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          if (result ? result.data : false) {
            return result.data['userGroupSubscription'];
          }
          else return;
        })
      )
  }

  getUserGroups(): Observable<any> {
    return this.api.watchQuery(USER_GROUPS_QUERY)
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['userGroups']);
        })
      )
  }

  createUserGroup(userGroup: UserGroup): Observable<any> {
    return this.api
      .mutation(CREATE_USER_GROUP_MUTATION, {
        data: userGroup.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createUserGroup']
        })
      );
  }

  updateUserGroup(userGroup: UserGroup): Observable<any> {
    return this.api
      .mutation(UPDATE_USER_GROUP_MUTATION, {
        where: {
          group_id: userGroup.id
        },
        data: userGroup.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateUserGroup'];
        })
      );
  }

  deleteUserGroup(userGroupId: string): Observable<string> {
    return this.api
      .mutation(DELETE_USER_GROUP_MUTATION, { where: { group_id: userGroupId } }).pipe(
        map((result: any) => {
          return result.data['deleteUserGroup'].group_id
        })
      );
  }

  //ROLE

  getRoles(group_id_in: string[]): Observable<any> {
    return this.api.watchQuery(ROLES_QUERY, { where: { group_id_in } })
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['roles']);
        })
      )
  }

  createRole(role: Role): Observable<any> {
    return this.api
      .mutation(CREATE_ROLE_MUTATION, {
        data: role.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createRole']
        })
      );
  }

  updateRole(role: Role): Observable<any> {
    return this.api
      .mutation(UPDATE_ROLE_MUTATION, {
        where: {
          role_id: role.id,
          group_id: role.group_id
        },
        data: role.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateRole'];
        })
      );
  }

  deleteRole(role: Role): Observable<string> {
    return this.api
      .mutation(DELETE_ROLE_MUTATION, {
        where: {
          role_id: role.id,
          group_id: role.group_id
        }
      }).pipe(
        map((result: any) => {
          return result.data['deleteRole'].role_id
        })
      );
  }


  //CITADEL PERMISSION

  getCitadelPermissions(): Observable<any> {
    return this.api.watchQuery(CITADEL_PERMISSIONS_QUERY)
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['citadelPermissions']);
        })
      )
  }
}
