import { gql } from 'apollo-angular';
import { ROTATION_IMAGE_ARRAY_INFO_FRAGMENT } from './fragments/_rotationImageArray';

export const ROTATION_IMAGE_ARRAYS_QUERY = gql`
  query rotationImageArrays {
    rotationImageArrays {
      ...RotationImageArrayInfo
    }
  }
  ${ROTATION_IMAGE_ARRAY_INFO_FRAGMENT}
`;

export const CREATE_ROTATION_IMAGE_ARRAY_MUTATION = gql`
  mutation createRotationImageArray($data: RotationImageArrayCreateInput!) {
    createRotationImageArray(data: $data) {
      ...RotationImageArrayInfo
    }
  }
  ${ROTATION_IMAGE_ARRAY_INFO_FRAGMENT}
`;
export const UPDATE_ROTATION_IMAGE_ARRAY_MUTATION = gql`
  mutation updateRotationImageArray(
    $where: RotationImageArrayWhereUniqueInput!
    $data: RotationImageArrayUpdateInput!
  ) {
    updateRotationImageArray(where: $where, data: $data) {
      ...RotationImageArrayInfo
    }
  }
  ${ROTATION_IMAGE_ARRAY_INFO_FRAGMENT}
`;

export const DELETE_ROTATION_IMAGE_ARRAY_MUTATION = gql`
  mutation deleteRotationImageArray(
    $where: RotationImageArrayWhereUniqueInput!
  ) {
    deleteRotationImageArray(where: $where) {
      id
    }
  }
`;
