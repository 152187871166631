import { gql } from 'apollo-angular';

import { CITADEL_TASK_INFO_FRAGMENT } from './fragments/_citadelTask';

export const CITADEL_TASKS_QUERY = gql`
  query citadelTasks($where: ParentTaskWhereUniqueInput) {
    citadelTasks(where: $where)
  }
`;

export const CITADEL_TASK_SUBSCRIPTION = gql`
  subscription citadelTaskSubscription($where: ParentTaskWhereInput) {
    citadelTaskSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_CITADEL_TASK_MUTATION = gql`
  mutation createCitadelTask($data: CitadelTaskCreateInput!) {
    createCitadelTask(data: $data) {
      ...CitadelTaskInfo
    }
  }
  ${CITADEL_TASK_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_TASK_MUTATION = gql`
  mutation updateCitadelTask(
    $where: CitadelTaskWhereUniqueInput!
    $data: CitadelTaskUpdateInput!
  ) {
    updateCitadelTask(where: $where, data: $data) {
      ...CitadelTaskInfo
    }
  }
  ${CITADEL_TASK_INFO_FRAGMENT}
`;

export const SIMULATE_TASK_EVENT_MUTATION = gql`
  mutation simulateTaskEvent(
    $where: CitadelTaskWhereUniqueInput!
    $data: CitadelTaskEventInput!
  ) {
    simulateTaskEvent(where: $where, data: $data) {
      ...CitadelTaskInfo
    }
  }
  ${CITADEL_TASK_INFO_FRAGMENT}
`;

export const DELETE_CITADEL_TASK_MUTATION = gql`
  mutation deleteCitadelTask(
    $where: CitadelTaskWhereUniqueInput!
  ) {
    deleteCitadelTask(where: $where) {
      task_id
    }
  }
`;