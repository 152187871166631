import { Deserializable } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { AssetZoneTypeEnum, NodeData } from '../utils/citadel-core.interface';
import { CitadelAsset } from './citadelAsset.model';
import { CitadelStorage } from './citadelStorage.model';

export interface StatusInterface {
  category: string;
  state: number;
}

export class AssetZone implements Deserializable {
  public id: string;
  public parent_asset_id: string;
  public sort_order: number;
  public parent_zone_id: string;
  public designation: string;
  public tag: string;
  public type: AssetZoneTypeEnum;
  public created_at: Date;
  public updated_at: Date;
  protected nodeData: NodeData;
  public readonly typeName = "AssetZone";
  constructor(protected citadelCoreService: CitadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, zone_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = zone_id;
    if (this.created_at) this.created_at = new Date(created_at);
    if (this.updated_at) this.updated_at = new Date(updated_at);
    return this;
  }

  get whereUniqueValue(): any {
    return {
      zone_id: this.id,
      parent_asset_id: this.parent_asset_id
    };
  }

  get createValue(): any {
    const {
      id,
      typeName,
      citadelCoreService,
      nodeData,
      ..._this
    } = this;
    return {
      ..._this
    };
  }

  get updateValue(): any {
    const {
      id,
      typeName,
      parent_asset_id,
      citadelCoreService,
      nodeData,
      ..._this
    } = this;
    return {
      ..._this
    };
  }

  get label(): string {
    return this.designation;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.fullTag}`.toLowerCase();
  }

  get parentNode(): CitadelAsset | AssetZone {
    if (this.parent_zone_id) return this.citadelCoreService.getAssetZone(this.parent_zone_id) ? this.citadelCoreService.getAssetZone(this.parent_zone_id) : null;
    else return this.parent_asset_id ? this.citadelCoreService.getCitadelAsset(this.parent_asset_id) : null;
  }

  get directChildStorages(): CitadelStorage[] {
    return this.citadelCoreService.getCitadelStorages.filter(storage => storage.parent_zone_id === this.id);
  }

  get directChildAssets(): CitadelAsset[] {
    return this.citadelCoreService.getCitadelInfrastructures.filter(asset => asset.parent_zone_id === this.id);
  }

  get directAssetZones(): AssetZone[] {
    return this.citadelCoreService.getAssetZones.filter(zone => zone.parent_zone_id === this.id);
  }

  get directChildren(): (CitadelAsset | AssetZone | CitadelStorage)[] {
    return [...this.directChildAssets, ...this.directAssetZones, ...this.directChildStorages];
  }

  get allChildren(): (CitadelAsset | AssetZone | CitadelStorage)[] {
    return this.directChildren
    .sort((zoneA, zoneB) => zoneA?.sort_order > zoneB?.sort_order ? 1 : -1)
    .reduce((allChildren, child) => {
      if (child instanceof AssetZone) return [...allChildren, child, ...(child?.allChildren) || []];
      else return [...allChildren, child];
    }, []);
  }

  get parentAssetIds(): string[] {
    if (this.parent_zone_id) {
      return this.citadelCoreService.getAssetZone(this.parent_zone_id).parentAssetIds;
    }
    else if (this.parent_asset_id) return this.citadelCoreService.getCitadelAsset(this.parent_asset_id).parentAssetIds.concat(this.parent_asset_id)
    else return [];
  }

  get parentZoneIds(): string[] {
    if (this.parent_zone_id) {
      return this.citadelCoreService.getAssetZone(this.parent_zone_id)?.parentZoneIds.concat(this.parent_zone_id) || [];
    }
    else return [];
  }

  get fullSortOrder(): string {
    if (!this.parent_zone_id) return this.sort_order?.toString().padStart(3, "0") || '000';
    const parentSortOrder = this.citadelCoreService.getAssetZone(this.parent_zone_id)?.fullSortOrder || '';
    return `${parentSortOrder}${this.sort_order?.toString().padStart(3, "0") || '000'}`;
  }

  get fullTag(): string {
    if (!this.parent_zone_id) return this.tag;
    const parentTags = this.citadelCoreService.getAssetZone(this.parent_zone_id)?.fullTag || '';
    return `${parentTags}${this.tag}`;
  }
}
