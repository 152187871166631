<section *mobxAutorun="{dontDetach: true}">
  <!-- Connexion -->
  <div class="grid grid-cols-4 gap-y-[60px] gap-x-20 items-center">
    <div *ngFor="let profile of avatarByUsers"
         class="text-center cursor-pointer"
         (click)="selectProfile(profile)">
      <div class="relative group">
        <mat-icon class="absolute z-10 invisible group-hover:visible right-2"
                  (click)="deleteProfile($event, profile)">close</mat-icon>
        <img *ngIf="profile.avatar_url"
             [src]="profile.avatar_url"
             alt=""
             class="flex rounded-[4rem] w-[112px] h-[115px] m-auto hover:shadow-2xl hover:scale-110 ease-in-out">
      </div>
      <div class="relative group">
        <div class="flex rounded-[4rem] w-[112px] h-[115px] m-auto text-8xl"
             *ngIf="!profile.avatar_url">
          <mat-icon class="absolute z-10 invisible group-hover:visible right-2"
                    (click)="deleteProfile($event, profile)">close</mat-icon>
          <mat-icon class="runner-icon gray-content w-[100%] h-[100%] cursor-pointer hover:shadow-2xl rounded-[4rem] hover:scale-110"
                    style="width: 100%; height:100%; font-size: 90px">person</mat-icon>
        </div>
      </div>
      <div class="flex w-1/2 pt-4 m-auto text-lg font-medium uppercase text-sparteBlue">{{profile.full_name}}</div>
    </div>
    <div class="w-full h-full cursor-pointer">
      <div class="border border-[#707070] border-dashed rounded-[4rem] w-[114px] h-[117px] m-auto hover:shadow-2xl hover:scale-110 ease-in-out">
        <div class="border-[50px] border-white flex rounded-[4rem] w-[112px] h-[115px] m-auto relative"
             (click)="addProfile()">
          <span class="material-symbols-outlined absolute left-[-24px] right-0 top-[-22px] bottom-0 text-[#707070] cursor-pointer"
                style="font-size: 60px"> add </span>
        </div>
      </div>
    </div>
  </div>
</section>