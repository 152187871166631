import { Deserializable } from '@sparte/utils';


export class ItemGroup implements Deserializable {
  public id: string;
  public designation: string;
  public created_at: Date;
  public updated_at: Date;
  public readonly typeName = "ItemGroup";

  deserialize(input: any): this {
    const { __typename, group_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = group_id;
    this.created_at = new Date(this.created_at);
    this.updated_at = new Date(this.updated_at);
    return this;
  }

  get createValue() {
    return {
      designation: this.designation,
    };
  }

  get updateValue() {
    return {
      designation: this.designation,
    };
  }

  get whereUniqueValue() {
    return {
      group_id: this.id,
    };
  }

  get label() {
    return this.designation;
  }

  get searchTerm() {
    return this.designation;
  }
}