import { gql } from 'apollo-angular';

export const ASSET_ZONE_INFO_FRAGMENT = gql`
  fragment AssetZoneInfo on AssetZone {
    zone_id
    parent_asset_id
    parent_zone_id
    designation
    type
    sort_order
    created_at
    updated_at
  }
`;
