import { gql } from 'apollo-angular';

export const CITADEL_USER_INFO_FRAGMENT = gql`
  fragment CitadelUserInfo on CitadelUser {
    user_id
    last_name
    first_name
    avatar_id
    mail
    roles_ids
    companies_ids
    groups_ids
    created_at
    updated_at
    auth_created
  }
`;
