import { gql } from 'apollo-angular';

export const SHARE_PROJECT_INFO_FRAGMENT = gql`
  fragment ShareProjectInfo on ShareProject {
    project_id
    name
    created_at
    updated_at
  }
`;
