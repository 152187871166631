import { gql } from "apollo-angular";

export const RESOURCE_BY_STOCK_INFO_FRAGMENT = gql`
  fragment ResourceByStockInfo on ResourceByStock {
    stock_id
    resource_id
    restock
    threshold
    restock_value
  }
`;