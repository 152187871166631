import { gql } from 'apollo-angular';


export const EVENT_ACTION_INFO_FRAGMENT = gql`
  fragment EventActionInfo on EventAction {
    post
    time_id
    event_date
    event_id
    assigned_to
    title
    status
    description
    priority
  }
`;