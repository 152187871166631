import { gql } from 'apollo-angular';

import { CARTO_LINK_INFO_FRAGMENT } from './fragments/_cartoLink';

export const CARTO_LINKS_QUERY = gql`
  query cartoLinks {
    processLinks
  }
`;

export const CARTO_LINK_SUBSCRIPTION = gql`
  subscription cartoLinkSubscription {
    processLinkSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_CARTO_LINK_MUTATION = gql`
  mutation createCartoLink($data: ProcessLinkCreateInput!) {
    createProcessLink(data: $data) {
      ...CartoLinkInfo
    }
  }
  ${CARTO_LINK_INFO_FRAGMENT}
`;

export const UPDATE_CARTO_LINK_MUTATION = gql`
  mutation updateCartoLink(
    $where: ProcessLinkWhereUniqueInput!
    $data: ProcessLinkUpdateInput!
  ) {
    updateProcessLink(where: $where, data: $data) {
      ...CartoLinkInfo
    }
  }
  ${CARTO_LINK_INFO_FRAGMENT}
`;

export const DELETE_CARTO_LINK_MUTATION = gql`
  mutation deleteCartoLink(
    $where: ProcessLinkWhereUniqueInput!
  ) {
    deleteProcessLink(where: $where) {
      link_id
    }
  }
`;