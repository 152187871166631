import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ViewChildren } from '@angular/core';
import { Dropdown, DropdownOptions, initFlowbite } from 'flowbite';


@Component({
  selector: 'sparte-dropdown',
  templateUrl: './sparte-dropdown.component.html',
  styleUrls: ['./sparte-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparteDropdownComponent implements AfterViewInit {
  @ViewChildren('dropdownDefaultButton') dropdownDefaultButton;
  @ViewChildren('dropdown') dropdownDefaultMenu;
  @Input() public title: string;
  @Input() public items: any[] = [];
  @Input() public prefixIcon: string;
  @Input() public customClasses: string = 'text-white bg-sparteBlue hover:bg-blue-800';
  @Input() public selectAction: Function;
  @Input() public allowClear: boolean = false;
  @Input() public showChevron: boolean = false;
  @Input() public id: string;
  @Output() public selected = new EventEmitter<string>();
  public dropdown: Dropdown;
  ngAfterViewInit(): void {
    initFlowbite();
    const $triggerEl = this.dropdownDefaultButton;
    const $targetEl = this.dropdownDefaultMenu;
    const options: DropdownOptions = {
      offsetDistance: 5,
    }

    this.dropdown = new Dropdown($targetEl.first.nativeElement, $triggerEl.first.nativeElement, options);
  }

  onItemSelect(item) {
    this.selectAction(item);
    this.selected.emit(item);
    this.dropdown?.hide();
  }

  clearSelection(event) {
    event.stopPropagation();
    this.selectAction(null);
    this.selected.emit(null);
    this.dropdown?.hide();
  }

  getDropdownID() {
    return this.id + '-dropdown';
  }

  trackByFn(index, item) {
    return item.id;
  }
}
