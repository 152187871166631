import { gql } from 'apollo-angular';

export const STOCK_NETWORK_INFO_FRAGMENT = gql`
  fragment StockNetworkInfo on StockNetwork {
    network_id
    designation
    from_sap
    created_at
    updated_at
  }
`;
