import { gql } from 'apollo-angular';

export const STOCK_LOGISTIC_FLOW_INFO_FRAGMENT = gql`
  fragment StockLogisticFlowInfo on StockLogisticFlow {
    flow_id
    name
    targets {
      business_ref
      network_ids
    }
    hpriority
    paused
    destination_id
    created_at
    updated_at
  }
`;
