import { gql } from "apollo-angular";

export const REQUESTED_RESOURCES_QUERY = gql`
  query requestedResources {
    requestedResources
  }
`;

export const REQUESTED_RESOURCE_SUBSCRIPTION = gql`
  subscription requestedResourceSubscription {
    requestedResourceSubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;