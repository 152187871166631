<section *mobxAutorun="{ dontDetach: true }" class="relative flex items-center justify-center w-full h-full">
  <ng-container *ngIf="initialized">
    <button
      class="flex items-center justify-center w-full h-full"
      id="dropdownButton"
      #dropdownButton
      data-dropdown-toggle="dropdownMenu">
      <mat-icon *ngIf="!avatarUrl">person</mat-icon>
      <img *ngIf="avatarUrl" [src]="avatarUrl" class="w-10 h-10 border rounded-full min-w-fit" />
    </button>
    <!-- Dropdown menu -->
    <div
      id="dropdownMenu"
      (mouseover)="mouseOver = true"
      (mouseleave)="mouseOver = false"
      #dropdownMenu
      class="z-[1000] hidden bg-white divide-y divide-gray-100 rounded-lg shadow border w-44 forceFixed">
      <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownButton">
        <li>
          <button
            id="accountButton"
            #accountButton
            data-dropdown-toggle="accountMenu"
            data-dropdown-placement="right-start"
            type="button"
            class="flex items-center justify-between w-full px-4 py-2 cursor-default hover:bg-gray-100">
            Compte
            <svg
              class="w-2.5 h-2.5 ml-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
          </button>
          <div
            id="accountMenu"
            #accountMenu
            class="z-10 hidden bg-white border divide-y divide-gray-100 rounded-lg shadow w-52">
            <ul class="py-2 text-sm text-gray-700" aria-labelledby="accountButton">
              <li>
                <span (click)="updatePassword()" class="block px-4 py-2 hover:bg-gray-100">Modifier Mot de Passe</span>
              </li>
              <li>
                <span (click)="updatePin()" class="block px-4 py-2 hover:bg-gray-100">Modifier Pin</span>
              </li>
              <li *ngIf="canUpdateAvatar">
                <span (click)="inputFile.click()" class="block px-4 py-2 hover:bg-gray-100">Modifier Avatar</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <span (click)="disconnectUser()" class="block px-4 py-2 hover:bg-sparteRed-50 hover:text-sparteRed">
            Se Déconnecter
          </span>
        </li>
      </ul>
    </div>
    <!-- <mat-menu #userMenu="matMenu">
    <button mat-menu-item
           ">Se Déconnecter</button>
    <button mat-menu-item
            >Modifier Mot de Passe</button>
    <button
            mat-menu-item
            >Modifier Avatar</button>
  </mat-menu> -->
    <input
      #inputFile
      class="hidden"
      type="file"
      name="file"
      [multiple]="false"
      (change)="onUpload($event.target.files)"
      accept="image/*" />
  </ng-container>
</section>
