import { EventEmitter, Injectable } from '@angular/core';
import { ApiService, errorMessageParser } from '@sparte/api';
import { CitadelCoreApiService, CitadelCoreService, CitadelStock, EntityArticlesByStock, OrderArticlesByStock } from '@sparte/citadel-core';
import { CurrentRouteService, SparteModulesService } from '@sparte/ui';
import { makeObservable, when } from 'mobx';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ArchivedArticle } from '../models/archivedArticle.model';
import { ArchivedOrder } from '../models/archivedOrder.model';
import { ArticleHistory } from '../models/articleHistory.model';
import { DashboardStat } from '../models/dashboardStat.model';
import { EntityArticle } from '../models/entityArticle.model';
import { OrderArticle } from '../models/orderArticle.model';
import { OrderLog } from '../models/orderLog.model';
import { RequestedResource } from '../models/requestedResource.model';
import { RunnerStat } from '../models/runnerStat.model';
import { DeliverySlip, Shipment } from '../models/shipment.model';
import { StockBusiness } from '../models/stockBusiness.model';
import { StockLogisticFlow } from '../models/stockLogisticFlow.model';
import { StockNetwork } from '../models/stockNetwork.model';
import { StockOperation } from '../models/stockOperation.model';
import { StockOrder } from '../models/stockOrder.model';
import { StockRequest } from '../models/stockRequest.model';
import { StockStorageEntity } from '../models/stockStorageEntity.model';
import { AvailableQuantitiesForReference, HistoryTypeEnum } from '../utils/stock-interfaces';
import { AccountingEntry } from './../models/accountingEntry.model';
import { StockApiService } from './stock-api.service';
import { StockUsersService } from './stock-users.service';

@Injectable({
  providedIn: 'root'
})
export class StockInventoryService extends CitadelCoreService {
  public stockOrdersFetched: EventEmitter<boolean>;
  private stockBusinesses: Map<string, StockBusiness>;
  private stockNetworks: Map<string, StockNetwork>;
  private stockOrders: Map<string, StockOrder>;
  private archivedOrders: Map<string, ArchivedOrder>;
  private orderArticles: Map<string, OrderArticle>;
  public articlesForOrder: Map<string, Set<string>>;
  private entityArticles: Map<string, EntityArticle>;
  private articlesForEntity: Map<string, Set<string>>;
  private archivedArticles: Map<string, ArchivedArticle>;
  private stockRequests: Map<string, StockRequest>;
  private requestedResources: Map<string, RequestedResource>;
  private resourcesForRequests: Map<string, Set<string>>;
  private accountingEntries: Map<string, AccountingEntry>;
  private articleHistories: Map<string, ArticleHistory>;
  private articleHistoryMap: Map<string, Set<string>>;
  private stockStorageEntities: Map<string, StockStorageEntity>;
  private stockStorageEntitiesByBarcode: Map<string, string>;
  private entitiesByParentIds: Map<string, Set<string>>;
  private articlesByShipmentRef: Map<string, Set<string>>;
  private deliverySlipsByShipmentRef: Map<string, Set<string>>;
  private deliverySlips: Map<string, DeliverySlip>;
  private stockLogisticFlows: Map<string, StockLogisticFlow>;
  private tempLogisticFlows: Map<string, StockLogisticFlow>;
  private stockOperations: Map<string, StockOperation>;
  private dashboardStats: Map<string, DashboardStat[]>;
  private runnerStats: Map<string, RunnerStat[]>;
  private shipments: Map<string, Shipment>;
  public defaultStorageLabel: string;
  private globalSearchTerms: Map<string, string>;
  private reloadSub: Subscription;
  constructor(
    private apiService: ApiService,
    protected sparteModulesService: SparteModulesService,
    protected currentRouteService: CurrentRouteService,
    protected citadelCoreApi: CitadelCoreApiService,
    private userService: StockUsersService,
    private stockApi: StockApiService,
    private toastr: ToastrService
  ) {
    super(sparteModulesService, currentRouteService, citadelCoreApi);
    makeObservable(this);
    this.stockOrdersFetched = new EventEmitter();
    this.subscriptionRefresh = new EventEmitter();
    this.stockBusinesses = new Map<string, StockBusiness>();
    this.stockNetworks = new Map<string, StockNetwork>();
    this.stockOrders = new Map<string, StockOrder>();
    this.archivedOrders = new Map<string, ArchivedOrder>();
    this.orderArticles = new Map<string, OrderArticle>();
    this.accountingEntries = new Map<string, AccountingEntry>();
    this.articlesForOrder = new Map<string, Set<string>>();
    this.entityArticles = new Map<string, EntityArticle>();
    this.articlesForEntity = new Map<string, Set<string>>();
    this.archivedArticles = new Map<string, ArchivedArticle>();
    this.articleHistories = new Map<string, ArticleHistory>();
    this.articleHistoryMap = new Map<string, Set<string>>();
    this.stockStorageEntities = new Map<string, StockStorageEntity>();
    this.stockStorageEntitiesByBarcode = new Map<string, string>();
    this.entitiesByParentIds = new Map<string, Set<string>>();
    this.articlesByShipmentRef = new Map<string, Set<string>>();
    this.stockLogisticFlows = new Map<string, StockLogisticFlow>();
    this.tempLogisticFlows = new Map<string, StockLogisticFlow>();
    this.stockOperations = new Map<string, StockOperation>();
    this.dashboardStats = new Map<string, DashboardStat[]>();
    this.runnerStats = new Map<string, RunnerStat[]>();
    this.shipments = new Map<string, Shipment>();
    this.globalSearchTerms = new Map<string, string>();
    this.stockRequests = new Map<string, StockRequest>();
    this.requestedResources = new Map<string, RequestedResource>();
    this.resourcesForRequests = new Map<string, Set<string>>();
    this.deliverySlipsByShipmentRef = new Map<string, Set<string>>();
    this.deliverySlips = new Map<string, DeliverySlip>();
    this.defaultStorageLabel = 'Quai de Livraison';

    when(() => this.apiService.apolloReady).then(async () => {
      this.apiService.authChanged.subscribe(async auth => {
        if (!auth) {
          this.clearData();
        }
        else {
          this.reloadDataSubscription();
        }
      });
    });

    when(() => this.apiService.isCitadelConnected).then(() => {
      this.citadelConnected = this.apiService.isCitadelConnected;
      this.apiService.citadelConnected.subscribe(connected => {
        if (!connected && this.citadelConnected) {
          this.clearData();
        }
        else if (connected && !this.citadelConnected) {
          this.fetchCurrentRouteData();
          this.reloadDataSubscription();
        }
        this.citadelConnected = connected;
      });
    });
  }

  public getSearchTerm(searchKey: string): string {
    return this.globalSearchTerms.get(searchKey);
  }

  public setSearchTerm(searchKey: string, term: string) {
    this.globalSearchTerms.set(searchKey, term);
  }

  public get getAllStockStorageEntities(): StockStorageEntity[] {
    return [...this.stockStorageEntities.values()];
  }

  public get getStockStorageEntities(): StockStorageEntity[] {
    return this.getAllStockStorageEntities.filter((entity) => entity.received);
  }

  public getStockStorageEntity(stockEntity_id: string): StockStorageEntity {
    return this.stockStorageEntities.get(stockEntity_id);
  }

  public getStockStorageEntitiesByOrder(order_id: string): StockStorageEntity[] {
    return this.getAllStockStorageEntities.filter(entity => entity?.order_id === order_id);
  }

  getStockStorageEntityByBarcode(barcode: string): StockStorageEntity {
    const entity_id = this.stockStorageEntitiesByBarcode.get(barcode);
    if (!entity_id) return;
    return this.stockStorageEntities.get(entity_id);
  }

  getStorageEntities(storage_id: string): StockStorageEntity[] {
    return this.getStockStorageEntities.filter(entity => entity.storage_id === storage_id).slice() || [];
  }

  public getChildrenEntities(parent_id: string): StockStorageEntity[] {
    if (!this.entitiesByParentIds.has(parent_id)) return [];
    const childIds = [...(this.entitiesByParentIds.get(parent_id) || [])];
    const entities = [];
    for (let i = 0; i < childIds.length; i++) {
      const entity_id = childIds[i];
      if (!this.stockStorageEntities.has(entity_id)) continue;
      entities.push(this.stockStorageEntities.get(entity_id));
    }
    return entities;
  }

  public getAllChildrenEntities(parent_id: string): StockStorageEntity[] {
    if (!this.entitiesByParentIds.has(parent_id)) return [];
    const childIds = [...(this.entitiesByParentIds.get(parent_id) || [])];
    const entities = [];
    for (let i = 0; i < childIds.length; i++) {
      const entity_id = childIds[i];
      if (!this.stockStorageEntities.has(entity_id)) continue;
      const childChildren = this.getAllChildrenEntities(entity_id);
      entities.push(this.stockStorageEntities.get(entity_id), ...childChildren);
    }
    return entities;
  }

  public get getStockBusinesses(): StockBusiness[] {
    return [...this.stockBusinesses.values()];
  }

  public getStockBusiness(business_ref: string): StockBusiness {
    return this.stockBusinesses.get(business_ref);
  }

  public async getFavoriteBusinessByUser(): Promise<string[]> {
    const favoriteBusiness = await this.fetchFavoriteBusinessByUser().catch(this.handleError) || [];
    return favoriteBusiness;
  }

  public getUserName(user_id: string): string {
    return this.userService.getUserName(user_id);
  }

  public get getStockNetworks(): StockNetwork[] {
    return [...this.stockNetworks.values()];
  }

  public getStockNetwork(network_id: string): StockNetwork {
    return this.stockNetworks.get(network_id);
  }

  public get getStockOrders(): StockOrder[] {
    return [...this.stockOrders.values()];
  }

  public getStockOrder(order_id: string): StockOrder {
    return this.stockOrders.get(order_id);
  }

  public get getArchivedOrders(): ArchivedOrder[] {
    return [...this.archivedOrders.values()];
  }

  public getArchivedOrder(order_id: string): ArchivedOrder {
    return this.archivedOrders.get(order_id);
  }

  public get getAllOrderArticles(): OrderArticle[] {
    return [...this.orderArticles.values()];
  }

  public getOrderArticles(order_id: string): OrderArticle[] {
    return [...(this.articlesForOrder.get(order_id) || [])]?.map(article_id => this.getOrderArticle(article_id)) ?? [];
  }

  public getOrderArticle(article_id: string): OrderArticle {
    return this.orderArticles.get(article_id);
  }

  public getStockOrderDeliveryDate(order_id: string): Date {
    const articles = this.getOrderArticles(order_id);
    if (!articles.length) return;
    return new Date(Math.min(...articles.map(article => article.delivery_date?.getTime())));
  }

  public get getAllEntityArticles(): EntityArticle[] {
    return [...this.entityArticles.values()];
  }

  public getEntityArticles(entity_id: string): EntityArticle[] {
    if (!this.articlesForEntity.has(entity_id)) return [];
    return [...this.articlesForEntity.get(entity_id)].reduce((acc, article_id) => {
      if (!this.entityArticles.has(article_id)) return acc;
      acc.push(this.entityArticles.get(article_id));
      return acc;
    }, []);
  }

  public getEntityArticle(instance_id: string): EntityArticle {
    return this.entityArticles.get(instance_id);
  }

  public getArticleHistory(instance_id: string): ArticleHistory[] {
    if (!this.articleHistoryMap.has(instance_id)) return [];
    return [...this.articleHistoryMap.get(instance_id)].reduce((acc, time_id) => {
      if (!this.articleHistories.has(time_id)) return acc;
      acc.push(this.articleHistories.get(time_id));
      return acc;
    }, []);
  }

  public getArchivedArticleHistory(archivedArticle: ArchivedArticle): ArticleHistory[] {
    if (!this.articleHistoryMap.has(archivedArticle.instance_id)) return [];
    return [...this.articleHistoryMap.get(archivedArticle.instance_id)].reduce((acc, time_id) => {
      if (!this.articleHistories.has(time_id)) return acc;
      const articleHistory = this.articleHistories.get(time_id);
      if ((articleHistory.history_type === HistoryTypeEnum.OutOfStock || articleHistory.history_type === HistoryTypeEnum.Shipped || articleHistory.history_type === HistoryTypeEnum.Requested) ?
        (articleHistory.time_id === archivedArticle.id) : (articleHistory.action_ts <= archivedArticle.archived_at)) {
        acc.push(articleHistory);
      }
      return acc;
    }, []);
  }

  public get getStockLogisticFlows(): StockLogisticFlow[] {
    return [...this.stockLogisticFlows.values()];
  }

  public get getTempStockLogisticFlows(): StockLogisticFlow[] {
    return [...this.tempLogisticFlows.values()];
  }

  public getStockLogisticFlow(flow_id: string): StockLogisticFlow {
    return this.stockLogisticFlows.get(flow_id);
  }

  public getTempStockLogisticFlow(flowId: string): StockLogisticFlow {
    return this.tempLogisticFlows.get(flowId);
  }

  public get getStockOperations(): StockOperation[] {
    return [...this.stockOperations.values()].filter(operation => !!operation.destination_id);
  }

  public getShipment(shipment_ref: string): Shipment {
    return this.shipments.get(shipment_ref);
  }

  public get getShipments(): Shipment[] {
    return [...this.shipments.values()];
  }

  public getDeliverySlip(entity_id: string): DeliverySlip {
    return this.deliverySlips.get(entity_id);
  }

  public getDeliverySlipsByShipmentRef(shipment_ref: string): DeliverySlip[] {
    return [...(this.deliverySlipsByShipmentRef.get(shipment_ref) || [])]?.map(deliverySlipId => this.getDeliverySlip(deliverySlipId));
  }

  public getStockOperation(operation_id: string): StockOperation {
    return this.stockOperations.get(operation_id);
  }

  public getStockOperationsForEntity(entity_id: string): StockOperation[] {
    return this.getStockOperations.filter(operation => operation.entity_id === entity_id);
  }

  public get getAllArchivedArticles(): ArchivedArticle[] {
    return [...this.archivedArticles.values()];
  }

  public get getArchivedArticles(): ArchivedArticle[] {
    return [...this.archivedArticles.values()].filter(article => article.archived);
  }

  public getArchivedArticle(time_id: string): ArchivedArticle {
    return this.archivedArticles.get(time_id)
  }

  public getDashboardStats(stat_name: string): DashboardStat[] {
    return this.dashboardStats.get(stat_name) || [];
  }

  public get getRunnerStats(): RunnerStat[] {
    return [...this.runnerStats.values()].flatMap(values => values);
  }

  public get getStockRequests(): StockRequest[] {
    return [...this.stockRequests.values()];
  }

  public getStockRequest(request_id: string): StockRequest {
    return this.stockRequests.get(request_id);
  }

  getRequestResources(request_id: string): RequestedResource[] {
    if (!this.resourcesForRequests.has(request_id)) return [];
    return [...this.resourcesForRequests.get(request_id)].reduce((acc, resource_id) => {
      if (!this.requestedResources.has(resource_id)) return acc;
      acc.push(this.requestedResources.get(resource_id));
      return acc;
    }, []);
  }

  public getAccountingEntry(entry_id: string): AccountingEntry {
    return this.accountingEntries.get(entry_id);
  }

  public get getAccountingEntries(): AccountingEntry[] {
    return Array.from(this.accountingEntries.values());
  }

  getEntityStock(entity_id: string): CitadelStock {
    const entity = this.stockStorageEntities.get(entity_id);
    if (!entity) return;
    return this.getBiznetDestination(entity.business_ref, entity.network_id, entity.shipment_ref);
  }

  getArticleStock(instance_id: string): CitadelStock {
    const article = this.entityArticles.get(instance_id) || this.orderArticles.get(instance_id);
    if (!article) return;
    return this.getBiznetDestination(article.business_ref, article.network_id, article.shipment_ref);
  }

  getBiznetDestination(business_ref: string, network_id: string, shipment_ref?: string): CitadelStock {
    if (shipment_ref) {
      const shipment = this.shipments.get(shipment_ref);
      if (shipment?.shipment_type === 'PICKUP') {
        return this.stockLogisticFlows.get('pickup')?.destination
      }
      return this.stockLogisticFlows.get('shipment')?.destination
    };
    const flow = this.getStockLogisticFlows.find(flow => flow.targets.some(target => target.business_ref === business_ref && (target.network_ids.includes('any') || target.network_ids.includes(network_id))));
    if (!flow) return this.stockLogisticFlows.get('default')?.destination;
    return flow.destination;
  }

  // STOCK BUSINESSES METHODS

  /**
   * add business to store
   * @param {any} stockBusiness business to add
   * @param {function} callback function to call when it is done
   */
  private addStockBusiness(stockBusiness: any, callback?: Function): void {
    if (!this.stockBusinesses.has(stockBusiness.reference)) {
      this.stockBusinesses.set(stockBusiness.reference, new StockBusiness(this).deserialize(stockBusiness));
    }
    else {
      this.stockBusinesses.get(stockBusiness.reference).deserialize(stockBusiness);
    }
    if (callback) callback(this.stockBusinesses.get(stockBusiness.reference));
  }

  /**
   * remove stock business from map
   * @param {string} stockbusinessRef business id
   * @param {function} callback function to check when it is done
   */
  private removeStockBusiness(stockbusinessRef: string, callback?: Function): void {
    this.stockBusinesses.delete(stockbusinessRef);
    if (callback) callback('deleted');
  }

  /**
   * Fetch stock businesses from database
   * @returns {Promise<void>} resolves when businesses are fetched
   */
  private async fetchStockBusinesses(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockBusinesses')) return resolve();
      this.stockApi.getStockBusinesses().subscribe({
        next: stockBusinesses => {
          stockBusinesses.forEach(stockBusiness => {
            this.addStockBusiness(stockBusiness);
          });
          this.fetchedObjects.push('StockBusinesses');
          if (!this.storeSubscriptions.has('StockBusiness')) {
            const businessSub = this.stockApi.stockBusinessSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockBusiness(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockBusiness(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockBusiness', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockBusiness', businessSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create stockBusiness in database
   * @param {StockBusiness} newBusiness business to create
   * @returns {Promise<StockBusiness>} created StockBusiness
   */
  public async createStockBusiness(newBusiness: StockBusiness): Promise<StockBusiness> {
    return new Promise<StockBusiness>((resolve, reject) => {
      this.stockApi.createStockBusiness(newBusiness).subscribe({
        next: stockBusinessRes => {
          this.addStockBusiness(stockBusinessRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockBusiness in database
   * @param {StockBusiness} business business to update
   * @returns {Promise<StockBusiness>} updated StockBusiness
   */
  public async updateStockBusiness(business: StockBusiness): Promise<StockBusiness> {
    return new Promise<StockBusiness>((resolve, reject) => {
      this.stockApi.updateStockBusiness(business).subscribe({
        next: stockBusinessRes => {
          this.addStockBusiness(stockBusinessRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockBusiness in database
   * @param {StockBusiness} business business to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteStockBusiness(business: StockBusiness): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.deleteStockBusiness(business).subscribe({
        next: stockbusinessRefRes => {
          this.removeStockBusiness(stockbusinessRefRes, resolve)
        },
        error: reject
      });
    });
  }

  //FAVORITE BUSINESS BY USER METHODS
  /**
  * Fetch favoriteBusinessByUser from database
  * @returns {Promise<void>} resolves when favoriteBusinessByUser are fetched
  */
  private async fetchFavoriteBusinessByUser(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.stockApi.getFavoriteBusinessByUser().subscribe({
        next: resolve,
        error: reject
      });
    });
  }

  /**
   * create favoriteBusinessByUser in database
   * @param {any} newFavoriteBusiness business to create
   * @returns {Promise<void>} created FavoriteBusinessByUser
   */
  public async createFavoriteBusinessByUser(business_ref: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.createFavoriteBusinessByUser(business_ref).subscribe({
        next: favoriteBusinessRes => {
          this.addStockBusiness(favoriteBusinessRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete favoriteBusinessByUser in database
   * @param {any} favoriteBusiness favoriteBusiness to delete
   * @returns {Promise<void>} deleted favoriteBusinessByUser
   */
  public async deleteFavoriteBusinessByUser(business_ref: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.deleteFavoriteBusinessByUser(business_ref).subscribe({
        next: favoriteBusinessRes => {
          this.removeStockBusiness(favoriteBusinessRes, resolve);
        },
        error: reject
      });
    });
  }

  // STOCK NETWORKS METHODS

  /**
   * add network to store
   * @param {any} stockNetwork network to add
   * @param {function} callback function to call when it is done
   */
  private addStockNetwork(stockNetwork: any, callback?: Function): void {
    if (!this.stockNetworks.has(stockNetwork.network_id)) {
      this.stockNetworks.set(stockNetwork.network_id, new StockNetwork(this).deserialize(stockNetwork));
    }
    else {
      this.stockNetworks.get(stockNetwork.network_id).deserialize(stockNetwork);
    }
    if (callback) callback(this.stockNetworks.get(stockNetwork.network_id));
  }

  /**
   * remove stock network from map
   * @param {string} networkId network id
   * @param {function} callback function to call when it is done
   */
  private removeStockNetwork(networkId, callback?: Function): void {
    this.stockNetworks.delete(networkId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch stock networks from database
   * @returns {Promise<void>} returns when it is done
   */
  private async fetchStockNetworks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockNetworks')) return resolve();
      this.stockApi.getStockNetworks().subscribe({
        next: stockNetworks => {
          stockNetworks.forEach(stockNetwork => {
            this.addStockNetwork(stockNetwork);
          });
          this.fetchedObjects.push('StockNetworks');

          if (!this.storeSubscriptions.has('StockNetwork')) {
            const networkSub = this.stockApi.stockNetworkSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockNetwork(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockNetwork(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockNetwork', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockNetwork', networkSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create stockNetwork in database
   * @param {StockNetwork} newNetwork network to create
   * @returns {Promise<StockNetwork>} created StockNetwork
   */
  public async createStockNetwork(newNetwork: StockNetwork): Promise<StockNetwork> {
    return new Promise<StockNetwork>((resolve, reject) => {
      this.stockApi.createStockNetwork(newNetwork).subscribe({
        next: stockNetworkRes => {
          this.addStockNetwork(stockNetworkRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockNetwork in database
   * @param {StockNetwork} network network to update
   * @returns {Promise<StockNetwork>} updated StockNetwork
   */
  public async updateStockNetwork(network: StockNetwork): Promise<StockNetwork> {
    return new Promise<StockNetwork>((resolve, reject) => {
      this.stockApi.updateStockNetwork(network).subscribe({
        next: stockNetworkRes => {
          this.addStockNetwork(stockNetworkRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockNetwork in database
   * @param {StockNetwork} network network to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteStockNetwork(network: StockNetwork): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.deleteStockNetwork(network).subscribe({
        next: stockNetworkIdRes => {
          this.removeStockNetwork(stockNetworkIdRes, resolve)
        },
        error: reject
      });
    });
  }

  // STOCK ORDERS METHODS

  /**
   * add order to store
   * @param {any} stockOrder order to add
   * @param {function} callback function to call when it is done
   */
  private addStockOrder(stockOrder: any, callback?: Function): void {
    if (!this.stockOrders.has(stockOrder.order_id)) {
      this.stockOrders.set(stockOrder.order_id, new StockOrder().deserialize(stockOrder));
    }
    else {
      this.stockOrders.get(stockOrder.order_id).deserialize(stockOrder);
    }
    if (callback) callback(this.stockOrders.get(stockOrder.order_id));
  }

  /**
   * remove stock order from map
   * @param {string} orderId order id
   * @param {function} callback function to call when it is done
   */
  private removeStockOrder(orderId, callback?: Function): void {
    this.stockOrders.delete(orderId);
    if (this.articlesForOrder.has(orderId)) {
      this.articlesForOrder.get(orderId).forEach(articleId => {
        this.orderArticles.delete(articleId);
      });
      this.articlesForOrder.delete(orderId);
    }
    if (callback) callback('deleted');
  }

  /**
   * Fetch Stock Orders from database
   * @returns {Promise<StockOrder[]>} returns StockOrders
   */
  private async fetchStockOrders(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockOrders')) return resolve();
      this.stockApi.getStockOrders().subscribe({
        next: stockOrdersRes => {
          stockOrdersRes.forEach(stockOrderRes => {
            this.addStockOrder(stockOrderRes);
          });
          this.fetchedObjects.push('StockOrders');
          if (!this.storeSubscriptions.has('StockOrder')) {
            const orderSub = this.stockApi.stockOrderSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockOrder(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockOrder(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockOrder', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockOrder', orderSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * Fetch Stock Order Logs from database
   * @param {string} order_id order id to fetch logs for
   * @returns {Promise<void>} returns when it is done
   */
  public async fetchOrderLogs(order_id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const order = this.stockOrders.get(order_id);
      if (!order) return resolve();
      this.stockApi.getOrderLogs(order_id).subscribe({
        next: orderLogsRes => {
          orderLogsRes.forEach(orderLogRes => {
            order.setOrderLog(new OrderLog().deserialize(orderLogRes));
          });
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * Fetch Archived Orders from database
   * @returns {Promise<ArchivedOrder[]>} returns ArchivedOrders
   */
  public async fetchArchivedOrders(from?: Date, to?: Date): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.getArchivedOrders(from, to).subscribe({
        next: archivedOrdersRes => {
          archivedOrdersRes.forEach(archivedOrderRes => {
            this.addArchivedOrder(archivedOrderRes);
          });
          if (!this.storeSubscriptions.has('ArchivedOrder')) {
            const archivedOrderSub = this.stockApi.archivedOrderSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                this.addArchivedOrder(JSON.parse(objectJson));
                this.subscriptionRefresh.emit({ objectType: 'ArchivedOrder', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ArchivedOrder', archivedOrderSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * add order to store
   * @param {any} archivedOrder order to add
   * @param {function} callback function to call when it is done
   */
  private addArchivedOrder(archivedOrder: any, callback?: Function): void {
    if (!this.archivedOrders.has(archivedOrder.order_id)) {
      this.archivedOrders.set(archivedOrder.order_id, new ArchivedOrder().deserialize(archivedOrder));
    }
    else {
      this.archivedOrders.get(archivedOrder.order_id).deserialize(archivedOrder);
    }
    if (callback) callback(this.archivedOrders.get(archivedOrder.order_id));
  }

  /**
   * close stockOrder : archive order and articles
   * @param {string} orderId id of order to close
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public closeStockOrder(orderId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.closeStockOrder(orderId).subscribe({
        next: stockOrderIdRes => {
          this.removeStockOrder(stockOrderIdRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockOrder : delete order and articles
   * @param {string} orderId id of order to close
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public deleteStockOrder(orderId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.deleteStockOrder(orderId).subscribe({
        next: stockOrderIdRes => {
          this.removeStockOrder(stockOrderIdRes, resolve);
        },
        error: reject
      });
    });
  }

  // SHIPMENTS METHODS

  /**
   * Fetch Shipments from database
   * @returns {Promise<Shipment[]>} returns Shipments
   */
  public async fetchShipments(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('Shipments')) return resolve();
      this.stockApi.getShipments().subscribe({
        next: shipmentsRes => {
          shipmentsRes.forEach(shipmentRes => {
            this.addShipment(shipmentRes);
          });
          this.fetchedObjects.push('Shipments');
          if (!this.storeSubscriptions.has('Shipment')) {
            const shipmentSub = this.stockApi.shipmentSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addShipment(JSON.parse(objectJson));
                    this.subscriptionRefresh.emit({ objectType: 'Shipment', objectId: objectId, mutationType: mutationType });
                    break;
                  case 'DELETED':
                    this.removeShipment(objectId);
                    break;
                }
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('Shipment', shipmentSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * Fetch DeliverySlips from database
   * @returns {Promise<DeliverySlip[]>} returns DeliverySlips
   */
  public async fetchDeliverySlips(shipment_ref: string): Promise<DeliverySlip[]> {
    return new Promise<DeliverySlip[]>((resolve, reject) => {
      this.stockApi.getDeliverySlips(shipment_ref).subscribe({
        next: deliverySlipsRes => {
          deliverySlipsRes.forEach(deliverySlipRes => {
            this.addDeliverySlip(deliverySlipRes);
          });
          if (!this.storeSubscriptions.has('DeliverySlip')) {
            const deliverySlipSub = this.stockApi.deliverySlipSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addDeliverySlip(JSON.parse(objectJson));
                    this.subscriptionRefresh.emit({ objectType: 'DeliverySlip', objectId: objectId, mutationType: mutationType });
                    break;
                  case 'DELETED':
                    this.removeDeliverySlip(objectId);
                    break;
                }
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('DeliverySlip', deliverySlipSub);
          }
          resolve(this.getDeliverySlipsByShipmentRef(shipment_ref));
        },
        error: reject
      });
    });
  }

  /** create shipment in database
   * @param {Shipment} newShipment shipment to create
   * @returns {Promise<Shipment>} created Shipment
  */
  public async createShipment(newShipment: Shipment, articles: (EntityArticle | OrderArticle)[]): Promise<Shipment> {
    return new Promise<Shipment>((resolve, reject) => {
      this.stockApi.createShipment(newShipment, articles).subscribe({
        next: shipmentRes => {
          this.addShipment(shipmentRes, resolve);
        },
        error: reject
      });
    });
  }

  /** update shipment in database
   * @param {Shipment} shipment shipment to update
   * @returns {Promise<Shipment>} updated Shipment
  */
  public async updateShipment(shipment: Shipment): Promise<Shipment> {
    return new Promise<Shipment>((resolve, reject) => {
      this.stockApi.updateShipment(shipment).subscribe({
        next: shipmentRes => {
          this.addShipment(shipmentRes, resolve);
        },
        error: reject
      });
    });
  }

  public async updateShipmentStatus(shipment_ref: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.updateShipmentStatus(shipment_ref).subscribe({
        next: shipmentRes => {
          this.addShipment(shipmentRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Add article to shipment
   * @param {string} shipment_ref shipment ref
   * @param {(OrderArticle | EntityArticle)[]} articles articles
   * @returns {Promise<Shipment>} updated Shipment
   */
  public async addArticlesToShipment(shipment_ref: string, articles: (OrderArticle | EntityArticle)[]): Promise<Shipment> {
    return new Promise<Shipment>((resolve, reject) => {
      this.stockApi.addArticlesToShipment(shipment_ref, articles).subscribe({
        next: shipmentRes => {
          this.addShipment(shipmentRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Remove article from shipment
   * @param {string} shipment_ref shipment ref
   * @param {any[]} articles articles
   * @returns {Promise<Shipment>} updated Shipment
   */
  public async removeArticlesFromShipment(shipment_ref: string, articles: any[]): Promise<Shipment> {
    return new Promise<Shipment>((resolve, reject) => {
      this.stockApi.removeArticlesFromShipment(shipment_ref, articles).subscribe({
        next: shipmentRes => {
          this.addShipment(shipmentRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Ships a shipment entity and returns a promise that resolves to a DeliverySlip.
   * @param shipment_ref - The reference of the shipment.
   * @param entity_id - The ID of the entity.
   * @returns A promise that resolves to a DeliverySlip.
   */
  public async shipShipmentEntity(shipment_ref: string, entity_id: string): Promise<DeliverySlip> {
    return new Promise<DeliverySlip>((resolve, reject) => {
      this.stockApi.shipShipmentEntity(shipment_ref, entity_id).subscribe({
        next: deliverySlipRes => {
          this.addDeliverySlip(deliverySlipRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * add shipment to store
   * @param {any} shipment shipment to add
   * @param {function} callback function to call when it is done
   */

  private addShipment(shipment: any, callback?: Function): void {
    if (!this.shipments.has(shipment.shipment_ref)) {
      this.shipments.set(shipment.shipment_ref, new Shipment(this).deserialize(shipment));
    }
    else {
      this.shipments.get(shipment.shipment_ref).deserialize(shipment);
    }
    if (callback) callback(this.shipments.get(shipment.shipment_ref));
  }

  /**
   * remove shipment from map
   * @param {string} shipmentId shipment id
   * @param {function} callback function to call when it is done
   */

  private removeShipment(shipmentId: string, callback?: Function): void {
    this.shipments.delete(shipmentId);
    if (callback) callback('deleted');
  }

  /**
   * Adds a delivery slip to the stock inventory.
   * If the delivery slip already exists, it updates the existing one.
   * Also updates the delivery slips by shipment reference.
   *
   * @param deliverySlip - The delivery slip to be added or updated.
   * @param callback - An optional callback function to be called after adding or updating the delivery slip.
   */
  private addDeliverySlip(deliverySlip: any, callback?: Function): void {
    if (!this.deliverySlips.has(deliverySlip.entity_id)) {
      this.deliverySlips.set(deliverySlip.entity_id, new DeliverySlip().deserialize(deliverySlip));
    }
    else {
      this.deliverySlips.get(deliverySlip.entity_id).deserialize(deliverySlip);
    }
    if (!this.deliverySlipsByShipmentRef.has(deliverySlip.shipment_ref)) {
      this.deliverySlipsByShipmentRef.set(deliverySlip.shipment_ref, new Set([deliverySlip.entity_id]));
    }
    else {
      this.deliverySlipsByShipmentRef.get(deliverySlip.shipment_ref).add(deliverySlip.entity_id);
    }
    if (callback) callback(this.deliverySlips.get(deliverySlip.entity_id));
  }

  /**
   * Removes a delivery slip from the stock inventory.
   * Also removes the delivery slip from the delivery slips by shipment reference.
   *
   * @param deliverySlipId - The ID of the delivery slip to be removed.
   * @param callback - An optional callback function to be called after removing the delivery slip.
   */
  private removeDeliverySlip(deliverySlipId: string, callback?: Function): void {
    if (this.deliverySlips.has(deliverySlipId)) {
      const deliverySlip = this.deliverySlips.get(deliverySlipId);
      if (this.deliverySlipsByShipmentRef.has(deliverySlip.shipment_ref)) {
        this.deliverySlipsByShipmentRef.get(deliverySlip.shipment_ref).delete(deliverySlipId);
      }
      this.deliverySlips.delete(deliverySlipId);
    }
    if (callback) callback('deleted');
  }


  // ORDER ARTICLES METHODS

  /**
   * add article to store
   * @param {any} orderArticle article to add
   * @param {function} callback function to call when it is done
   */
  private addOrderArticle(orderArticle: any, callback?: Function): void {
    if (!this.orderArticles.has(orderArticle.article_id)) {
      this.orderArticles.set(orderArticle.article_id, new OrderArticle(this).deserialize(orderArticle));
    }
    else {
      this.orderArticles.get(orderArticle.article_id).deserialize(orderArticle);
    }
    const article = this.orderArticles.get(orderArticle.article_id);
    if (this.articlesForOrder.has(article.order_id)) {
      this.articlesForOrder.get(article.order_id).add(article.id);
    }
    else {
      this.articlesForOrder.set(article.order_id, new Set([article.id]));
    }
    if (callback) callback(article);
  }

  /**
   * remove stock order article from map
   * @param {string} articleId article id
   * @param {string} parentId order id
   * @param {function} callback function to call when it is done
   */
  private removeOrderArticle(articleId: string, parentId: string, callback?: Function): void {
    if (this.orderArticles.has(articleId)) {
      this.orderArticles.delete(articleId);
    }
    if (this.articlesForOrder.has(parentId)) {
      this.articlesForOrder.get(parentId).delete(articleId);
    }
    if (callback) callback('deleted');
  }

  /**
   * Fetch order articles from database
   * @returns {Promise<void>} returns array of OrderArticle
   */
  private async fetchOrderArticles(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('OrderArticles')) return resolve();
      this.stockApi.fetchOrderArticles().subscribe({
        next: orderArticlesRes => {
          orderArticlesRes.forEach(orderArticle => {
            this.addOrderArticle(orderArticle);
          });
          this.fetchedObjects.push('OrderArticles');
          if (!this.storeSubscriptions.has('OrderArticle')) {
            const orderArticleSub = this.stockApi.articlesByOrderSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, parentId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addOrderArticle(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeOrderArticle(objectId, parentId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'OrderArticle', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('OrderArticle', orderArticleSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  // ENTITY ARTICLES METHODS

  /**
   * add article to store
   * @param {any} entityArticle article to add
   * @param {function} callback function to call when it is done
   */
  private addEntityArticle(entityArticle: any, callback?: Function): void {
    if (!entityArticle) return;
    if (!this.entityArticles.has(entityArticle.instance_id)) {
      this.entityArticles.set(entityArticle.instance_id, new EntityArticle(this).deserialize(entityArticle));
    } else {
      this.entityArticles.get(entityArticle.instance_id).deserialize(entityArticle);
    }

    if (this.articlesForEntity.has(entityArticle.entity_id)) {
      this.articlesForEntity.get(entityArticle.entity_id).add(entityArticle.instance_id);
    } else {
      this.articlesForEntity.set(entityArticle.entity_id, new Set([entityArticle.instance_id]));
    }

    if (!entityArticle.shipment_ref) return;
    if (this.articlesByShipmentRef.has(entityArticle.shipment_ref)) {
      this.articlesByShipmentRef.get(entityArticle.shipment_ref).add(entityArticle.instance_id);
    } else {
      this.articlesByShipmentRef.set(entityArticle.shipment_ref, new Set([entityArticle.instance_id]));
    }

    if (callback) {
      callback(this.entityArticles.get(entityArticle.instance_id));
    }
  }

  /**
   * remove stock entity article from map
   * @param {string} instanceId article id
   * @param {string} parentId entity id
   * @param {function} callback function to call when it is done
   */

  private removeEntityArticle(instanceId: string, parentId: string, callback?: Function): void {
    if (this.entityArticles.has(instanceId)) {
      const entityArticle = this.entityArticles.get(instanceId);
      if (entityArticle.shipment_ref && this.articlesByShipmentRef.has(entityArticle.shipment_ref)) {
        this.articlesByShipmentRef.get(entityArticle.shipment_ref).delete(instanceId);
      }
      this.entityArticles.delete(instanceId);
    }
    if (this.articlesForEntity.has(parentId)) {
      this.articlesForEntity.get(parentId).delete(instanceId);
    }

    if (callback) callback('deleted');
  }

  /**
   * Fetch entity articles from database
   * @returns {Promise<void>} returns array of EntityArticle
   */
  private async fetchEntityArticles(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('EntityArticles')) return resolve();
      this.stockApi.fetchEntityArticles().subscribe({
        next: entityArticlesRes => {
          entityArticlesRes.forEach(entityArticle => {
            this.addEntityArticle(entityArticle);
          });
          this.fetchedObjects.push('EntityArticles');
          if (!this.storeSubscriptions.has('EntityArticle')) {
            const entityArticleSub = this.stockApi.articlesByEntitySubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, parentId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addEntityArticle(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeEntityArticle(objectId, parentId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'EntityArticle', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('EntityArticle', entityArticleSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * get available article quantity for reference
   * @param {string} reference article reference
   * @returns {Promise<AvailableQuantitiesForReference>} returns available quantity
   */
  public async getAvailableArticleQuantityForReference(reference: string, onlyAvailable: boolean): Promise<AvailableQuantitiesForReference> {
    return new Promise<AvailableQuantitiesForReference>((resolve, reject) => {
      this.stockApi.getAvailableArticleQuantityForReference(reference, onlyAvailable).subscribe({
        next: availableQuantityRes => {
          const {
            availableResourceQuantity,
            availableArticles,
            availableOrderedResourceQuantity,
            lockedArticles,
            lockedResourceQuantity,
            availableOrderedArticles,
            lockedOrderedResourceQuantity,
            lockedOrderedArticles
          } = availableQuantityRes;

          resolve({
            availableResourceQuantity,
            availableArticles: availableArticles.map(article => new EntityArticle(this).deserialize(article)),
            availableOrderedResourceQuantity,
            lockedArticles: lockedArticles.map(article => new EntityArticle(this).deserialize(article)),
            lockedResourceQuantity,
            availableOrderedArticles: availableOrderedArticles.map(article => new OrderArticle(this).deserialize(article)),
            lockedOrderedResourceQuantity,
            lockedOrderedArticles: lockedOrderedArticles.map(article => new OrderArticle(this).deserialize(article))
          });
        },
        error: reject
      });
    });
  }

  /**
   * Create entity article in database
   * @param {EntityArticle} entityArticle entity article to create
   * @param {StockStorageEntity} entity entity to add article to
   * @returns {Promise<EntityArticle>} returns created EntityArticle
   */
  public async createArticleInEntity(entityArticle: EntityArticle, entity: StockStorageEntity): Promise<EntityArticle> {
    return new Promise<EntityArticle>((resolve, reject) => {
      this.stockApi.createArticleInEntity(entityArticle, entity).subscribe({
        next: entityArticleRes => {
          this.addEntityArticle(entityArticleRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Add articles to entity
   * @param {StockStorageEntity} entity entity to add articles to
   * @param {OrderArticle[]} articles articles to add
   * @returns {Promise<EntityArticle[]>} returns array of EntityArticle
   */
  public async addArticlesToEntity(entity: StockStorageEntity, articles: OrderArticle[]): Promise<EntityArticle[]> {
    return new Promise<EntityArticle[]>((resolve, reject) => {
      this.stockApi.addArticlesToEntity(entity.id, entity.order_id, articles).subscribe({
        next: entityArticlesRes => {
          entityArticlesRes.forEach(entityArticle => {
            this.addEntityArticle(entityArticle);
          });
          resolve(entityArticlesRes);
        },
        error: reject
      });
    });
  }

  /**
   * Remove article from entity
   * @param article article to remove some quantity from
   * @param quantity quantity to remove
   * @returns removed quantity
   */
  removeArticleFromEntity(article: EntityArticle, quantity: number): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.stockApi.removeArticleFromEntity(article.entity_id, article.id, quantity).subscribe({
        next: res => {
          resolve(res);
        },
        error: reject
      });
    });
  }

  /**
   * Archive Entity Articles
   * @param {EntityArticle[]} articles articles to archive
   * @returns {Promise<void>} returns when it is done
   */
  public async archiveEntityArticles(articles: EntityArticle[], comment: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.archiveEntityArticles(articles, comment).subscribe({
        next: archivedArticlesRes => {
          archivedArticlesRes.forEach(archivedArticle => {
            this.addArchivedArticle(archivedArticle);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  // ARCHIVED ARTICLES METHODS

  /**
   * add article to store
   * @param {any} archivedArticle article to add
   * @param {function} callback function to call when it is done
   */
  private addArchivedArticle(archivedArticle: any, callback?: Function): void {
    if (!archivedArticle) return;
    if (!this.archivedArticles.has(archivedArticle.time_id)) {
      this.archivedArticles.set(archivedArticle.time_id, new ArchivedArticle(this).deserialize(archivedArticle));
    }
    else {
      this.archivedArticles.get(archivedArticle.time_id).deserialize(archivedArticle);
    }
    if (callback) callback(this.archivedArticles.get(archivedArticle.time_id));
  }

  /**
   * Add article history to store
   * @param {any} articleHistory article history to add
   * @param {function} callback function to call when it is done
   */
  private addArticleHistory(articleHistory: any, callback?: Function): void {
    if (this.articleHistoryMap.has(articleHistory.instance_id)) {
      this.articleHistoryMap.get(articleHistory.instance_id).add(articleHistory.time_id);
    }
    else {
      this.articleHistoryMap.set(articleHistory.instance_id, new Set([articleHistory.time_id]));
    }
    if (!this.articleHistories.has(articleHistory.time_id)) {
      this.articleHistories.set(articleHistory.time_id, new ArticleHistory(this.defaultStorageLabel).deserialize(articleHistory));
    }
    else {
      this.articleHistories.get(articleHistory.time_id).deserialize(articleHistory);
    }
    if (callback) callback(this.articleHistories.get(articleHistory.time_id));
  }

  /**
   * Fetch archived articles from database
   * @param {Date} from: date from which to fetch archived articles
   * @param {Date} to: date to which to fetch archived articles
   * @returns {Promise<ArchivedArticle[]>} returns array of ArchivedArticle
   */
  public async fetchArchivedArticles(from?: Date, to?: Date): Promise<ArchivedArticle[]> {
    return new Promise<ArchivedArticle[]>((resolve, reject) => {
      this.stockApi.getArchivedArticles(from, to).subscribe({
        next: archivedArticlesRes => {
          archivedArticlesRes.forEach(archivedArticle => {
            this.addArchivedArticle(archivedArticle);
          });
          if (!this.storeSubscriptions.has('ArchivedArticle')) {
            const archivedArticleSub = this.stockApi.archivedArticleSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addArchivedArticle(JSON.parse(objectJson));
                    this.subscriptionRefresh.emit({ objectType: 'ArchivedArticle', objectId: objectId, mutationType: mutationType });
                    break;
                  default:
                    break;
                }
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ArchivedArticle', archivedArticleSub);
          }
          resolve(archivedArticlesRes);
        },
        error: reject
      });
    });
  }

  /**
   * Fetch archived articles from database for a given business ref
   * @param {string} business_ref: business ref to fetch archived articles for
   * @returns {Promise<ArchivedArticle[]>} returns array of ArchivedArticle by business
   */
  public async fetchArchivedArticlesByBusiness(business_ref: string): Promise<ArchivedArticle[]> {
    return new Promise<ArchivedArticle[]>((resolve, reject) => {
      this.stockApi.getArchivedArticlesByBusiness(business_ref).subscribe({
        next: archivedArticlesRes => {
          let archivedArticles = [];
          archivedArticlesRes.forEach(archivedArticle => {
            this.addArchivedArticle(archivedArticle, (article) => {
              archivedArticles.push(article);
            });
          });
          if (!this.storeSubscriptions.has('ArchivedArticle')) {
            const archivedArticleSub = this.stockApi.archivedArticleSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addArchivedArticle(JSON.parse(objectJson));
                    this.subscriptionRefresh.emit({ objectType: 'ArchivedArticle', objectId: objectId, mutationType: mutationType });
                    break;
                  default:
                    break;
                }
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ArchivedArticle', archivedArticleSub);
          }
          resolve(archivedArticles);
        },
        error: reject
      });
    });
  }

  /**
   * Fetch archived articles from database for a given shipment ref
   * @param {string} shipment_ref: shipment ref to fetch archived articles for
   * @returns {Promise<ArchivedArticle[]>} returns array of ArchivedArticle by shipment
   */
  public async fetchArchivedArticlesByShipment(shipment_ref: string): Promise<ArchivedArticle[]> {
    return new Promise<ArchivedArticle[]>((resolve, reject) => {
      this.stockApi.getArchivedArticlesByShipment(shipment_ref).subscribe({
        next: archivedArticlesRes => {
          let archivedArticles = [];
          archivedArticlesRes.forEach(archivedArticle => {
            this.addArchivedArticle(archivedArticle, (article) => {
              archivedArticles.push(article);
            });
          });
          if (!this.storeSubscriptions.has('ArchivedArticle')) {
            const archivedArticleSub = this.stockApi.archivedArticleSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addArchivedArticle(JSON.parse(objectJson));
                    this.subscriptionRefresh.emit({ objectType: 'ArchivedArticle', objectId: objectId, mutationType: mutationType });
                    break;
                  default:
                    break;
                }
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('ArchivedArticle', archivedArticleSub);
          }
          resolve(archivedArticles);
        },
        error: reject
      });
    });
  }

  /**
   * Fetch articles history from database
   * @param {string[]} articleIds: ids of articles to fetch history for
   * @returns {Promise<void>} returns when done
   */
  public async fetchArticlesHistory(articleIds: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.fetchArticlesHistory(articleIds).subscribe({
        next: articlesHistoryRes => {
          articlesHistoryRes.forEach(articleHistory => {
            this.addArticleHistory(articleHistory);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  // STOCK STORAGES ENTITIES METHODS

  /**
   * add storage to store
   * @param stockStorageEntity storage to add
   * @param callback function to call when it's done
   */
  private addStockStorageEntity(stockStorageEntity: any, callback?: Function): void {
    const previousEntity = this.stockStorageEntities.get(stockStorageEntity.entity_id);
    if (previousEntity?.parent_entity_id && previousEntity.parent_entity_id !== stockStorageEntity.parent_entity_id) {
      this.entitiesByParentIds.get(previousEntity.parent_entity_id).delete(previousEntity.id);
    }
    if (!this.stockStorageEntities.has(stockStorageEntity.entity_id)) {
      this.stockStorageEntities.set(stockStorageEntity.entity_id, new StockStorageEntity(this).deserialize(stockStorageEntity));
    }
    else {
      this.stockStorageEntities.get(stockStorageEntity.entity_id).deserialize(stockStorageEntity);
    }
    const entity = this.stockStorageEntities.get(stockStorageEntity.entity_id);
    if (entity.barcode) {
      this.stockStorageEntitiesByBarcode.set(entity.barcode, entity.id);
    }
    if (entity.parent_entity_id) {
      if (this.entitiesByParentIds.has(entity.parent_entity_id)) {
        this.entitiesByParentIds.get(entity.parent_entity_id).add(entity.id);
      }
      else {
        this.entitiesByParentIds.set(entity.parent_entity_id, new Set([entity.id]));
      }
    }
    if (callback) callback(entity);
  }

  /**
   * Reassigns entity articles to a different business reference and network ID.
   *
   * @param articles - The array of entity articles to be reassigned.
   * @param business_ref - The new business reference.
   * @param network_id - The new network ID.
   * @returns A Promise that resolves with the result of the reassignment.
   */
  public reassignEntityArticles(articles: EntityArticle[], business_ref: string, network_id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.stockApi.reassignEntityArticles(articles, business_ref, network_id).subscribe({
        next: result => {
          resolve(result);
        },
        error: reject
      });
    });
  }

  /**
   * remove stock storage from map
   * @param {string} storageId id of storage to remove
   * @param {Function} callback function to call when it is done
   */
  private removeStockStorageEntity(storageEntityId, callback?: Function): void {
    const entity = this.stockStorageEntities.get(storageEntityId);
    if (!entity) {
      if (callback) callback('deleted');
      return;
    }
    if (entity.parent_entity_id) {
      if (this.entitiesByParentIds.has(entity.parent_entity_id)) {
        this.entitiesByParentIds.get(entity.parent_entity_id).delete(entity.id);
      }
    }
    if (this.articlesForEntity.has(entity.id)) {
      this.articlesForEntity.get(entity.id).forEach(articleId => {
        this.removeEntityArticle(articleId, entity.id);
      });
      this.articlesForEntity.delete(entity.id);
    }
    if (entity.barcode) {
      this.stockStorageEntitiesByBarcode.delete(entity.barcode);
    }
    this.stockStorageEntities.delete(storageEntityId);
    if (callback) callback('deleted');
  }

  /**
   * fetch stock storages entities from database
   * @returns {Promise<void>} returns promise when it's done
   */
  private async fetchStockStorageEntities(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockStorageEntities')) return resolve();
      this.stockApi.getStockStorageEntities().subscribe({
        next: stockStorageEntitiesRes => {
          stockStorageEntitiesRes.forEach(stockStorageEntity => {
            this.addStockStorageEntity(stockStorageEntity);
          });
          this.fetchedObjects.push('StockStorageEntities');
          if (!this.storeSubscriptions.has('StockStorageEntity')) {
            const storageEntitySub = this.stockApi.stockStorageEntitySubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockStorageEntity(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockStorageEntity(objectId, resolve);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockStorageEntity', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockStorageEntity', storageEntitySub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * add storage to store
   * @param stockStorageEntity storage to add
   * @param callback function to call when it's done
   */
  private addStockStorageEntityHistory(entity_id: string, entityHistory: any): void {
    if (entityHistory && this.stockStorageEntities.has(entity_id)) {
      this.stockStorageEntities.get(entity_id).setHistory(entityHistory);
    }
  }

  /**
   * fetch stock storages entities last history from database
   * @returns {Promise<void>} returns promise when it's done
   */
  private async fetchEntitiesLastHistory(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockStorageEntitiesLastHistory')) return resolve();
      this.stockApi.getEntitiesLastHistory().subscribe({
        next: entitiesLastHistoryRes => {
          entitiesLastHistoryRes.forEach(entityLastHistory => {
            if (this.stockStorageEntities.has(entityLastHistory.entity_id)) {
              this.stockStorageEntities.get(entityLastHistory.entity_id).lastHistoryDate = new Date(entityLastHistory.history_ts);
            }
          });
          this.fetchedObjects.push('StockStorageEntitiesLastHistory');
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * fetch history for entity
   * @param {string} entity_id id of entity to fectch history
   * @returns Promise completes when over
   */
  public async fetchStockStorageEntityHistory(entity_id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.getEntityHistory(entity_id).subscribe({
        next: entityHistory => {
          this.addStockStorageEntityHistory(entity_id, entityHistory);
          if (!this.storeSubscriptions.has('StockStorageEntityHistory')) {
            const storageEntityHistorySub = this.stockApi.stockStorageEntityHistorySubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                this.addStockStorageEntityHistory(objectId, JSON.parse(objectJson));
                this.subscriptionRefresh.emit({ objectType: 'StockStorageEntityHistory', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockStorageEntityHistory', storageEntityHistorySub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create stockStorageEntity with article from reception
   * @param {StockStorageEntity} newStorageEntity storage to create
   * @returns {Promise<StockStorageEntity>} created StockStorageEntity
   */
  public createStockStorageEntityFromReception(newStorageEntity: StockStorageEntity, articles: OrderArticle[]): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.createStockStorageEntityFromReception(newStorageEntity, articles).subscribe({
        next: stockStorageEntityRes => {
          this.addStockStorageEntity(stockStorageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * create and receive a stockStorageEntity
   * @param {StockStorageEntity} newStorageEntity storage to create
   * @returns {Promise<StockStorageEntity>} created StockStorageEntity
   */
  public createStockStorageEntityWithBarcode(newStorageEntity: StockStorageEntity): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.createStockStorageEntityWithBarcode(newStorageEntity).subscribe({
        next: stockStorageEntityRes => {
          this.addStockStorageEntity(stockStorageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Lock Stock Storage Entity and creates barcode
   * @param {StockStorageEntity} storageEntity storage to lock
   * @returns {Promise<StockStorageEntity>} locked StockStorageEntity
   */
  public lockStockStorageEntity(storageEntity: StockStorageEntity): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.lockStockStorageEntity(storageEntity).subscribe({
        next: stockStorageEntityRes => {
          this.addStockStorageEntity(stockStorageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * receive stockStorageEntity in database
   * @param {StockStorageEntity} storageEntity storage to receive
   * @returns {Promise<StockStorageEntity>} received StockStorageEntity
   */
  public receiveStockStorageEntity(storageEntity: StockStorageEntity): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.receiveStockStorageEntity(storageEntity).subscribe({
        next: stockStorageEntityRes => {
          this.addStockStorageEntity(stockStorageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * transfer an entity to another entity
   * @param {StockStorageEntity} entity source entity
   * @param {string} parent_entity_id: destination entity id
   * @returns {Promise<void>} resolve when finished
  */
  public transferEntityToEntity(entity: StockStorageEntity, parent_entity_id: string): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.transferEntityToEntity(entity, parent_entity_id).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * transfer stockStorageEntity to a storage in database
   * @param {StockStorageEntity} entity: entity to transfer
   * @param {string} destination_id: destination id (storage id)
   * @returns {Promise<void>} resolve when finished
   */
  public transferEntityToStorage(entity: StockStorageEntity, destination_id: string): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.transferEntityToStorage(entity, destination_id).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Packages a stock storage entity and returns a promise that resolves with the packaged entity.
   * @param entity The stock storage entity to package.
   * @param weight The weight of the packaged entity.
   * @param dimensions The dimensions of the packaged entity.
   * @returns A promise that resolves with the packaged stock storage entity.
   */
  public packageStockStorageEntity(entity: StockStorageEntity, weight?: number, dimensions?: string): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.packageStockStorageEntity(entity, weight, dimensions).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  public unpackStockStorageEntity(entity: StockStorageEntity): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.unpackStockStorageEntity(entity).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Withdraws a specified quantity of articles to a given entity.
   * @param resource_id - The ID of the resource.
   * @param article_id - The article ID.
   * @param instance_id - The article instance ID.
   * @param business_ref - The business reference.
   * @param network_id - The ID of the network.
   * @param shipment_ref - The shipment reference.
   * @param origin_id - The ID of the origin.
   * @param entity_id - The ID of the entity.
   * @param quantity - The quantity of articles to withdraw.
   * @returns A promise that resolves with the updated stock storage entity.
   */
  public withdrawArticlesToEntity(resource_id: string, article_id: string, instance_id: string, business_ref: string, network_id: string, shipment_ref: string, origin_id: string, entity_id: string, quantity: number): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.withdrawArticlesToEntity(resource_id, article_id, instance_id, business_ref, network_id, shipment_ref, origin_id, entity_id, quantity,).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }


  /**
   * delete stockStorageEntity in database
   * @param {StockStorageEntity} storageEntity storage to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public deleteStockStorageEntity(storageEntity: StockStorageEntity): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.deleteStockStorageEntity(storageEntity).subscribe({
        next: stockStorageEntityIdRes => {
          this.removeStockStorageEntity(stockStorageEntityIdRes, resolve);
        },
        error: reject
      });
    });
  }

  // STOCK LOGISTIC FLOW

  /**
   * add logisticFlow to store
   * @param stockLogisticFlow logisticFlow to add
   * @param {function} callback function to call when it is done
   */
  private addStockLogisticFlow(stockLogisticFlow: any, callback?: Function): void {
    if (!this.stockLogisticFlows.has(stockLogisticFlow.flow_id)) {
      this.stockLogisticFlows.set(stockLogisticFlow.flow_id, new StockLogisticFlow(this).deserialize(stockLogisticFlow));
    }
    else {
      this.stockLogisticFlows.get(stockLogisticFlow.flow_id).deserialize(stockLogisticFlow);
    }
    if (callback) callback(this.stockLogisticFlows.get(stockLogisticFlow.flow_id));
  }

  /**
   * remove stock logisticFlow from map
   * @param {string} logisticFlowId id of logisticFlow to remove
   * @param {Function} callback function to call when it is done
   */
  private removeStockLogisticFlow(logisticFlowId, callback?: Function): void {
    this.stockLogisticFlows.delete(logisticFlowId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch stockLogisticFlows from database
   * @returns {Promise<void>} fetched StockLogisticFlows
   */
  private async fetchStockLogisticFlows(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockLogisticFlows')) return resolve();
      this.stockApi.getStockLogisticFlows().subscribe({
        next: stockLogisticFlowsRes => {
          stockLogisticFlowsRes.forEach(stockLogisticFlowRes => {
            this.addStockLogisticFlow(stockLogisticFlowRes);
          });
          this.fetchedObjects.push('StockLogisticFlows');
          if (!this.storeSubscriptions.has('StockLogisticFlow')) {
            const logisticFlowSub = this.stockApi.stockLogisticFlowSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockLogisticFlow(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockLogisticFlow(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockLogisticFlow', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockLogisticFlow', logisticFlowSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create stockLogisticFlow in database
   * @param {any} newLogisticFlow logisticFlow to create
   * @returns {Promise<StockLogisticFlow>} created StockLogisticFlow
   */
  public createStockLogisticFlow(newLogisticFlow: any): Promise<StockLogisticFlow> {
    return new Promise<StockLogisticFlow>((resolve, reject) => {
      this.stockApi.createStockLogisticFlow(newLogisticFlow).subscribe({
        next: stockLogisticFlowRes => {
          this.addStockLogisticFlow(stockLogisticFlowRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockLogisticFlow in database
   * @param {StockLogisticFlow} logisticFlow logisticFlow to update
   * @returns {Promise<StockLogisticFlow>} updated StockLogisticFlow
   */
  public updateStockLogisticFlow(logisticFlow: StockLogisticFlow): Promise<StockLogisticFlow> {
    return new Promise<StockLogisticFlow>((resolve, reject) => {
      this.stockApi.updateStockLogisticFlow(logisticFlow).subscribe({
        next: stockLogisticFlowRes => {
          this.addStockLogisticFlow(stockLogisticFlowRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * pause or unpause stockLogisticFlow in database
   * @param {string} flow_id logisticFlow id to pause / unpause
   * @param {boolean} paused pause / unpause
   * @returns {Promise<StockLogisticFlow>} updated StockLogisticFlow
   */
  public pauseStockLogisticFlow(flow_id: string, paused: boolean): Promise<StockLogisticFlow> {
    return new Promise<StockLogisticFlow>((resolve, reject) => {
      this.stockApi.pauseStockLogisticFlow(flow_id, paused).subscribe({
        next: stockLogisticFlowRes => {
          this.addStockLogisticFlow(stockLogisticFlowRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockLogisticFlow in database
   * @param {string} logisticFlowId id of logisticFlow to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public deleteStockLogisticFlow(logisticFlowId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (logisticFlowId.startsWith('temp')) {
        this.removeTempStockLogisticFlow(logisticFlowId, resolve);
      }
      else {
        this.stockApi.deleteStockLogisticFlow(logisticFlowId).subscribe({
          next: stockLogisticFlowIdRes => {
            this.removeStockLogisticFlow(stockLogisticFlowIdRes, resolve)
          },
          error: reject
        });
      }
    });
  }

  /**
   * add temp logisticFlow to store
   * @param stockLogisticFlow logisticFlow to add
   * @param {function} callback function to call when it is done
   */
  public addTempStockLogisticFlow(stockLogisticFlow: StockLogisticFlow): void {
    const tempId = `temp-${this.tempLogisticFlows.size}`;
    stockLogisticFlow.id = tempId;
    this.tempLogisticFlows.set(stockLogisticFlow.id, stockLogisticFlow);
  }

  /**
   * remove temp stock logisticFlow from map
   * @param {string} logisticFlowId id of logisticFlow to remove
   * @param {Function} callback function to call when it is done
   */
  public removeTempStockLogisticFlow(logisticFlowId, callback?: Function): void {
    this.tempLogisticFlows.delete(logisticFlowId);
    if (callback) callback('deleted');
  }

  // STOCK OPERATIONS

  /**
   * add storage to store
   * @param stockOperation storage to add
   * @param callback function to call when it's done
   */
  private addStockOperation(stockOperation: any, callback?: Function): void {
    if (!stockOperation) return;
    if (!this.stockOperations.has(stockOperation.operation_id)) {
      this.stockOperations.set(stockOperation.operation_id, new StockOperation(this).deserialize(stockOperation));
    }
    else {
      this.stockOperations.get(stockOperation.operation_id).deserialize(stockOperation);
    }
    if (callback) callback(this.stockOperations.get(stockOperation.operation_id));
  }

  /**
   * remove stock storage from map
   * @param {string} storageId id of storage to remove
   * @param {Function} callback function to call when it is done
   */
  private removeStockOperation(operationId, callback?: Function): void {
    this.stockOperations.delete(operationId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch stockOperations from database
   * @returns {Promise<void>} returns when it is done
   */
  private async fetchStockOperations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockOperations')) return resolve();
      this.stockApi.getStockOperations().subscribe({
        next: stockOperationsRes => {
          stockOperationsRes.forEach(stockOperationRes => {
            this.addStockOperation(stockOperationRes);
          });
          this.fetchedObjects.push('StockOperations');
          if (!this.storeSubscriptions.has('StockOperation')) {
            const operationSub = this.stockApi.stockOperationSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockOperation(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockOperation(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'StockOperation', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockOperation', operationSub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create stockOperation in database
   * @param {string} entity_id entity to create operation for
   * @param {string} destination_id stock where operation should go
   * @returns {Promise<StockOperation>} created StockOperation
   */
  public createStockOperation(entity_id: string, destination_id: string): Promise<StockOperation> {
    return new Promise<StockOperation>((resolve, reject) => {
      this.stockApi.createStockOperation(entity_id, destination_id).subscribe({
        next: stockOperationRes => {
          if (stockOperationRes) {
            this.addStockOperation(stockOperationRes, resolve);
          }
          else resolve(null);
        },
        error: reject
      });
    });
  }

  /**
   * update stockOperation in database
   * @param {StockOperation} operation stockOperation to update
   * @returns {Promise<StockOperation>} updated StockOperation
   */
  public pickStockStorageEntity(operation: StockOperation): Promise<StockOperation> {
    return new Promise<StockOperation>((resolve, reject) => {
      this.stockApi.pickStockStorageEntity(operation).subscribe({
        next: stockOperationRes => {
          this.addStockOperation(stockOperationRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * deletes stockOperation in database
   * @param {StockOperation} operation stockOperation to update
   * @param {string} destination_id id of Storage to put Entity Operation in
   * @returns {Promise<StockOperation>} updated StockOperation
   */
  public cancelStockOperation(operation: StockOperation, destination_id?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.cancelStockOperation(operation, destination_id).subscribe({
        next: resolve, error: reject
      });
    });
  }

  /**
   * transfer entity articles in another entity
   * @param {StockStorageEntity} entity entity to transfer articles from
   * @param {string} destination_id: destination id
   * @returns {Promise<void>} resolve when finished
  */
  public transferArticlesInEntity(entity: StockStorageEntity, destination_id: string): Promise<StockStorageEntity> {
    return new Promise<StockStorageEntity>((resolve, reject) => {
      this.stockApi.transferArticlesInEntity(entity, destination_id).subscribe({
        next: storageEntityRes => {
          this.addStockStorageEntity(storageEntityRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
 * transfer entity article in another entity
 * @param {string} entityId entity id
 * @param {EntityArticle} article article to transfer
 * @returns {Promise<void>} transfered EntityArticle
*/
  public transferArticleToEntity(entityId: string, article: EntityArticle): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.transferArticleToEntity(entityId, article).subscribe({
        next: resolve,
        error: reject
      });
    });
  }

  /**
   * Fetches entity articles by stock.
   *
   * @param stock_id - The ID of the stock.
   * @param resource_id - The ID of the resource.
   * @returns A promise that resolves to an array of EntityArticlesByStock objects.
   */
  public fetchEntityArticlesByStock(stock_id: string, resource_id: string): Promise<EntityArticlesByStock[]> {
    return new Promise<EntityArticlesByStock[]>((resolve, reject) => {
      this.stockApi.getEntityArticlesByStock(stock_id, resource_id).subscribe({
        next: entityArticleRes => {
          let entityArticles = [];
          entityArticleRes.forEach(entityArticle => {
            entityArticles.push(new EntityArticlesByStock().deserialize(entityArticle));
          });
          resolve(entityArticles);
        },
        error: reject
      });
    });
  }

  public fetchOrderArticlesByStock(stock_id: string, resource_id: string): Promise<OrderArticlesByStock[]> {
    return new Promise<OrderArticlesByStock[]>((resolve, reject) => {
      this.stockApi.getOrderArticlesByStock(stock_id, resource_id).subscribe({
        next: orderArticlesRes => {
          let orderArticles = [];
          orderArticlesRes.forEach(orderArticle => {
            orderArticles.push(new OrderArticlesByStock().deserialize(orderArticle));
          });
          resolve(orderArticles);
        },
        error: reject
      });
    });
  }

  /**
 * update entityArticlesByStock in database
 * @returns {Promise<EntityArticlesByStock>} update entityArticlesByStock
 */
  public async updateEntityArticlesByStock(entityArticle: EntityArticle, stock_id: string): Promise<EntityArticlesByStock> {
    return new Promise<EntityArticlesByStock>((resolve, reject) => {
      return this.stockApi.updateEntityArticlesByStock(entityArticle, stock_id).subscribe({
        next: resDetailByStock => {
          const udpatedResourceDetail = new EntityArticlesByStock().deserialize(resDetailByStock);
          resolve(udpatedResourceDetail);
        },
        error: reject
      })
    });
  }

  /**
 * update entity article in database
 * @returns {Promise<EntityArticle>} update entity article
 */
  public async updateEntityArticle(entityArticle: EntityArticle): Promise<EntityArticle> {
    return new Promise<EntityArticle>((resolve, reject) => {
      return this.stockApi.updateEntityArticle(entityArticle).subscribe({
        next: respUpdateEntityArticle => {
          const updatedEntityArticle = new EntityArticle(this).deserialize(respUpdateEntityArticle);
          resolve(updatedEntityArticle);
        },
        error: reject
      })
    })
  };

  // STOCK REQUESTS METHODS

  /**
   * add stockRequest to store
   * @param stockRequest stockRequest to add
   * @param callback function to call when it's done
   */
  private addStockRequest(stockRequest: any, callback?: Function): void {
    if (!this.stockRequests.has(stockRequest.request_id)) {
      this.stockRequests.set(stockRequest.request_id, new StockRequest(this, this.userService).deserialize(stockRequest));
    }
    else {
      this.stockRequests.get(stockRequest.request_id).deserialize(stockRequest);
    }
    if (callback) callback(this.stockRequests.get(stockRequest.request_id));
  }

  /**
   * remove stockRequest from map
   * @param {string} stockRequestId id of stockRequest to remove
   * @param {Function} callback function to call when it is done
   */
  private removeStockRequest(stockRequestId, callback?: Function): void {
    this.stockRequests.delete(stockRequestId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch Stock Requests from database
   * @returns {Promise<void>} returns when it is done
   */
  private async fetchStockRequests(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('StockRequests')) return resolve();
      this.stockApi.getStockRequests().subscribe({
        next: stockRequestsRes => {
          stockRequestsRes.forEach(stockRequestRes => {
            this.addStockRequest(stockRequestRes);
          });
          this.fetchedObjects.push('StockRequests');

          if (!this.storeSubscriptions.has('StockRequest')) {
            const requestedResourceSub = this.stockApi.stockRequestSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addStockRequest(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeStockRequest(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'StockRequest' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('StockRequest', requestedResourceSub);
          }
          resolve();
        },
        error: reject
      });
    })
  }

  /**
   * create stockRequest in database
   * @param {StockRequest} stockRequest stockRequest to create
   * @returns {Promise<StockRequest>} created StockRequest
   */
  public createStockRequest(stockRequest: StockRequest): Promise<StockRequest> {
    return new Promise<StockRequest>((resolve, reject) => {
      this.stockApi.createStockRequest(stockRequest).subscribe({
        next: stockRequestRes => {
          this.addStockRequest(stockRequestRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update stockRequest in database
   * @param {StockRequest} stockRequest stockRequest to update
   * @returns {Promise<StockRequest>} updated StockRequest
   */
  public updateStockRequest(stockRequest: StockRequest): Promise<StockRequest> {
    return new Promise<StockRequest>((resolve, reject) => {
      this.stockApi.updateStockRequest(stockRequest).subscribe({
        next: stockRequestRes => {
          this.addStockRequest(stockRequestRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * Updates the requested resources for a stock request.
   * @param stockRequest - The stock request to update.
   * @returns A promise that resolves with the updated stock request.
   */
  public cancelStockRequest(stockRequest: StockRequest): Promise<StockRequest> {
    return new Promise<StockRequest>((resolve, reject) => {
      this.stockApi.cancelStockRequest(stockRequest).subscribe({
        next: stockRequestRes => {
          this.addStockRequest(stockRequestRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete stockRequest in database
   * @param {StockRequest} stockRequest stockRequest to delete
   * @returns {Promise<string>} deleted StockRequest id
   */
  public deleteStockRequest(stockRequest: StockRequest): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.deleteStockRequest(stockRequest).subscribe({
        next: stockRequestRes => {
          this.removeStockRequest(stockRequestRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update purchaseOrder in database
   * @param {StockRequest} purchaseOrder purchaseOrder to update
   * @returns {Promise<StockRequest>} updated PurchaseOrder
   */
  public updatePurchaseOrder(purchaseOrder: StockRequest): Promise<StockRequest> {
    return new Promise<StockRequest>((resolve, reject) => {
      this.stockApi.updatePurchaseOrder(purchaseOrder).subscribe({
        next: purchaseOrderRes => {
          this.addStockRequest(purchaseOrderRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete purchaseOrder in database
   * @param {StockRequest} purchaseOrder purchaseOrder to delete
   * @returns {Promise<string>} deleted PurchaseOrder id
   */
  public archivePurchaseOrder(purchaseOrder: StockRequest): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.stockApi.archivePurchaseOrder(purchaseOrder).subscribe({
        next: purchaseOrderRes => {
          this.removeStockRequest(purchaseOrderRes, resolve);
        },
        error: reject
      });
    });
  }

  // REQUESTED RESOURCES

  /**
   * add requestedResource to store
   * @param requestedResource requestedResource to add
   * @param callback function to call when it's done
   */
  private addRequestedResource(requestedResource: any, callback?: Function): void {
    if (!this.requestedResources.has(requestedResource.requested_resource_id)) {
      this.requestedResources.set(requestedResource.requested_resource_id, new RequestedResource(this).deserialize(requestedResource));
    }
    else {
      this.requestedResources.get(requestedResource.requested_resource_id).deserialize(requestedResource);
    }
    if (this.resourcesForRequests.has(requestedResource.request_id)) {
      this.resourcesForRequests.get(requestedResource.request_id).add(requestedResource.requested_resource_id);
    }
    else {
      this.resourcesForRequests.set(requestedResource.request_id, new Set([requestedResource.requested_resource_id]));
    }
    if (callback) callback(this.requestedResources.get(requestedResource.requested_resource_id));
  }

  /**
   * remove requestedResource from map
   * @param {string} requestedResourceId id of requestedResource to remove
   * @param {Function} callback function to call when it is done
   */
  private removeRequestedResource(requestedResourceId: string, parentId: string, callback?: Function): void {
    this.requestedResources.delete(requestedResourceId);
    if (this.resourcesForRequests.has(parentId)) {
      this.resourcesForRequests.get(parentId).delete(requestedResourceId);
    }
    if (callback) callback('deleted');
  }

  /**
   * Fetch Requested Resources from database
   * @returns {Promise<void>} returns when it is done
   */
  private async fetchRequestedResources(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('RequestedResources')) return resolve();
      this.stockApi.getRequestedResources().subscribe({
        next: requestedResources => {
          requestedResources.forEach(requestedResource => {
            this.addRequestedResource(requestedResource);
          });
          this.fetchedObjects.push('RequestedResources');

          if (!this.storeSubscriptions.has('RequestedResource')) {
            const requestedResourceSub = this.stockApi.requestedResourceSubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, parentId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addRequestedResource(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeRequestedResource(objectId, parentId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'RequestedResource' });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('RequestedResource', requestedResourceSub);
          }
          resolve();
        },
        error: reject
      });
    })
  }

  // ACCOUNTING ENTRIES METHODS

  /**
   * add entry to store
   * @param {any} accountingEntry entry to add
   * @param {function} callback function to call when it is done
   */
  private addAccountingEntry(accountingEntry: any, callback?: Function): void {
    if (!this.accountingEntries.has(accountingEntry.entry_id)) {
      this.accountingEntries.set(accountingEntry.entry_id, new AccountingEntry(this).deserialize(accountingEntry));
    }
    else {
      this.accountingEntries.get(accountingEntry.entry_id).deserialize(accountingEntry);
    }
    if (callback) callback(this.accountingEntries.get(accountingEntry.entry_id));
  }

  /**
   * remove accounting Entry from map
   * @param {string} accountingEntryId accountingEntryId
   * @param {function} callback function to check when it is done
   */
  private removeAccountingEntry(accountingEntryId: string, callback?: Function): void {
    this.accountingEntries.delete(accountingEntryId);
    if (callback) callback('deleted');
  }

  /**
   * Fetch accounting entries from database
   * @returns {Promise<void>} resolves when entries are fetched
   */
  private async fetchAccountingEntries(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fetchedObjects.includes('AccountingEntries')) return resolve();
      this.stockApi.getAccountingEntries().subscribe({
        next: accountingEntries => {
          accountingEntries.forEach((accountingEntry) => {
            this.addAccountingEntry(accountingEntry);
          });
          this.fetchedObjects.push('AccountingEntries');

          if (!this.storeSubscriptions.has('AccountingEntry')) {
            const accountingEntrySub = this.stockApi.accountingEntrySubscription().subscribe({
              next: data => {
                if (!data) return;
                const { mutationType, objectId, objectJson } = data;
                switch (mutationType) {
                  case 'CREATED':
                  case 'UPDATED':
                    this.addAccountingEntry(JSON.parse(objectJson));
                    break;
                  case 'DELETED':
                    this.removeAccountingEntry(objectId);
                    break;
                }
                this.subscriptionRefresh.emit({ objectType: 'AccountingEntry', objectId: objectId, mutationType: mutationType });
              },
              error: error => {
                console.error(error);
              }
            });
            this.storeSubscriptions.set('AccountingEntry', accountingEntrySub);
          }
          resolve();
        },
        error: reject
      });
    });
  }

  /**
 * update accountingEntry in database
 * @param {AccountingEntry} entry to update
 * @returns {Promise<AccountingEntry>} updated AccountingEntry
 */
  public async updateAccountingEntry(entry: AccountingEntry): Promise<AccountingEntry> {
    return new Promise<AccountingEntry>((resolve, reject) => {
      this.stockApi.updateAccountingEntry(entry).subscribe({
        next: (AccountingEntryRes) => {
          this.addStockBusiness(AccountingEntryRes, resolve);
        },
        error: reject
      });
    });
  }

  public async fetchDashboardStats(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.stockApi.getDashboardStats().subscribe({
        next: dashboardStats => {
          if (dashboardStats) {
            this.dashboardStats.clear();
            dashboardStats.forEach(dashboardStat => {
              if (!this.dashboardStats.has(dashboardStat.stat_name)) {
                this.dashboardStats.set(dashboardStat.stat_name, [new DashboardStat().deserialize(dashboardStat)]);
              }
              else {
                this.dashboardStats.get(dashboardStat.stat_name).push(new DashboardStat().deserialize(dashboardStat));
              }
            });
          }
          this.stockApi.getRunnerStats().subscribe({
            next: runnerStats => {
              if (runnerStats) {
                this.runnerStats.clear();
                runnerStats.forEach(runnerStat => {
                  if (!this.runnerStats.has(runnerStat.user_id)) {
                    this.runnerStats.set(runnerStat.user_id, [new RunnerStat().deserialize(runnerStat)]);
                  }
                  else {
                    this.runnerStats.get(runnerStat.user_id).push(new RunnerStat().deserialize(runnerStat));
                  }
                });
              }
              resolve();
            },
            error: reject
          });
        },
        error: reject
      });
    });
  }

  protected async fetchCurrentRouteData(): Promise<void> {
    const startTime = performance.now();
    switch (this.sparteModulesService.currentModule.name) {
      case 'logistics':
        await this.initLogisticsModule();
        break;
      case 'business':
        await this.initBusinessModule();
        break;
      default:
        break;
    }
    switch (this.fullRoute) {
      case '/logistics/dashboard':
        await this.initLogisticsDashboard();
        break;
      case '/logistics/entities':
      case '/logistics/entities/free-move':
        await this.initLogisticsEntities();
        break;
      case '/logistics/search':
        await this.initLogisticsSearch();
        break;
      case '/logistics/stocks':
        await this.initLogisticsStocks();
        break;
      case '/logistics/operations':
        await this.initLogisticsOperations();
        break;
      case '/logistics/flows':
        break;
      case '/logistics/orders':
        await this.initLogisticsOrders();
        break;
      case '/logistics/shipping':
      case '/logistics/pickup':
        await this.initLogisticsShipments();
        break;
      case '/logistics/archived_articles':
        break;
      case '/business/business':
        await this.initBusinessProjects();
        break;
      case '/business/requests':
        await this.initBusinessRequests();
        break;
      case '/purchase/purchase_orders':
        await this.initPurchaseOrders();
        break;
      case '/accounting/entries':
        await this.initAccountingEntries();
        break;
      case '/digital-dna/assets/tree':
        await this.initDigitalDnaAssets();
        break;
      case '/digital-dna/resources':
        await this.initDigitalDnaResources();
        break;
    }
    const endTime = performance.now();
    console.log(`${this.fullRoute} initialization time : ${endTime - startTime}ms`);
    this.setReadyRoute(this.currentRouteService.currentRoute);
  }

  private async initLogisticsModule() {
    await this.fetchStockBusinesses().catch(this.handleError);
    await this.fetchStockNetworks().catch(this.handleError);
    await this.fetchStockLogisticFlows().catch(this.handleError);
    await this.fetchAssetZones().catch(this.handleError);
    await this.fetchCitadelStocks().catch(this.handleError);
    await this.fetchStorages().catch(this.handleError);
    await this.fetchStockStorageEntities().catch(this.handleError);
    await this.fetchEntitiesLastHistory().catch(this.handleError);
    await this.fetchStockOperations().catch(this.handleError);
    await this.fetchEntityArticles().catch(this.handleError);
    await this.fetchResources().catch(this.handleError);
  }

  private async initBusinessModule() {
    await this.fetchStockBusinesses().catch(this.handleError);
    await this.fetchStockNetworks().catch(this.handleError);
    await this.fetchStockRequests().catch(this.handleError);
    await this.fetchResources().catch(this.handleError);
    await this.fetchRequestedResources().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchEntityArticles().catch(this.handleError);
  }

  private async initLogisticsDashboard() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchArchivedOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
  }

  private async initLogisticsEntities() {
    await this.fetchShipments().catch(this.handleError);
  }

  private async initLogisticsSearch() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
    await this.fetchArchivedArticles().catch(this.handleError);
  }

  private async initLogisticsStocks() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
  }

  private async initLogisticsOperations() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
  }

  private async initLogisticsOrders() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
  }

  private async initLogisticsShipments() {
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchOrderArticles().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
  }

  private async initBusinessProjects() {
    await this.fetchAssetZones().catch(this.handleError);
    await this.fetchCitadelStocks().catch(this.handleError);
    await this.fetchStorages().catch(this.handleError);
    await this.fetchStockLogisticFlows().catch(this.handleError);
    await this.fetchStockStorageEntities().catch(this.handleError);
    await this.fetchStockOperations().catch(this.handleError);
    await this.fetchStockOrders().catch(this.handleError);
    await this.fetchShipments().catch(this.handleError);
  }

  private async initBusinessRequests() {
    await this.fetchAccountingEntries().catch(this.handleError);
  }

  private async initPurchaseOrders() {
    await this.fetchStockBusinesses().catch(this.handleError);
    await this.fetchStockNetworks().catch(this.handleError);
    await this.fetchStockRequests().catch(this.handleError);
    await this.fetchRequestedResources().catch(this.handleError);
    await this.fetchResources().catch(this.handleError);
  }

  private async initAccountingEntries() {
    await this.fetchStockBusinesses().catch(this.handleError);
    await this.fetchStockNetworks().catch(this.handleError);
    await this.fetchAccountingEntries().catch(this.handleError);
    await this.fetchResourceCatalogs().catch(this.handleError);
    await this.fetchResources().catch(this.handleError);
  }

  private async initDigitalDnaAssets() {
    await this.fetchCitadelInfrastructures().catch(this.handleError);
    await this.fetchAssetZones().catch(this.handleError);
    await this.fetchStorages().catch(this.handleError);
  }

  private async initDigitalDnaResources() {
    await this.fetchCitadelInfrastructures().catch(this.handleError);
    await this.fetchResources().catch(this.handleError);
    await this.fetchResourceCatalogs().catch(this.handleError);
    await this.fetchResourceLibraries().catch(this.handleError);
  }

  protected clearData(): void {
    this.readyModules = [];
    this.setReadyRoute('');
    this.fetchedObjects = [];

    this.stockBusinesses.clear();
    this.stockNetworks.clear();
    this.stockOrders.clear();
    this.archivedOrders.clear();
    this.orderArticles.clear();
    this.articlesForOrder.clear();
    this.entityArticles.clear();
    this.articlesForEntity.clear();
    this.archivedArticles.clear();
    this.stockRequests.clear();
    this.requestedResources.clear();
    this.resourcesForRequests.clear();
    this.accountingEntries.clear();
    this.articleHistories.clear();
    this.articleHistoryMap.clear();
    this.stockStorageEntities.clear();
    this.stockStorageEntitiesByBarcode.clear();
    this.entitiesByParentIds.clear();
    this.articlesByShipmentRef.clear();
    this.deliverySlipsByShipmentRef.clear();
    this.deliverySlips.clear();
    this.stockLogisticFlows.clear();
    this.tempLogisticFlows.clear();
    this.stockOperations.clear();
    this.dashboardStats.clear();
    this.runnerStats.clear();
    this.shipments.clear();
    this.globalSearchTerms.clear();
    this.citadelInfrastructures.clear();
    this.citadelItems.clear();
    this.citadelStocks.clear();
    this.assetZones.clear();
    this.assetCategories.clear();
    this.assetModels.clear();
    this.parameterSets.clear();
    this.citadelTasks.clear();
    this.stockNodes.clear();
    this.resourceCatalogs.clear();
    this.resourceLibraries.clear();
    this.resources.clear();
    this.resourcesByReference.clear();
    this.resourcesByStock.clear();
    this.resourceQuantitiesByStock.clear();
    this.stockResourcesMap.clear();
    this.citadelStorages.clear();
    this.itemGroups.clear();
    this.importConfigsByDataset.clear();
    this.resourceLinks.clear();

    this.reloadSub?.unsubscribe();
    this.storeSubscriptions.forEach((subscription, key) => {
      subscription?.unsubscribe();
    });
    this.storeSubscriptions.clear();
    console.log('cleared stocks data');
  }

  private reloadDataSubscription(): void {
    this.reloadSub?.unsubscribe();
    this.reloadSub = this.stockApi.reloadDataSubscription().subscribe({
      next: () => {
        this.clearData();
        this.apiService.redirectToDefault();
      },
      error: error => {
        console.log(error);
      }
    })
  }

  handleError = (error: any) => {
    const formattedErrors = errorMessageParser(error);
    formattedErrors.forEach(formattedError => {
      this.toastr.error(formattedError.details, formattedError.message);
    });
  }
}
