import { gql } from "apollo-angular";

export const DASSAULT_ARTICLES_QUERY = gql`
  query dassaultArticles($where: DassaultArticleWhereInput!) {
    dassaultArticles(where: $where)
  }
`;

export const DASSAULT_ARTICLE_SUBSCRIPTION = gql`
  subscription dassaultArticleSubscription {
    dassaultArticleSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;