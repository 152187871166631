import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@sparte/api';
import { CitadelCoreModule, CitadelCoreService, UserService } from '@sparte/citadel-core';
import { ErrorsModule } from '@sparte/errors';
import { UiModule } from '@sparte/ui';
import { ModuleInterface } from '@sparte/utils';
import { MobxAngularModule } from 'mobx-angular';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxElectronModule } from 'ngx-electron';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ChangelogDialogComponent } from './components/changelog-dialog/changelog-dialog.component';
import { ComponentsModule } from './components/components.module';
import { PrintPageComponent } from './pages/print-page/print-page.component';
import { StockApiService } from './services/stock-api.service';
import { StockInventoryService } from './services/stock-inventory.service';
import { StockUsersService } from './services/stock-users.service';

function getStockServiceFactory(citadelStoreService: StockInventoryService): StockInventoryService {
  return citadelStoreService;
}

function getStockUserServiceFactory(stockUsersService: StockUsersService): StockUsersService {
  return stockUsersService;
}

export const modulesMap = new Map<string, ModuleInterface>([
  ['logistics',
    {
      name: 'logistics',
      label: 'Logistique',
      link: '/logistics',
      icon: 'conveyor_belt',
      fallbackRoute: '/logistics/entities',
      navLinks: [
        {
          id: 'dashboard',
          label: 'Dashboard',
          link: 'dashboard',
          permission_id: 'dashboard:read',
          icon: 'dashboard',
          outlined: true
        },
        {
          id: 'entities',
          permission_id: 'entities:read',
          label: 'Inventaire',
          link: 'entities',
          icon: 'inventory',
          outlined: true,
          children: [
            {
              id: 'free-move',
              permission_id: 'entities:read',
              label: 'Déplacement',
              link: 'free-move',
              icon: 'sync_alt',
              hide: true
            }
          ]
        },
        {
          id: 'search',
          permission_id: 'search:read',
          label: 'Recherche',
          link: 'search',
          icon: 'search',
          outlined: false
        },
        {
          id: 'stocks',
          permission_id: 'stocks:read',
          label: 'Stock Manager',
          link: 'stocks',
          icon: 'pallet',
          outlined: false
        },
        {
          id: 'operations',
          permission_id: 'operations:read',
          label: 'Opérations',
          link: 'operations',
          icon: 'assignment',
          outlined: true
        },
        {
          id: 'flows',
          permission_id: 'flows:read',
          label: 'Flux',
          link: 'flows',
          icon: 'route',
          outlined: true
        },
        {
          id: 'orders',
          permission_id: 'orders:read',
          label: 'Réception',
          link: 'orders',
          icon: 'local_shipping',
          outlined: true
        },
        {
          id: 'shipping',
          permission_id: 'shipping:read',
          label: 'Expédition',
          link: 'shipping',
          icon: 'local_shipping',
          outlined: false
        },
        {
          id: 'pickup',
          permission_id: 'pickup:read',
          label: 'Pickup',
          link: 'pickup',
          icon: 'back_hand',
          outlined: true
        },
        {
          id: 'archived_articles',
          permission_id: 'archived_articles:read',
          label: 'Articles Archivés',
          link: 'archived_articles',
          icon: 'archive',
          outlined: true
        }
      ]
    }
  ],
  ['business',
    {
      label: 'Business',
      name: 'business',
      link: '/business',
      icon: 'business_center',
      fallbackRoute: '/business/projects',
      navLinks: [
        {
          id: 'projects',
          label: 'Suivi de Projet',
          permission_id: 'business:read',
          link: 'projects',
          icon: 'account_balance_wallet',
          outlined: true
        },
        {
          id: 'requests',
          label: 'Expressions de besoin',
          permission_id: 'requests:read',
          link: 'requests',
          icon: 'checklist_rtl',
          outlined: true
        }
      ]
    }
  ],
  ['accounting',
    {
      label: 'Comptabilité',
      name: 'accounting',
      link: '/accounting',
      icon: 'account_balance',
      fallbackRoute: '/accounting/entries',
      navLinks: [
        {
          id: 'entries',
          label: 'Mouvements',
          permission_id: 'entries:read',
          link: 'entries',
          icon: 'swap_horiz'
        }
      ]

    }
  ],
  ['purchase',
    {
      label: 'Achat',
      name: 'purchase',
      link: '/purchase',
      icon: 'shopping_cart',
      fallbackRoute: '/purchase/purchase_orders',
      navLinks: [
        {
          id: 'purchase_orders',
          label: 'Ordres d\'achats',
          permission_id: 'purchase_orders:read',
          link: 'purchase_orders',
          icon: 'inventory',
          outlined: true
        }
      ]
    }
  ],
  ['digital-dna',
    {
      label: 'Digital DNA',
      name: 'digital-dna',
      link: '/digital-dna',
      svg: 'assets/images/Dna.svg',
      fallbackRoute: '/digital-dna/assets/tree',
      navLinks: [
        {
          id: 'assets',
          label: 'Assets',
          link: 'assets',
          icon: 'language',
          children: [
            {
              id: 'tree',
              permission_id: 'assets:read',
              label: 'Infrastructures',
              link: 'tree',
              icon: 'domain'
            }
          ]
        },
        {
          id: 'resources',
          label: 'Ressources',
          link: 'resources',
          permission_id: 'resources:read',
          icon: 'diamond',
          outlined: true
        },
        {
          id: 'users',
          label: 'Organisation',
          link: 'users',
          permission_id: 'users:read',
          icon: 'sensor_occupied'
        }
      ]
    }
  ]
]);

@NgModule({
  declarations: [
    AppComponent,
    PrintPageComponent,
    ChangelogDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MobxAngularModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule.forRoot({ modulesMap }),
    CitadelCoreModule,
    HttpClientModule,
    NgxElectronModule,
    UiModule,
    ErrorsModule,
    NgxBarcode6Module,
    ComponentsModule
  ],
  providers: [
    StockApiService,
    {
      provide: CitadelCoreService,
      useFactory: getStockServiceFactory,
      deps: [StockInventoryService],
    },
    {
      provide: UserService,
      useFactory: getStockUserServiceFactory,
      deps: [StockUsersService],
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
