import { getOperationStatusDisplayName } from './../utils/stock-utils';
import { OperationStatusEnum, OperationTypeEnum } from '../utils/stock-interfaces';
import { Deserializable, datePrettyfier } from '@sparte/utils';

export class EntityHistory implements Deserializable {
  public entity_id: string;
  public operation_id: string;
  public operation_type: OperationTypeEnum;
  public hpriority: boolean;
  public user: string;
  public origin: string;
  public destination: string;
  public created_at: Date;
  public picked_at: Date;
  public done_at: Date;
  public readonly typeName = "EntityHistory";


  constructor(private defaultLabel: string) { }

  deserialize(input: any): this {
    const { __typename, created_at, picked_at, done_at, ..._input } = input;
    Object.assign(this, _input);
    if (created_at) this.created_at = new Date(created_at);
    if (picked_at) this.picked_at = new Date(picked_at);
    if (done_at) this.done_at = new Date(done_at);
    return this;
  }

  get updateValue() {
    return;
  }

  get searchTerm(): string {
    return;
  }

  get label(): string {
    switch (this.operation_type) {
      case OperationTypeEnum.CREATION:
        return "Créé";
      case OperationTypeEnum.RECEPTION:
        return "Réceptionné";
      case OperationTypeEnum.TRANSFER:
        return getOperationStatusDisplayName(this.status);
      case OperationTypeEnum.WITHDRAWAL:
        return "Articles Prélevés";
      case OperationTypeEnum.PACKAGING:
        return "Conditionné";
      case OperationTypeEnum.UNPACK:
        return "Déconditionné";
      default:
        return "Erreur Type Opération";
    }
  }

  get isReceivedHistory(): boolean {
    return !this.origin && !this.destination
  }

  get originLabel(): string {
    return this.origin === 'default' ? this.defaultLabel : this.origin;
  }

  get destinationLabel(): string {
    return this.destination === 'default' ? this.defaultLabel : this.destination;
  }

  get status(): OperationStatusEnum {
    if (this.done_at) {
      return OperationStatusEnum.DONE;
    }
    else if (this.picked_at) {
      return OperationStatusEnum.ONGOING;
    }
    else {
      return OperationStatusEnum.PENDING;
    }
  }

  get historyDate() {
    return this.done_at || this.picked_at || this.created_at;
  }

  get prettyDay(): string {
    return datePrettyfier(this.historyDate, 'L');
  }

  get prettyHour() {
    return datePrettyfier(this.historyDate, 'LT');
  }
}