import * as moment from 'moment';

import { FileService } from './file.service';

moment.locale('fr');

export class SparteFile {
  id: string;
  name: string;
  url: string;
  thumbnail_url: string;
  content_type: string;
  size: number;
  created_at: Date;
  updated_at: Date;
  user_id: string;
  imageTitle: string;
  typeName = 'SparteFile';
  fileService: FileService;
  constructor(fileService: FileService) {
    this.fileService = fileService;
  }

  deserialize(input: any): this {
    const { __typename, file_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = file_id;
    return this;
  }

  get icon(): string {
    if (this.isImage) {
      return 'insert_photo';
    } else if (this.isPdf) {
      return 'picture_as_pdf';
    } else {
      return 'insert_drive_file';
    }
  }

  get isOwner(): boolean {
    return this.fileService.isOwner(this.user_id);
  }

  get user(): string {
    return this.isOwner ? 'Vous' : '—';
  }

  get prettyDate(): string {
    return moment(this.created_at).format('L');
  }

  get prettySize(): string {
    return `${(this.size / 1048576).toFixed(2) || 0} Mo`;
  }

  get isPdf(): boolean {
    return this.content_type === 'application/pdf';
  }

  get isImage(): boolean {
    return this.content_type.startsWith('image');
  }

  get isVideo(): boolean {
    return this.content_type.startsWith('video');
  }

  get isSvg(): boolean {
    return this.content_type === 'image/svg+xml';
  }

  get fileLoaded(): boolean {
    return !!this.url;
  }

  get thumbnailLoaded(): boolean {
    return this.isSvg ? this.fileLoaded : !!this.thumbnail_url;
  }
}
