<section *mobxAutorun="{ dontDetach: true }"
         class="mat-typography">
  <section *ngFor="let rowData of rowsData; last as isLast;"
           class="row-section no-break"
           [ngClass]="{'not-last': !isLast }"
           fxLayout="row"
           fxLayoutAlign="start center">
    <div class="row-tag">{{ rowData.binsTag }} </div>
    <div *ngFor="let binData of sortedBins(rowData.binDatas);"
         fxLayout="row"
         fxLayoutAlign="start center"
         class="bin-data">
      <div class="barcode-container"
           fxLayout="column"
           fxLayoutAlign="end center">
        <div class="side-barcode">
          <ngx-barcode6 [bc-value]="binData.barcode"
                        [bc-width]="2"
                        [bc-height]="66"
                        [bc-text-margin]="0"
                        [bc-margin]="0"
                        [bc-margin-top]="0"
                        [bc-margin-bottom]="0"
                        [bc-margin-left]="0"
                        [bc-margin-right]="0"
                        [bc-display-value]="false" />
        </div>
        <span class="bin-tag"
              [ngStyle]="{ 'color': getColor(binData.levelTag, true)}">{{ binData.binTag }}</span>
      </div>
      <div class="level-tags"
           fxLayout="column"
           fxLayoutAlign="end center">
        <div *ngFor="let rowTag of sortedLevels(rowData.rowsTags)"
             class="level-tag"
             [ngStyle]="{ 'background-color': getColor(rowTag, rowTag === binData.levelTag)}"
             [ngClass]="{ 'current-level': rowTag === binData.levelTag }">{{ rowTag }}</div>
      </div>
    </div>
  </section>
</section>