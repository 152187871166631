import { gql } from 'apollo-angular';

import { RESOURCE_BY_STOCK_INFO_FRAGMENT } from './fragments/_resourceByStock';

export const RESOURCES_BY_STOCK_QUERY = gql`
  query resourcesByStock($where: StockWhereUniqueInput!) {
    resourcesByStock(where: $where)
  }
`;

export const RESOURCE_QUANTITY_BY_STOCK_QUERY = gql`
  query resourceQuantityByStock($where: StockWhereUniqueInput!) {
    resourceQuantityByStock(where: $where)
  }
`;

export const CREATE_RESOURCE_BY_STOCK_MUTATION = gql`
  mutation createResourceByStock($data: ResourceByStockCreateInput!) {
    createResourceByStock(data: $data) {
      ...ResourceByStockInfo
    }
  }
  ${RESOURCE_BY_STOCK_INFO_FRAGMENT}
`;

export const UPDATE_RESOURCE_BY_STOCK_MUTATION = gql`
  mutation updateResourceByStock(
    $where: ResourceByStockWhereUniqueInput!
    $data: ResourceByStockUpdateInput!
  ) {
    updateResourceByStock(where: $where, data: $data) {
      ...ResourceByStockInfo
    }
  }
  ${RESOURCE_BY_STOCK_INFO_FRAGMENT}
`;

export const DELETE_RESOURCE_BY_STOCK_MUTATION = gql`
  mutation deleteResourceByStock($where: ResourceByStockWhereUniqueInput!) {
    deleteResourceByStock(where: $where) {
      resource_id
      stock_id
    }
  }
`;

export const RESOURCE_BY_STOCK_SUBSCRIPTION = gql`
  subscription resourceByStockSubscription {
    resourceByStockSubscription {
      mutationType
      objectId
      parentId
      objectJson
    }
  }
`;