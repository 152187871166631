import { gql } from 'apollo-angular';
import { ENTITY_ARTICLE_INFO_FRAGMENT } from './fragments/_article';
import { STOCK_OPERATION_INFO_FRAGMENT } from './fragments/_stockOperation';

export const STOCK_OPERATIONS_QUERY = gql`
  query stockOperations {
    stockOperations
  }
`;

export const CREATE_STOCK_OPERATION_MUTATION = gql`
  mutation createStockOperation(
    $data: StockOperationCreateInput!
  ) {
    createStockOperation(data: $data) {
      ...StockOperationInfo
    }
  }
  ${STOCK_OPERATION_INFO_FRAGMENT}
`;

export const PICK_STOCK_STORAGE_ENTITY_MUTATION = gql`
  mutation pickStockStorageEntity($where: StockOperationWhereUniqueInput!) {
    pickStockStorageEntity(where: $where) {
      ...StockOperationInfo
    }
  }
  ${STOCK_OPERATION_INFO_FRAGMENT}
`;

export const WITHDRAW_ARTICLES_TO_ENTITY_MUTATION = gql`
  mutation withdrawArticlesToEntity(
    $where: WithdrawArticlesWhereInput!
    $data: TransferToEntityInput!
  ) {
    withdrawArticlesToEntity(where: $where, data: $data) {
      ...EntityArticleInfo
    }
  }
  ${ENTITY_ARTICLE_INFO_FRAGMENT}
`;

export const CANCEL_STOCK_OPERATION_MUTATION = gql`
  mutation cancelStockOperation(
    $where: StockOperationWhereUniqueInput!
    $data: TransferToStorageInput
  ) {
    cancelStockOperation(where: $where, data: $data) {
      operation_id
    }
  }
`;

export const STOCK_OPERATION_SUBSCRIPTION = gql`
  subscription stockOperationSubscription {
    stockOperationSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
