import { gql } from 'apollo-angular';

export const CITADEL_VARIABLE_MODEL_INFO_FRAGMENT = gql`
  fragment CitadelVariableModelInfo on CitadelVariableModel {
    variable_model_id
    display_name
    value_type
    measure
    unit
    select_values
    min_value
    max_value
    min_array_length
    max_array_length
    description
    is_public
    user_id
    is_array
    zorder
  }
`;
