import { Injectable } from '@angular/core';
import { ApiService, FileService } from '@sparte/api';
import { UserApiService, UserService } from '@sparte/citadel-core';
import { makeObservable } from 'mobx';

@Injectable({
  providedIn: 'root'
})
export class StockUsersService extends UserService {
  constructor(
    protected userApi: UserApiService,
    protected fileService: FileService,
    protected api: ApiService
  ) {
    super(userApi, fileService, api);
    makeObservable(this);
    this.api.authChanged.subscribe((auth) => {
      if (!auth) {
        this.clearData();
        return;
      }
      this.initStore();
    });
  }

  public getUserName = (user_id: string): string => {
    return this.getCitadelUser(user_id)?.label || "Utilisateur invalide";
  }

  protected async initStore() {
    await this.fetchCitadelUsers().catch(err => console.error(err));
    await this.fetchUserGroups().catch(err => console.error(err));
    await this.fetchCitadelPermissions().catch(err => console.error(err));
    await this.fetchRolesForGroups(this.getUserGroups.map(group => group.id)).catch(err => console.error(err));
    this.setStoreInitialized();
    this.subscriptions();
  }

  protected clearData() {
    this.citadelUsers.clear();
    this.roles.clear();
    this.userGroups.clear();
    this.userNodes.clear();
    this.citadelPermissions.clear();
    this.storeInitialized = false;
    console.log('cleared users data');
  }

  subscriptions = async () => {
    this.userApi.userGroupSubscription().subscribe(data => {
      if (!data) return;
      const { mutationType, objectId, objectJson } = data;
      switch (mutationType) {
        case "CREATED":
        case "UPDATED":
          this.addUserGroup(JSON.parse(objectJson));
          break;
        case "DELETED":
          this.removeUserGroup(objectId);
          break;
        default:
          console.log('untracked mutation type', data);
          break;
      }
      this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'UserGroup' });
    });
    this.userApi.citadelUserSubscription().subscribe(data => {
      if (!data) return;
      const { mutationType, objectId, objectJson } = data;
      switch (mutationType) {
        case "CREATED":
        case "UPDATED":
          this.addCitadelUser(JSON.parse(objectJson));
          break;
        case "DELETED":
          this.removeCitadelUser(objectId);
          break;
        default:
          console.log('untracked mutation type', data);
          break;
      }
      this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'CitadelUser' });
    });
  }
}
