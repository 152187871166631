import { Injectable } from '@angular/core';
import {
  ACCOUNTING_ENTRIES_QUERY,
  ACCOUNTING_ENTRY_SUBSCRIPTION,
  ADD_ARTICLES_TO_ENTITY_MUTATION,
  ApiService,
  ARCHIVED_ARTICLES_BY_BUSINESS_QUERY,
  ARCHIVED_ARTICLES_BY_SHIPMENT_QUERY,
  ARCHIVED_ARTICLES_QUERY,
  ARCHIVED_ARTICLE_SUBSCRIPTION,
  ARCHIVED_ORDERS_QUERY,
  ARCHIVED_ORDER_SUBSCRIPTION,
  ARCHIVE_ENTITY_ARTICLES_MUTATION,
  ARCHIVE_PURCHASE_ORDER_MUTATION,
  ARTICLES_BY_ENTITY_QUERY,
  ARTICLES_BY_ENTITY_SUBSCRIPTION,
  ARTICLES_BY_ORDER_QUERY,
  ARTICLES_BY_ORDER_SUBSCRIPTION,
  ARTICLES_HISTORY_QUERY,
  AVAILABLE_ARTICLE_QUANTITY_FOR_REFERENCE_QUERY,
  CANCEL_STOCK_OPERATION_MUTATION,
  CLOSE_ORDER_MUTATION,
  CREATE_ARTICLE_IN_ENTITY_MUTATION,
  CREATE_FAVORITE_BUSINESS_BY_USER_MUTATION,
  CREATE_SHIPMENT_MUTATION,
  CREATE_STOCK_BUSINESS_MUTATION,
  CREATE_STOCK_LOGISTIC_FLOW_MUTATION,
  CREATE_STOCK_NETWORK_MUTATION,
  CREATE_STOCK_OPERATION_MUTATION,
  CREATE_STOCK_REQUEST_MUTATION,
  CREATE_STOCK_STORAGE_ENTITY_FROM_RECEPTION_MUTATION,
  CREATE_STOCK_STORAGE_ENTITY_WITH_BARCODE_MUTATION,
  DASHBOARD_STATS_QUERY,
  DELETE_FAVORITE_BUSINESS_BY_USER_MUTATION,
  DELETE_ORDER_MUTATION,
  DELETE_STOCK_BUSINESS_MUTATION,
  DELETE_STOCK_LOGISTIC_FLOW_MUTATION,
  DELETE_STOCK_NETWORK_MUTATION,
  DELETE_STOCK_REQUEST_MUTATION,
  DELETE_STOCK_STORAGE_ENTITY_MUTATION,
  DELIVERY_SLIPS_QUERY,
  DELIVERY_SLIP_SUBSCRIPTION,
  ENTITES_LAST_HISTORY_QUERY,
  ENTITY_HISTORY_QUERY,
  FAVORITE_BUSINESS_BY_USER_QUERY,
  LOCK_STOCK_STORAGE_ENTITY_MUTATION,
  ORDERS_QUERY,
  ORDER_LOGS_QUERY,
  ORDER_SUBSCRIPTION,
  PACKAGE_STOCK_STORAGE_ENTITY_MUTATION,
  PAUSE_STOCK_LOGISTIC_FLOW_MUTATION,
  PICK_STOCK_STORAGE_ENTITY_MUTATION,
  RECEIVE_STOCK_STORAGE_ENTITY_MUTATION,
  RELOAD_DATA_SUBSCRIPTION,
  REMOVE_ARTICLES_FROM_SHIPMENT_MUTATION,
  REMOVE_ARTICLE_FROM_ENTITY_MUTATION,
  REQUESTED_RESOURCES_QUERY,
  REQUESTED_RESOURCE_SUBSCRIPTION,
  ENTITY_ARTICLES_BY_STOCK_QUERY,
  RUNNER_STATS_QUERY,
  SHIPMENTS_QUERY,
  SHIPMENT_SUBSCRIPTION,
  SHIP_SHIPMENT_ENTITY_MUTATION,
  STOCK_BUSINESSES_QUERY,
  STOCK_BUSINESS_SUBSCRIPTION,
  STOCK_LOGISTIC_FLOWS_QUERY,
  STOCK_LOGISTIC_FLOW_SUBSCRIPTION,
  STOCK_NETWORKS_QUERY,
  STOCK_NETWORK_SUBSCRIPTION,
  STOCK_OPERATIONS_QUERY,
  STOCK_OPERATION_SUBSCRIPTION,
  STOCK_REQUESTS_QUERY,
  STOCK_REQUEST_SUBSCRIPTION,
  STOCK_STORAGE_ENTITIES_QUERY,
  STOCK_STORAGE_ENTITY_HISTORY_SUBSCRIPTION,
  STOCK_STORAGE_ENTITY_SUBSCRIPTION,
  TRANSFER_ARTICLES_IN_ENTITY_MUTATION,
  TRANSFER_ENTITY_TO_ENTITY_MUTATION,
  TRANSFER_ENTITY_TO_STORAGE_MUTATION,
  UNPACK_STOCK_STORAGE_ENTITY_MUTATION,
  UPDATE_ACCOUNTING_ENTRY_MUTATION,
  UPDATE_ENTITY_ARTICLE_MUTATION,
  UPDATE_PURCHASE_ORDER_MUTATION,
  CANCEL_REQUEST_MUTATION,
  UPDATE_ENTITY_ARTICLES_BY_STOCK_MUTATION,
  UPDATE_SHIPMENT_MUTATION,
  UPDATE_SHIPMENT_STATUS_MUTATION,
  UPDATE_STOCK_BUSINESS_MUTATION,
  UPDATE_STOCK_LOGISTIC_FLOW_MUTATION,
  UPDATE_STOCK_NETWORK_MUTATION,
  UPDATE_STOCK_REQUEST_MUTATION,
  WITHDRAW_ARTICLES_TO_ENTITY_MUTATION,
  ORDER_ARTICLES_BY_STOCK_QUERY,
  TRANSFER_ARTICLE_TO_ENTITY_MUTATION,
  REASSIGN_ENTITY_ARTICLES_MUTATION
} from '@sparte/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingEntry } from '../models/accountingEntry.model';
import { OrderArticle } from '../models/orderArticle.model';
import { Shipment } from '../models/shipment.model';
import { StockBusiness } from '../models/stockBusiness.model';
import { StockLogisticFlow } from '../models/stockLogisticFlow.model';
import { StockNetwork } from '../models/stockNetwork.model';
import { StockOperation } from '../models/stockOperation.model';
import { StockRequest } from '../models/stockRequest.model';
import { StockStorageEntity } from '../models/stockStorageEntity.model';
import { EntityArticle } from './../models/entityArticle.model';

@Injectable({
  providedIn: 'root',
})
export class StockApiService {
  constructor(private api: ApiService) { }

  // STOCK BUSINESSES
  stockBusinessSubscription(): Observable<any> {
    return this.api.subscription(STOCK_BUSINESS_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockBusinessSubscription'];
        })
      )
  }

  /**
   * fetch stockBusinesses
   * @returns {Observable} array de stockBusinesses
   */
  getStockBusinesses(): Observable<any[]> {
    return this.api.watchQuery(STOCK_BUSINESSES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockBusinesses']);
      })
    );
  }

  /**
   * create a stockBusiness
   * @param {StockBusiness} stockBusiness: data du stockBusiness to create
   * @returns {Observable} stockBusiness created
   */
  createStockBusiness(stockBusiness: StockBusiness): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_BUSINESS_MUTATION, {
        data: stockBusiness.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockBusiness'];
        })
      );
  }

  /**
   * update a stockBusiness
   * @param {StockBusiness} stockBusiness: stockBusiness to update
   * @returns {Observable} stockBusiness updated
   */
  updateStockBusiness(stockBusiness: StockBusiness): Observable<any> {
    return this.api
      .mutation(UPDATE_STOCK_BUSINESS_MUTATION, {
        where: stockBusiness.whereUniqueValue,
        data: stockBusiness.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStockBusiness'];
        })
      );
  }

  /**
   * delete stockBusiness
   * @param {StockBusiness} stockBusiness: stockBusiness to delete
   * @returns {Observable} id du stockBusiness deleted
   */
  deleteStockBusiness(stockBusiness: StockBusiness): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_BUSINESS_MUTATION, {
        where: stockBusiness.whereUniqueValue,
      })
      .pipe(
        map((result: any) => {
          return result.data['deleteStockBusiness'].reference;
        })
      );
  }

  // FAVORITE BUSINESS BY USER
  /**
   * fetch favoriteBusinessByUser
   * @returns {Observable} array de favoriteBusinessByUser
   */
  getFavoriteBusinessByUser(): Observable<any[]> {
    return this.api.watchQuery(FAVORITE_BUSINESS_BY_USER_QUERY).pipe(
      map((result: any) => {
        return result.data['favoriteBusinessByUser'];
      })
    );
  }

  /**
   * create a favoriteBusinessByUser
   * @param {any} favoriteBusinessByUser: data favoriteBusinessByUser to create
   * @returns {Observable} favoriteBusinessByUser created
   */
  createFavoriteBusinessByUser(business_ref: string): Observable<any> {
    return this.api
      .mutation(CREATE_FAVORITE_BUSINESS_BY_USER_MUTATION, { data: { business_ref } })
      .pipe(
        map((result: any) => {
          return result.data['createFavoriteBusinessByUser'];
        })
      );
  }

  /**
   * update a favoriteBusinessByUser
   * @param {any} favoriteBusinessByUser: favoriteBusinessByUser to update
   * @returns {Observable} favoriteBusinessByUser updated
   */
  deleteFavoriteBusinessByUser(business_ref: string): Observable<any> {
    return this.api
      .mutation(DELETE_FAVORITE_BUSINESS_BY_USER_MUTATION, {
        data: { business_ref }
      })
      .pipe(
        map((result: any) => {
          return result.data['deleteFavoriteBusinessByUser'];
        })
      );
  }

  // STOCK NETWORKS
  stockNetworkSubscription(): Observable<any> {
    return this.api.subscription(STOCK_NETWORK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockNetworkSubscription'];
        })
      );
  }

  /**
   * fetch stockNetworks
   * @returns {Observable} array de stockNetworks
   */
  getStockNetworks(): Observable<any[]> {
    return this.api.watchQuery(STOCK_NETWORKS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockNetworks']);
      })
    );
  }

  /**
   * create a stockNetwork
   * @param {StockNetwork} stockNetwork: stockNetwork to create
   * @returns {Observable} stockNetwork created
   */
  createStockNetwork(stockNetwork: StockNetwork): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_NETWORK_MUTATION, {
        data: stockNetwork.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockNetwork'];
        })
      );
  }

  /**
   * update a stockNetwork
   * @param stockNetwork: stockNetwork to update
   * @returns {Observable} stockNetwork updated
   */
  updateStockNetwork(stockNetwork: StockNetwork): Observable<any> {
    return this.api
      .mutation(UPDATE_STOCK_NETWORK_MUTATION, {
        where: stockNetwork.whereUniqueValue,
        data: stockNetwork.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStockNetwork'];
        })
      );
  }

  /**
   * delete stockNetwork
   * @param {StockNetwork} stockNetwork: stockNetwork to delete
   * @returns {Observable} stockNetwork deleted
   */
  deleteStockNetwork(stockNetwork: StockNetwork): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_NETWORK_MUTATION, {
        where: stockNetwork.whereUniqueValue,
      })
      .pipe(
        map((result: any) => {
          return result.data['deleteStockNetwork'].network_id;
        })
      );
  }

  // STOCK ORDERS
  /**
   * Fetch Archived Orders in real time
   */
  archivedOrderSubscription(): Observable<any> {
    return this.api.subscription(ARCHIVED_ORDER_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['archivedOrderSubscription'];
        })
      )
  }

  stockOrderSubscription(): Observable<any> {
    return this.api.subscription(ORDER_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockOrderSubscription'];
        })
      )
  }

  /**
   * fetch stockOrders
   * @returns {Observable} array of stockOrders
   */
  getStockOrders(): Observable<any[]> {
    return this.api.watchQuery(ORDERS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockOrders']);
      })
    );
  }

  /**
   * Fetch Order Logs
   * @param order_id order id to fetch logs for
   * @returns array of order logs
   */
  getOrderLogs(order_id: string): Observable<any[]> {
    return this.api.watchQuery(ORDER_LOGS_QUERY, { where: { order_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['orderLogs']);
      })
    );
  }

  /**
   * fetch archived stockOrders
   * @param { Date } from: start time
   * @param { Date } to: end time
   * @returns {Observable} array de stockOrders
   */
  getArchivedOrders(from: Date, to: Date): Observable<any[]> {
    return this.api.watchQuery(ARCHIVED_ORDERS_QUERY, { where: { from, to } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['archivedOrders']);
      })
    );
  }

  /**
   * close stock order
   * @param {string} order_id : id de l'order a fermer
   * @returns {Observable} stockOrder fermé
   */
  closeStockOrder(order_id: string): Observable<string> {
    return this.api
      .mutation(CLOSE_ORDER_MUTATION, { where: { order_id } })
      .pipe(
        map((result: any) => {
          return result.data['closeStockOrder'].order_id;
        })
      );
  }

  /**
   * delete stock order
   * @param {string} order_id : id de l'order a delete
   * @returns {Observable} stockOrder fermé
   */
  deleteStockOrder(order_id: string): Observable<string> {
    return this.api
      .mutation(DELETE_ORDER_MUTATION, { where: { order_id } })
      .pipe(
        map((result: any) => {
          return result.data['deleteStockOrder'].order_id;
        })
      );
  }

  //SHIPPING ORDERS

  /**
   * Fetch Shipping Orders in real time
   * @returns subscription
   */

  shipmentSubscription(): Observable<any> {
    return this.api.subscription(SHIPMENT_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['shipmentSubscription'];
        })
      )
  }

  /**
   * fetch shipping orders
   * @returns {Observable} array of Shipments
   */

  getShipments(): Observable<any[]> {
    return this.api.watchQuery(SHIPMENTS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['shipments']);
      })
    );
  }

  /**
   * create a shipping order
   * @param {shipment} shipment: Shipment to create
   * @returns {Observable} shipment created
   */

  createShipment(shipment: Shipment, articles: (EntityArticle | OrderArticle)[]): Observable<any> {
    return this.api
      .mutation(CREATE_SHIPMENT_MUTATION, {
        data: {
          ...shipment.createValue,
          articles: articles.map(article => article.selectValue)
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['createShipment'];
        })
      );
  }

  /**
   * update a shipping order
   * @param {shipment} shipment: Shipment to update
   * @returns {Observable} shipment updated
   */
  updateShipment(shipment: Shipment): Observable<any> {
    return this.api
      .mutation(UPDATE_SHIPMENT_MUTATION, {
        where: shipment.whereUniqueValue,
        data: shipment.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateShipment'];
        })
      );
  }

  updateShipmentStatus(shipment_ref: string): Observable<any> {
    return this.api
      .mutation(UPDATE_SHIPMENT_STATUS_MUTATION, {
        where: { shipment_ref }
      })
      .pipe(
        map((result: any) => {
          return result.data['updateShipmentStatus'];
        })
      );
  }

  /**
   * Add Article To Shipment
   * @param {string} shipment_ref : shipment ref
   * @param {(EntityArticle | OrderArticle)[]} articles : articles to add
   */
  addArticlesToShipment(shipment_ref: string, articles: (EntityArticle | OrderArticle)[]): Observable<any> {
    return this.api.mutation(UPDATE_SHIPMENT_MUTATION, {
      where: { shipment_ref },
      data: { articles: articles.map(article => article.selectValue) }
    }).pipe(
      map((result: any) => {
        return result.data['updateShipment'];
      })
    );
  }

  /**
   * Remove Article From Shipment
   * @param {string} shipment_ref : shipment ref
   * @param {any[]} articles : article to remove
   */
  removeArticlesFromShipment(shipment_ref: string, articles: any[]): Observable<any> {
    return this.api.mutation(REMOVE_ARTICLES_FROM_SHIPMENT_MUTATION, {
      where: { shipment_ref },
      data: { articles }
    }).pipe(
      map((result: any) => {
        return result.data['removeArticlesFromShipment'];
      })
    );
  }

  /**
   * Ships a shipment entity.
   *
   * @param shipment_ref - The reference of the shipment.
   * @param entity_id - The ID of the entity.
   * @returns An Observable that emits the result of the shipment operation.
   */
  shipShipmentEntity(shipment_ref: string, entity_id: string): Observable<any> {
    return this.api
      .mutation(SHIP_SHIPMENT_ENTITY_MUTATION, {
        where: { shipment_ref, entity_id }
      })
      .pipe(
        map((result: any) => {
          return result.data['shipShipmentEntity'];
        })
      );
  }

  // DELIVERY SLIPS

  /**
   * Fetch Delivery Slips in real time
   * @returns subscription
   */
  deliverySlipSubscription(): Observable<any> {
    return this.api.subscription(DELIVERY_SLIP_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['deliverySlipSubscription'];
        })
      )
  }

  /**
   * Fetch Delivery Slips
   * @param {string} shipment_ref : shipment ref
   * @returns {Observable} array of delivery slips
   */
  getDeliverySlips(shipment_ref: string): Observable<any[]> {
    return this.api.watchQuery(DELIVERY_SLIPS_QUERY, { where: { shipment_ref } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['deliverySlips']);
      })
    );
  }

  // ARTICLES

  /**
   * Fetch Articles By Order in real time
   * @returns subscription
   */
  articlesByOrderSubscription(): Observable<any> {
    return this.api.subscription(ARTICLES_BY_ORDER_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['articlesByOrderSubscription'];
        })
      )
  }

  /**
   * Fetch Articles By Entity in real time
   * @returns {Observable} subscription
   */
  articlesByEntitySubscription(): Observable<any> {
    return this.api.subscription(ARTICLES_BY_ENTITY_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['articlesByEntitySubscription'];
        })
      )
  }

  /**
   * fetch articles for orders
   * @returns {Observable} orderArticles array
   */
  fetchOrderArticles(): Observable<any[]> {
    return this.api.watchQuery(ARTICLES_BY_ORDER_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['articlesByOrder']);
      })
    );
  }

  /**
   * fetch articles for entities
   * @returns {Observable} entityArticles array
   */
  fetchEntityArticles(): Observable<any[]> {
    return this.api.watchQuery(ARTICLES_BY_ENTITY_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['articlesByEntity']);
      })
    );
  }

  /**
   * fetch available quantity for article resource_id
   * @param {string} resource_id : article resource_id
   * @returns {Observable} available quantity
   */
  getAvailableArticleQuantityForReference(resource_id: string, onlyAvailable: boolean): Observable<any> {
    return this.api.watchQuery(AVAILABLE_ARTICLE_QUANTITY_FOR_REFERENCE_QUERY, { where: { resource_id, onlyAvailable } }).pipe(
      map((result: any) => {
        return result.data['availableArticleQuantityForReference'];
      })
    );
  }

  /**
   * Fetch Articles History
   * @param {string[]} instance_id_in array of articles id to fetch history for
   * @returns {Observable} array of articles history
   */
  fetchArticlesHistory(instance_id_in: string[]): Observable<any[]> {
    return this.api.watchQuery(ARTICLES_HISTORY_QUERY, { where: { instance_id_in } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['articlesHistory']);
      })
    );
  }

  /**
   * Create entity article in database
   * @param {EntityArticle} entityArticle : entity article to create
   * @param {StockStorageEntity} stockStorageEntity : stockStorageEntity to create article for
   * @returns {Observable} entityArticle created
   */
  createArticleInEntity(entityArticle: EntityArticle, stockStorageEntity: StockStorageEntity): Observable<any> {
    return this.api.mutation(CREATE_ARTICLE_IN_ENTITY_MUTATION, {
      data: entityArticle.createValue,
      where: stockStorageEntity.whereUniqueValue
    }).pipe(
      map((result: any) => {
        return result.data['createArticleInEntity'];
      })
    );
  }

  /**
   * Add Articles To Entity
   * @param {string} entity_id id of the entity
   * @param {OrderArticle[]} articles array of articles to add
   * @returns {Observable<any[]>} array of articles added
   */
  addArticlesToEntity(entity_id: string, order_id: string, articles: OrderArticle[]): Observable<any[]> {
    return this.api.mutation(ADD_ARTICLES_TO_ENTITY_MUTATION, {
      data: {
        entity_id,
        order_id,
        articles: articles.map(article => article.receptionValue)
      }
    }).pipe(
      map((result: any) => {
        return result.data['addArticlesToEntity'];
      })
    );
  }


  /**
   * Remove Article From Entity
   * @param entity_id id of the entity
   * @param instance_id id of the entity article instance
   * @param quantity quantity to remove
   * @returns {Observable<number>} quantity removed
   */
  removeArticleFromEntity(entity_id: string, instance_id: string, quantity: number): Observable<number> {
    return this.api.mutation(REMOVE_ARTICLE_FROM_ENTITY_MUTATION, { data: { entity_id, instance_id, quantity } }).pipe(
      map((result: any) => {
        return result.data['removeArticleFromEntity'];
      })
    );
  }

  /**
   * Updates an entity article.
   * @param entityArticle - The entity article to be updated.
   * @returns An Observable that emits the updated entity article.
   */
  updateEntityArticle(entityArticle: EntityArticle): Observable<any> {
    return this.api
      .mutation(UPDATE_ENTITY_ARTICLE_MUTATION, {
        where: entityArticle.whereUniqueValue,
        data: entityArticle.updateArticleValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateEntityArticle'];
        })
      );
  }

  /**
   * Archive Entity Articles
   * @param {EntityArticle[]} articles array of articles to archive
   * @returns {Observable<any[]>} array of articles archived
   */
  archiveEntityArticles(articles: EntityArticle[], comment: string): Observable<any[]> {
    return this.api.mutation(ARCHIVE_ENTITY_ARTICLES_MUTATION, {
      data: {
        comment,
        articles: articles.map(article => article.selectValue)
      }
    }).pipe(
      map((result: any) => {
        return result.data['archiveEntityArticles'];
      })
    );
  }


  // ARCHIVED ARTICLES
  /**
   * fetch archived articles
   * @param {Date} from : start time
   * @param {Date} to : end time
   * @returns {Observable} archivedArticles array
   */
  getArchivedArticles(from: Date, to: Date): Observable<any[]> {
    return this.api.watchQuery(ARCHIVED_ARTICLES_QUERY, { where: { from, to } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['archivedArticles']);
      })
    );
  }

  /**
   * Fetch Archived Articles by business
   * @param {string} reference : business ref
   * @returns {Observable} archivedArticles array
   */
  getArchivedArticlesByBusiness(reference: string): Observable<any[]> {
    return this.api.watchQuery(ARCHIVED_ARTICLES_BY_BUSINESS_QUERY, { where: { reference } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['archivedArticlesByBusiness']);
      })
    );
  }

  /**
   * fetch archived articles by shipment
   * @param {string} shipment_ref : shipment ref
   * @returns {Observable} archivedArticles array
   */
  getArchivedArticlesByShipment(shipment_ref: string): Observable<any[]> {
    return this.api.watchQuery(ARCHIVED_ARTICLES_BY_SHIPMENT_QUERY, { where: { shipment_ref } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['archivedArticlesByShipment']);
      })
    );
  }

  /**
   * Get archived articles subscription
   * @returns subscription
   */
  archivedArticleSubscription(): Observable<any> {
    return this.api.subscription(ARCHIVED_ARTICLE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['archivedArticleSubscription'];
        })
      )
  }

  // STOCK STORAGES ENTITIES
  stockStorageEntitySubscription(): Observable<any> {
    return this.api.subscription(STOCK_STORAGE_ENTITY_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockStorageEntitySubscription'];
        })
      )
  }

  /**
   * fetch stockStoragesEntities
   * @returns {Observable} array de orderArticle
   */
  getStockStorageEntities(): Observable<any[]> {
    return this.api.watchQuery(STOCK_STORAGE_ENTITIES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockStorageEntities']);
      })
    );
  }

  /**
   * create a stockStorageEntity
   * @param {StockStorageEntity} stockStorageEntity stockStorageEntity to create
   * @returns {Observable} stockStorageEntity created
   */
  createStockStorageEntityFromReception(stockStorageEntity: StockStorageEntity, articles: OrderArticle[]): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_STORAGE_ENTITY_FROM_RECEPTION_MUTATION, {
        data: {
          ...stockStorageEntity.createValueFromReception,
          articles: articles.map(article => article.receptionValue)
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockStorageEntityFromReception'];
        })
      );
  }

  /**
   * create a stockStorageEntity
   * @param {StockStorageEntity} stockStorageEntity stockStorageEntity to create
   * @returns {Observable} stockStorageEntity created
   */
  createStockStorageEntityWithBarcode(stockStorageEntity: StockStorageEntity): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_STORAGE_ENTITY_WITH_BARCODE_MUTATION, {
        data: stockStorageEntity.createValueWithBarcode
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockStorageEntityWithBarcode'];
        })
      );
  }

  /**
   * Lock Stock Storage Entity and creates Barcode
   * @param {StockStorageEntity} stockStorageEntity: stockStorageEntity to lock
   * @returns {Observable} stockStorageEntity locked
   */
  lockStockStorageEntity(stockStorageEntity: StockStorageEntity): Observable<any> {
    return this.api
      .mutation(LOCK_STOCK_STORAGE_ENTITY_MUTATION, {
        where: stockStorageEntity.whereUniqueValue
      })
      .pipe(
        map((result: any) => {
          return result.data['lockStockStorageEntity'];
        })
      );
  }

  /**
   * mark stockStorageEntity as received
   * @param {StockStorageEntity} stockStorageEntity: stockStorageEntity to receive
   * @returns {Observable} stockStorageEntity received
   */
  receiveStockStorageEntity(stockStorageEntity: StockStorageEntity): Observable<any> {
    return this.api
      .mutation(RECEIVE_STOCK_STORAGE_ENTITY_MUTATION, {
        where: stockStorageEntity.whereUniqueValue,
        data: { storage_id: stockStorageEntity.storage_id }
      })
      .pipe(
        map((result: any) => {
          return result.data['receiveStockStorageEntity'];
        })
      );
  }

  /**
   * transfer an entity to another entity
   * @param {StockStorageEntity} stockStorageEntity stockStorageEntity to update
   * @param {string} entity_id : entity id to transfer to
   * @returns {Observable} updated entity
   */
  transferEntityToEntity(stockStorageEntity: StockStorageEntity, entity_id: string): Observable<any> {
    return this.api.mutation(TRANSFER_ENTITY_TO_ENTITY_MUTATION, {
      where: stockStorageEntity.whereUniqueValue,
      data: { entity_id }
    })
      .pipe(
        map((result: any) => {
          return result.data['transferEntityToEntity'];
        })
      );
  }

  /**
   * transfer an entity to a storage
   * @param {StockStorageEntity} stockStorageEntity stockStorageEntity to update
   * @param {string} destination_id : storage id to transfer to
   * @returns {Observable} updated entity
   */
  transferEntityToStorage(stockStorageEntity: StockStorageEntity, destination_id: string): Observable<any> {
    return this.api.mutation(TRANSFER_ENTITY_TO_STORAGE_MUTATION, {
      where: stockStorageEntity.whereUniqueValue,
      data: { destination_id }
    })
      .pipe(
        map((result: any) => {
          return result.data['transferEntityToStorage'];
        })
      );
  }

  /**
   * Packages a stock storage entity with the given weight and user ID.
   * @param stockStorageEntity The stock storage entity to package.
   * @param weight The weight to package the stock storage entity with.
   * @param dimensions The dimensions to package the stock storage entity with.
   * @returns An observable that emits the packaged stock storage entity.
   */
  packageStockStorageEntity(stockStorageEntity: StockStorageEntity, weight: number, dimensions: string): Observable<any> {
    return this.api.mutation(PACKAGE_STOCK_STORAGE_ENTITY_MUTATION, {
      where: stockStorageEntity.whereUniqueValue,
      data: { weight, dimensions }
    })
      .pipe(
        map((result: any) => {
          return result.data['packageStockStorageEntity'];
        })
      );
  }

  /**
   * Unpacks a stock storage entity and returns an observable of the result.
   * @param stockStorageEntity The stock storage entity to unpack.
   * @returns An observable of the result.
   */
  unpackStockStorageEntity(stockStorageEntity: StockStorageEntity): Observable<any> {
    return this.api.mutation(UNPACK_STOCK_STORAGE_ENTITY_MUTATION, {
      where: stockStorageEntity.whereUniqueValue
    })
      .pipe(
        map((result: any) => {
          return result.data['unpackStockStorageEntity'];
        })
      );
  }

  /**
   * Reassigns entity articles.
   *
   * @param articles - The array of entity articles.
   * @param business_ref - The business reference.
   * @param network_id - The network ID.
   * @returns An observable that emits the result of the reassignment.
   */
  reassignEntityArticles(articles: EntityArticle[], business_ref: string, network_id: string): Observable<any> {
    return this.api.mutation(REASSIGN_ENTITY_ARTICLES_MUTATION, {
      where: articles.map(article => article.selectValue),
      data: { business_ref, network_id }
    }).pipe(
      map((result: any) => {
        return result.data['reassignEntityArticles'];
      })
    );
  }

  /**
   * delete stockStorageEntity
   * @param {StockStorageEntity} stockStorageEntity: stockStorageEntity to delete
   * @returns {Observable} stockStorageEntity deleted
   */
  deleteStockStorageEntity(stockStorageEntity: StockStorageEntity): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_STORAGE_ENTITY_MUTATION, {
        where: {
          ...stockStorageEntity.whereUniqueValue
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['deleteStockStorageEntity'].entity_id;
        })
      );
  }

  // STOCK LOGISTIC FLOWS

  stockLogisticFlowSubscription(): Observable<any> {
    return this.api.subscription(STOCK_LOGISTIC_FLOW_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockLogisticFlowSubscription'];
        })
      )
  }

  /**
   * fetch stockLogisticFlows
   * @returns {Observable} array de stockLogisticFlow
   */
  getStockLogisticFlows(): Observable<any[]> {
    return this.api.watchQuery(STOCK_LOGISTIC_FLOWS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockLogisticFlows']);
      })
    );
  }

  /**
   * create a stockLogisticFlow
   * @param {StockLogisticFlow} stockLogisticFlow stockLogisticFlow to create
   * @returns {Observable} stockLogisticFlow created
   */
  createStockLogisticFlow(stockLogisticFlow: StockLogisticFlow): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_LOGISTIC_FLOW_MUTATION, {
        data: stockLogisticFlow.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockLogisticFlow'];
        })
      );
  }

  /**
   * update a stockLogisticFlow
   * @param stockLogisticFlow stockLogisticFlow to update
   * @returns {Observable} stockLogisticFlow updated
   */
  updateStockLogisticFlow(stockLogisticFlow: StockLogisticFlow): Observable<any> {
    return this.api
      .mutation(UPDATE_STOCK_LOGISTIC_FLOW_MUTATION, {
        where: {
          flow_id: stockLogisticFlow.id
        },
        data: stockLogisticFlow.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStockLogisticFlow'];
        })
      );
  }

  /**
   * pause stockLogisticFlow
   * @param flow_id: flow id
   * @param paused: if paused
   * @returns {Observable} stockLogisticFlow paused
   */
  pauseStockLogisticFlow(flow_id: string, paused: boolean): Observable<any> {
    return this.api
      .mutation(PAUSE_STOCK_LOGISTIC_FLOW_MUTATION, {
        where: { flow_id },
        data: { paused }
      })
      .pipe(
        map((result: any) => {
          return result.data['pauseStockLogisticFlow'];
        })
      );
  }

  /**
   * delete stockLogisticFlow
   * @param flow_id: id du stockLogisticFlow to delete
   * @returns {Observable} stockLogisticFlow deleted
   */
  deleteStockLogisticFlow(flow_id: string): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_LOGISTIC_FLOW_MUTATION, { where: { flow_id } })
      .pipe(
        map((result: any) => {
          return result.data['deleteStockLogisticFlow'].flow_id;
        })
      );
  }

  // STOCK OPERATIONS

  stockOperationSubscription(): Observable<any> {
    return this.api.subscription(STOCK_OPERATION_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockOperationSubscription'];
        })
      )
  }

  /**
   * fetch stockOperations
   * @returns {Observable} array de stockOperation
   */
  getStockOperations(): Observable<any[]> {
    return this.api.watchQuery(STOCK_OPERATIONS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockOperations']);
      })
    );
  }

  /**
   * create a stockOperation
   * @param {string} entity_id entity to create an operation for
   * @param {string} destination_id operation destination
   * @returns {Observable} stockOperation created
   */
  createStockOperation(entity_id: string, destination_id: string): Observable<any> {
    return this.api.mutation(CREATE_STOCK_OPERATION_MUTATION, {
      data: { entity_id, destination_id }
    }).pipe(
      map((result: any) => {
        return result.data['createStockOperation'];
      })
    );
  }

  /**
   * pick an entity for stockOperation
   * @param {StockOperation} stockOperation stockOperation to update
   * @returns {Observable} stockOperation updated
   */
  pickStockStorageEntity(stockOperation: StockOperation): Observable<any> {
    return this.api.mutation(PICK_STOCK_STORAGE_ENTITY_MUTATION, {
      where: stockOperation.whereUniqueValue
    })
      .pipe(
        map((result: any) => {
          return result.data['pickStockStorageEntity'];
        })
      );
  }

  /**
   * transfer articles in another entity
   * @param {StockStorageEntity} stockStorageEntity stockStorageEntity to update
   * @param {string} entity_id : entity id to transfer to
   * @returns {Observable} updated articles
   */
  transferArticlesInEntity(stockStorageEntity: StockStorageEntity, entity_id: string): Observable<any> {
    return this.api.mutation(TRANSFER_ARTICLES_IN_ENTITY_MUTATION, {
      where: stockStorageEntity.whereUniqueValue,
      data: { entity_id }
    })
      .pipe(
        map((result: any) => {
          return result.data['transferArticlesInEntity'];
        })
      );
  }

  transferArticleToEntity(entityId: string, article: EntityArticle): Observable<any> {
    return this.api.mutation(TRANSFER_ARTICLE_TO_ENTITY_MUTATION, {
      where: { entity_id: entityId },
      data: article.selectValue
    })
      .pipe(
        map((result: any) => {
          return result.data['transferArticleToEntity'];
        })
      );
  }

  /**
   * withdraw articles to entity
   * @param {string} resource_id : resource id of articles to withdraw if not instance_id
   * @param {string} article_id : article id of articles to withdraw if not resource_id
   * @param {string} instance_id : instance id of articles to withdraw if not article_id or resource_id
   * @param {string} business_ref : business ref of articles to withdraw
   * @param {string} network_id : network id of articles to withdraw
   * @param {string} shipment_ref : shipment ref of articles to withdraw
   * @param {string} origin_id : origin id of origin entity
   * @param {string} entity_id : entity id to withdraw to
   * @param {number} quantity : quantity to withdraw
   * @returns {Observable} updated articles
   */
  withdrawArticlesToEntity(resource_id: string, article_id: string, instance_id: string, business_ref: string, network_id: string, shipment_ref: string, origin_id: string, entity_id: string, quantity: number): Observable<any> {
    return this.api.mutation(WITHDRAW_ARTICLES_TO_ENTITY_MUTATION, {
      where: { resource_id, article_id, instance_id, business_ref, network_id, shipment_ref, entity_id: origin_id },
      data: { entity_id, quantity }
    })
      .pipe(
        map((result: any) => {
          return result.data['withdrawArticlesToEntity'];
        })
      );
  }

  /**
   * cancel stockOperation
   * @param {StockOperation} stockOperation stockOperation to cancel
   * @param {string} destination_id id of Storage to put Entity Operation in
   * @returns {Observable} stockOperation canceled
   */
  cancelStockOperation(stockOperation: StockOperation, destination_id: string): Observable<any> {
    return this.api.mutation(CANCEL_STOCK_OPERATION_MUTATION, {
      where: stockOperation.whereUniqueValue,
      data: { destination_id }
    })
      .pipe(
        map((result: any) => {
          return result.data['cancelStockOperation'].operation_id;
        })
      );
  }

  stockStorageEntityHistorySubscription(): Observable<any> {
    return this.api.subscription(STOCK_STORAGE_ENTITY_HISTORY_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockStorageEntityHistorySubscription'];
        })
      );
  }

  /**
   * Get Operations history for entity
   * @param {string} entity_id : id of entity
   * @returns array of past operations for entity
   */
  getEntityHistory(entity_id: string): Observable<any> {
    return this.api.mutation(ENTITY_HISTORY_QUERY, { where: { entity_id } })
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['getEntityHistory']);
        })
      );
  }

  /**
   * Get last History of each Entity
   * @returns {Observable}
   */
  getEntitiesLastHistory(): Observable<any[]> {
    return this.api.watchQuery(ENTITES_LAST_HISTORY_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['entitiesLastHistory']);
      })
    );
  }

  getDashboardStats(): Observable<any[]> {
    return this.api.watchQuery(DASHBOARD_STATS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['dashboardStats']);
      })
    );
  }

  getRunnerStats(): Observable<any[]> {
    return this.api.watchQuery(RUNNER_STATS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['runnerStats']);
      })
    );
  }

  // STOCK REQUEST
  stockRequestSubscription(): Observable<any> {
    return this.api.subscription(STOCK_REQUEST_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['stockRequestSubscription'];
        })
      )
  }

  /**
   * Fetch stockRequests
   * @returns {Observable} stockRequests array
   */
  getStockRequests(): Observable<any[]> {
    return this.api.watchQuery(STOCK_REQUESTS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockRequests']);
      })
    );
  }

  /**
   * Create stockRequest
   * @param {StockRequest} stockRequest: stockRequest to create
   * @returns {Observable} created stockRequest
   */
  createStockRequest(stockRequest: StockRequest): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_REQUEST_MUTATION, {
        data: stockRequest.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockRequest']
        })
      );
  }

  /**
   * update a stockRequest
   * @param {StockRequest} stockRequest: stockRequest to update
   * @returns {Observable} stockRequest updated
   */
  updateStockRequest(stockRequest: StockRequest): Observable<any> {
    return this.api
      .mutation(UPDATE_STOCK_REQUEST_MUTATION, {
        where: stockRequest.whereUniqueValue,
        data: stockRequest.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStockRequest'];
        })
      );
  }

  cancelStockRequest(stockRequest: StockRequest): Observable<any> {
    return this.api
      .mutation(CANCEL_REQUEST_MUTATION, { where: stockRequest.whereUniqueValue })
      .pipe(
        map((result: any) => {
          return result.data['cancelStockRequest'];
        })
      );
  }

  /**
   * delete stockRequest
   * @param {StockRequest} stockRequest: stockRequest to delete
   * @returns {Observable} id du stockRequest deleted
   */
  deleteStockRequest(stockRequest: StockRequest): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_REQUEST_MUTATION, {
        where: stockRequest.whereUniqueValue
      }).pipe(
        map((result: any) => {
          return result.data['deleteStockRequest'].request_id;
        })
      );
  }

  // PURCHASE ORDERS

  /**
   * update a purchaseOrder
   * @param {StockRequest} purchaseOrder: purchaseOrder to update
   * @returns {Observable} updated purchaseOrder
   */
  updatePurchaseOrder(purchaseOrder: StockRequest): Observable<any> {
    return this.api.mutation(UPDATE_PURCHASE_ORDER_MUTATION, {
      where: purchaseOrder.whereUniqueValue,
      data: purchaseOrder.purchaseOrderUpdateValue
    }).pipe(
      map((result: any) => {
        return result.data['updatePurchaseOrder'];
      })
    );
  }

  /**
   * delete a purchaseOrder
   * @param {StockRequest} purchaseOrder: purchaseOrder to delete
   * @returns {Observable} id of the deleted purchaseOrder
   */
  archivePurchaseOrder(purchaseOrder: StockRequest): Observable<string> {
    return this.api.mutation(ARCHIVE_PURCHASE_ORDER_MUTATION, {
      where: purchaseOrder.whereUniqueValue
    }).pipe(
      map((result: any) => {
        return result.data['archivePurchaseOrder'].request_id;
      })
    );
  }

  // REQUESTED RESOURCES

  /**
   * Fetch requested resources in realtime
   * @returns {Observable} subscription
   */
  requestedResourceSubscription(): Observable<any> {
    return this.api.subscription(REQUESTED_RESOURCE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['requestedResourceSubscription'];
        })
      )
  }

  /**
   * Fetch requested resources from database
   * @returns {Observable} array of requested resources
   */
  getRequestedResources(): Observable<any[]> {
    return this.api.watchQuery(REQUESTED_RESOURCES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['requestedResources']);
      })
    );
  }

  // ACCOUNTING ENTRIES
  accountingEntrySubscription(): Observable<any> {
    return this.api.subscription(ACCOUNTING_ENTRY_SUBSCRIPTION).pipe(
      map((result: any) => {
        return result.data['accountingEntrySubscription'];
      })
    );
  }

  /**
   * récupérer les accountingEntries
   * @returns {Observable} array de accountingEntries
   */
  getAccountingEntries(): Observable<any[]> {
    return this.api.watchQuery(ACCOUNTING_ENTRIES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['accountingEntries']);
      })
    );
  }

  /**
 * update a AccountingEntry
 * @param {AccountingEntry} accountingEntry: accountingEntry to update
 * @returns {Observable} AccountingEntry updated
 */
  updateAccountingEntry(accountingEntry: AccountingEntry): Observable<any> {
    return this.api
      .mutation(UPDATE_ACCOUNTING_ENTRY_MUTATION, {
        where: accountingEntry.whereUniqueValue,
        data: accountingEntry.updateValue,
      })
      .pipe(
        map((result: any) => {
          return result.data['updateAccountingEntry'];
        })
      );
  }

  reloadDataSubscription(): Observable<any> {
    return this.api.subscription(RELOAD_DATA_SUBSCRIPTION).pipe(
      map((result: any) => {
        return result.data['reloadDataSubscription'];
      })
    );
  }

  // RESOURCE DETAIL BY STOCK

  /**
   * Retrieves entity articles by stock and resource ID.
   * @param stock_id The ID of the stock.
   * @param resource_id The ID of the resource.
   * @returns An Observable that emits an array of entity articles.
   */
  getEntityArticlesByStock(stock_id: string, resource_id: string): Observable<any[]> {
    return this.api.watchQuery(ENTITY_ARTICLES_BY_STOCK_QUERY, { where: { stock_id, resource_id } }).pipe(
      map((result: any) => {
        return result.data['entityArticlesByStock'];
      })
    );
  }


  /**
   * Retrieves order articles by stock and resource ID.
   *
   * @param stock_id The ID of the stock.
   * @param resource_id The ID of the resource.
   * @returns An Observable that emits an array of order articles.
   */
  getOrderArticlesByStock(stock_id: string, resource_id: string): Observable<any[]> {
    return this.api.watchQuery(ORDER_ARTICLES_BY_STOCK_QUERY, { where: { stock_id, resource_id } }).pipe(
      map((result: any) => {
        return result.data['orderArticlesByStock'];
      })
    );
  }

  /**
   * Update a entityArticlesByStock
   * @returns {Observable} updated entityArticlesByStock
   */
  updateEntityArticlesByStock(entityArticle: EntityArticle, stock_id: string): Observable<any> {
    return this.api
      .mutation(UPDATE_ENTITY_ARTICLES_BY_STOCK_MUTATION, {
        where: { ...entityArticle.whereUniqueValue, stock_id },
        data: entityArticle.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateEntityArticlesByStock'];
        })
      );
  }
}