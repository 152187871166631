<section class="relative flex flex-col items-stretch justify-start w-full h-full p-4 mat-typography">
  <div class="mb-4 text-3xl font-medium tracking-widest text-center uppercase text-sparteBlue">Journal des modifications</div>
  <div class="overflow-y-auto max-h-[640px]">
    <div class="my-2"
         *ngFor="let version of changelogData;trackBy: ngForTrackByFn">
      <div class="mb-2 font-medium text-sparteBlue">{{version.tag}}</div>
      <div *ngFor="let log of version.logs">
        <div class="mt-2 ml-2 text-sparteBlue"> {{log.category}}</div>
        <ul>
          <li *ngFor="let change of log.changes"
              class="ml-4 text-sparteWhite-500"> {{change}} </li>
        </ul>
      </div>
    </div>
  </div>
  <button tabindex="-1"
          class="text-sparteBlue bg-white hover:bg-sparteBlue-50 border-sparteWhite-200 focus:ring-0 font-medium rounded-full text-sm px-5 py-2.5 m-auto mt-2 focus:outline-none border flex justify-between items-center"
          (click)="closeDialog()">OK</button>
</section>