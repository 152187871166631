import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sparte-button',
  templateUrl: './sparte-button.component.html',
  styleUrls: ['./sparte-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparteButtonComponent {
  @ViewChild('forLabel', { static: false }) forLabel: ElementRef<HTMLElement>;
  public _reverseIcon: boolean = false;
  public _iconFilled: boolean = false;
  public _roundedLg: boolean = false;
  public _red = false;
  public _green = false;
  public _orange = false;
  public _grey = false;
  public _gold = false;
  public _emphezise = false;
  public _small = false;
  private isDown: boolean = false;
  @Input() disabled: boolean = false;
  @Input() focused: boolean = false;
  @Input() icon: string = undefined;
  @Input() label: string = undefined;
  @Input() labelFor: string = undefined;
  @Input() value: number = undefined;
  @Input() set red(param) { this._red = true }
  @Input() set gold(param) { this._gold = true }
  @Input() set green(param) { this._green = true }
  @Input() set orange(param) { this._orange = true }
  @Input() set grey(param) { this._grey = true }
  @Input() set emphezise(param) { this._emphezise = true }
  @Input() set roundedLg(param) { this._roundedLg = true }
  @Input() set reverseIcon(param) { this._reverseIcon = true }
  @Input() set iconFilled(param) { this._iconFilled = true }
  @Input() set small(param) { this._small = true }
  @Output() onClick = new EventEmitter();

  get buttonClasses(): string {
    let classes = []
    this._small ? classes.push('h-10') : classes.push('h-14')
    this.focused ? classes.push(this.backgroundColor + ' text-white ' + this.focusedHoverColor) : classes.push(this.textColor + ' bg-white ' + this.hoverColor)
    this._roundedLg ? classes.push('rounded-lg') : classes.push('rounded-full')
    this._emphezise ? classes.push(this.emphasisBordorColor) : classes.push('border')
    if (this.label === undefined) this._small ? classes.push('px-2') : classes.push('px-8')
    this.disabled ? classes.push('cursor-not-allowed') : classes.push('cursor-pointer')
    return classes.join(' ')
  }

  get iconClasses(): string {
    let classes = []
    this._iconFilled ? classes.push('material-symbols material-icons') : classes.push('material-symbols-outlined material-icons-outlined')
    if(this._reverseIcon) classes.push('-scale-x-100')
    return classes.join(' ')
  }

  get textColor(): string {
    if (this._grey || this.disabled) return 'text-sparteGrey';
    if (this._red) return 'text-sparteRed';
    if (this._green) return 'text-sparteGreen';
    if (this._orange) return 'text-sparteOrange';
    if (this._gold) return 'text-sparteGold';
    return 'text-sparteBlue';
  }

  get backgroundColor(): string {
    if (this._grey || this.disabled) return 'bg-sparteGrey';
    if (this._red) return 'bg-sparteRed';
    if (this._green) return 'bg-sparteGreen';
    if (this._orange) return 'bg-sparteOrange';
    if (this._gold) return 'bg-sparteGold';
    return 'bg-sparteBlue';
  }

  get hoverColor(): string {
    if (this._grey || this.disabled) return 'hover:bg-sparteGrey-50';
    if (this._red) return 'hover:bg-sparteRed-50';
    if (this._green) return 'hover:bg-sparteGreen-50';
    if (this._orange) return 'hover:bg-sparteOrange-50';
    if (this._gold) return 'hover:bg-sparteGold-50';
    return 'hover:bg-sparteBlue-50';
  }

  get focusedHoverColor(): string {
    if (this._grey || this.disabled) return 'hover:bg-sparteGrey-800';
    if (this._red) return 'hover:bg-sparteRed-800';
    if (this._green) return 'hover:bg-sparteGreen-800';
    if (this._orange) return 'hover:bg-sparteOrange-800';
    if (this._gold) return 'hover:bg-sparteGold-800';
    return 'hover:bg-sparteBlue-800';
  }

  get emphasisBordorColor(): string {
    if (!this._emphezise || this.disabled) return 'border';
    if (this._red) return 'border-sparteRed';
    if (this._green) return 'border-sparteGreen';
    if (this._orange) return 'border-sparteOrange';
    if (this._gold) return 'border-sparteGold';
    return 'border-sparteBlue';
  }

  public forClick() {
    if(this.disabled) return;
    this.forLabel.nativeElement.click();
  }

  clickDown() {
    if(this.disabled) return;
    this.isDown = true;
    this.focused =! this.focused;
  }

  endClick() {
    if(this.disabled) return;
    if (!this.isDown) return;
    this.isDown = false;
    this.focused = !this.focused;
  }

  valueFormat(value: number): string {
    let finalString = '';
    if (value < 10000) {
      finalString = value.toString();
    }
    if (value >= 10000) {
      finalString = (value / 1000).toFixed(1) + 'K';
    }
    if (value > 1000000) {
      finalString = (value / 1000000).toFixed(1) + 'M';
    }
    if (value > 1000000000) {
      finalString = (value / 1000000000).toFixed(1) + 'B';
    }
    return finalString;
  }

  onMouseClick() {
    if(this.disabled) return;
    this.onClick.emit();
  }
}
