import { gql } from "apollo-angular";
import { IMPORT_CONFIG_INFO_FRAGMENT } from "./fragments/_importConfig";


export const IMPORT_CONFIGS_FOR_DATASET_QUERY = gql`
  query importConfigsForDataset($dataset: String!) {
    importConfigsForDataset(dataset: $dataset)
  }
`;

export const CREATE_IMPORT_CONFIG_MUTATION = gql`
  mutation createImportConfig($data: ImportConfigCreateInput!) {
    createImportConfig(data: $data) {
      ...ImportConfigInfo
    }
  }
  ${IMPORT_CONFIG_INFO_FRAGMENT}
`;

export const UPDATE_IMPORT_CONFIG_MUTATION = gql`
  mutation updateImportConfig($where: ImportConfigWhereUniqueInput! $data: ImportConfigUpdateInput!) {
    updateImportConfig(where: $where, data: $data) {
      ...ImportConfigInfo
    }
  }
  ${IMPORT_CONFIG_INFO_FRAGMENT}
`;

export const DELETE_IMPORT_CONFIG_MUTATION = gql`
  mutation deleteImportConfig($where: ImportConfigWhereUniqueInput!) {
    deleteImportConfig(where: $where) {
      config_id
    }
  }
`;