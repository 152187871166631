<section class="w-full h-full overflow-hidden" *mobxAutorun="{ dontDetach: true }">
  <div
    (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()"
    [ngClass]="{ 'w-80': isExpanded, 'w-[88px]': !isExpanded }"
    class="select-none h-full absolute block transition-all duration-300 bg-sparteWhite shadow-xl overflow-hidden p-4 z-[999]">
    <!-- wtf material? just use a normal z-index -->
    <!--LOGO-->
    <div class="flex w-full mb-8">
      <div
        [ngClass]="{ 'w-[94px]': isExpanded, 'w-14': !isExpanded }"
        class="h-12 transition-all duration-300 shrink-0">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
      <div
        [ngClass]="{ 'opacity-1 ': isExpanded, 'opacity-0': !isExpanded }"
        class="object-none transition-all duration-300 w-36 shrink-0">
        <ng-container *ngTemplateOutlet="logoText"></ng-container>
      </div>
    </div>
    <!--HEADER MENU-->
    <ng-container *ngIf="sparteModules.length > 0">
      <div
        (click)="toggleSubmenu()"
        [ngClass]="{ 'rounded-md': !showSubmenu, 'rounded-t-md': showSubmenu }"
        class="flex w-full cursor-pointer h-14 bg-sparteBlue">
        <div class="w-14 min-w-[56px] h-14 flex items-center justify-center">
          <span
            *ngIf="currentModule?.icon"
            class="w-8 h-8 text-3xl leading-8 material-icons material-symbols-outlined text-sparteWhite">
            {{ currentModule?.icon }}
          </span>
          <img *ngIf="currentModule?.svg" class="w-full h-full p-3" src="{{ currentModule?.svg }}" />
        </div>
        <span
          [ngClass]="{ 'opacity-1': isExpanded, 'opacity-0': !isExpanded }"
          class="text-sparteWhite uppercase tracking-widest {{
            fontSize
          }} font-bold self-center text-center flex-grow transition-opacity duration-500 truncate">
          {{ currentModule?.label }}
        </span>
        <div class="p-3 h-14 w-14">
          <span
            [ngClass]="{ 'rotate-180': showSubmenu, 'rotate-0': !showSubmenu }"
            class="w-8 h-8 text-3xl transition-all duration-300 material-icons text-sparteWhite">
            expand_more
          </span>
        </div>
      </div>
      <!--SUBMENU-->
      <div *ngIf="showSubmenu" class="absolute z-10 w-72">
        <div
          *ngFor="let module of sparteModules; let isLast = last; trackBy: trackByIdx"
          [routerLink]="module.fallbackRoute"
          (click)="setShowSubmenu(false)"
          [ngClass]="{ 'rounded-b-md': isLast }"
          class="flex w-full h-12 cursor-pointer bg-sparteBlue-800 hover:bg-sparteBlue-700">
          <div *ngIf="module?.icon" class="w-12 h-12 p-3">
            <span class="w-6 h-6 text-2xl leading-6 material-icons text-sparteWhite">{{ module?.icon }}</span>
          </div>
          <div *ngIf="module?.svg" class="h-12 min-w-[48px] py-3">
            <img class="w-full h-full" src="{{ module?.svg }}" />
          </div>
          <span class="self-center w-full text-base font-bold tracking-widest uppercase truncate text-sparteWhite">
            {{ module?.label }}
          </span>
        </div>
      </div>
    </ng-container>
    <!--NAV LINKS-->
    <div
      *ngIf="currentModule?.navLinks"
      class="h-[calc(100vh-320px)] mt-8 relative block ml-1 transition-all duration-300"
      [ngClass]="{ 'w-full': isExpanded, 'w-12': !isExpanded }">
      <perfect-scrollbar>
        <div *ngFor="let navlink of currentModule.navLinks; trackBy: trackByIdx">
          <div
            *ngIf="!navlink.hide"
            class="flex items-center w-full h-12 rounded-full cursor-pointer hover:bg-sparteBlue hover:bg-opacity-10"
            [ngClass]="{ 'mb-2': marginShow(navlink) }"
            [routerLink]="currentModule.link + '/' + navlink.link"
            (click)="setShowSubmenu(false)"
            routerLinkActive="bg-sparteBlue bg-opacity-20">
            <div class="w-12 h-12 p-3">
              <span
                class="w-6 h-6 text-2xl leading-6 text-sparteBlue"
                [ngClass]="navlink.outlined ? 'material-symbols-outlined material-icons-outlined' : 'material-icons'">
                {{ navlink.icon }}
              </span>
            </div>
            <div class="flex justify-between w-full">
              <span
                [ngClass]="{ 'opacity-1': isExpanded, 'opacity-0': !isExpanded }"
                class="ml-4 text-base font-bold tracking-widest uppercase truncate text-sparteBlue">
                {{ navlink.label }}
              </span>
              <span
                *ngIf="navlink.count"
                class="bg-sparteBlue-100 text-sparteBlue-800 self-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                {{ navlink.count }}
              </span>
            </div>
          </div>
          <!--CHILDREN LINKS-->
          <ng-container *ngFor="let childLink of navlink.children; trackBy: trackByIdx; last as isLast">
            <div
              *ngIf="!childLink.hide"
              [routerLink]="currentModule.link + '/' + navlink.link + '/' + childLink.link"
              (click)="setShowSubmenu(false)"
              class="flex items-center justify-start w-full h-12 cursor-pointer"
              [ngClass]="{ 'mb-6': isLast }">
              <div
                class="h-full ml-[22px] w-1 bg-sparteBlue opacity-20 shrink-0"
                [ngClass]="{ 'rounded-b-full': isLast }"></div>
              <span
                routerLinkActive
                #rla="routerLinkActive"
                [ngClass]="{
                  visible: isExpanded,
                  hidden: !isExpanded,
                  'text-opacity-100': rla.isActive,
                  'text-opacity-50': !rla.isActive
                }"
                class="w-full ml-4 font-semibold tracking-widest capitalize truncate text-sparteBlue text-md hover:text-opacity-100">
                {{ childLink.label }}
              </span>
            </div>
          </ng-container>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="flex items-center justify-center w-full">
      <sparte-button
        class="w-16 transition-all duration-300 h-14"
        icon="sync"
        matTooltip="Rafraichir"
        (onClick)="refreshData()" />
    </div>
    <div class="flex items-center justify-center w-full gap-4 p-1">
      <sparte-user-profile class="w-16 transition-all duration-300 h-14 shrink-0" />
      <div *ngIf="changeLog && isExpanded" class="object-none transition-all duration-300 shrink-0">
        <ng-container *ngTemplateOutlet="changeLog" />
      </div>
    </div>
  </div>
  <div class="ml-[88px] h-full">
    <div class="relative flex flex-col w-auto h-full max-h-screen p-8 overflow-hidden">
      <ng-container *ngTemplateOutlet="routerOutlet"></ng-container>
    </div>
  </div>
</section>
