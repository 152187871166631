import { gql } from "apollo-angular";

export const FABRICATION_ORDERS_QUERY = gql`
  query fabricationOrders {
    fabricationOrders
  }
`;

export const FABRICATION_ORDERS_BY_POSTS_QUERY = gql`
  query fabricationOrdersByPosts($where: PostWhereInput!) {
    fabricationOrdersByPosts(where: $where)
  }
`;

export const COMPLETED_FABRICATION_ORDERS_BY_POSTS_QUERY = gql`
  query completedOfsByPosts($where: PostWhereInput!) {
    completedOfsByPosts(where: $where)
  }
`;

export const SIMULATED_FABRICATION_ORDERS_BY_POSTS_QUERY = gql`
  query simulatedOfsByPosts($where: PostWhereInput!) {
    simulatedOfsByPosts(where: $where)
  }
`;

export const FABRICATION_ORDER_SUBSCRIPTION = gql`
  subscription fabricationOrderSubscription {
    fabricationOrderSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const FABRICATION_ORDER_FOR_POST_SUBSCRIPTION = gql`
  subscription fabricationOrderForPostSubscription($where: PostWhereInput!) {
    fabricationOrderForPostSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const COMPLETED_OFS_BY_POST_SUBSCRIPTION = gql`
  subscription completedOfsByPostsSubscription($where: PostWhereInput!) {
    completedOfsByPostsSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const SIMULATED_OFS_BY_POSTS_SUBSCRIPTION = gql`
  subscription simulatedOfsByPostsSubscription($where: PostWhereInput!) {
    simulatedOfsByPostsSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;