import { Deserializable } from '@sparte/utils';


export class OrderLog implements Deserializable {
  id: string;
  order_id: string;
  entity_id: string;
  barcode: string;
  operator: string;
  received_at: Date;
  typeName = 'OrderLog';
  deserialize(input: any): this {
    const { __typename, time_id, received_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = time_id;
    if (received_at) this.received_at = new Date(received_at);
    return this;
  }

  get label(): string {
    return `Entité ${this.barcode} reçue le ${this.prettyReceivedAt} par ${this.operator}`;
  }

  get prettyReceivedAt(): string {
    return this.received_at.toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  get searchTerm(): string {
    return;
  }

  get updateValue() {
    return;
  }
}