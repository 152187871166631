import { errorMessageParser } from '@sparte/api';
import { ArchivedArticle } from '../models/archivedArticle.model';
import { EntityArticle } from '../models/entityArticle.model';
import { OrderArticle } from '../models/orderArticle.model';
import { StockTypeEnum, OperationStatusEnum } from './stock-interfaces';

export const getOperationStatusDisplayName = (operationStatus: OperationStatusEnum): string => {
  switch (operationStatus) {
    case OperationStatusEnum.PENDING:
      return "En attente";
    case OperationStatusEnum.ONGOING:
      return "En cours";
    case OperationStatusEnum.DONE:
    default:
      return "Transféré";
  }
}

export const getStorageTypeDisplayName = (storageType: string): string => {
  switch (storageType) {
    case StockTypeEnum.StockStorage:
      return "Stock";
    case StockTypeEnum.StockAisle:
      return "Row";
    case StockTypeEnum.StockBay:
      return "Bay";
    case StockTypeEnum.StockLevel:
      return "Level";
    case StockTypeEnum.StockBin:
      return "Bin";
  }
}

export const emailValidator = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const phoneValidator = (phone: string): boolean => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(phone);
}

export function getArticleIcon(article_id: string): { icon, class, tooltip } {
  const article = this.stockInventoryService.getEntityArticle(article_id) || this.stockInventoryService.getArchivedArticle(article_id) || this.stockInventoryService.getOrderArticle(article_id);
  if (!article) return { icon: 'error', class: 'material-icons-outlined text-sparteWhite-500', tooltip: 'Article introuvable' };
  let shipment_type = '';
  if (article.shipment_ref) {
    const shipment = this.stockInventoryService.getShipment(article.shipment_ref)
    shipment_type = shipment?.shipment_type;
  }
  if (article instanceof ArchivedArticle) {
    if (article.archived) return { icon: 'unarchive', class: 'material-icons-outlined text-sparteWhite-500', tooltip: 'Article archivé' };
    else return { icon: 'logout', class: 'material-icons-outlined text-sparteWhite-500', tooltip: 'Article transféré' };
  }
  if (article instanceof OrderArticle) {
    if (article.shipment_ref && shipment_type === 'PICKUP') return { icon: 'local_shipping', class: 'material-symbols text-sparteBlue', tooltip: 'Article en attente de livraison pour un pickup' };
    else if (article.shipment_ref) return { icon: 'local_shipping', class: 'material-symbols text-sparteBlue', tooltip: 'Article en attente de livraison pour une éxpédition' };
    return { icon: 'local_shipping', class: 'material-icons-outlined text-sparteBlue', tooltip: 'Article en attente de livraison' };
  }
  if (article instanceof EntityArticle) {
    if (!article.isReceived) return { icon: 'pending_actions', class: 'material-icons-outlined text-sparteGreen', tooltip: 'Article en cours de réception' }
    if (article.shipment_ref && shipment_type === 'PICKUP') return { icon: 'back_hand', class: 'material-symbols-outlined text-sparteGreen', tooltip: 'Article en pickup' };
    else if (article.shipment_ref) return { icon: 'package_2', class: 'material-symbols-outlined text-sparteGreen', tooltip: 'Article en éxpédition' };
    if (article.transfer) return { icon: 'moving', class: 'material-icons text-sparteGreen', tooltip: 'Article en transfert' };
    if (article.withdraw) return { icon: 'outbox', class: 'material-icons text-sparteGreen', tooltip: 'Article en retrait' };
    return { icon: 'pallet', class: 'material-icons text-sparteGreen', tooltip: 'Article en stock' };
  }
}