import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloLink, ApolloQueryResult, InMemoryCache, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LinkInterface, ModuleInterface } from '@sparte/utils';
import { Apollo, MutationResult } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { IApiStore, IUser } from './auth.store';
import { SparteAuth } from './sparte.auth';

interface SparteErrorMessage {
  message: string;
  type: string;
  details: string;
  code: string;
  status: number;
}

export interface RoutesPermissions {
  route: string;
  children?: RoutesPermissions[];
  permission?: string;
}

const graphQLErrorToSparteError = ({ message, extensions }) => {
  return {
    message,
    type: extensions?.type,
    details: extensions?.details,
    code: extensions?.code,
    status: extensions?.statusCode || 200
  }
}

export const errorMessageParser = (error: any): SparteErrorMessage[] => {
  if (!error) return [];
  const sparteErrors: SparteErrorMessage[] = [];
  if (error.graphQLErrors?.length > 0) {
    error.graphQLErrors.forEach(({ message, extensions }) => {
      if (message === 'MultipleErrors' && extensions?.details?.length > 0) {
        extensions.details.forEach(({ message, extensions }) => {
          sparteErrors.push(graphQLErrorToSparteError({ message, extensions }));
        });
      }
      else {
        sparteErrors.push(graphQLErrorToSparteError({ message, extensions }));
      }
    });
  }
  if (error.networkError?.error?.errors?.length > 0) {
    error.networkError.error.errors.forEach(({ message, extensions }) => {
      if (message === 'MultipleErrors' && extensions?.details?.length > 0) {
        extensions.details.forEach(({ message, extensions }) => {
          sparteErrors.push(graphQLErrorToSparteError({ message, extensions }));
        });
      }
      else {
        sparteErrors.push({
          message: message,
          type: extensions?.type,
          details: extensions?.details,
          code: extensions?.code,
          status: error.networkError.status
        });
      }
    });
  }
  return sparteErrors;
};

export interface Auth {
  authStore: IApiStore;
  setApiStore: Function;
  login: Function;
  loginPin: Function;
  logout: Function;
  renewTokens: Function;
  localLogin: Function;
  signup?: Function;
  verify?: Function;
  askResetPassword?: Function;
  resetPassword?: Function;
  updatePassword?: Function;
  updatePin?: Function;
  loginCallback?: Function;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public auth: Auth;
  public apiUrl: string;
  public local: boolean;
  @observable public online: boolean = false;
  @observable public isCitadelConnected: boolean = false;
  @observable public isAuthenticated: boolean = false;
  @observable public currentUser: IUser;
  @observable public apolloReady: boolean = false;
  private session_id: string;
  public modulesMap: Map<string, ModuleInterface>;
  private userRoutes: string[] = [];
  onlineChanged: EventEmitter<boolean>;
  authChanged: EventEmitter<boolean>;
  citadelConnected: EventEmitter<boolean>;
  private linkCreated: boolean = false;
  constructor(
    public apollo: Apollo,
    private router: Router,
    private zone: NgZone,
    private httpLink: HttpLink,
    private jwtHelper: JwtHelperService,
    private toastr: ToastrService
  ) {
    makeObservable(this);
    if (!localStorage.getItem("profiles")) localStorage.setItem("profiles", JSON.stringify([]));
    this.onlineChanged = new EventEmitter<boolean>();
    this.authChanged = new EventEmitter<boolean>();
    this.citadelConnected = new EventEmitter<boolean>();
    this.createOnline$().subscribe(isOnline => {
      this.setIsOnline(isOnline);
      console.log('Connection status changed, new online : ', this.online);
      this.onlineChanged.emit(this.online);
    });
  }

  get profiles(): string[] {
    return JSON.parse(localStorage.getItem("profiles")) || [];
  }

  private addProfile(mail: string): void {
    const profilesArray: string[] = JSON.parse(localStorage.getItem('profiles'));
    if (!profilesArray.includes(mail)) {
      profilesArray.push(mail);
      localStorage.setItem('profiles', JSON.stringify(profilesArray));
    }
  }

  public removeProfile(mail: string): void {
    const profilesArray: string[] = JSON.parse(localStorage.getItem('profiles'));
    if (profilesArray.includes(mail)) {
      profilesArray.splice(profilesArray.indexOf(mail), 1);
      localStorage.setItem('profiles', JSON.stringify(profilesArray));
    }
  }

  @action setIsOnline = (value: boolean) => {
    this.online = value;
  }

  @action setIsCitadelConnected = (value: boolean) => {
    this.isCitadelConnected = value;
  }

  @action setApolloReady = () => {
    this.apolloReady = true;
  }

  @action setAuthenticated = (auth: boolean) => {
    this.currentUser = this.auth.authStore.currentUser;
    this.isAuthenticated = auth;
    this.authChanged.emit(auth);
  }

  public init() {
    this.initApolloClient(() => {
      this.auth = new SparteAuth(this);
    });
    this.authChanged.subscribe(auth => {
      this.userRoutes = ['/', '/login'];
      if (!auth) {
        this.redirectToLogin();
        return;
      };
      this.userRoutes.push('/updatepassword', '/updatepin');
      let navigateTo;
      this.modulesMap.forEach((module, name) => {
        module.navLinks.forEach(navLink => {
          const routePerms = this.recursiveRoutes(navLink, module.link);
          routePerms.forEach(routePerm => {
            if (this.currentUser.hasPermission(routePerm.permission)) {
              this.userRoutes.push(routePerm.route);
            }
          });
        });
      });
      if (navigateTo) this.zone.run(() => this.router.navigateByUrl(navigateTo));
      else if (this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  get _accessToken() {
    return this.auth?.authStore?.accessToken;
  }

  get validApiUrl(): boolean {
    return this.apiUrl ? (this.apiUrl.startsWith('http') && this.apiUrl.endsWith('/graphql')) : false;
  }

  public login(mail?: string, password?: string, keepLoggedIn?: boolean): Promise<any> {
    return this.auth.login(mail, password, keepLoggedIn).then(() => {
      if (keepLoggedIn) this.addProfile(mail);
      if (this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  public loginPin(mail?: string, pin?: string, keepLoggedIn?: boolean): Promise<any> {
    return this.auth.loginPin(mail, pin, keepLoggedIn).then(() => {
      if (this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  public signup(mail: string, password: string, pin: string, first_name: string, last_name: string): Promise<boolean> {
    return this.auth.signup(mail, password, pin, first_name, last_name);
  }

  public verify(mail: string, code: string): Promise<any> {
    return this.auth.verify(mail, code).then(() => {
      if (this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  public updatePassword(password: string, newPassword: string): Promise<boolean> {
    return this.auth.updatePassword(password, newPassword);
  }

  public updatePin(password: string, newPin: string): Promise<boolean> {
    return this.auth.updatePin(password, newPin)
  }

  public askResetPassword(mail: string): Promise<boolean> {
    return this.auth.askResetPassword(mail);
  }

  public resetPassword(mail: string, code: string, password: string, pin: string): Promise<any> {
    return this.auth.resetPassword(mail, code, password, pin).then(() => {
      if (this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  public renewTokens(): void {
    this.auth.renewTokens().catch(this.logout).then(next => {
      if (next && this.router.url === '/login') this.zone.run(() => this.router.navigateByUrl('/'));
    });
  }

  public logout = (): void => {
    this.auth.logout(() => {
      this.initApolloClient();
      this.linkCreated = false;
      this.zone.run(() => this.router.navigate(['/']));
    });
  }

  public authCheck = (): boolean => {
    if (!this.isAuthenticated) return false;
    const tokenExpired = this.jwtHelper.isTokenExpired(this._accessToken);
    if (tokenExpired) this.logout();
    return !tokenExpired;
  }

  public routeCheck = (route: string): boolean => {
    if (this.currentUser?.isSuperAdmin) return true;
    return this.userRoutes.includes(route) || this.userRoutes.some(userRoute => route?.startsWith(userRoute));
  }

  private recursiveRoutes = (navLink: LinkInterface, parentRoute?: string): RoutesPermissions[] => {
    const routes: RoutesPermissions[] = [];
    if (navLink.permission_id) routes.push({ route: `${parentRoute}/${navLink.link}`, permission: navLink.permission_id });
    if (navLink.children) {
      navLink.children.forEach(child => {
        routes.push(...this.recursiveRoutes(child, `${parentRoute}/${navLink.link}`));
      });
    }
    return routes;
  }

  public redirectToDefault = (): void => {
    if (this.currentUser) this.zone.run(() => this.router.navigateByUrl('/'));
  }

  public redirectToLogin = (): void => {
    this.zone.run(() => this.router.navigateByUrl('/login'));
  }

  errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }, index) => {
        const title = extensions?.code ? `[${extensions.code}]` : '[GraphQL error]';
        if (extensions?.code === 'INTERNAL_SERVER_ERROR') this.toastr.error(extensions.details, message);
        console.error(`${title}: Message: ${message}${extensions?.details ? ', Details: ' + JSON.stringify(extensions.details) : ''}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
      });

    }
    if (networkError) {
      if ((networkError as HttpErrorResponse).error?.errors) {
        (networkError as HttpErrorResponse).error.errors.forEach(({ message, locations, path, extensions }) => {
          const title = extensions?.code ? `[${extensions.code}]` : '[GraphQL error]';
          if (extensions?.code === 'INTERNAL_SERVER_ERROR') this.toastr.error(extensions.details, message);
          console.error(`${title}: Message: ${message}${extensions?.details ? ', Details: ' + JSON.stringify(extensions.details) : ''}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        });
      }
      else {
        this.toastr.error(networkError.message, '[Network error]');
        console.error(`[Network error] : ${networkError}`);
      }
    }
  });

  public setApolloClientAuthLink = () => {
    if (this.linkCreated) return;
    if (!this.apollo.client) this.initApolloClient();
    const GRAPHQL_ENDPOINT = this.local ? '://localhost:4000/graphql' : this.apiUrl.replace('http', '');
    const WS_ENDPOINT = this.local ? 'ws://localhost:4000/graphql' : this.apiUrl.replace('http', 'ws');

    const auth = setContext((_, { headers }) => {
      if (!headers) {
        headers = new HttpHeaders();
      }
      if (!this._accessToken) {
        return {
          headers: headers.append('citadelsession', this.session_id),
          withCredentials: false,
        };
      } else {
        // console.log('token', token);
        return {
          headers: headers.append('Authorization', `Bearer ${this._accessToken}`).append('citadelsession', this.session_id),
          withCredentials: true,
        };
      }
    });

    const http = this.httpLink.create({
      uri: `http${GRAPHQL_ENDPOINT}`,
    });
    const uploadLink = createUploadLink({
      uri: `http${GRAPHQL_ENDPOINT}`,
      headers: {
        citadelsession: this.session_id,
        authorization: `Bearer ${this._accessToken}`,
        'X-Apollo-Operation-Name': 'citadel_file'
      }
    });
    const wsLink = new GraphQLWsLink(
      createClient({
        url: WS_ENDPOINT,
        connectionParams: {
          citadelsession: this.session_id,
          authorization: `Bearer ${this._accessToken}`,
        },
        shouldRetry: (errOrCloseEvent) => {
          return true;
        },
        on: {
          connected: () => {
            this.setIsCitadelConnected(true);
            console.log('Citadel connected');
            this.citadelConnected.emit(true);
          },
          closed: () => {
            this.setIsCitadelConnected(false);
            console.log('Citadel closed');
            this.citadelConnected.emit(false);
          }
        },
        retryWait: async (retries) => {
          console.log(`Reconnexion attempt ${retries + 1}`);
          if (retries === 99) {
            console.log('Out of attempts');
            return;
          }
          let retryValue = retries;
          if (this.online) retryValue = 0;
          const delay = (3 + retryValue * 1.5) * 1000;
          console.log(`retrying to connect in ${delay} ms`);
          await new Promise((resolve) => setTimeout(resolve, delay));
        },
        retryAttempts: 100
      }),
    );

    const isFile = (value) => (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob);
    const isUpload = ({ variables }) => Object.values(variables).some(isFile);
    const isSub = ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as any;
      return kind === 'OperationDefinition' && operation === 'subscription';
    };
    const requestLink = split(
      isUpload, uploadLink, split(isSub, wsLink, auth.concat(http))
    );
    const link = ApolloLink.from([this.errorLink, requestLink]);
    this.apollo.client.setLink(link);
    this.linkCreated = true;
  }

  private initApolloClient = (callback?: Function) => {
    if (this.apollo.client) {
      this.apollo.removeClient();
    };
    this.session_id = uuidv4();
    const GRAPHQL_ENDPOINT = this.local ? '://localhost:4000/graphql' : this.apiUrl.replace('http', '');

    const http = this.httpLink.create({
      uri: `http${GRAPHQL_ENDPOINT}`,
    });

    const link = ApolloLink.from([this.errorLink, http]);
    const cache = new InMemoryCache();
    try {
      this.apollo.create({
        link,
        cache,
      });
      this.setApolloReady();
      if (callback) callback();
    }
    catch (e) {
      console.error("Impossible d'initialiser Apollo.", e);
    }
  }

  watchQuery(query, variables?) {
    return new Observable<ApolloQueryResult<any>>((observer) => {
      this.apollo
        .watchQuery({
          query,
          variables,
          // pollInterval: 300000,
          fetchPolicy: 'network-only',
          errorPolicy: 'all'
        })
        .valueChanges.subscribe({
          next: (data) => {
            observer.next(data);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        });
    });
  }

  subscription(subscription, variables?) {
    return this.apollo.subscribe({
      query: subscription,
      variables: variables,
      fetchPolicy: 'cache-first',
    });
  }

  mutation(mutation, variables?) {
    return new Observable<MutationResult<any>>((observer) => {
      this.apollo.mutate({
        mutation: mutation,
        variables: variables,
      }).subscribe({
        next: (data: any) => {
          observer.next(data);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        }
      });
    });
  }

  createOnline$() {
    return merge<any>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
