
import { gql } from 'apollo-angular';

export const CARTO_OUTPUT_INFO_FRAGMENT = gql`
  fragment CartoOutputInfo on ProcessOutput {
    output_id
    label
    input{
      id
      label
      type
    }
    location
  }
`;
