import { gql } from "apollo-angular";

export const DASSAULT_TOOLS_QUERY = gql`
  query dassaultTools($where: DassaultToolWhereInput!) {
    dassaultTools(where: $where)
  }
`;

export const DASSAULT_TOOL_SUBSCRIPTION = gql`
  subscription dassaultToolSubscription {
    dassaultToolSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;