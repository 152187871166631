<button id="dropdownButton"
        #dropdownButton
        data-dropdown-toggle="dropdownMenu"
        class="bg-gray-50 border border-gray-300 text-gray-900 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex justify-between items-center {{width}} mb-8"
        type="button">
  <span>{{_placeholder}}</span>
  <svg class="w-4 h-4 ml-2"
       aria-hidden="true"
       fill="none"
       stroke="currentColor"
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg">
    <path stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"></path>
  </svg>
</button>
<!-- Dropdown menu -->
<div class="fixed {{width}}">
  <div id="dropdownMenu"
       #dropdownMenu
       class="z-10 hidden bg-gray-50 divide-y divide-gray-100 rounded-lg shadow border relative {{width}}">
    <ul class="py-3 space-y-3 text-sm text-gray-700 relative max-h-48 overflow-y-auto"
        aria-labelledby="dropdownButton">
      <span *ngIf="items.length===0"
            class="ml-2">Pas de valeur à afficher</span>
      <li *ngFor="let item of items; trackBy: trackByFn"
          class="hover:bg-gray-100 h-8 pl-2 flex items-center">
        <input type="checkbox"
               id="{{item.value}}"
               *ngIf="_multiSelect"
               class="form-checkbox h-4 w-4 text-sparteBlue transition duration-150 ease-in-out rounded focus:ring-0 cursor-pointer"
               [checked]="item.selected"
               (click)="handleSelect(item)">
        <label id="{{item.value}}"
               for="{{item.value}}"
               (click)="handleClick(item)"
               class="ml-2 text-sm font-medium text-gray-900 w-full select-none cursor-pointer">{{item.viewValue}}</label>
      </li>
    </ul>
  </div>
</div>