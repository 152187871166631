import { gql } from 'apollo-angular';

export const CITADEL_STOCK_INFO_FRAGMENT = gql`
  fragment CitadelStockInfo on CitadelStock {
    stock_id
    designation
    tag
    locked
    self_service
    accounting
    business_ref
    restock_users
    reservation
    created_at
    updated_at
  }
`;
