import { gql } from 'apollo-angular';

export const CITADEL_ITEM_INFO_FRAGMENT = gql`
  fragment CitadelItemInfo on CitadelItem {
    asset_id
    parent_asset_id
    designation
    model_id
    parent_zone_id
    sort_order
    guid
    group_id
    parent_systems_ids
    asset_instances {
      variables {
        values
        variable_model_id
      }
    }
    variables {
      values
      variable_model_id
    }
    created_at
    updated_at
  }
`;
