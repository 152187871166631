import { gql } from 'apollo-angular';
import { RESOURCE_LIBRARY_INFO_FRAGMENT } from './fragments/_resourceLibrary';

export const RESOURCE_LIBRARIES_QUERY = gql`
  query resourceLibraries {
    resourceLibraries
  }
`;

export const CREATE_RESOURCE_LIBRARY_MUTATION = gql`
  mutation createResourceLibrary($data: ResourceLibraryCreateInput!) {
    createResourceLibrary(data: $data) {
      ...ResourceLibraryInfo
    }
  }
  ${RESOURCE_LIBRARY_INFO_FRAGMENT}
`;

export const UPDATE_RESOURCE_LIBRARY_MUTATION = gql`
  mutation updateResourceLibrary(
    $where: ResourceLibraryWhereUniqueInput!
    $data: ResourceLibraryUpdateInput!
  ) {
    updateResourceLibrary(where: $where, data: $data) {
      ...ResourceLibraryInfo
    }
  }
  ${RESOURCE_LIBRARY_INFO_FRAGMENT}
`;

export const DELETE_RESOURCE_LIBRARY_MUTATION = gql`
  mutation deleteResourceLibrary($where: ResourceLibraryWhereUniqueInput!) {
    deleteResourceLibrary(where: $where) {
      library_id
    }
  }
`;

export const RESOURCE_LIBRARY_SUBSCRIPTION = gql`
  subscription resourceLibrarySubscription {
    resourceLibrarySubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
