import { Deserializable } from '@sparte/utils';
import { CitadelStock } from './citadelStock.model';
import { CitadelTask } from './citadelTask.model';

export class ResourceLink implements Deserializable {
  public id: string;
  public task_id: string;
  public resource_id: string;
  public link_from: string;
  public link_to: string;
  public created_at: Date;
  public updated_at: Date;
  public typeName = "ResourceLink";
  private _origin: CitadelStock | CitadelTask;
  private _destination: CitadelStock | CitadelTask;

  deserialize(input: any): this {
    const { __typename, link_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = link_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this._origin = null;
    this._destination = null;
    return this;
  }

  get searchTerm(): string {
    return;
  }

  get label(): string {
    return;
  }

  get whereUniqueValue(): any {
    return {
      link_id: this.id,
      task_id: this.task_id
    }
  }

  get createValue(): any {
    const { id, typeName, created_at, updated_at, _origin, _destination, ..._this } = this;
    return _this;
  }

  get updateValue(): any {
    const { id, typeName, created_at, updated_at, resource_id, task_id, _origin, _destination, ..._this } = this;
    return _this;
  }

  get isValid(): boolean {
    return //this.citadelCoreService.getCitadelTasks.some(task => task.in_resources.some(res => res.nodeId === this.link_to)) && this.citadelCoreService.getCitadelTasks.some(task => task.out_resources.some(res => res.nodeId === this.link_from));
  }


  get origin(): CitadelTask | CitadelStock {
    /* if (this.resource_id) {
      if (!this._origin) {
        const citadelTask = this.citadelCoreService.getCitadelTasks.find(task => task.out_resources.some(resourceForTask => resourceForTask.nodeId === this.link_from));
        if (citadelTask) {
          this._origin = citadelTask;
        }
        else {
          const stockNode = this.citadelCoreService.getStockNodes.find(sNode => sNode.id === this.link_from);
          if (stockNode) {
            const citadelStock = stockNode.citadelStock;
            if (citadelStock) {
              this._origin = citadelStock;
            }
          }
        }
      }
      return this._origin;
    } */
    return null;
  }

  get destination(): CitadelTask | CitadelStock {
    /* if (this.resource_id) {
      if (!this._destination) {
        const citadelTask = this.citadelCoreService.getCitadelTasks.find(task => task.in_resources.some(resourceForTask => resourceForTask.nodeId === this.link_to));
        if (citadelTask) {
          this._destination = citadelTask;
        }
        else {
          const stockNode = this.citadelCoreService.getStockNodes.find(sNode => sNode.id === this.link_to);
          if (stockNode) {
            const citadelStock = stockNode.citadelStock;
            if (citadelStock) {
              this._destination = citadelStock;
            }
          }
        }
      }
      return this._destination;
    } */
    return null;
  }

}