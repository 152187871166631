import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CREATE_FILE_MUTATION,
  DELETE_FILE_MUTATION,
  RENAME_FILE_MUTATION,
  SPARTE_BATCH_FILES_QUERY,
  SPARTE_FILE_QUERY,
} from '../../queries';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class FileApi {
  constructor(private api: ApiService) { }

  getSparteFile(file_id: string): Observable<any> {
    return this.api
      .watchQuery(SPARTE_FILE_QUERY, { where: { file_id } })
      .pipe(
        map((result: any) => {
          return result.data['file'];
        })
      );
  }

  getBatchFiles(filesIds: string[]): Observable<any> {
    return this.api
      .watchQuery(SPARTE_BATCH_FILES_QUERY, { where: { file_id_in: filesIds } })
      .pipe(
        map((result: any) => {
          return JSON.parse(result.data['batchFiles']);
        })
      );
  }

  uploadSparteFile(file: File, args: any): Observable<any> {
    let user_id = '';
    if (this.api.currentUser) {
      user_id = this.api.currentUser.id;
    }
    return this.api
      .mutation(CREATE_FILE_MUTATION, { file, ...args, user_id })
      .pipe(
        map((result) => {
          return result.data['createFile'];
        })
      );
  }

  renameSparteFile(file_id: string, name: string): Observable<any> {
    return this.api
      .mutation(RENAME_FILE_MUTATION, {
        where: { file_id },
        data: { name },
      })
      .pipe(
        map((result) => {
          return result.data['renameFile'];
        })
      );
  }

  deleteSparteFile(file_id: string): Observable<any> {
    return this.api
      .mutation(DELETE_FILE_MUTATION, { where: { file_id } })
      .pipe(
        map((result) => {
          return result.data['deleteFile'];
        })
      );
  }
}
