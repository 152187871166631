import { gql } from 'apollo-angular';

export const DASSAULT_ORDERS_QUERY = gql`
  query dassaultOrders {
    dassaultOrders
  }
`;

export const BATCH_INSERT_DASSAULT_ORDERS_MUTATION = gql`
  mutation batchInsertDassaultOrders($data: DassaultOrderBatchCreateInput!) {
    batchInsertDassaultOrders(data: $data)
  }
`;

export const DASSAULT_ORDER_SUBSCRIPTION = gql`
  subscription dassaultOrderSubscription {
    dassaultOrderSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;