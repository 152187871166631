import { Deserializable, quantityPrettyfier } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';

import { Resource } from './resource.model';

export class ResourceForTask implements Deserializable {
  private node_id: string;
  public resource_id: string;
  public quantity: number;
  public typeName = "ResourceForTask";

  constructor(private citadelCoreService: CitadelCoreService) { }
  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get searchTerm(): string {
    return;
  }

  get label(): string {
    return `${this.resource?.designation}${this.quantity ? ` – ${quantityPrettyfier(this.quantity)}` : ''}`;
  }

  get inLabel(): string {
    return `${this.quantity ? `${quantityPrettyfier(this.quantity)} – ` : ''}${this.resource?.designation}`;
  }
  get outLabel(): string {
    return this.label;
  }

  get updateValue(): any {
    const { typeName, citadelCoreService, ..._this } = this;
    return _this;
  }

  get nodeId(): string {
    return this.node_id;
  }

  get resource(): Resource {
    return this.citadelCoreService.getResource(this.resource_id);
  }

  get stageLabel(): string {
    return this.resource ? `${quantityPrettyfier(this.quantity)} * ${this.resource.designation}` : "";
  }
}