import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GalleryViewerComponent } from '../gallery-viewer/gallery-viewer.component';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(public dialog: MatDialog) {}

  /**
   * Ouvre le dialogue pour afficher la gallerie d'images
   * @param {SparteFile[]} fileArray: images a afficher.
   * @param {Number} index: index de l'image a afficher en premier
   * @param {boolean} showImageTitle: si on doit afficher le titre des images
   * @param {String} panelClass: nom de la classe utilisée pour afficher le dialogue
   */
  display(
    fileArray: any[],
    index = 0,
    showImageTitle = false,
    panelClass = 'gallery-dialog-container'
  ): void {
    this.dialog.open(GalleryViewerComponent, {
      data: {
        files: fileArray,
        index: index,
        showImageTitle: showImageTitle,
      },
      panelClass: panelClass,
    });
  }
}
