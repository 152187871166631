<div class="confirmation-container mat-typography">
  <h2 class="flex justify-center p-3 text-white bg-sparteOrange-500"
      [ngClass]="data.titleClass || default.titleClass"> {{data.title || default.title}} </h2>
  <span class="information-text"
        *ngIf="data.text"
        [innerHtml]="data.text"></span>
  <div class="buttons flex justify-center items-center gap-[100px]">
    <sparte-button mat-button
                   (click)="closeDialog()"
                   color="accent"
                   red
                   emphezise
                   roundedLg
                   label="ok"
                   icon="done"> {{data.confirm || default.confirm}} </sparte-button>
  </div>