import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '@sparte/ui';
import { MobxAngularModule } from 'mobx-angular';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ActionStepperComponent } from './action-stepper/action-stepper.component';
import { ArticleBasketComponent } from './article-basket/article-basket.component';
import { ReassignArticleFormComponent } from './article-basket/reassign-article-form/reassign-article-form.component';
import { ArticleHistoryComponent } from './article-history/article-history.component';
import { ArticleQuantityFormComponent } from './article-quantity-form/article-quantity-form.component';
import { EntityHistoryDrawerComponent } from './entity-history-drawer/entity-history-drawer.component';
import { ObjectInfosDrawerComponent } from './object-infos-drawer/object-infos-drawer.component';
import { PrintShipmentSlipComponent } from './print-shipment-slip/print-shipment-slip.component';
import { RemoveArticleFormComponent } from './remove-article-form/remove-article-form.component';
import { ShippingOrderComponent } from './shipping-order/shipping-order.component';
import { StocksConfirmationDialogComponent } from './stocks-confirmation-dialog/stocks-confirmation-dialog.component';

@NgModule({
  declarations: [
    ArticleQuantityFormComponent,
    StocksConfirmationDialogComponent,
    ActionStepperComponent,
    ObjectInfosDrawerComponent,
    ArticleHistoryComponent,
    ShippingOrderComponent,
    EntityHistoryDrawerComponent,
    PrintShipmentSlipComponent,
    RemoveArticleFormComponent,
    ArticleBasketComponent,
    ReassignArticleFormComponent
  ],
  imports: [CommonModule, MobxAngularModule, NgxBarcode6Module, UiModule],
  exports: [
    ArticleQuantityFormComponent,
    StocksConfirmationDialogComponent,
    ActionStepperComponent,
    ObjectInfosDrawerComponent,
    ArticleHistoryComponent,
    ShippingOrderComponent,
    EntityHistoryDrawerComponent,
    PrintShipmentSlipComponent,
    RemoveArticleFormComponent,
    ArticleBasketComponent
  ],
})
export class ComponentsModule { }
