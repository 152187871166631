import { GroupTypeEnum } from '@sparte/citadel-core';
import { Deserializable } from '@sparte/utils';
import { UserService } from '../services/user.service';
import { CitadelUser } from './citadelUser.model';
import { Role } from './role.model';

export class UserGroup implements Deserializable {
  public id: string;
  public name: string;
  public description: string;
  public group_type: GroupTypeEnum;
  public parent_group_id: string;
  public company_id: string;
  public infrastructures_ids: string[];
  private created_at: Date;
  private updated_at: Date;
  public fetchedRoles: boolean;
  public x = 0;
  public y = 0;
  public readonly typeName = "UserGroup";
  constructor(private userService: UserService) {
    this.fetchedRoles = false;
  }

  deserialize(input: any): this {
    const { __typename, created_at, updated_at, group_id, infrastructures_ids, ..._input } = input;
    Object.assign(this, _input);
    this.id = group_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.infrastructures_ids = infrastructures_ids || [];
    return this;
  }

  get createValue(): any {
    const { id, fetchedRoles, userService, created_at, updated_at, company_id, infrastructures_ids, typeName, ..._this } = this;
    return {
      ..._this
    }
  }

  get updateValue(): any {
    const { id, fetchedRoles, userService, created_at, updated_at, company_id, typeName, ..._this } = this;
    return {
      ..._this
    }
  }

  get label(): string {
    return this.name
  }

  get roles(): Role[] {
    return this.userService.getRoles.filter(role => role.group_id === this.id);
  }

  get company(): UserGroup {
    return this.userService.getUserGroup(this.company_id);
  }

  get parentGroup(): UserGroup {
    return this.userService.getUserGroup(this.parent_group_id);
  }

  get childrenGroups(): UserGroup[] {
    if (this.group_type !== GroupTypeEnum.Company) return this.userService.getUserGroups.filter(group => group.parent_group_id === this.id);
    return this.userService.getUserGroups.filter(group => group.id !== this.id && group.company_id === this.id);
  }

  get users(): CitadelUser[] {
    return this.userService.getCitadelUsers.filter(user => user.groups_ids.includes(this.id) || user.companies_ids.includes(this.id));
  }

  get parentGroupsIds(): string[] {
    if (!this.parent_group_id) return [];
    return [this.parent_group_id, ...this.parentGroup?.parentGroupsIds];
  }

  get searchTerm(): string {
    return this.name?.toLowerCase();
  }

  get position(): { x: number, y: number } {
    return { x: this.x, y: this.y };
  }

  get matIcon(): string {
    switch (this.group_type) {
      case 'Company':
        return "business"
      case 'Service':
        return "miscellaneous_services"
      case 'Team':
        return "group";
      default:
        return "report";
    }
  }

  get getIcon(): string {
    return `./assets/icons/${this.matIcon}_black_24dp.svg`;
  }

  get getCartoIcon(): string {
    return `./assets/icons/${this.matIcon}_white_24dp.svg`;
  }

  get getSize(): number {
    switch (this.group_type) {
      case 'Company':
        return 20;
      case 'Service':
        return 15;
      case 'Team':
        return 12;
    }
  }
}