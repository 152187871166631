import { gql } from 'apollo-angular';

export const ORDERS_QUERY = gql`
  query stockOrders {
    stockOrders
  }
`;

export const ORDER_LOGS_QUERY = gql`
  query orderLogs($where: StockOrderWhereUniqueInput!) {
    orderLogs(where: $where)
  }
`;

export const ARCHIVED_ORDERS_QUERY = gql`
  query archivedOrders($where: TimeFrameInput) {
    archivedOrders(where: $where)
  }
`;

export const CLOSE_ORDER_MUTATION = gql`
  mutation closeStockOrder($where: StockOrderWhereUniqueInput!) {
    closeStockOrder(where: $where) {
      order_id
    }
  }
`;

export const DELETE_ORDER_MUTATION = gql`
  mutation deleteStockOrder($where: StockOrderWhereUniqueInput!) {
    deleteStockOrder(where: $where) {
      order_id
    }
  }
`;

export const ORDER_SUBSCRIPTION = gql`
  subscription stockOrderSubscription {
    stockOrderSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const ARCHIVED_ORDER_SUBSCRIPTION = gql`
subscription archivedOrderSubscription {
  archivedOrderSubscription {
    mutationType
    objectId
    objectJson
  }
}
`;

export const RELOAD_DATA_SUBSCRIPTION = gql`
  subscription reloadDataSubscription {
    reloadDataSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;