<button type="button"
        class="w-full px-4 text-sm font-medium transition-colors border select-none"
        [disabled]="disabled"
        [ngClass]="buttonClasses"
        (click)="onMouseClick()"
        (pointerdown)="clickDown()"
        (pointerleave)="endClick()"
        (pointerup)="endClick()">
  <label [for]="labelFor"
         #forLabel
         class="flex items-center justify-center w-full h-full gap-4"
         [ngClass]="disabled ? 'cursor-not-allowed': 'cursor-pointer'">
    <span [ngClass]="iconClasses"
          *ngIf="icon">{{icon}}</span>
    <span class="tracking-widest uppercase truncate"
          *ngIf="label">{{label}}</span>
    <span class="px-4 py-1 text-xs font-medium rounded-full valueDisplay"
          [ngClass]="!focused ? backgroundColor + ' text-white' : textColor + ' bg-white'"
          *ngIf="value !== undefined">{{valueFormat(value)}}</span>
  </label>
</button>