import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sparte-spinner',
  templateUrl: './sparte-spinner.component.html',
  styleUrls: ['./sparte-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparteSpinnerComponent implements OnInit {
  public _style: 'round' | 'fancy' | 'fancy2' | 'bar' = 'round';
  public _size: string;
  public borderSize: string;
  public diameterDiff: string;

  @Input() enabled: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() primary: string = '#183e77';
  @Input() secondary: string = 'lightgray';
  @Input() set round(param) { this._style = 'round'; }
  @Input() set fancy(param) { this._style = 'fancy'; }
  @Input() set fancy2(param) { this._style = 'fancy2'; }
  @Input() set bar(param) { this._style = 'bar'; }
  @Input() set small(param) { this.size = 'small'; }
  @Input() set medium(param) { this.size = 'medium'; }
  @Input() set large(param) { this.size = 'large'; }

  constructor() { }

  ngOnInit(): void {

    //size
    switch (this.size) {
      case 'small':
        this._size = '24px';
        this.borderSize = '2px';
        this.diameterDiff = '4px';
        break;
      case 'medium':
        this._size = '48px';
        this.borderSize = '4px';
        this.diameterDiff = '8px';
        break;
      case 'large':
        this._size = '96px';
        this.borderSize = '6px';
        this.diameterDiff = '16px';
        break;
    }
  }
}
