import { Deserializable, UnitMeasurementEnum } from '@sparte/utils';

export class Resource implements Deserializable {
  public id: string;
  public reference: string;
  public catalog_id: string;
  public designation: string;
  public description: string;
  public unit: UnitMeasurementEnum;
  public created_at: Date;
  public updated_at: Date;
  public typeName = "Resource";
  private snapshot: string;

  deserialize(input: any): this {
    const { __typename, resource_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = resource_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this.snapshotObject();
    return this;
  }

  private snapshotObject() {
    this.snapshot = JSON.stringify(this.snapshotValue);
  }

  applySnapshot() {
    this.deserialize(JSON.parse(this.snapshot));
  }

  get searchTerm(): string {
    return `${this.reference} ${this.designation}`.toLowerCase();
  }

  get label(): string {
    return `${this.reference} - ${this.designation}`;
  }

  get whereUniqueValue(): any {
    return {
      resource_id: this.id
    }
  }

  get createValue(): any {
    const { id, typeName, snapshot, created_at, updated_at, ..._this } = this;
    return _this;
  }


  get updateValue(): any {
    const { id, typeName, snapshot, created_at, updated_at, ..._this } = this;
    return _this;
  }

  get snapshotValue() {
    const { id, snapshot, typeName, ..._this } = this;
    return { resource_id: id, ..._this };
  }
}