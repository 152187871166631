import { Injectable } from '@angular/core';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';
import { ElectronService } from 'ngx-electron';

@Injectable({
  providedIn: 'root'
})
export class ElectronStockService {
  public isInElectron = false;
  @observable upToDate = false;
  @observable updateProgress = -1;
  constructor(private electron: ElectronService) {
    if (this.electron.isElectronApp) {
      makeObservable(this);
      this.isInElectron = true;
      this.sendMessage('ready');
      this.electron.ipcRenderer.on('info', (event, args) => {
        console.log('info', args);
      });
      this.electron.ipcRenderer.on('dev', (event, args) => {
        console.log('dev', args);
      });
      this.electron.ipcRenderer.on('err', (event, args) => {
        console.error('err', args);
      });
      this.eventSubscribe('update', data => {
        if (data.eventType === 'upToDate') {
          this.setUpToDate();
        }
      });
    }
    else {
      this.setUpToDate();
    }
  }

  @action setUpToDate() {
    this.upToDate = true;
  }

  eventSubscribe(eventName: string, callback: Function) {
    if (this.electron.isElectronApp) {
      // console.log('desktopSubscribe', eventName);
      this.electron.ipcRenderer.on(eventName, (event, args) => {
        console.log('desktopReceived', eventName);
        if (this.isJsonString(args)) {
          callback(JSON.parse(args));
        }
        else {
          callback(args);
        }
      });
    }
  }

  eventSubscribeOnce(eventName: string, callback: Function) {
    if (this.electron.isElectronApp) {
      // console.log('desktopSubscribeOnce', eventName);
      this.electron.ipcRenderer.once(eventName, (event, args) => {
        console.log('desktopReceived', eventName);
        if (this.isJsonString(args)) {
          callback(JSON.parse(args));
        }
        else {
          callback(args);
        }
      });
    }
  }

  enventUnsubscribe(eventName: string) {
    if (this.electron.isElectronApp) {
      this.electron.ipcRenderer.removeAllListeners(eventName);
    }
  }

  sendMessage(eventType: string, msg?: any) {
    // console.log('electronMessage', eventType, msg);
    if (this.electron.isElectronApp) {
      this.electron.ipcRenderer.send('angular', {
        eventType: eventType,
        message: msg
      });
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
