import { gql } from 'apollo-angular';

import { CARTO_RESOURCE_INFO_FRAGMENT } from './fragments/_cartoResource';

export const CARTO_RESOURCES_QUERY = gql`
  query cartoResources {
    processResources
  }
`;

export const CARTO_RESOURCE_SUBSCRIPTION = gql`
  subscription cartoResourceSubscription {
    processResourceSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_CARTO_RESOURCE_MUTATION = gql`
  mutation createCartoResource($data: ProcessResourceCreateInput!) {
    createProcessResource(data: $data) {
      ...CartoResourceInfo
    }
  }
  ${CARTO_RESOURCE_INFO_FRAGMENT}
`;

export const UPDATE_CARTO_RESOURCE_MUTATION = gql`
  mutation updateCartoResource(
    $where: ProcessResourceWhereUniqueInput!
    $data: ProcessResourceUpdateInput!
  ) {
    updateProcessResource(where: $where, data: $data) {
      ...CartoResourceInfo
    }
  }
  ${CARTO_RESOURCE_INFO_FRAGMENT}
`;

export const DELETE_CARTO_RESOURCE_MUTATION = gql`
  mutation deleteCartoResource(
    $where: ProcessResourceWhereUniqueInput!
  ) {
    deleteProcessResource(where: $where) {
      resource_id
    }
  }
`;