import { gql } from 'apollo-angular';

export const STOCK_BUSINESS_INFO_FRAGMENT = gql`
  fragment StockBusinessInfo on StockBusiness {
    reference
    designation
    network_ids
    addresses {
      label
      address{
        street
        zip_code
        city
        country
      }
    }
    from_sap
    created_at
    updated_at
  }
`;
