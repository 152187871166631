import { Deserializable } from '@sparte/utils';

export class UserNode implements Deserializable {
  public id: string;
  public user_id: string;
  public group_id: string;
  searchTerm: string;
  label: string;
  public x = 0;
  public y = 0;
  public readonly typeName = "UserNode";
  constructor() { }

  deserialize(input: any): this {
    const { __typename, node_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = node_id;
    return this;
  }

  get position(): { x: number, y: number } {
    return { x: this.x, y: this.y };
  }

  get whereUniqueValue(): any {
    return {
      node_id: this.id,
      group_id: this.group_id
    };
  }

  get updateValue(): any {
    const { id, user_id, group_id, typeName, ..._this } = this;
    return {
      ..._this
    }
  }

  get createValue(): any {
    const { id, typeName, ..._this } = this;
    return {
      ..._this
    }
  }
}