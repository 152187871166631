import { gql } from 'apollo-angular';
import { CITADEL_USER_INFO_FRAGMENT } from './fragments/_citadelUser';

//permet de récupérer les urls des avatar par mails.
export const GET_USERS_AVATARS_QUERY = gql`
  query getUsersAvatars($where: UserAvatarWhereInput!){
    getUsersAvatars(where:$where){
      mail
      avatar_url
      full_name
    }
  }
`;

export const CHECK_USER_PIN = gql`
  query checkUserPin($mail: String!){
    checkUserPin(mail: $mail)
  }
`;

export const CITADEL_USERS_QUERY = gql`
  query citadelUsers {
    citadelUsers
  }
`;

export const GET_USERS_INFO_QUERY = gql`
  query getUsersInfo($where: UserAuthWhereInput!) {
    getUsersInfo(where: $where)
  }
`;

export const CREATE_CITADEL_USER_MUTATION = gql`
  mutation createCitadelUser($data: CitadelUserCreateInput!) {
    createCitadelUser(data: $data) {
      ...CitadelUserInfo
    }
  }
  ${CITADEL_USER_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_USER_MUTATION = gql`
  mutation updateCitadelUser(
    $where: CitadelUserWhereUniqueInput!
    $data: CitadelUserUpdateInput!
  ) {
    updateCitadelUser(where: $where, data: $data) {
      ...CitadelUserInfo
    }
  }
  ${CITADEL_USER_INFO_FRAGMENT}
`;

export const UPDATE_SELF_AVATAR_MUTATION = gql`
mutation updateSelfAvatar($data: UpdateAvatarInput!) {
    updateSelfAvatar(data: $data) {
      ...CitadelUserInfo
    }
  }
  ${CITADEL_USER_INFO_FRAGMENT}
`;

export const DELETE_CITADEL_USER_MUTATION = gql`
  mutation deleteCitadelUser ($where: CitadelUserWhereUniqueInput!) {
    deleteCitadelUser(where: $where) {
      user_id
    }
  }
`;

export const ARCHIVE_CITADEL_USER_MUTATION = gql`
  mutation archiveCitadelUser($where: CitadelUserWhereUniqueInput!) {
    archiveCitadelUser(where: $where) {
      user_id
    }
  }
`;

export const CITADEL_USER_SUBSCRIPTION = gql`
  subscription citadelUserSubscription {
    citadelUserSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
