import { Deserializable } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { NodeData } from '../utils/citadel-core.interface';
import { AssetCategory } from './assetCategory.model';
import { AssetInstance } from './assetInstance.model';
import { AssetModel } from './assetModel.model';
import { AssetZone } from './assetZone.model';
import { CitadelVariable } from './citadelVariable.model';
import { ParameterSet } from './parameterSet.model';
import { CitadelStorage } from './citadelStorage.model';

export enum AssetTypeEnum {
  Infrastructure = "CitadelInfrastructure",
  Item = "CitadelItem"
}

export abstract class CitadelAsset implements Deserializable {
  public id: string;
  // public owners_ids: string[];
  // public creators_ids: string[];
  // public operators_ids: string[];
  // public maintainers_ids: string[];
  // public users_ids: string[];
  public sort_order: number;
  public parent_asset_id: string;
  public designation: string;
  public model_id: string;
  public parent_zone_id: string;
  public parent_systems_ids: string[];
  public asset_instances: AssetInstance;
  public variables: CitadelVariable[] = [];
  public created_at: Date;
  public updated_at: Date;
  protected nodeData: NodeData;
  typeName: string;

  constructor(protected citadelCoreService: CitadelCoreService) { }
  deserialize(input: any): this {
    throw new Error('Method not implemented.');
  }

  deserializeAsset(input: any) {
    const { __typename, variables, created_at, updated_at, asset_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = asset_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this.variables = (variables || []).map(variable => new CitadelVariable().deserialize(variable));
    return this;
  }

  get updateValue(): any {
    return;
  }

  get searchTerm(): any {
    return `${this.designation} ${this.model?.name} ${this.category?.name}`.toLowerCase();
  }

  get label(): any {
    return this.designation;
  }

  get type(): string {
    return this.typeName;
  }

  get model(): AssetModel {
    return this.citadelCoreService.getAssetModel(this.model_id);
  }

  get category_id(): string {
    return this.model?.category_id;
  }

  get category(): AssetCategory {
    return this.citadelCoreService.getAssetCategory(this.category_id);
  }

  get allParameterSets(): ParameterSet[] {
    return [...new Set([...(this.model ? this.model.parameterSets : []), ...(this.category ? this.category.parameterSets : [])])];
  }

  get parentNode(): CitadelAsset | AssetZone {
    if (this.parent_zone_id) return this.citadelCoreService.getAssetZone(this.parent_zone_id) ? this.citadelCoreService.getAssetZone(this.parent_zone_id) : null;
    else return this.parent_asset_id ? this.citadelCoreService.getCitadelAsset(this.parent_asset_id) : null;
  }

  get directChildAssets(): CitadelAsset[] {
    return this.citadelCoreService.getCitadelInfrastructures.filter(asset => asset.parent_asset_id === this.id && asset.parent_zone_id === null);
  }

  get assetZones(): AssetZone[] {
    return this.citadelCoreService.getAssetZones.filter(zone => zone.parent_asset_id === this.id);
  }

  get directAssetZones(): AssetZone[] {
    return this.assetZones.filter(zone => zone.parent_zone_id === null);
  }

  get directChildren(): (CitadelAsset | AssetZone | CitadelStorage)[] {
    return [...this.directChildAssets, ...this.directAssetZones];
  }

  get parentAsset(): CitadelAsset {
    return this.parent_asset_id ? this.citadelCoreService.getCitadelAsset(this.parent_asset_id) : null;
  }

  get parentAssetIds(): string[] {
    return this.parentAsset?.parentAssetIds.concat(this.parent_asset_id) || [];
  }

  get parentZone(): AssetZone {
    return this.parent_zone_id ? this.citadelCoreService.getAssetZone(this.parent_zone_id) : null;
  }

  get parentZoneIds(): string[] {
    if (this.parent_zone_id) {
      return this.citadelCoreService.getAssetZone(this.parent_zone_id).parentZoneIds.concat(this.parent_zone_id);
    }
    else return [];
  }

  get allChildren(): (CitadelAsset | AssetZone | CitadelStorage)[] {
    return this.directChildren
    .sort((zoneA, zoneB) => zoneA?.sort_order > zoneB?.sort_order ? 1 : -1)
    .reduce((allChildren, child) => {
      if(child instanceof AssetZone) return [...allChildren, child, ...(child?.allChildren || [])];
      else return [...allChildren, child];
    }, []);
  }

  hasParameterSet(set_id: string): boolean {
    return this.allParameterSets.some(set => set.id === set_id);
  }

  variableValues = (variable_model_id: string): string[] => {
    if (this.variables ? this.variables.length > 0 : false) {
      const targetVar = this.variables.find(variable => variable.variable_model_id === variable_model_id);
      if (targetVar) {
        return targetVar.values;
      }
    }
    else return null;
  }

  variableValueById = (variable_model_id: string): string => {
    if (this.variables ? this.variables.length > 0 : false) {
      const targetVar = this.variables.find(variable => variable.variable_model_id === variable_model_id);
      if (targetVar) {
        return targetVar.values.join(', ');
      }
    }
    else return;
  }

  variableValueByName = (variable_model_name: string): string => {
    if (this.variables ? this.variables.length > 0 : false) {
      const variableModel = this.allParameterSets.flatMap(set => set.variableModels).find(variableModel => variableModel.display_name === variable_model_name);
      if (!variableModel) return;
      const targetVar = this.variables.find(variable => variable.variable_model_id === variableModel.variable_model_id);
      if (targetVar) {
        return targetVar.values.join(', ');
      }
    }
    else return;
  }
}