import { Deserializable } from '@sparte/utils';
import { Point } from 'pixi.js';
import { CitadelCoreService } from '../services/citadel-core.service';
import { CitadelStock } from './citadelStock.model';

export class StockNode implements Deserializable {
  public id: string;
  public task_id: string;
  public stock_id: string;
  public x: number;
  public y: number;
  public created_at: Date;
  public updated_at: Date;
  typeName = "StockNode";

  constructor(private citadelCoreService: CitadelCoreService) { }
  deserialize(input: any): this {
    const { __typename, node_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = node_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);

    return this;
  }
  get searchTerm(): string {
    return;
  }
  get label(): string {
    return this.citadelStock?.label || '';
  }

  get whereUniqueValue(): any {
    return {
      node_id: this.id,
      task_id: this.task_id
    }
  }

  get createValue(): any {
    const { id, typeName, created_at, updated_at, citadelCoreService, ..._this } = this;
    return _this;
  }

  get updateValue(): any {
    const { id, typeName, created_at, updated_at, citadelCoreService, ..._this } = this;
    return _this;
  }

  setPosition(position: Point) {
    this.x = Math.round(position.x);
    this.y = Math.round(position.y);
  }

  get position(): Point {
    return new Point(this.x, this.y);
  }

  get citadelStock(): CitadelStock {
    return this.stock_id ? this.citadelCoreService.getCitadelStock(this.stock_id) : null;
  }

  get resources_ids(): string[] {
    /* if (this.citadelStock) {
      return this.citadelStock.resources_ids || [];
    }
    else  */
    return [];
  }
}