import { AvatarByUser, UserService } from '@sparte/citadel-core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, errorMessageParser } from '@sparte/api';
import { validate } from 'email-validator';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sparte-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit {
  @observable formMode: 'SELECT' | 'LOGIN' | 'PIN' | 'SIGNUP' | 'VERIFY' | 'FORGOT' | 'UPDATEPASS' | 'RESET' | 'UPDATEPIN' = 'SELECT';
  @observable emailError: string;
  @observable passwordError: string;
  @observable newPasswordError: string;
  @observable pinError: string;
  @observable newPinError: string;
  @observable codeError: string;
  @observable fetching: boolean = false;
  avatarByUser: AvatarByUser[];
  @observable email: string;
  @observable password: string;
  numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  @observable pin: string;
  @observable code: string;
  @observable newPassword: string;
  @observable newPin: string;
  @observable confirmPassword: string;
  @observable confirmPin: string;
  @observable firstName: string;
  @observable lastName: string;
  keepMeLoggedIn: boolean;
  selectedProfile: AvatarByUser;
  constructor(
    private api: ApiService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    makeObservable(this);

    if (!this.profiles?.length) this.formMode = 'LOGIN';
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.data.subscribe(data => {
      if (data.formMode) {
        this.setFormMode(data.formMode);
        if (this.formMode === 'UPDATEPASS' || 'UPDATEPIN') {
          this.email = this.api.currentUser?.email;
        }
      }
    });
  }

  get profiles(): string[] {
    return this.api.profiles;
  }

  get canSubmit(): boolean {
    switch (this.formMode) {
      case 'LOGIN':
        return this.emailValid && !!this.password;
      case 'PIN':
        return this.emailValid && this.passwordMatch && !!this.pin;
      case 'VERIFY':
        return this.emailValid && !!this.code;
      case 'SIGNUP':
        return this.emailValid && !!this.newPassword && this.newPasswordValid && !!this.confirmPassword && !!this.newPin && this.newPinValid && this.confirmPin && !!this.firstName
          && !!this.lastName && this.passwordMatch;
      case 'FORGOT':
        return this.emailValid;
      case 'RESET':
        return this.emailValid && !!this.newPassword && this.newPasswordValid && !!this.confirmPassword && !!this.newPin && this.newPinValid && this.confirmPin && !!this.code && this.passwordMatch;
      case 'UPDATEPASS':
        return !!this.password && !!this.newPassword && this.newPasswordValid && !!this.confirmPassword && this.passwordMatch;
      case 'UPDATEPIN':
        return !!this.password && !!this.newPin && this.newPinValid && !!this.confirmPin && this.passwordMatch && this.pinMatch;
    }
  }

  get formTitle(): string {
    switch (this.formMode) {
      case 'LOGIN':
      case 'PIN':
        return 'Connection';
      case 'SIGNUP':
        return 'Inscription';
      case 'VERIFY':
        return 'Vérification';
      case 'FORGOT':
        return 'Mot de passe oublié';
      case 'RESET':
        return 'Réinitialisation du mot de passe';
      case 'UPDATEPASS':
        return 'Mise à jour du mot de passe';
      case 'UPDATEPIN':
        return 'Mise à jour du code';
    }
  }

  get buttonTitle(): string {
    switch (this.formMode) {
      case 'LOGIN':
      case 'PIN':
        return 'Connexion';
      case 'SIGNUP':
        return 'S\'inscrire';
      case 'VERIFY':
        return 'Vérifier';
      case 'FORGOT':
        return 'Envoyer';
      case 'RESET':
        return 'Réinitialiser';
      case 'UPDATEPIN':
      case 'UPDATEPASS':
        return 'Mettre à jour';
    }
  }

  get buttonLogo(): string {
    switch (this.formMode) {
      case 'LOGIN':
      case 'SIGNUP':
      case 'PIN':
        return 'login';
      case 'UPDATEPIN':
      case 'UPDATEPASS':
        return 'done';
      case 'VERIFY':
        return 'task_alt';
      case 'FORGOT':
        return 'send';
      case 'RESET':
        return 'restart_alt';
      default:
        return 'login';
    }
  }

  get emailValid(): boolean {
    return validate(this.email);
  }

  get pinValid(): boolean {
    return validate(this.pin)
  }

  get firstNameValid(): boolean {
    return !!this.firstName;
  }

  get lastNameValid(): boolean {
    return !!this.lastName;
  }

  get newPasswordValid(): boolean {
    return this.newPassword.length >= 8;
  }

  get newPinValid(): boolean {
    return this.newPin.length >= 4;
  }

  get passwordMatch(): boolean {
    return this.newPassword === this.confirmPassword;
  }

  get pinMatch(): boolean {
    return this.newPin === this.confirmPin;
  }

  get showBackButton(): boolean {
    return this.formMode === 'LOGIN' ? this.profiles.length > 0 : true;
  }

  @action updateMail = (mail: string) => {
    this.email = mail;
  }

  @action updatePassword = (password: string) => {
    this.password = password;
  }

  @action updatePin = (pin: string) => {
    this.pin = pin;
  }

  @action updateCode = (code: string) => {
    this.code = code;
  }

  @action updateNewPassword = (password: string) => {
    this.newPassword = password;
  }

  @action updateNewPin = (pin: string) => {
    this.newPin = pin;
  }

  @action updateConfirmPassword = (password: string) => {
    this.confirmPassword = password;
  }

  @action updateConfirmPin = (pin: string) => {
    this.confirmPin = pin;
  }

  @action updateFirstName = (firstName: string) => {
    this.firstName = firstName;
  }

  @action updateLastName = (lastName: string) => {
    this.lastName = lastName;
  }

  @action setFormMode = (mode: 'LOGIN' | 'SELECT' | 'PIN' | 'SIGNUP' | 'VERIFY' | 'FORGOT' | 'UPDATEPASS' | 'RESET') => {
    this.formMode = mode;
  }

  @action setFetching = (fetching: boolean) => {
    this.fetching = fetching;
  }

  @action setEmailError = (error?: string) => {
    this.emailError = error;
  }

  @action setPasswordError = (error?: string) => {
    this.passwordError = error;
  }

  @action setPinError = (error?: string) => {
    this.pinError = error;
  }

  @action setNewPinError = (error?: string) => {
    this.newPinError = error
  }

  @action setNewPasswordError = (error?: string) => {
    this.newPasswordError = error;
  }

  @action setCodeError = (error?: string) => {
    this.codeError = error;
  }

  checkEmail() {
    this.setEmailError(null);
    if (!this.email) return;
    if (!this.emailValid) {
      this.setEmailError('Email invalide');
    }
    else {
      this.setEmailError(null);
    }
  }

  checkNewPassword() {
    this.setNewPasswordError(null);
    if (!this.newPassword) return;
    if (!this.newPasswordValid) {
      this.setNewPasswordError('Le mot de passe doit contenir au moins 8 caractères');
    }
    else {
      this.setNewPasswordError(null);
    }
  }

  checkNewPin() {
    this.setNewPinError(null);
    if (!this.newPin) return;
    if (!this.newPinValid) {
      this.setNewPinError('Le Pin doit contenir au moins 4 caractères');
    }
    else {
      this.setNewPinError(null);
    }
  }

  checkErrors = (err) => {
    this.setFetching(false);
    const errors = errorMessageParser(err);
    errors.forEach(error => {
      switch (error.type) {
        case 'mail':
        case 'user_id':
        case 'token':
          this.setEmailError(error.details);
          break;
        case 'password':
          this.setPasswordError(error.details);
          break;
        case 'newPassword':
          this.setNewPasswordError(error.details);
          break;
        case 'pin':
          this.setPinError(error.details);
          break;
        case 'newPin':
          this.setNewPinError(error.details);
          break;
        case 'code':
          this.setCodeError(error.details);
          break;
      }
    });
  }

  submitForm() {
    this.setFetching(true);
    switch (this.formMode) {
      case 'LOGIN':
        this.api.login(this.email, this.password, this.keepMeLoggedIn).catch(this.checkErrors);
        break;
      case 'PIN':
        this.api.loginPin(this.email, this.pin, this.keepMeLoggedIn).catch(this.checkErrors);
        break;
      case 'SIGNUP':
        this.api.signup(this.email, this.newPassword, this.newPin, this.firstName, this.lastName).catch(this.checkErrors).then(res => {
          if (res) {
            this.setFetching(false);
            this.toastr.success('Un email de vérification vous a été envoyé.', '');
            this.setFormMode('VERIFY');
          }
        });
        break;
      case 'VERIFY':
        this.api.verify(this.email, this.code).catch(this.checkErrors);
        break;
      case 'FORGOT':
        this.api.askResetPassword(this.email).catch(this.checkErrors).then(res => {
          if (res) {
            this.setFetching(false);
            this.toastr.success('Un email de réinitialisation vous a été envoyé.', '');
            this.setFormMode('RESET');
          }
        });
        break;
      case 'UPDATEPASS':
        this.api.updatePassword(this.password, this.newPassword).catch(this.checkErrors).then(res => {
          if (res) {
            this.setFetching(false);
            this.toastr.success('Votre mot de passe a été mis à jour.', '');
          }
        });
        break;
      case 'UPDATEPIN':
        this.api.updatePin(this.password, this.newPin).catch(this.checkErrors).then(res => {
          if (res) {
            this.setFetching(false);
            this.toastr.success('Votre code a été mis à jour.', '');
          }
        });
        break;
      case 'RESET':
        this.api.resetPassword(this.email, this.code, this.newPassword, this.newPin).catch(this.checkErrors);
        break;
    }
  }

  onProfileSelected = async (profile: AvatarByUser) => {
    this.selectedProfile = profile;
    this.email = profile.mail;
    this.pin = '';
    const hasPin = await this.userService.checkUserPin(profile.mail);
    this.setFormMode(hasPin ? 'PIN' : 'LOGIN');
  }


  signup() {
    this.setFormMode('SIGNUP');
  }

  login() {
    this.setFormMode('LOGIN');
  }

  loginPin() {
    this.setFormMode('PIN')
  }

  resetPassword() {
    this.setFormMode('FORGOT');
  }

  back() {
    if (this.profiles.length > 0) {
      this.setFormMode('SELECT')
    }
    else {
      this.setFormMode('LOGIN')
    }
  }

  @action setPinValue(number) {
    this.pin += number;
    if (this.pin.length >= 4) {
      this.api.loginPin(this.email, this.pin, this.keepMeLoggedIn).catch(this.checkErrors);
    }
  }

  @action handleClear() {
    this.pin = '';
  }

  @action erase() {
    this.pin = this.pin.slice(0, this.pin.length - 1)
  }
}
