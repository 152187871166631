import { Deserializable } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { Resource } from './resource.model';

export class EntityArticlesByStock implements Deserializable {
  public stock_id: string;
  public instance_id: string;
  public business_ref: string;
  public network_id: string;
  public resource_id: string;
  public quantity: number;
  public value: number;
  public typeName = "EntityArticlesByStock";
  constructor() {}

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get searchTerm(): string {
    return ;
  }

  get label(): string {
    return ;
  }

  get whereUniqueValue(): any {
    return {
      stock_id: this.stock_id,
      instance_id: this.instance_id
    }
  }

  get updateValue(): any {
    return {
      quantity: this.quantity,
      value: this.value,
    };
  }
  get unitValue(): number {
    return this.value / this.quantity || 0;
  }
}


export class OrderArticlesByStock implements Deserializable {
  public stock_id: string;
  public article_id: string;
  public business_ref: string;
  public network_id: string;
  public resource_id: string;
  public quantity: number;
  public value: number;
  public typeName = "EntityArticlesByStock";
  constructor() {}

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get searchTerm(): string {
    return ;
  }

  get label(): string {
    return ;
  }

  get whereUniqueValue(): any {
    return {
      stock_id: this.stock_id,
      article_id: this.article_id
    }
  }

  get updateValue(): any {
    return {
      quantity: this.quantity,
      value: this.value,
    };
  }
  get unitValue(): number {
    return this.value / this.quantity || 0;
  }
}

export class ResourceQuantity implements Deserializable {
  stock_id: string;
  resource_id: string;
  quantity: number;
  ordered_quantity: number;
  value: number;
  public typeName = "ResourceQuantity";
  constructor(private citadelCoreService: CitadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get searchTerm(): string {
    return `${this.resource?.reference} ${this.resource?.designation}`.toLowerCase();
  }

  get label(): string {
    return this.resource?.designation;
  }

  get createValue(): any {
    const { typeName, citadelCoreService, ..._this } = this;
    return _this;
  }


  get updateValue(): any {
    const { resource_id, stock_id, typeName, citadelCoreService, ..._this } = this;
    return _this;
  }

  get reference(): string {
    return this.resource?.reference;
  }

  get designation(): string {
    return this.resource?.designation;
  }

  get description(): string {
    return this.resource?.description;
  }

  get resource(): Resource {
    return this.citadelCoreService.getResource(this.resource_id);
  }
}

export class ResourceByStock implements Deserializable {
  public stock_id: string;
  public resource_id: string;
  public restock: boolean;
  public threshold: number;
  public restock_value: number;
  public typeName = "ResourceByStock";
  constructor(private citadelCoreService: CitadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get searchTerm(): string {
    return `${this.resource?.reference} ${this.resource?.designation}`.toLowerCase();
  }

  get label(): string {
    return this.resource?.label;
  }

  get reference(): string {
    return this.resource?.reference;
  }

  get description(): string {
    return this.resource?.description;
  }

  get designation(): string {
    return this.resource?.designation;
  }

  get whereUniqueValue(): any {
    return {
      stock_id: this.stock_id,
      resource_id: this.resource_id
    }
  }

  get createValue(): any {
    const { typeName, citadelCoreService, ..._this } = this;
    return _this;
  }


  get updateValue(): any {
    const { resource_id, stock_id, typeName, citadelCoreService, ..._this } = this;
    return _this;
  }

  get resource(): Resource {
    return this.citadelCoreService.getResource(this.resource_id);
  }
}