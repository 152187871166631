import { StockInventoryService } from '../services/stock-inventory.service';
import { Deserializable } from '@sparte/utils';
import { StockBusiness } from './stockBusiness.model';

export class EntryArticle {
  resource_id: string;
  instance_id: string;
  entity_id: string;
  quantity: number;
  designation: string;
  original_value: number;
  original_quantity: number;

  constructor() { }

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get createValue() {
    return {
      instance_id: this.instance_id,
      entity_id: this.entity_id,
      quantity: this.quantity
    }
  }

  get value(): number {
    if (!this.original_quantity) return;
    return this.original_value * (this.quantity / this.original_quantity);
  }

  get unitValue(): number {
    if (!this.original_quantity) return;
    return this.original_value / this.original_quantity;
  }
}

export class AccountingEntry implements Deserializable {
  public id: string;
  public request_id: string;
  public entry_ref: string;
  public business_from: string;
  public business_to: string;
  public articles: EntryArticle[];
  public status: boolean;
  public created_at: Date;
  private updated_at: Date;
  public readonly typeName = 'AccountingEntry';

  constructor(private stockInventoryService: StockInventoryService) {
    this.articles = [];
  }

  get searchTerm(): string {
    return `${this.businessFrom?.reference} ${this.businessFrom?.designation} ${this.businessTo?.reference} ${this.businessTo?.designation}`.toLowerCase();
  }

  get businessFrom(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_from);
  }

  get businessTo(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_to);
  }

  get label(): string {
    return `${this.business_from} -> ${this.business_to}`;
  }

  deserialize(input: any): this {
    const { entry_id, articles, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = entry_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this.articles = articles.map(article => new EntryArticle().deserialize(article));
    return this;
  }

  get createValue() {
    return {
      business_from: this.business_from,
      business_to: this.business_to,
      articles: this.articles.map(article => article.createValue)
    }
  }

  get whereUniqueValue() {
    return {
      entry_id: this.id
    }
  }

  get updateValue() {
    return {
      status: this.status
    }
  }
}
