import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BinData, PrintRowData } from '../services/data-print.service';

@Component({
  selector: 'sparte-print-row-barcodes',
  templateUrl: './print-row-barcodes.component.html',
  styleUrls: ['./print-row-barcodes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintRowBarcodesComponent implements OnInit {
  @Input() rowsData: PrintRowData[];
  @Input() isLast: boolean;
  private colorsMap: Map<string, string>;
  private colors = ["#E30D68", "#45A735", "#482080", "#009CDC", "#EC411B", "#c1092b"];
  constructor() {
    this.colorsMap = new Map<string, string>();
  }

  ngOnInit(): void {
  }

  sortedBins(bins: BinData[]): BinData[] {
    return bins.sort((binA, binB) => binA.levelTag > binB.levelTag ? 1 : -1);
  }

  sortedLevels(levels: string[]): string[] {
    return levels.sort((a, b) => a > b ? -1 : 1);
  }

  getColor(tag: string, current: boolean): string {
    if (current) {
      if (this.colorsMap.has(tag)) {
        return this.colorsMap.get(tag);
      }
      else {
        let color;
        if (this.colors.length > 0) {
          color = this.colors.splice(0, 1);
        }
        else {
          color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        }
        this.colorsMap.set(tag, color)
      }
    }
    else return "#818386";
  }
}
