<section *mobxAutorun="{ dontDetach: true }"
         class="mat-typography main-section"
         [ngClass]="printFormat"
         fxLayout="row wrap">
  <section *ngFor="let binData of binBarcodes"
           class="row-card"
           [ngClass]="printFormat"
           fxLayout="row"
           fxLayoutAlign="start center"
           [ngStyle]="{ 'color': getColor()}">
    <div fxLayout="column"
         fxLayoutAlign="center center"
         class="names-container">
      <span class="storage-name">{{ binData.storageName }} </span>
      <span class="bin-name">{{ binData.binName }} </span>
    </div>
    <div class="barcode-container"
         fxLayout="column"
         fxLayoutAlign="end center">
      <div class="side-barcode">
        <ngx-barcode6 [bc-value]="binData.barcode"
                      [bc-width]="2"
                      [bc-height]="66"
                      [bc-text-margin]="0"
                      [bc-margin]="0"
                      [bc-margin-top]="0"
                      [bc-margin-bottom]="0"
                      [bc-margin-left]="0"
                      [bc-margin-right]="0"
                      [bc-display-value]="false" />
      </div>
      <span class="bin-tag">{{ binData.binTag }}</span>
    </div>
  </section>
</section>