import { EventEmitter, Injectable, Output } from '@angular/core';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UeSocketService {
  @observable public connected: boolean = false;
  public onSocketConnection: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private socket: Socket) {
    makeObservable(this);
    this.socket.on('connect', () => {
      this.setConnected(true);
    });
    this.socket.on('disconnect', () => {
      this.setConnected(false);
    });
  }

  @action setConnected(connected: boolean) {
    this.connected = connected;
    this.onSocketConnection.emit(connected);
    console.log('connected : ', connected);
  }

  connect() {
    this.socket.connect();
  }

  sendMessage(data: any) {
    if (this.connected) this.socket.emit('message', data);
  }

  getMessage() {
    return this.socket.fromEvent('message').pipe(map((data: any) => data.msg));
  }
}
