
import { gql } from 'apollo-angular';

export const CARTO_LINK_INFO_FRAGMENT = gql`
  fragment CartoLinkInfo on ProcessLink {
    link_id
    id_from
    id_to
  }
`;
