import { CitadelStock, CitadelStorage } from '@sparte/citadel-core';
import { Deserializable } from '@sparte/utils';
import * as moment from 'moment';
import { StockInventoryService } from '../services/stock-inventory.service';
import { ArchivedOrder } from './archivedOrder.model';
import { EntityArticle } from './entityArticle.model';
import { EntityHistory } from './entityHistory.model';
import { Shipment } from './shipment.model';
import { StockBusiness } from './stockBusiness.model';
import { StockNetwork } from './stockNetwork.model';
import { StockOrder } from './stockOrder.model';

moment.locale('fr');

export class StockStorageEntity implements Deserializable {
  public id: string;
  public order_id: string;
  public business_ref: string;
  public network_id: string;
  public shipment_ref: string;
  public storage_id: string;
  public zorder: number;
  public parent_entity_id: string;
  public trash: boolean;
  public locked: boolean;
  public received: boolean;
  private history: Map<string, EntityHistory>;
  private created_at: Date;
  private updated_at: Date;
  public barcode: string;
  public weight: number;
  public dimensions: string;
  public readonly typeName = "StockStorageEntity";
  private temp: boolean;
  private snapshot: string;
  public lastHistoryDate: Date;
  public shipment_ready: boolean;
  constructor(private stockInventoryService: StockInventoryService) {
    this.history = new Map<string, EntityHistory>();
  }

  deserialize(input: any, temp = false): this {
    const { __typename, deleted, entity_id, created_at, updated_at, articles, ..._input } = input;
    Object.assign(this, _input);
    this.id = entity_id;
    this.temp = temp;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.snapshotObject();
    return this;
  }

  setHistory(entityHistory: any) {
    this.history.clear();
    for (let i = 0; i < entityHistory.length; i++) {
      const operation = entityHistory[i];
      this.history.set(operation.operation_id, new EntityHistory(this.stockInventoryService.defaultStorageLabel).deserialize(operation));
      if (this.lastHistoryDate ? (this.history.get(operation.operation_id).historyDate > this.lastHistoryDate) : true) {
        this.lastHistoryDate = this.history.get(operation.operation_id).historyDate;
      }
    }
  }

  private snapshotObject() {
    this.snapshot = JSON.stringify(this.snapshotValue);
  }

  applySnapshot() {
    this.deserialize({ entity_id: this.id, ...JSON.parse(this.snapshot) });
  }

  get snapshotValue() {
    const {
      id,
      temp,
      typeName,
      history,
      stockInventoryService,
      snapshot,
      articles,
      ..._this
    } = this;
    const articlesUpdateValues = articles.map(article => article?.updateValue);
    return {
      articles: articlesUpdateValues,
      ..._this
    };
  }

  get whereUniqueValue() {
    return {
      entity_id: this.id
    }
  }

  get createValueFromReception() {
    return {
      order_id: this.order_id,
      business_ref: this.business_ref,
      network_id: this.network_id,
      shipment_ref: this.shipment_ref,
      zorder: this.zorder,
      trash: this.trash,
    };
  }

  get createValueWithBarcode() {
    return {
      business_ref: this.business_ref,
      network_id: this.network_id,
      storage_id: this.storage_id,
      shipment_ref: this.shipment_ref
    };
  }

  get updateValue() {
    return;
  }

  workerValue(labelsByTag, inactiveMonths) {
    return {
      id: this.id,
      storage_id: this.storage_id,
      stock_id: this.stock?.id, // Stock where the entity shoulb be located
      isDelivery: this.isDelivery,
      isPicked: this.isPicked,
      parent_entity_id: this.parent_entity_id,
      allChildren: this.allChildren.map(child => child.workerValue(labelsByTag, inactiveMonths)),
      allParentsSearchTerms: this.allParents.map(parent => parent.searchTerm),
      articlesLength: this.articles.length,
      inactive: this.inactive(inactiveMonths),
      searchTerm: this.fullSearchTerm + labelsByTag.get(this.storage?.fullTag)?.join(' ').toLowerCase()
    };
  }

  get articles(): EntityArticle[] {
    return this.stockInventoryService.getEntityArticles(this.id) ?? [];
  }

  get isTemp(): boolean {
    return this.temp;
  }

  get getHistory(): EntityHistory[] {
    return Array.from(this.history.values()).sort((opA, opB) => (opA.historyDate > opB.historyDate ? -1 : 1));
  }

  get lastActivity(): string {
    return moment(this.lastHistoryDate, "YYYYMMDD").fromNow();
  }

  get searchTerm(): string {
    return `${this.barcode} ${this.order?.reference} ${this.business_ref} ${this.businessDes} ${this.networkDes} ${this.storage?.searchTerm} ${this.shipment_ref}`.toLowerCase();
  }

  get fullSearchTerm(): string {
    return `${this.searchTerm} ${this.articles.map(art => art?.searchTerm).join(' ')}`.toLowerCase();
  }

  get label(): string {
    return this.barcode || this.packNumber;
  }

  get packNumber(): string {
    return (this.zorder || 1).toFixed(0);
  }

  get business(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_ref);
  }

  get network(): StockNetwork {
    return this.stockInventoryService.getStockNetwork(this.network_id);
  }

  get parentZoneIds(): string[] {
    let zoneIds = [];
    if (!this.storage) return [];
    this.storage.parentZoneIds ? zoneIds = [this.storage_id, ...this.storage.parentZoneIds] : zoneIds = [this.storage_id];
    return zoneIds
  }

  get shipment(): Shipment {
    return this.stockInventoryService.getShipment(this.shipment_ref);
  }

  get businessDes(): string {
    return this.business?.designation || this.shipment?.shipment_ref || "NA";
  }

  get networkDes(): string {
    return this.network?.designation || "NA";
  }

  get storage(): CitadelStorage {
    return this.stockInventoryService.getCitadelStorage(this.storage_id);
  }

  get stock(): CitadelStock {
    return this.stockInventoryService.getEntityStock(this.id);
  }

  get isDelivery(): boolean {
    return this.storage_id === 'default' || this.storage?.delivery;
  }

  get isPicked(): boolean {
    return this.storage_id?.startsWith('pickedby');
  }

  get stocks_ids(): string[] {
    return (this.storage_id === 'default' ? ['default'] : this.storage?.stocks_ids) || [];
  }

  get storageLabel(): string {
    if (!this.storage_id) return 'Emplacement nul';
    if (this.storage_id === 'default') return this.stockInventoryService.defaultStorageLabel;
    if (this.storage_id.startsWith('pickedby')) return this.stockInventoryService.getUserName(this.storage_id.split('_')[1]);
    return this.storage?.fullTag || 'N/A';
  }

  get order(): StockOrder | ArchivedOrder {
    return this.stockInventoryService.getStockOrder(this.order_id) || this.stockInventoryService.getArchivedOrder(this.order_id);
  }

  get totalValue(): number {
    return this.articles.reduce((sum, article) => {
      sum += (article?.value || 0);
      return sum;
    }, 0);
  }

  // The value right of the inequality operation indicates the differance in months between now and the last entity movement
  public inactive(timeFrame: number): boolean {
    return (Math.round(moment(new Date()).diff(moment(this.lastHistoryDate), 'months', true)) >= timeFrame);
  }

  get depth(): number {
    return this.parentEntityIds?.length || 0;
  }

  get shipmentDepth(): number {
    if (!this.shipment_ref || !this.parent_entity_id) return 0;
    const parentEntity = this.stockInventoryService.getStockStorageEntity(this.parent_entity_id);
    if (!parentEntity) return 0;
    if (parentEntity.shipment_ref === this.shipment_ref) return parentEntity.shipmentDepth + 1;
    else return 0;
  }

  get allParents(): StockStorageEntity[] {
    return this.parentEntityIds.map(parentId => this.stockInventoryService.getStockStorageEntity(parentId));
  }

  get parentEntityIds(): string[] {
    if (!this.parent_entity_id) return [];
    const parentEntity = this.stockInventoryService.getStockStorageEntity(this.parent_entity_id);
    if (!parentEntity) return [];
    if (parentEntity.parentEntityIds.includes(this.id)) throw new Error(`Circular reference detected in entity ${this.id}`);
    return [...parentEntity.parentEntityIds, this.parent_entity_id];
  }

  get directChildren(): StockStorageEntity[] {
    return this.stockInventoryService.getChildrenEntities(this.id);
  }

  get allChildren(): StockStorageEntity[] {
    return this.stockInventoryService.getAllChildrenEntities(this.id);
  }
}
