import { gql } from "apollo-angular";

export const IMPORT_CONFIG_INFO_FRAGMENT = gql `
  fragment ImportConfigInfo on ImportConfig {
    config_id
    dataset
    name
    user_id
    columns {
      key
      number
    }
    created_at
    updated_at
  }
`;