<div class="noscroll relative {{width}} h-full"
     [id]="_btn">
  <input type="text"
         id="simple-search"
         autocomplete="off"
         [disabled]="disabled"
         [(ngModel)]="searchTerm"
         (ngModelChange)="handleKeyDown()"
         class="noscroll bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-full pr-10 p-2.5"
         [ngClass]="[_noBorder ? 'border-0' : 'border border-gray-300', red ? 'border-sparteRed' : '']"
         [placeholder]="_placeholder"
         required>
  <div class="absolute inset-y-0 right-0 flex items-center justify-center pr-3 noscroll"
       *ngIf="!disabled">
    <svg *ngIf="selectedItem !== ''"
         (click)="handleClear()"
         class="w-4 h-2 cursor-pointer noscroll"
         aria-hidden="true"
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 14 14">
      <path stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <svg *ngIf="selectedItem === ''"
         class="w-4 h-4 ml-2 noscroll"
         aria-hidden="true"
         fill="none"
         stroke="currentColor"
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"></path>
    </svg>
  </div>
</div>
<!-- Dropdown menu -->
<div class="noscroll fixed z-40 min-w-[256px]"
     *ngIf="!disabled"
     [style]="parentWidth">
  <div [id]="_menu"
       class="relative z-10 hidden w-full border divide-y divide-gray-100 rounded-lg shadow noscroll bg-gray-50">
    <div *ngIf="searchTerm !== '' && _addNew"
         class="flex items-center p-3 text-sm font-medium border-t border-gray-200 rounded-b-lg cursor-pointer noscroll text-sparteBlue bg-gray-50 hover:bg-gray-100"
         (click)="handleAddNew()">
      <mat-icon> add </mat-icon>
      <div class="flex gap-2 select-none noscroll">
        <span class="text-lg font-semibold noscroll">{{addNewPlaceholder}}</span>
        <span class="self-center text-base font-bold uppercase noscroll">{{searchTerm}}</span>
      </div>
    </div>
    <ul class="relative py-3 space-y-3 text-sm text-gray-700 noscroll max-h-48">
      <cdk-virtual-scroll-viewport [itemSize]="20"
                                   class="w-full overflow-y-auto noscroll important-height">
        <span *ngIf="!filteredItems?.length"
              class="ml-2 noscroll">Pas de valeur à afficher</span>
        <li *cdkVirtualFor="let item of filteredItems; trackBy: trackByFn"
            class="flex items-center w-full h-8 pl-2 noscroll hover:bg-gray-100" (click)="handleSelect(item)">
          <input type="checkbox"
                 [id]="item.value"
                 *ngIf="_multiSelect"
                 class="w-4 h-4 transition duration-150 ease-in-out rounded cursor-pointer noscroll form-checkbox text-sparteBlue focus:ring-0"
                 [checked]="item.selected">
          <label [id]="item.value"
                 (click)="handleClick(item)"
                 class="w-full ml-2 text-sm font-medium text-justify text-gray-900 truncate cursor-pointer select-none noscroll">{{item.viewValue}}</label>
        </li>
      </cdk-virtual-scroll-viewport>
    </ul>
  </div>
</div>