import { ChangeDetectionStrategy, Component, ContentChild, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ApiService } from '@sparte/api';
import { CitadelCoreService } from '@sparte/citadel-core';
import { LinkInterface, ModuleInterface } from '@sparte/utils';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';
import { Subscription } from 'rxjs';
import { SparteModulesService } from '../services/sparte-modules.service';

@Component({
  selector: 'sparte-menu',
  templateUrl: './sparte-menu.component.html',
  styleUrls: ['./sparte-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SparteMenuComponent implements OnInit, OnDestroy {
  @ContentChild('routerOutlet', { static: false }) routerOutlet: TemplateRef<any>;
  @ContentChild('customLogin', { static: false }) customLogin: TemplateRef<any>;
  @ContentChild('changeLog', { static: false }) changeLog: TemplateRef<any>;
  @ContentChild('logo', { static: false }) logo: TemplateRef<any>;
  @ContentChild('logoText', { static: false }) logoText: TemplateRef<any>;
  @observable public isExpanded: boolean = false;
  @observable public showSubmenu: boolean = false;
  public sparteModules: ModuleInterface[] = [];
  @observable public currentModule: ModuleInterface;
  private moduleChange: Subscription;
  private authSub: Subscription;
  @observable public isAuthenticated = false;
  public fontSize: string = null;
  constructor(
    private api: ApiService,
    private sparteModulesService: SparteModulesService,
    private citadelCoreService: CitadelCoreService
  ) {
    makeObservable(this)
  }

  ngOnInit(): void {
    this.authSub = this.api.authChanged.subscribe(auth => {
      this.setIsAuthenticated(auth);
    });
    if (this.sparteModulesService.currentModule) {
      this.sparteModules = this.sparteModulesService.sparteModules;
      this.setCurrentModule(this.sparteModulesService.currentModule);
      this.getFontSize();
    }
    this.moduleChange = this.sparteModulesService.moduleChangeEvent.subscribe(newModule => {
      if (!newModule) {
        this.sparteModules = [];
      }
      else if (this.sparteModules.length === 0) {
        this.sparteModules = this.sparteModulesService.sparteModules;
      }
      this.setCurrentModule(newModule);
      this.getFontSize();
    });
  }

  @action private setIsAuthenticated(authenticated: boolean) {
    this.isAuthenticated = authenticated;
  }

  @action public toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  @action public setShowSubmenu(value: boolean) {
    this.showSubmenu = value;
  }

  @action private setExpanded(value: boolean) {
    this.isExpanded = value;
  }

  @action private setCurrentModule(module: ModuleInterface) {
    this.currentModule = module;
  }

  getFontSize = () => {
    const textLength = this.currentModule?.label?.length;
    this.fontSize = 'text-xl';
    if (textLength > 10) this.fontSize = 'text-lg'
    if (textLength > 15) this.fontSize = 'text-base'
    if (textLength > 20) this.fontSize = 'text-xs'
  }

  marginShow = (navLink: LinkInterface): boolean => {
    return navLink.children ? navLink.children.every(child => child.hide) : true;
  }

  ngOnDestroy(): void {
    this.moduleChange?.unsubscribe();
    this.authSub?.unsubscribe();
  }

  mouseenter() {
    this.setExpanded(true);
  }

  mouseleave() {
    this.setExpanded(false);
    this.setShowSubmenu(false);
  }

  refreshData() {
    this.citadelCoreService.refreshStoreData();
  }

  trackByIdx(index) {
    return index;
  }
}
