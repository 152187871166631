import { AssetZone, CitadelAsset, CitadelStorage } from "../models";

export enum GroupTypeEnum {
  Company = "Company",
  Service = "Service",
  Team = "Team"
}

export enum AssetZoneTypeEnum {
  Zone = "Zone",
  Stock = "Stock",
  Post = "Post",
  Line = "Line",
  Level = "Level",
  Aisle = "Aisle",
  Rack = "Rack"
}

export class NodeData {
  id: string;
  name: string;
  type: string;
  typeName: string;
  icon: string;
  code: string;
  sort_order: number;
  parent_asset_id: string;
  parent_zone_id: string;
  children: boolean;
  expanded: boolean = true;
  constructor(item: AssetZone | CitadelAsset | CitadelStorage) {
    this.id = item.id;
    this.name = item.label;
    this.icon = this.getIcon(item);
    this.children = !!item.directChildren?.length;
    this.type = item.type || item.typeName;
    this.typeName = item.typeName;
    this.parent_asset_id = item.parent_asset_id;
    this.parent_zone_id = item.parent_zone_id;
    item instanceof CitadelAsset ? this.sort_order = 0 : this.sort_order = item.sort_order;
  }

  getIcon(item: AssetZone | CitadelAsset | CitadelStorage): string {
    switch (item.type) {
      case "Zone":
        return "category";
      case "Stock":
        return "shelves";
      case "Post":
        return "pages";
      case "Line":
        return "linear_scale";
      case "Storage":
        return "crop_free";
      case "Aisle":
        return "horizontal_distribute"
      case "Rack":
        return "border_all";
      case "Level":
        return "vertical_distribute";
      case "CitadelInfrastructure":
        return "domain";
      case "CitadelItem":
        return "precision_manufacturing";
      case "CitadelStock":
        return "warehouse";
      case "Storage":
        return "pallet";
      default:
        return "crop_free";
    }
  }
}

export interface AvatarByUser {
  mail: string;
  avatar_url: string;
  full_name: string;
}