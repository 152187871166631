import { Resource } from '@sparte/citadel-core';
import { Deserializable } from '@sparte/utils';
import { StockInventoryService } from '../services/stock-inventory.service';
import { EntityArticle } from './entityArticle.model';
import { OrderArticle } from './orderArticle.model';
import { RequestStatus } from './stockRequest.model';

export class RequestArticle {
  instance_id: string;
  origin_business_ref: string;
  origin_network_id: string;
  post: string;
  entity_id: string;
  order_id: string;
  article_id: string;
  quantity: number;
  self_service: boolean;

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }
}

export interface ArticleSelection {
  business_ref: string;
  entityArticlesQuantity: number;
  orderedArticlesQuantity: number;
}

export class RequestedResource implements Deserializable {
  public id: string;
  public request_id: string;
  public preparation_order: string;
  public resource_id: string;
  public business_ref: string;
  public network_id: string;
  public post: string;
  public da_ref: string;
  public articles: RequestArticle[];
  public available_quantity: number = null;
  public _availableQuantity: number = 0;
  public available_order_quantity: number = null;
  public _availableOrderQuantity: number = 0;
  public locked_quantity: number = null;
  public _lockedQuantity: number = 0;
  public locked_order_quantity: number = null;
  public _lockedOrderedQuantity: number = 0;
  public availableArticles: EntityArticle[] = [];
  public availableOrderedArticles: OrderArticle[] = [];
  public lockedArticles: EntityArticle[] = [];
  public lockedOrderedArticles: OrderArticle[] = [];
  public requested_quantity: number;
  public received_quantity: number;
  public transfered_quantity: number;
  created_at: Date;
  updated_at: Date;
  public selectedAvailableArticles: ArticleSelection[] = [];
  public selectedLockedArticles: ArticleSelection[] = [];
  public readonly typeName = "RequestedResource";
  private snapshot: string;
  public _requested_quantity: number;

  constructor(private stockInventoryService: StockInventoryService) { }

  deserialize(input: any): this {
    const { __typename, requested_resource_id, articles, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = requested_resource_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this._requested_quantity = this.requested_quantity;
    this.articles = (articles || []).map(article => new RequestArticle().deserialize(article));
    this.snapshotObject();
    return this;
  }

  private snapshotObject() {
    this.snapshot = JSON.stringify(this.snapshotValue);
  }

  applySnapshot() {
    this.deserialize(JSON.parse(this.snapshot));
  }

  get label(): string {
    return this.resource?.label;
  }

  get createValue() {
    return {
      resource_id: this.resource_id,
      network_id: this.network_id,
      post: this.post,
      da_ref: this.da_ref,
      requested_quantity: this.requested_quantity,
      selectedLockedArticles: this.selectedLockedArticles,
      selectedAvailableArticles: this.selectedAvailableArticles
    }
  }

  get updateValue() {
    return {
      resource_id: this.resource_id,
      network_id: this.network_id,
      post: this.post,
      da_ref: this.da_ref,
      requested_quantity: this.requested_quantity,
      selectedLockedArticles: this.selectedLockedArticles,
      selectedAvailableArticles: this.selectedAvailableArticles
    }
  }

  get snapshotValue() {
    const {
      id,
      snapshot,
      typeName,
      lockedArticles,
      lockedOrderedArticles,
      availableArticles,
      availableOrderedArticles,
      stockInventoryService,
      ..._this
    } = this;
    return {
      requested_resource_id: id,
      lockedArticles: lockedArticles.map(article => article?.snapshotValue),
      lockedOrderedArticles: lockedOrderedArticles.map(article => article?.snapshotValue),
      ..._this
    };
  }

  get searchTerm(): string {
    return `${this.reference} ${this.designation} ${this.networkDes} ${this.business_ref} ${this.post} ${this.da_ref}`.toLowerCase();
  }

  get resource(): Resource {
    return this.stockInventoryService.getResource(this.resource_id);
  }

  get reference(): string {
    return this.resource?.reference;
  }

  get designation(): string {
    return this.resource?.designation;
  }

  get description(): string {
    return this.resource?.description;
  }

  get networkDes(): string {
    return this.stockInventoryService.getStockNetwork(this.network_id)?.designation;
  }

  get isDraft(): boolean {
    return this.stockInventoryService.getStockRequest(this.request_id)?.request_status !== RequestStatus.Done;
  }

  get purchaseQuantity(): number {
    if (this.isDraft) {
      return this.requested_quantity - Math.min(this.selectedAvailableEntityArticlesQuantity + this.selectedAvailableOrderedArticlesQuantity, this.requested_quantity);
    };
    return this.requested_quantity - this.available_quantity - this.available_order_quantity - this.locked_quantity - this.locked_order_quantity;
  }

  get getAvailableQuantity(): number {
    if (this.isDraft) return Math.min(this.requested_quantity, this._availableQuantity);
    return this.available_quantity;
  }

  get getAvailableOrderedQuantity(): number {
    if (this.isDraft) return this._availableOrderQuantity;
    return this.available_order_quantity;
  }

  get getLockedQuantity(): number {
    if (this.isDraft) return this._lockedQuantity;
    return this.locked_quantity;
  }

  get getLockedOrderQuantity(): number {
    if (this.locked_order_quantity === null) return this._lockedOrderedQuantity;
    return this.locked_order_quantity;
  }

  get remainingPurchaseQuantity(): number {
    if (this.isDraft) {
      const remaining = this.requested_quantity - this.selectedAvailableEntityArticlesQuantity - this.selectedAvailableOrderedArticlesQuantity - this.selectedLockedEntityArticlesQuantity - this.selectedLockedOrderArticlesQuantity
      return Math.max(remaining, 0);
    }
    return this.purchaseQuantity;
  }

  get selectedAvailableEntityArticlesQuantity(): number {
    return this.selectedAvailableArticles.reduce((acc, curr) => acc + curr.entityArticlesQuantity, 0);
  }

  get selectedAvailableOrderedArticlesQuantity(): number {
    return this.selectedAvailableArticles.reduce((acc, curr) => acc + curr.orderedArticlesQuantity, 0);
  }

  get selectedLockedEntityArticlesQuantity(): number {
    return this.selectedLockedArticles.reduce((acc, curr) => acc + curr.entityArticlesQuantity, 0);
  }

  get selectedLockedOrderArticlesQuantity(): number {
    return this.selectedLockedArticles.reduce((acc, curr) => acc + curr.orderedArticlesQuantity, 0);
  }

  get canCancel(): boolean {
    if (this.isDraft) return false;
    if (!this.articles?.length && (this.available_quantity > 0 || this.available_order_quantity > 0 || this.locked_quantity > 0 || this.locked_order_quantity > 0)) return false;
    return this.articles.every(article => {
      if (article.self_service) return true;
      if (!article.instance_id && !article.article_id) return true;
      if (article.instance_id) return !!this.stockInventoryService.getEntityArticle(article.instance_id) && this.stockInventoryService.getEntityArticle(article.instance_id).quantity === article.quantity;
      if (article.article_id) return !!this.stockInventoryService.getOrderArticle(article.article_id) && this.stockInventoryService.getOrderArticle(article.article_id).quantity === article.quantity;
    });
  }
}