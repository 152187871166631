import { gql } from 'apollo-angular';

import { PERMISSION_INFO_FRAGMENT } from './fragments/_permission';

export const ALL_PERMISSIONS_QUERY = gql`
  query allPermissions {
    allPermissions {
      ...PermissionInfo
    }
  }
  ${PERMISSION_INFO_FRAGMENT}
`;

export const CHILD_PERMISSIONS_QUERY = gql`
  query childPermissions {
    childPermissions
  }
`;

export const CREATE_PERMISSION_MUTATION = gql`
  mutation createPermission($data: PermissionCreateInput!) {
    createPermission(data: $data) {
      ...PermissionInfo
    }
  }
  ${PERMISSION_INFO_FRAGMENT}
`;
export const UPDATE_PERMISSION_MUTATION = gql`
  mutation updatePermission(
    $where: PermissionWhereUniqueInput!
    $data: PermissionUpdateInput!
  ) {
    updatePermission(where: $where, data: $data) {
      ...PermissionInfo
    }
  }
  ${PERMISSION_INFO_FRAGMENT}
`;

export const DELETE_PERMISSION_MUTATION = gql`
  mutation deletePermission($where: PermissionWhereUniqueInput!) {
    deletePermission(where: $where) {
      id
    }
  }
`;
