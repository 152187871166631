import { Deserializable } from '@sparte/utils';
import { OrderLog } from './orderLog.model';

export class StockOrder implements Deserializable {
  public id: string;
  public reference: string;
  private orderLogs: Map<string, OrderLog>;
  private created_at: Date;
  private updated_at: Date;
  public searchTerm: string;
  public readonly typeName = 'StockOrder';

  constructor() {
    this.orderLogs = new Map<string, OrderLog>();
  }

  deserialize(input: any): this {
    const {
      __typename,
      created_at,
      updated_at,
      order_id,
      ..._input
    } = input;
    Object.assign(this, _input);
    this.id = order_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    return this;
  }

  get updateValue() {
    return;
  }

  get label(): string {
    return this.reference;
  }

  get getOrderLogs(): OrderLog[] {
    return [...this.orderLogs.values()];
  }

  setOrderLog(orderLog: OrderLog): void {
    this.orderLogs.set(orderLog.id, orderLog);
  }
}
