import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGardActivate } from '@sparte/api';
import { AuthComponent } from '@sparte/ui';

export const defaultRoute = '/login';

export const appRoutes: Routes = [
  { path: '', redirectTo: defaultRoute, pathMatch: 'full' },
  { path: 'login', canActivate: [AuthGardActivate], component: AuthComponent, data: { formMode: 'LOGIN' } },
  { path: 'updatepassword', canActivate: [AuthGardActivate], component: AuthComponent, data: { formMode: 'UPDATEPASS' } },
  { path: 'updatepin', canActivate: [AuthGardActivate], component: AuthComponent, data: { formMode: 'UPDATEPIN' } },
  { path: 'logistics', canActivateChild: [AuthGardActivate], loadChildren: () => import('./modules/logistics/logistics.module').then(m => m.LogisticsModule) },
  { path: 'business', canActivateChild: [AuthGardActivate], loadChildren: () => import('./modules/business-manager/business-manager.module').then(m => m.BusinessManagerModule) },
  { path: 'accounting', canActivateChild: [AuthGardActivate], loadChildren: () => import('./modules/accounting-manager/accounting-manager.module').then(m => m.AccountingManagerModule) },
  { path: 'purchase', canActivateChild: [AuthGardActivate], loadChildren: () => import('./modules/purchase-manager/purchase-manager.module').then(m => m.PurchaseManagerModule) },
  { path: 'digital-dna', canActivateChild: [AuthGardActivate], loadChildren: () => import('@sparte/digital-dna').then(m => m.DigitalDnaModule) },
  { path: '**', redirectTo: defaultRoute }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
