import { Type } from '@angular/core';

export interface ComponentContainerData {
  updateModel: any;
  formTitle: string;
  createModel: boolean;
  canDelete: boolean;
  saveCondition: Function;
  cancelFunction?: Function;
  closeFunction?: Function;
}

export class ComponentContainer {
  constructor(public component: Type<any>, public data: ComponentContainerData) { }
}
