import { CurveFactory } from 'd3';

/**
 * interface for deserialization of stock models
 */
export interface Deserializable {
  searchTerm: string;
  label: string;
  typeName: string;
  createValue?: any;
  whereUniqueValue?: any;
  updateValue: any;
  deserialize(input: any): this;
}

export interface DagreGraphConfig {
  rankdir: 'TB' | 'BT' | 'LR' | 'RL',
  align?: undefined | 'UL' | 'UR' | 'DL' | 'DR',
  nodesep: number,
  edgesep: number,
  ranksep: number,
  acyclicerGreedy: boolean,
  ranker: 'network-simplex' | 'tight-tree' | 'longest-path',
  curveType: CurveFactory
}

export interface ModuleInterface {
  name: string;
  label: string;
  link: string;
  svg?: string;
  icon?: string;
  navLinks?: LinkInterface[];
  fallbackRoute?: string;
}

export interface LinkInterface {
  id: string;
  permission_id?: string;
  label: string;
  link: string;
  name?: string;
  svg?: string;
  icon?: string;
  class?: string;
  count?: number;
  children?: LinkInterface[];
  outlined?: boolean;
  hide?: boolean;
}

export enum VariableTypeEnum {
  String = "Text",
  Number = "Number",
  Boolean = "Yes/No",
  Select = "Select",
  DateTime = "Timestamp",
  DateRange = "Date Range",
  File = "File"
}

export enum SystemModelEnum {
  System = "System",
  SubSystem = "SubSystem",
  Branch = "Branch"
}

export interface SubscriptionEvent {
  objectType: string;
  objectId: string;
  mutationType: 'CREATED' | 'UPDATED' | 'DELETED';
}

export interface UnitInterface {
  id: string,
  label: string,
  symbol: string,
  ratio: number
}

export interface MeasureInterface {
  id: string,
  label: string,
  categories: string[],
  units: UnitInterface[]
}

export enum UnitMeasurementEnum {
  Quantity = "Quantity",
  Length = "Length",
  Area = "Area",
  Mass = "Mass",
  Volume = "Volume"
}
export const UnitMeasurementData: MeasureInterface[] = [
  {
    id: 'acceleration',
    label: "Acceleration",
    categories: ["Structural"],
    units: [
      { id: "mpsq", label: "Meters per second squared", symbol: "m/s²", ratio: 1 },
      { id: "kmpsq", label: "Kilometers per second squared", symbol: "km/s²", ratio: 0.001 }
    ]
  },
  {
    id: 'airflow',
    label: "Air Flow",
    categories: ["HVAC"],
    units: [
      { id: "cmps", label: "Cubic meters per second", symbol: "m³/s", ratio: 1 },
      { id: "cmph", label: "Cubic meters per hour", symbol: "m³/h", ratio: 1 },
      { id: "lph", label: "Liters per hour", symbol: "L/h", ratio: 1 },
      { id: "lpm", label: "Liters per minute", symbol: "L/min", ratio: 1 },
      { id: "lps", label: "Liters per second", symbol: "L/s", ratio: 1 }
    ]
  },
  {
    id: 'airflowdensity',
    label: "Air Flow Density",
    categories: ["HVAC"],
    units: [
      { id: "cmphsm", label: "Cubic meters per hour square meter", symbol: "m³/(h·m²)", ratio: 1 },
      { id: "lpsm", label: "Liters per second square meter", symbol: "L/(s·m²)", ratio: 1 }
    ]
  },
  {
    id: 'angle',
    label: "Angle",
    categories: ["General"],
    units: [
      { id: "deg", label: "Degrees", symbol: "°", ratio: 1 },
      { id: "rad", label: "Radians", symbol: "rad", ratio: 0.017453292519444445 },
      { id: "grad", label: "Gradians", symbol: "grad", ratio: 1.1111111111 }
    ]
  },
  {
    id: 'area',
    label: "Area",
    categories: ["General"],
    units: [
      { id: "sqm", label: "Square meters", symbol: "m²", ratio: 1 },
      { id: "sqcm", label: "Square centimeters", symbol: "cm²", ratio: 1 },
      { id: "sqmm", label: "Square milimeters", symbol: "mm²", ratio: 1 },
      { id: "hec", label: "Hectares", symbol: "hectare", ratio: 1 }
    ]
  },
  {
    id: 'current',
    label: "Current",
    categories: ["Electrical"],
    units: [
      { id: "amp", label: "Amperes", symbol: "A", ratio: 1 },
      { id: "kamp", label: "Kiloamperes", symbol: "kA", ratio: 0.001 },
      { id: "mamp", label: "Miliamperes", symbol: "mA", ratio: 1000 }
    ]
  },
  {
    id: 'density',
    label: "Density",
    categories: ["HVAC", "Piping"],
    units: [
      { id: "kgpcm", label: "Kilograms per cubic meter", symbol: "kg/m³", ratio: 1 }
    ]
  },
  {
    id: 'elecpotential',
    label: "Electrical Potential",
    categories: ["Electrical"],
    units: [
      { id: "vo", label: "Volts", symbol: "V", ratio: 1 },
      { id: "kvo", label: "Kilovolts", symbol: "kV", ratio: 0.001 },
      { id: "mvo", label: "Milivolts", symbol: "mV", ratio: 1000 }
    ]
  },
  {
    id: 'energy',
    label: "Energy",
    categories: ["Energy"],
    units: [
      { id: "jou", label: "Joules", symbol: "J", ratio: 1 },
      { id: "cal", label: "Calories", symbol: "cal", ratio: 1 },
      { id: "kcal", label: "Kilocalories", symbol: "kcal", ratio: 1 },
      { id: "kwh", label: "Kilowatt hours", symbol: "kWh", ratio: 1 }
    ]
  },
  {
    id: 'force',
    label: "Force",
    categories: ["Structural"],
    units: [
      { id: "new", label: "Newtons", symbol: "N", ratio: 1 },
      { id: "knew", label: "Kilonewtons", symbol: "kN", ratio: 1 },
      { id: "kgf", label: "Kilograms force", symbol: "kgf", ratio: 1 }
    ]
  },
  {
    id: 'frequency',
    label: "Frequency",
    categories: ["Structural", "Electrical"],
    units: [
      { id: "hz", label: "Hertz", symbol: "Hz", ratio: 1 },
      { id: "cyps", label: "Cycles per second", symbol: "cps", ratio: 1 }
    ]
  },
  {
    id: 'length',
    label: "Length",
    categories: ["General"],
    units: [
      { id: "met", label: "Meters", symbol: "m", ratio: 1 },
      { id: "cmet", label: "Centimeters", symbol: "cm", ratio: 100 },
      { id: "mmet", label: "Milimeters", symbol: "mm", ratio: 1000 },
      { id: "kmet", label: "Kilometers", symbol: "km", ratio: 0.001 }
    ]
  },
  {
    id: 'mass',
    label: "Mass",
    categories: ["Structural"],
    units: [
      { id: "kgs", label: "Kilograms", symbol: "kg", ratio: 1 },
      { id: "tons", label: "Tonnes", symbol: "t", ratio: 0.001 }
    ]
  },
  {
    id: 'number',
    label: "Number",
    categories: ["General"],
    units: [
      { id: "gen", label: "General", symbol: "", ratio: 1 },
      { id: "perc", label: "Percentage", symbol: "%", ratio: 0.01 }
    ]
  },
  {
    id: 'period',
    label: "Period",
    categories: ["Structural"],
    units: [
      { id: "secs", label: "Seconds", symbol: "s", ratio: 1 },
      { id: "msecs", label: "Miliseconds", symbol: "ms", ratio: 1 },
      { id: "mins", label: "Minutes", symbol: "min", ratio: 1 },
      { id: "hrs", label: "Hours", symbol: "s", ratio: 1 }
    ]
  },
  {
    id: 'power',
    label: "Power",
    categories: ["Electrical", "HVAC"],
    units: [
      { id: "wat", label: "Watts", symbol: "W", ratio: 1 },
      { id: "kwat", label: "Kilowatts", symbol: "kW", ratio: 1 },
      { id: "hp", label: "Horsepower", symbol: "hp", ratio: 1 },
      { id: "calps", label: "Calories per second", symbol: "cal/s", ratio: 1 }
    ]
  },
  {
    id: 'pressure',
    label: "Pressure",
    categories: ["Structural", "HVAC", "Piping"],
    units: [
      { id: "pas", label: "Pascals", symbol: "Pa", ratio: 1 },
      { id: "kpas", label: "Kilopascals", symbol: "kPa", ratio: 0.001 },
      { id: "npsm", label: "Newtons per square meter", symbol: "N/m²", ratio: 1 },
      { id: "knpsm", label: "Kilonewtons per square meter", symbol: "kN/m²", ratio: 1 }
    ]
  },
  {
    id: 'speed',
    label: "Speed",
    categories: ["General"],
    units: [
      { id: "mps", label: "Meters per second", symbol: "m/s", ratio: 1 },
      { id: "kmps", label: "Kilometers per hour", symbol: "km/h", ratio: 0.001 }
    ]
  },
  {
    id: 'temperature',
    label: "Temperature",
    categories: ["Electrical", "HVAC", "Piping"],
    units: [
      { id: "cel", label: "Celsius", symbol: "°C", ratio: 1 },
      { id: "kel", label: "Kelvin", symbol: "K", ratio: 1 },
      { id: "fah", label: "Fahrenheit", symbol: "°F", ratio: 1 }
    ]
  },
  {
    id: 'volume',
    label: "Volume",
    categories: ["General", "Piping"],
    units: [
      { id: "cubm", label: "Cubic meters", symbol: "m³", ratio: 1 },
      { id: "litres", label: "Litres", symbol: "L", ratio: 1 }
    ]
  }
]

//DATE LOCALES

export const fr = {

    days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
    months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Effacer",
    weekStart: 1,
    format: "dd/mm/yyyy"

};

//TAILWIND CSS
export type twSpacingUnits = "0"
  | "px"
  | "0.5"
  | "1"
  | "1.5"
  | "2"
  | "2.5"
  | "3"
  | "3.5"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "14"
  | "16"
  | "20"
  | "24"
  | "28"
  | "32"
  | "36"
  | "40"
  | "44"
  | "48"
  | "52"
  | "56"
  | "60"
  | "64"
  | "72"
  | "80"
  | "96"

export type twWidthUnits = "w-0"
  | "w-px"
  | "w-0.5"
  | "w-1"
  | "w-1.5"
  | "w-2"
  | "w-2.5"
  | "w-3"
  | "w-3.5"
  | "w-4"
  | "w-5"
  | "w-6"
  | "w-7"
  | "w-8"
  | "w-9"
  | "w-10"
  | "w-11"
  | "w-12"
  | "w-14"
  | "w-16"
  | "w-20"
  | "w-24"
  | "w-28"
  | "w-32"
  | "w-36"
  | "w-40"
  | "w-44"
  | "w-48"
  | "w-52"
  | "w-56"
  | "w-60"
  | "w-64"
  | "w-72"
  | "w-80"
  | "w-96"
  | "w-auto"
  | "w-1/2"
  | "w-1/3"
  | "w-2/3"
  | "w-1/4"
  | "w-2/4"
  | "w-3/4"
  | "w-1/5"
  | "w-2/5"
  | "w-3/5"
  | "w-4/5"
  | "w-1/6"
  | "w-2/6"
  | "w-3/6"
  | "w-4/6"
  | "w-5/6"
  | "w-1/12"
  | "w-2/12"
  | "w-3/12"
  | "w-4/12"
  | "w-5/12"
  | "w-6/12"
  | "w-7/12"
  | "w-8/12"
  | "w-9/12"
  | "w-10/12"
  | "w-11/12"
  | "w-full"
  | "w-screen"
  | "w-min"
  | "w-max"
  | "w-fit"

export type twHeightUnits = "h-0"
  | "h-px"
  | "h-0.5"
  | "h-1"
  | "h-1.5"
  | "h-2"
  | "h-2.5"
  | "h-3"
  | "h-3.5"
  | "h-4"
  | "h-5"
  | "h-6"
  | "h-7"
  | "h-8"
  | "h-9"
  | "h-10"
  | "h-11"
  | "h-12"
  | "h-14"
  | "h-16"
  | "h-20"
  | "h-24"
  | "h-28"
  | "h-32"
  | "h-36"
  | "h-40"
  | "h-44"
  | "h-48"
  | "h-52"
  | "h-56"
  | "h-60"
  | "h-64"
  | "h-72"
  | "h-80"
  | "h-96"
  | "h-auto"
  | "h-1/2"
  | "h-1/3"
  | "h-2/3"
  | "h-1/4"
  | "h-2/4"
  | "h-3/4"
  | "h-1/5"
  | "h-2/5"
  | "h-3/5"
  | "h-4/5"
  | "h-1/6"
  | "h-2/6"
  | "h-3/6"
  | "h-4/6"
  | "h-5/6"
  | "h-full"
  | "h-screen"
  | "h-min"
  | "h-max"
  | "h-fit"