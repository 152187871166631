import { UserService } from '@sparte/citadel-core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, FileService, FileSpecs, SparteFile } from '@sparte/api';
import { makeObservable, when } from 'mobx';
import { action, observable } from 'mobx-angular';
import { Subscription } from 'rxjs';
import { Dropdown, DropdownOptions, initFlowbite } from 'flowbite';

@Component({
  selector: 'sparte-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @observable public initialized: boolean;
  public dropdown: Dropdown;
  public menuDropdown: Dropdown;
  public mouseOver: boolean = false;
  private refreshSub: Subscription;
  public fileSpecs: FileSpecs = {
    makeUrl: true,
    makeThumbnail: false,
    sizeX: 160,
    sizeY: 160
  }
  constructor(
    private api: ApiService,
    private userService: UserService,
    private fileService: FileService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone
  ) {
    makeObservable(this);
  }

  ngOnInit(): void {
    when(() => this.userService.storeInitialized, () => {
      this.setInitialized();
      this.refreshSub = this.userService.subscriptionRefresh.subscribe(({ objectType }) => {
        if (objectType === "CitadelUser" || objectType === "SparteFile") {
          this.cdRef.detectChanges();
        }
      });
    });
  }

  ngAfterViewInit(): void {

    when(() => this.initialized).then(() => {

      initFlowbite();
      const ddButton = document.getElementById('dropdownButton');
      const ddMenu = document.getElementById('dropdownMenu');
      const accButton = document.getElementById('accountButton');
      const accMenu = document.getElementById('accountMenu');

      const options: DropdownOptions = {
        triggerType: 'hover',
        placement: 'right',
        onHide: () => {
          if (!this.mouseOver) this.dropdown?.hide();
        }
      }

      this.dropdown = new Dropdown(ddMenu, ddButton);
      this.menuDropdown = new Dropdown(accMenu, accButton, options);
    });
  }

  ngOnDestroy(): void {
    this.refreshSub?.unsubscribe();
  }

  get avatarUrl(): string {
    return this.userService.currentUser?.avatarUrl;
  }

  get canUpdateAvatar(): boolean {
    return !!this.userService.currentUser;
  }

  @action setInitialized = () => {
    this.initialized = true;
    this.cdRef.detectChanges();
  }

  disconnectUser() {
    this.api.logout();
    this.cdRef.detectChanges();
  }

  updatePassword = () => {
    this.zone.run(() => this.router.navigateByUrl('/updatepassword'));
  }

  updatePin = () => {
    this.zone.run(() => this.router.navigateByUrl('/updatepin'));
  }

  onUpload = async (files: FileList) => {
    if (files.length === 1) {
      const file = files[0];
      const _URL = window.URL;
      const img = new Image();
      img.onload = async () => {
        await this.fileService.createSparteFile(file, this.fileSpecs, async (fileRes: SparteFile) => {
          if (this.userService.currentUser) {
            this.userService.currentUser.avatar_id = fileRes.id;
            await this.userService.updateSelfAvatar(fileRes.id);
            this.cdRef.detectChanges();
          }
        });
      };
      img.src = await _URL.createObjectURL(file);
    }
  }
}
