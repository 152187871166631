import { gql } from "apollo-angular";

export const RANGE_OPERATIONS_QUERY = gql`
  query rangeOperations {
    rangeOperations
  }
`;

export const RANGE_OPERATIONS_FOR_FABRICATION_ORDERS_QUERY = gql`
  query rangeOperationsForFabricationOrders($where: FabricationOrderWhereInput!) {
    rangeOperationsForFabricationOrders(where: $where)
  }
`;

export const RANGE_OPERATION_SUBSCRIPTION = gql`
  subscription rangeOperationSubscription {
    rangeOperationSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const RANGE_OPERATION_FOR_FABRICATION_ORDER_SUBSCRIPTION = gql`
  subscription rangeOperationForFabricationOrderSubscription($where: FabricationOrderWhereInput!) {
    rangeOperationForFabricationOrderSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;