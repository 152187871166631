import { ModuleInterface } from '@sparte/utils';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { UiModule } from '@sparte/ui';
import { ApolloModule } from 'apollo-angular';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FileUploaderComponent } from './files/file-uploader/file-uploader.component';
import { FileService } from './files/services/file.service';
import { ApiService } from './services/api.service';
import { UeSocketService } from './services/ue-socket.service';
import { MobxAngularModule } from 'mobx-angular';

const config: SocketIoConfig = {
  url: 'http://127.0.0.1:3000', options: {
    reconnection: true,
    autoConnect: false
  }
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MobxAngularModule,
    UiModule,
    ApolloModule,
    SocketIoModule.forRoot(config),
    JwtModule.forRoot({
      config: {}
    })
  ],
  declarations: [
    FileUploaderComponent
  ],
  exports: [
    FileUploaderComponent
  ],
  providers: [
    ApiService,
    UeSocketService,
    FileService
  ],
})
export class ApiModule {
  public static forRoot(args?: any): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: 'local', useValue: environment.local },
        { provide: 'apiUrl', useValue: environment.apiUrl },
        { provide: 'connectSocket', useValue: args?.connectSocket },
        { provide: 'modulesMap', useValue: args?.modulesMap }
      ],
    };
  }
  constructor(
    @Inject('apiUrl') apiUrl: string,
    @Inject('local') local: boolean,
    @Inject('connectSocket') connectSocket: boolean,
    @Inject('modulesMap') modulesMap: Map<string, ModuleInterface>,
    private api: ApiService,
    private socketService: UeSocketService
  ) {
    this.api.apiUrl = apiUrl;
    this.api.local = local;
    this.api.modulesMap = modulesMap;
    if (connectSocket) {
      this.socketService.connect();
    }
    this.api.init();
  }
}
