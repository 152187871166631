import { Deserializable } from '@sparte/utils';

export class ImportConfigColumn {
  key: string;
  number: number;

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }
}

export class ImportConfig implements Deserializable {
  public id: string;
  public dataset: string;
  public name: string;
  public user_id: string;
  public columns: ImportConfigColumn[];
  public created_at: Date;
  public updated_at: Date;
  public typeName = "ImportConfig";
  public create: boolean;
  public update: boolean;

  deserialize(input: any, create=false): this {
    const { __typename, config_id, columns, created_at, updated_at, ..._intput } = input;
    Object.assign(this, _intput);
    this.id = config_id;
    this.create = create;
    this.update = false;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this.columns = (columns || []).map(column => new ImportConfigColumn().deserialize(column));
    return this;
  }

  get whereUniqueValue() {
    return {
      config_id: this.id,
      dataset: this.dataset
    };
  }

  get createValue() {
    const { id, create, update, typeName, created_at, updated_at, ..._this } = this;
    return {
      ..._this
    };
  }

  get updateValue() {
    const { id, create, update, user_id, typeName, dataset, created_at, updated_at, ..._this } = this;
    return {
      ..._this
    };
  }

  get searchTerm() {
    return `${this.name}`.toLowerCase();
  }

  get label() {
    return this.name;
  }
}