import { gql } from 'apollo-angular';

export const CITADEL_STATE_QUERY = gql`
  query getCitadelState {
    getCitadelState
  }
`;

export const CASSANDRA_STATE_QUERY = gql`
  query getCassandraState {
    getCassandraState
  }
`;