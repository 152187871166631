import { gql } from 'apollo-angular';

import { STOCK_NETWORK_INFO_FRAGMENT } from './fragments/_stockNetwork';

export const STOCK_NETWORKS_QUERY = gql`
  query stockNetworks {
    stockNetworks
  }
`;

export const STOCK_NETWORK_SUBSCRIPTION = gql`
  subscription stockNetworkSubscription {
    stockNetworkSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_STOCK_NETWORK_MUTATION = gql`
  mutation createStockNetwork($data: StockNetworkCreateInput!) {
    createStockNetwork(data: $data) {
      ...StockNetworkInfo
    }
  }
  ${STOCK_NETWORK_INFO_FRAGMENT}
`;

export const UPDATE_STOCK_NETWORK_MUTATION = gql`
  mutation updateStockNetwork(
    $where: StockNetworkWhereUniqueInput!
    $data: StockNetworkUpdateInput!
  ) {
    updateStockNetwork(where: $where, data: $data) {
      ...StockNetworkInfo
    }
  }
  ${STOCK_NETWORK_INFO_FRAGMENT}
`;
export const DELETE_STOCK_NETWORK_MUTATION = gql`
  mutation deleteStockNetwork(
    $where: StockNetworkWhereUniqueInput!
  ) {
    deleteStockNetwork(where: $where) {
      network_id
    }
  }
`;