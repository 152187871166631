import { gql } from 'apollo-angular';

import { ACCOUNTING_ENTRY_INFO_FRAGMENT } from './fragments/_accountingEntry';


export const ACCOUNTING_ENTRIES_QUERY = gql`
  query accountingEntries {
    accountingEntries
  }
`;

export const UPDATE_ACCOUNTING_ENTRY_MUTATION = gql`
  mutation updateAccountingEntry(
    $where: AccountingEntryWhereUniqueInput!
    $data: AccountingEntryUpdateInput!
  ) {
    updateAccountingEntry(where: $where, data: $data) {
      ...AccountingEntryInfo
    }
  }
  ${ACCOUNTING_ENTRY_INFO_FRAGMENT}
`;

export const ACCOUNTING_ENTRY_SUBSCRIPTION = gql`
  subscription accountingEntrySubscription {
    accountingEntrySubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;