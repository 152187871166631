import { gql } from 'apollo-angular';

export const SHARE_FOLDER_INFO_FRAGMENT = gql`
  fragment ShareFolderInfo on ShareFolder {
    folder_id
    project_id
    name
    thumbnail_url
    completed
    payed
    versions
    created_at
    updated_at
  }
`;
