import { gql } from 'apollo-angular';

import { STORAGE_INFO_FRAGMENT } from './fragments/_storage';

export const STORAGES_QUERY = gql`
  query storages {
    storages
  }
`;

export const CREATE_STORAGE_MUTATION = gql`
  mutation createStorage($data: StorageCreateInput!) {
    createStorage(data: $data) {
      ...StorageInfo
    }
  }
  ${STORAGE_INFO_FRAGMENT}
`;

export const UPDATE_STORAGE_MUTATION = gql`
  mutation updateStorage(
    $where: StorageWhereUniqueInput!
    $data: StorageUpdateInput!
  ) {
    updateStorage(where: $where, data: $data) {
      ...StorageInfo
    }
  }
  ${STORAGE_INFO_FRAGMENT}
`;

export const UPDATE_STORAGE_STOCK_IDS_MUTATION = gql`
  mutation updateStorageStockIds($data: [StorageUpdateStockInput!]!) {
    updateStorageStockIds(data: $data) {
      ...StorageInfo
    }
  }
  ${STORAGE_INFO_FRAGMENT}
`;

export const DELETE_STORAGE_MUTATION = gql`
  mutation deleteStorage($where: StorageWhereUniqueInput!) {
    deleteStorage(where: $where) {
      storage_id
    }
  }
`;

export const STORAGE_SUBSCRIPTION = gql`
  subscription storageSubscription {
    storageSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
