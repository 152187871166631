import { gql } from 'apollo-angular';

export const PERSPECTIVE_INFO_FRAGMENT = gql`
  fragment PerspectiveInfo on Perspective {
    pers_id
    folder_id
    file_id
    comments {
      comment_id
      commenter_id
      comment
      lines {
        points_json
        color
        ratiox
        ratioy
      }
      updated_at
      created_at
    }
    created_at
    updated_at
  }
`;
