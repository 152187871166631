import { Deserializable } from '@sparte/utils';

export class OrderArticleHistory implements Deserializable {
  article_id: string;
  designation: string;
  reference: string;
  resource_id: string;
  business_des: string;
  business_ref: string;
  network_des: string;
  original_value: number;
  post: string;
  provider: string;
  original_quantity: number;

  typeName = "OrderArticleHistory";

  constructor() { }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get label(): string {
    return `${this.designation} (${this.reference})`;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.reference} ${this.business_des} ${this.network_des} ${this.provider}`.toLowerCase();
  }

  get updateValue(): any {
    return;
  }
}

export class ArchivedOrder implements Deserializable {
  order_id: string;
  time_id: string;
  reference: string;
  operator: string;
  articles: OrderArticleHistory[];
  created_at: Date;
  archived_at: Date;
  deleted_at: Date;
  public readonly typeName = "ArchivedOrder";

  constructor() { }

  deserialize(input: any): this {
    const { __typename, created_at, archived_at, deleted_at, articles, ..._input } = input;
    Object.assign(this, _input);
    if (created_at) this.created_at = new Date(created_at);
    if (deleted_at) this.deleted_at = new Date(deleted_at);
    if (archived_at) this.archived_at = new Date(archived_at);
    this.articles = (articles || []).map((article: any) => new OrderArticleHistory().deserialize(article));
    return this;
  }

  get id(): string {
    return this.order_id;
  }

  get label(): string {
    return this.reference;
  }

  get searchTerm(): string {
    return `${this.reference}}`.toLowerCase();
  }

  get updateValue(): any {
    return;
  }

  get totalValue(): number {
    return this.articles.reduce((acc, article) => acc + article.original_value, 0);
  }
}