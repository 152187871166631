import { SparteFile } from '@sparte/api';
import { UserService } from '../services/user.service';
import { Deserializable } from '@sparte/utils';
import { Role } from './role.model';
import { UserGroup } from './userGroup.model';

export class CitadelUser implements Deserializable {
  public id: string;
  public last_name: string;
  public first_name: string;
  public avatar_id: string;
  public mail: string;
  public roles_ids: string[];
  public companies_ids: string[];
  public groups_ids: string[];
  public auth_created: boolean;
  private created_at: Date;
  private updated_at: Date;
  public avatar: SparteFile;
  public getIcon = "./assets/icons/person_black_24dp.svg";
  public getCartoIcon = "./assets/icons/person_white_24dp.svg";
  public readonly typeName = "CitadelUser";
  constructor(private userService: UserService) { }

  deserialize(input: any): this {
    const { __typename, prod, created_at, updated_at, auth0_id, user_id, roles_ids, companies_ids, groups_ids, ..._input } = input;
    Object.assign(this, _input);
    this.id = user_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.roles_ids = roles_ids || [];
    this.groups_ids = groups_ids || [];
    this.companies_ids = companies_ids || [];
    if (!!this.avatar_id) {
      this.getAvatar();
    }
    return this;
  }

  get whereUniqueValue(): any {
    return {
      user_id: this.id
    }
  }

  get createValue(): any {
    const { id, avatar, avatar_id, getIcon, getCartoIcon, typeName, created_at, updated_at, userService, auth_created, ..._this } = this;
    return {
      ..._this
    }
  }

  get updateValue(): any {
    const { id, avatar, avatar_id, mail, getIcon, getCartoIcon, typeName, created_at, updated_at, userService, auth_created, ..._this } = this;
    return {
      ..._this
    }
  }

  get searchTerm(): string {
    return `${this.last_name} ${this.first_name} ${this.mail}`.toLowerCase();
  }

  get label(): string {
    return `${this.last_name} ${this.first_name}`;
  }

  get roles(): Role[] {
    return this.roles_ids.reduce((roles, roleId) => {
      const role = this.userService.getRole(roleId);
      if (!!role) roles.push(role);
      return roles;
    }, []);
  }

  get groups(): UserGroup[] {
    return this.groups_ids.reduce((groups, groupId) => {
      const group = this.userService.getUserGroup(groupId);
      if (!!group) groups.push(group);
      return groups;
    }, []);
  }

  get companies(): UserGroup[] {
    return this.companies_ids.reduce((groups, companyId) => {
      const group = this.userService.getUserGroup(companyId);
      if (!!group) groups.push(group);
      return groups;
    }, []);
  }

  get avatarUrl(): string {
    return !!this.avatar ? this.avatar.url : null;
  }

  async getAvatar() {
    this.avatar = await this.userService.getFile(this.avatar_id);
    this.userService.subscriptionRefresh.emit(({ objectType: 'SparteFile', objectId: this.avatar_id, mutationType: 'UPDATED' }));
  }
}
