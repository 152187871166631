import { gql } from 'apollo-angular';

import { SHARE_FOLDER_INFO_FRAGMENT } from './fragments/_shareFolder';

export const SHARE_FOLDER_QUERY = gql`
  query shareFolder($where: ShareFolderWhereUniqueInput!) {
    shareFolder(where: $where)
  }
`;

export const SHARE_FOLDERS_QUERY = gql`
  query shareFolders($where: ShareProjectWhereUniqueInput!) {
    shareFolders(where: $where)
  }
`;

export const CREATE_SHARE_FOLDER_MUTATION = gql`
  mutation createShareFolder($data: ShareFolderCreateInput!) {
    createShareFolder(data: $data) {
      ...ShareFolderInfo
    }
  }
  ${SHARE_FOLDER_INFO_FRAGMENT}
`;
export const UPDATE_SHARE_FOLDER_MUTATION = gql`
  mutation updateShareFolder(
    $where: ShareFolderWhereUniqueInput!
    $data: ShareFolderUpdateInput!
  ) {
    updateShareFolder(where: $where, data: $data) {
      ...ShareFolderInfo
    }
  }
  ${SHARE_FOLDER_INFO_FRAGMENT}
`;

export const DELETE_SHARE_FOLDER_MUTATION = gql`
  mutation deleteShareFolder($where: ShareFolderWhereUniqueInput!) {
    deleteShareFolder(where: $where) {
      folder_id
    }
  }
`;

export const SHARE_FOLDER_SUBSCRIPTION = gql`
  subscription shareFolderSubscription($where: ShareProjectWhereInput!) {
    shareFolderSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;