import { Deserializable, VariableTypeEnum } from '@sparte/utils';

export class CitadelVariableModel implements Deserializable {
  variable_model_id: string;
  display_name: string;
  value_type: VariableTypeEnum;
  measure: string;
  unit: string;
  select_values: string[] = [];
  min_value: number;
  max_value: number;
  min_array_length: number;
  max_array_length: number;
  description: string;
  is_public: boolean;
  user_id: string;
  is_array: boolean;
  zorder: number;
  typeName = "CitadelVariableModel";

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get updateValue() {
    const { typeName, ..._this } = this;
    return _this;
  }

  get label(): string {
    return `${this.display_name} (${this.unit || this.value_type})`;
  }

  get searchTerm(): string {
    return this.display_name;
  }
}