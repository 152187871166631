import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import changes from '../../changelog.json';

interface LogData {
  category: string,
  changes: string[]
}

interface ChangeLogData {
  id: number,
  tag: string,
  logs: LogData[]
}

@Component({
  selector: 'sparte-changelog-dialog',
  templateUrl: './changelog-dialog.component.html',
  styleUrls: ['./changelog-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogDialogComponent implements OnInit {
  public changelogData: ChangeLogData[];
  constructor(public dialogRef: MatDialogRef<ChangelogDialogComponent>) { }

  ngOnInit(): void {
    this.changelogData = Array.from(changes.versions).sort((verA, verB) => {
      return verA.id > verB.id ? -1 : 1;
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngForTrackByFn(index, item) {
    return item.id;
  }
}
