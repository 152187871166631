import { gql } from 'apollo-angular';

export const STOCK_NODE_INFO_FRAGMENT = gql`
  fragment StockNodeInfo on StockNode {
    node_id
    task_id
    stock_id
    x
    y
    created_at
    updated_at
  }
`;
