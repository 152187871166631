import { gql } from 'apollo-angular';

export const USER_GROUP_INFO_FRAGMENT = gql`
  fragment UserGroupInfo on UserGroup {
    group_id
    name
    description
    group_type
    infrastructures_ids
    company_id
    parent_group_id
    created_at
    updated_at
    x
    y
  }
`;
