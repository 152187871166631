import { CommonModule } from '@angular/common';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';

if (environment.production) {
  Sentry.init({
    dsn: "https://dcd55dc9e1434f7299602f84437f4a12@o238272.ingest.sentry.io/1407220",
    integrations: [Sentry.browserTracingIntegration()],
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandler }],
})
export class ErrorsModule {
}
