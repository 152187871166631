import { Deserializable } from '@sparte/utils';


export class CitadelVariable implements Deserializable {
  typeName = "CitadelVariable";
  values: string[] = [];
  variable_model_id: string;
  created_at: Date;
  updated_at: Date;
  files: string[];
  file: string;
  deserialize(input: any): this {
    const { __typename, values, ..._input } = input;
    Object.assign(this, _input);
    //TODO: init files / file
    this.values = values || [];
    return this;
  }

  get updateValue() {
    const { typeName, files, file, created_at, updated_at, ..._this } = this;
    return _this;
  }

  get label() {
    return this.values.join(", ");
  }

  get searchTerm() {
    return '';
  }

  getFiles() {
    //FIXME:
    // if (this.variableModel.value_type === "File") {
    //   if (this.variableModel.is_array) {
    //     this.values.forEach(value => {
    //       this.files.push(value);
    //     })
    //   }
    //   else {
    //     (this.file as any) = this.values.shift();
    //   }
    // }
  }
}