import { InfrastructureAddress } from './infrastructureAddress.model';
import { CitadelCoreService } from "../services/citadel-core.service";
import { CitadelAsset } from "./citadelAsset.model";

export class CitadelInfrastructure extends CitadelAsset {
  address: InfrastructureAddress;
  public typeName = "CitadelInfrastructure";
  constructor(citadelCoreService: CitadelCoreService) {
    super(citadelCoreService);
  }

  deserialize(input: any): this {
    return this.deserializeAsset(input);
  }

  get whereUniqueValue(): any {
    return {
      asset_id: this.id
    }
  }

  get updateValue(): any {
    const {
      id,
      nodeData,
      typeName,
      citadelCoreService,
      created_at,
      updated_at,
      ..._this
    } = this;
    return {
      ..._this
    }
  }

  get createValue(): any {
    const {
      id,
      nodeData,
      typeName,
      citadelCoreService,
      created_at,
      updated_at,
      ..._this
    } = this;
    return {
      ..._this
    }
  }
}