import { CitadelStock } from '@sparte/citadel-core';
import { Deserializable } from '@sparte/utils';
import { OperationStatusEnum, OperationTypeEnum } from '../utils/stock-interfaces';
import { StockInventoryService } from './../services/stock-inventory.service';
import { StockLogisticFlow } from './stockLogisticFlow.model';
import { StockStorageEntity } from './stockStorageEntity.model';
import { EntityArticle } from './entityArticle.model';

export class StockOperation implements Deserializable {
  public id: string;
  public entity_id: string;
  public article_instance_id: string;
  public shipment_ref: string;
  public destination_id: string;
  public operation_type: OperationTypeEnum;
  public hpriority: boolean;
  public user_created: boolean;
  public status: OperationStatusEnum;
  public user_id: string;
  public deadline: Date;
  public created_at: Date;
  public updated_at: Date;
  readonly typeName = "StockOperation";

  constructor(private stockInventoryService: StockInventoryService) { }
  deserialize(input: any): this {
    const { __typename, created_at, updated_at, deadline, operation_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = operation_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    if (deadline) this.deadline = new Date(deadline);
    return this;
  }

  get label(): string {
    return `${this.barcode}:${this.storageLabel}`;
  }

  get searchTerm(): string {
    return `${this.stockStorageEntity?.searchTerm}`.toLowerCase();
  }

  get whereUniqueValue(): any {
    return {
      entity_id: this.entity_id,
      operation_id: this.id
    }
  }

  get updateValue(): any {
    return;
  }

  get stockLogisticFlow(): StockLogisticFlow {
    const flow = this.stockInventoryService.getStockLogisticFlows.find(flow => flow.targets.some(target => target.business_ref === this.businessRef && (target.network_ids.includes('any') || target.network_ids.includes(this.networkId))));
    return flow || this.stockInventoryService.getStockLogisticFlows.find(flow => flow.defaultFlow);
  }

  get stockStorageEntity(): StockStorageEntity {
    return this.stockInventoryService.getStockStorageEntity(this.entity_id);
  }

  get entityArticle(): EntityArticle {
    if (!this.article_instance_id) return;
    return this.stockInventoryService.getEntityArticle(this.article_instance_id);
  }

  get stocks_ids(): string[] {
    return this.stockStorageEntity?.storage?.stocks_ids || [];
  }

  get storageLabel(): string {
    return this.stockStorageEntity?.storageLabel;
  }

  get barcode(): string {
    return this.stockStorageEntity?.barcode;
  }

  get businessRef(): string {
    return this.stockStorageEntity?.business_ref;
  }

  get businessDes(): string {
    switch (this.operation_type) {
      case OperationTypeEnum.WITHDRAWAL:
        return this.entityArticle?.businessDes;
      case OperationTypeEnum.TRANSFER:
      default:
        return this.stockStorageEntity?.businessDes;
    }
  }

  get networkId(): string {
    switch (this.operation_type) {
      case OperationTypeEnum.WITHDRAWAL:
        return this.entityArticle?.network_id;
      case OperationTypeEnum.TRANSFER:
      default:
        return this.stockStorageEntity?.network_id;
    }
  }

  get networkDes(): string {
    switch (this.operation_type) {
      case OperationTypeEnum.WITHDRAWAL:
        return this.entityArticle?.networkDes;
      case OperationTypeEnum.TRANSFER:
      default:
        return this.stockStorageEntity?.networkDes;
    }
  }

  get paused(): boolean {
    return this.stockLogisticFlow?.paused ? !this.hpriority : false;
  }

  get destination(): CitadelStock {
    return this.stockInventoryService.getCitadelStock(this.destination_id);
  }

  get icon(): string {
    switch (this.operation_type) {
      case OperationTypeEnum.TRANSFER:
        switch (this.status) {
          case OperationStatusEnum.PENDING:
            return 'trending_up';
          case OperationStatusEnum.ONGOING:
            return 'transfer_within_a_station';
          default:
            return 'error_outline';
        }
      case OperationTypeEnum.WITHDRAWAL:
        return 'move_to_inbox';
      case OperationTypeEnum.PACKAGING:
        return 'orders';
      default:
        return 'help';
    }
  }
}