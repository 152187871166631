import { gql } from "apollo-angular";
import { AUTH_PAYLOAD_FRAGMENT } from "./fragments/_auth";

export const LOGIN_MUTATION = gql`
  mutation login($mail: String!, $password: String!) {
    login(mail: $mail, password: $password) {
      ...AuthPayload
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`;

export const LOGIN_PIN_MUTATION = gql`
  mutation loginPin($mail: String!, $pin: String!){
    loginPin(mail: $mail, pin: $pin){
      ...AuthPayload
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`;

export const RENEW_TOKEN_MUTATION = gql`
  mutation renewToken {
    renewToken {
      ...AuthPayload
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`;

export const SIGNUP_MUTATION = gql`
  mutation signup($mail: String!, $password: String!, $pin: String!, $first_name: String!, $last_name: String!) {
    signup(mail: $mail, password: $password, pin: $pin, first_name: $first_name, last_name: $last_name)
  }
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($mail: String!, $code: String!) {
    verifyEmail(mail: $mail, code: $code) {
      ...AuthPayload
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`;

export const UDPATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($password: String!, $newPassword: String!) {
    updatePassword(password: $password, newPassword: $newPassword)
  }
`;

export const UPDATE_PIN_MUTATION = gql`
  mutation updatePin($password: String!, $newPin: String!){
    updatePin(password: $password, newPin: $newPin)
  }
`;

export const ASK_RESET_PASSWORD_MUTATION = gql`
  mutation askResetPassword($mail: String!) {
    askResetPassword(mail: $mail)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($mail: String!, $code: String!, $newPassword: String!, $newPin: String!) {
    resetPassword(mail: $mail, code: $code, newPassword: $newPassword, newPin: $newPin) {
      ...AuthPayload
    }
  }
  ${AUTH_PAYLOAD_FRAGMENT}
`;