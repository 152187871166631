import { gql } from 'apollo-angular';

export const FILE_INFO_FRAGMENT = gql`
  fragment FileInfo on File {
    file_id
    name
    url
    content_type
    created_at
    size
    updated_at
    thumbnail_url
    user_id
  }
`;
