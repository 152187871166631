import { gql } from 'apollo-angular';

import { STOCK_NODE_INFO_FRAGMENT } from './fragments/_stockNode';

export const STOCK_NODES_QUERY = gql`
  query stockNodes($where: CitadelTaskContextInput) {
    stockNodes(where: $where)
  }
`;

export const STOCK_NODE_SUBSCRIPTION = gql`
  subscription stockNodeSubscription($where: CitadelTaskWhereInput) {
    stockNodeSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_STOCK_NODE_MUTATION = gql`
  mutation createStockNode($data: StockNodeCreateInput!) {
    createStockNode(data: $data) {
      ...StockNodeInfo
    }
  }
  ${STOCK_NODE_INFO_FRAGMENT}
`;

export const UPDATE_STOCK_NODE_MUTATION = gql`
  mutation updateStockNode(
    $where: StockNodeWhereUniqueInput!
    $data: StockNodeUpdateInput!
  ) {
    updateStockNode(where: $where, data: $data) {
      ...StockNodeInfo
    }
  }
  ${STOCK_NODE_INFO_FRAGMENT}
`;
export const DELETE_STOCK_NODE_MUTATION = gql`
  mutation deleteStockNode(
    $where: StockNodeWhereUniqueInput!
  ) {
    deleteStockNode(where: $where) {
      node_id
    }
  }
`;