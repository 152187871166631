import { CitadelCoreService } from '../services/citadel-core.service';
import { AssetZoneTypeEnum } from '../utils/citadel-core.interface';
import { AssetZone } from './assetZone.model';
import { CitadelAsset } from './citadelAsset.model';


export class CitadelItem extends CitadelAsset {
  guid: string;
  group_id: string;
  public typeName = "CitadelItem";
  constructor(citadelCoreService: CitadelCoreService) {
    super(citadelCoreService);
  }

  deserialize(input: any): this {
    const asset = this.deserializeAsset(input);
    Object.assign(this, asset);
    return this;
  }

  get whereUniqueValue(): any {
    return {
      asset_id: this.id
    }
  }

  get updateValue(): any {
    const {
      id,
      typeName,
      citadelCoreService,
      nodeData,
      created_at,
      updated_at,
      variables,
      ..._this
    } = this;
    return {
      ..._this,
      variables: variables.map(variable => variable.updateValue)
    }
  }

  get createValue(): any {
    const {
      id,
      typeName,
      citadelCoreService,
      nodeData,
      created_at,
      updated_at,
      variables,
      ..._this
    } = this;
    return {
      ..._this,
      variables: variables.map(variable => variable.updateValue)
    }
  }

  get post(): AssetZone {
    return this.parentZoneIds.map(zone_id => this.citadelCoreService.getAssetZone(zone_id)).find(zone => zone.type === AssetZoneTypeEnum.Post);
  }
}