import { EntityArticle } from "../models/entityArticle.model";
import { OrderArticle } from "../models/orderArticle.model";

export enum StockTypeEnum {
  StockStorage = "StockStorage",
  StockAisle = "StockAisle",
  StockBay = "StockBay",
  StockLevel = "StockLevel",
  StockBin = "StockBin"
}

export enum OperationStatusEnum {
  PENDING = "PENDING",
  ONGOING = "ONGOING",
  DONE = "DONE"
}

export enum OperationTypeEnum {
  CREATION = "CREATION",
  RECEPTION = "RECEPTION",
  TRANSFER = "TRANSFER",
  WITHDRAWAL = "WITHDRAWAL",
  PACKAGING = "PACKAGING",
  UNPACK = "UNPACK"
}

export enum HistoryTypeEnum {
  EntityReceived = "EntityReceived", // receiveStockStorageEntity (RECEPTION ENTITE)
  ArticleTransferIn = "ArticleTransferIn", // (TRANSFERT ARTICLE DANS ENTITE)
  ArticleTransferOut = "ArticleTransferOut", // (TRANSFERT ARTICLE VERS ENTITE)
  EntityMoved = "EntityMoved", // transferEntityToStorage (TRANSFERT ENTITE VERS EMPLACEMENT)
  EntityToEntity = "EntityToEntity", // transferEntityToEntity (TRANSFERT ENTITE VERS AUTRE ENTITE)
  ArticlePoured = "ArticlePoured", // transferArticlesInEntity (VERSER CONTENU ENTITE DANS AUTRE ENTITE)
  Added = "Added", // createArticleInEntity (AJOUT ARTICLE)
  UpdatedArticle = "UpdatedArticle", // updateArticleInEntity (MODIFICATION ARTICLE)
  ArticleWithdrawal = "ArticleWithdrawal", // withdrawArticlesToEntity (PRELEVEMENT)
  OutOfStock = "OutOfStock", // archiveEntityArticles (ARCHIVAGE ARTICLE)
  Packaged = "Packaged", // packageStockStorageEntity (CONDITIONNEMENT)
  Shipped = "Shipped", // shipped Articles (ARTICLE EXPEDIE)
  Requested = "Requested", // Requested Articles (ARTICLE SORTI PAR UNE EXPRESSION DE BESOIN OU UNE EXPEDITION)
  Request = "Request", // Request Articles (ARTICLE IMPORTE PAR UNE EXPRESSION DE BESOIN OU UNE EXPEDITION)
  ReassignedArticle = "ReassignedArticle" // Reassigned Article (ARTICLE REAFFECTE)
}

export interface AvailableQuantitiesForReference {
  availableResourceQuantity: number;
  availableArticles: EntityArticle[];
  availableOrderedResourceQuantity: number;
  lockedArticles: EntityArticle[];
  lockedResourceQuantity: number;
  availableOrderedArticles: OrderArticle[];
  lockedOrderedResourceQuantity: number;
  lockedOrderedArticles: OrderArticle[];
}