import { gql } from 'apollo-angular';
import { POST_MAINTENANCE_INFO_FRAGMENT } from './fragments/_postMaintenance';

export const POSTS_MAINTENANCES_QUERY = gql`
  query getPostsMaintenance($where: PostWhereInput!) {
    getPostsMaintenance(where: $where)
  }
`;

export const CREATE_POST_MAINTENANCE_MUTATION = gql`
  mutation createPostMaintenance($data: PostMaintenanceCreateInput!) {
    createPostMaintenance(data: $data) {
      ...PostMaintenanceInfo
    }
  }
  ${POST_MAINTENANCE_INFO_FRAGMENT}
`;

export const UPDATE_POST_MAINTENANCE_MUTATION = gql`
  mutation updatePostMaintenance(
    $where: PostMaintenanceWhereUniqueInput!
    $data: PostMaintenanceUpdateInput!
  ) {
    updatePostMaintenance(where: $where, data: $data) {
      ...PostMaintenanceInfo
    }
  }
  ${POST_MAINTENANCE_INFO_FRAGMENT}
`;

export const DELETE_POST_MAINTENANCE_MUTATION = gql`
  mutation deletePostMaintenance($where: PostMaintenanceWhereUniqueInput!) {
    deletePostMaintenance(where: $where) {
      asset_id
    }
  }
`;

export const POST_MAINTENANCE_SUBSCRIPTION = gql`
  subscription postMaintenanceSubscription($where: PostWhereInput!) {
    postMaintenanceSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;
