import { Deserializable } from '@sparte/utils';

export class CitadelPermission implements Deserializable {
  public id: string;
  public name: string;
  public category: string;
  public zorder: number;
  public modules: string[] = [];
  private created_at: Date;
  private updated_at: Date;
  public readonly typeName = "CitadelPermission";

  deserialize(input: any): this {
    const { __typename, created_at, updated_at, permission_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = permission_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    return this;
  }

  get label(): string {
    return this.name
  }

  get updateValue() {
    return;
  }

  get searchTerm(): string {
    return `${this.name} ${this.id} ${this.category} ${this.modules?.join(' ')}`.toLowerCase()
  }
}
