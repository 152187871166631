import { gql } from "apollo-angular";

export const DASSAULT_PROCESSES_QUERY = gql`
  query dassaultProcesses($where: DassaultProcessWhereInput!) {
    dassaultProcesses(where: $where)
  }
`;

export const DASSAULT_PROCESS_SUBSCRIPTION = gql`
  subscription dassaultProcessSubscription {
    dassaultProcessSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;