import { gql } from 'apollo-angular';

export const MAXIMO_EVENTS_BY_ITEMS_QUERY = gql`
  query maximoEventsByItems($where: MaximoItemWhereInput!) {
    maximoEventsByItems(where: $where)
  }
`;

export const BATCH_INSERT_MAXIMO_DATA_MUTATION = gql`
  mutation batchInsertMaximoData($data: MaximoDataBatchCreateInput!) {
    batchInsertMaximoData(data: $data)
  }
`;

export const MAXIMO_EVENT_SUBSCRIPTION = gql`
  subscription maximoEventSubscription($where: MaximoItemWhereInput!) {
    maximoEventSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;