import { gql } from 'apollo-angular';

export const STORAGE_INFO_FRAGMENT = gql`
  fragment StorageInfo on Storage {
    storage_id
    stocks_ids
    delivery
    parent_zone_id
    designation
    tag
    sort_order
    barcode
    created_at
    updated_at
  }
`;
