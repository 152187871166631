import { Deserializable } from '@sparte/utils';


export class ResourceCatalog implements Deserializable {
  public id: string;
  public library_id: string;
  public designation: string;
  public description: string;
  public created_at: Date;
  public updated_at: Date;
  public readonly typeName = "ResourceCatalog";

  deserialize(input: any): this {
    const { __typename, catalog_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = catalog_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    return this;
  }

  get label(): string {
    return this.designation;
  }

  get whereUniqueValue() {
    return {
      catalog_id: this.id
    }
  }

  get createValue() {
    const {
      created_at,
      updated_at,
      typeName,
      ..._this
    } = this;
    return _this;
  }

  get updateValue() {
    const {
      created_at,
      updated_at,
      typeName,
      ..._this
    } = this;
    return _this;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.description}`.toLowerCase();
  }
}