import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@sparte/material';
import { MobxAngularModule } from 'mobx-angular';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ElectronService } from 'ngx-electron';
import { ToastrModule } from 'ngx-toastr';
import { AuthComponent } from './auth/auth.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { CurrentRouteComponent } from './current-route/current-route.component';
import { CustomScrollComponent } from './custom-scroll/custom-scroll.component';
import { DataImportComponent } from './data-import/data-import.component';
import { LongPressDirective } from './directives/long-press.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { GalleryViewerComponent } from './gallery-viewer/gallery-viewer.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { PrintRowBarcodesComponent } from './print-row-barcodes/print-row-barcodes.component';
import { PrintStorageBarcodesComponent } from './print-storage-barcodes/print-storage-barcodes.component';
import { RotationCanvasComponent } from './rotation-canvas/rotation-canvas.component';
import { RouteSelectComponent } from './route-select/route-select.component';
import { UiService } from './services/ui.service';
import { SparteAutocompleteComponent } from './sparte-autocomplete/sparte-autocomplete.component';
import { SparteButtonComponent } from './sparte-button/sparte-button.component';
import { SparteCategoryHeaderComponent } from './sparte-category-header/sparte-category-header.component';
import { SparteDrawerComponent } from './sparte-drawer/sparte-drawer.component';
import { SparteDropdownComponent } from './sparte-dropdown/sparte-dropdown.component';
import { SparteFormDropdownComponent } from './sparte-form-dropdown/sparte-form-dropdown.component';
import { SparteLoaderComponent } from './sparte-loader/sparte-loader.component';
import { SparteMenuComponent } from './sparte-menu/sparte-menu.component';
import { SparteSearchComponent } from './sparte-search/sparte-search.component';
import { SparteSortComponent } from './sparte-sort/sparte-sort.component';
import { SparteSpinnerComponent } from './sparte-spinner/sparte-spinner.component';
import { SparteToggleComponent } from './sparte-toggle/sparte-toggle.component';
import { StatCardComponent } from './stat-card/stat-card.component';
import { FormInfoDirective } from './update-form-container/form-info.directive';
import { UpdateFormContainerComponent } from './update-form-container/update-form-container.component';
import { SparteModalComponent } from './sparte-modal/sparte-modal.component';
import { SparteColumnTitleComponent } from './sparte-column-title/sparte-column-title.component';
import { SparteKeyboardComponent } from './sparte-keyboard/sparte-keyboard.component';
import { PrettyQuantityPipe } from './pipes/pretty-quantity.pipe';
import { PrettyDatePipe } from './pipes/pretty-date.pipe';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MobxAngularModule,
    RouterModule,
    NgxBarcode6Module,
    ToastrModule.forRoot({
      maxOpened: 3,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true,
    }),
  ],
  declarations: [
    ConfirmationDialogComponent,
    SparteLoaderComponent,
    CustomScrollComponent,
    GalleryViewerComponent,
    RouteSelectComponent,
    RotationCanvasComponent,
    InputDialogComponent,
    ScrollSpyDirective,
    InformationDialogComponent,
    SparteMenuComponent,
    UpdateFormContainerComponent,
    FormInfoDirective,
    SparteSearchComponent,
    SparteDropdownComponent,
    CurrentRouteComponent,
    SparteDrawerComponent,
    SparteFormDropdownComponent,
    SparteSpinnerComponent,
    LongPressDirective,
    AuthComponent,
    SparteAutocompleteComponent,
    HighlighterPipe,
    ConnexionComponent,
    SparteToggleComponent,
    StatCardComponent,
    ReversePipe,
    SparteCategoryHeaderComponent,
    SparteButtonComponent,
    SparteSortComponent,
    DataImportComponent,
    PrintRowBarcodesComponent,
    PrintStorageBarcodesComponent,
    SparteModalComponent,
    SparteColumnTitleComponent,
    SparteKeyboardComponent,
    UserProfileComponent,
    PrettyQuantityPipe,
    PrettyDatePipe,
  ],
  exports: [
    ConfirmationDialogComponent,
    SparteLoaderComponent,
    MaterialModule,
    CustomScrollComponent,
    GalleryViewerComponent,
    RouteSelectComponent,
    RotationCanvasComponent,
    InputDialogComponent,
    ScrollSpyDirective,
    InformationDialogComponent,
    SparteMenuComponent,
    UpdateFormContainerComponent,
    SparteSearchComponent,
    SparteDropdownComponent,
    CurrentRouteComponent,
    SparteDrawerComponent,
    SparteFormDropdownComponent,
    SparteSpinnerComponent,
    LongPressDirective,
    AuthComponent,
    SparteAutocompleteComponent,
    HighlighterPipe,
    ConnexionComponent,
    SparteToggleComponent,
    StatCardComponent,
    ReversePipe,
    SparteCategoryHeaderComponent,
    SparteButtonComponent,
    SparteSortComponent,
    DataImportComponent,
    PrintRowBarcodesComponent,
    PrintStorageBarcodesComponent,
    SparteModalComponent,
    SparteColumnTitleComponent,
    SparteKeyboardComponent,
    UserProfileComponent,
    PrettyQuantityPipe,
    PrettyDatePipe
  ],
  providers: [ElectronService, UiService],
})
export class UiModule {
  constructor() {}
}
