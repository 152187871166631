import { quantityPrettyfier, datePrettyfier } from '@sparte/utils';
import { StockInventoryService } from '../services/stock-inventory.service';
import { ArticleHistory } from './articleHistory.model';
import { ArticleInstance } from './articleInstance.model';


export class ArchivedArticle extends ArticleInstance {
  public instance_id: string;
  public archived: boolean;
  public business_des: string;
  public network_des: string;
  public _reference: string;
  public order_ref: string;
  public typeName = "ArchivedArticle";
  public archived_at: Date;
  constructor(stockInventoryService: StockInventoryService) {
    super(stockInventoryService);
  }

  deserialize(input: any): this {
    const { __typename, time_id, reference, archived_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = time_id;
    this._reference = reference;
    if (archived_at) this.archived_at = new Date(archived_at);
    return this;
  }


  get searchTerm(): string {
    return `${this.designation} ${this.order_ref} ${this.reference} ${this.business_des} ${this.network_des} ${this.provider} ${this.history.map(hist => hist.searchTerm).join(' ')}`.toLowerCase();
  }

  get label(): string {
    return this.designation;
  }

  get updateValue() {
    return;
  }

  get orderRef(): string {
    return this.order_ref ?? "N/A";
  }

  get businessDes(): string {
    return this.business_des ?? "N/A";
  }

  get networkDes(): string {
    return this.network_des ?? "N/A";
  }

  get storageLabel(): string {
    return "";
  }

  get reference(): string {
    return this.resource?.reference ?? this._reference;
  }

  get history(): ArticleHistory[] {
    return this.stockInventoryService.getArchivedArticleHistory(this);
  }

  get removedHistory(): ArticleHistory {
    return this.history.find(hist => hist.action_ts.toISOString() === this.archived_at.toISOString());
  }

  get getHistory(): ArticleHistory[] {
    return this.history.sort((opA, opB) => (opA.action_ts > opB.action_ts ? -1 : 1));
  }

  get removedPrettyDay(): string {
    return datePrettyfier(this.archived_at, 'L');
  }

  get removedComment(): string {
    return this.removedHistory?.comment;
  }

  get prettyQuantity(): string {
    return this.removedPrettyQuantity;
  }

  get removedQuantity(): number {
    return this.removedHistory?.quantity;
  }

  get removedPrettyQuantity(): string {
    return this.removedQuantity ? `- ${quantityPrettyfier(this.removedQuantity)}` : "0";
  }

  //XLSX REPORT VARIABLES
  get prettyReportQuantity(): string {
    return this.removedPrettyQuantity;
  }

  get prettyReportOriginalQuantity(): string {
    return quantityPrettyfier(this.original_quantity);
  }
}