import { UserService } from '../services/user.service';
import { Deserializable } from '@sparte/utils';
import { UserGroup } from './userGroup.model';

export class Role implements Deserializable {
  public id: string;
  public group_id: string;
  public name: string;
  public description: string;
  public permissions_ids: string[];
  private created_at: Date;
  private updated_at: Date;
  public readonly typeName = "Role";

  constructor(private userService: UserService) { }

  deserialize(input: any): this {
    const { __typename, auth0_id, created_at, updated_at, permissions_ids, role_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = role_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    this.permissions_ids = permissions_ids || [];
    return this;
  }

  get label(): string {
    return this.name
  }

  get searchTerm(): string {
    return this.name?.toLowerCase();
  }

  get createValue(): any {
    const { id, userService, created_at, updated_at, typeName, ..._this } = this;
    return {
      ..._this
    }
  }

  get updateValue(): any {
    const { id, group_id, userService, created_at, updated_at, typeName, ..._this } = this;
    return {
      ..._this
    }
  }

  get group(): UserGroup {
    return this.userService.getUserGroup(this.group_id);
  }
}
