import { gql } from 'apollo-angular';

import { PARAMETER_SET_INFO_FRAGMENT } from './fragments/_parameterSet';

export const PARAMETER_SETS_QUERY = gql`
  query parameterSets {
    parameterSets
  }
`;

export const CREATE_PARAMETER_SET_MUTATION = gql`
  mutation createParameterSet($data: ParameterSetCreateInput!) {
    createParameterSet(data: $data) {
      ...ParameterSetInfo
    }
  }
  ${PARAMETER_SET_INFO_FRAGMENT}
`;

export const UPDATE_PARAMETER_SET_MUTATION = gql`
  mutation updateParameterSet(
    $where: ParameterSetWhereUniqueInput!
    $data: ParameterSetUpdateInput!
  ) {
    updateParameterSet(where: $where, data: $data) {
      ...ParameterSetInfo
    }
  }
  ${PARAMETER_SET_INFO_FRAGMENT}
`;

export const DELETE_PARAMETER_SET_MUTATION = gql`
  mutation deleteParameterSet($where: ParameterSetWhereUniqueInput!) {
    deleteParameterSet(where: $where) {
      set_id
    }
  }
`;

export const PARAMETER_SET_SUBSCRIPTION = gql`
  subscription parameterSetSubscription {
    parameterSetSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
