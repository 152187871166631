import { gql } from 'apollo-angular';

import { CITADEL_INFRASTRUCTURE_INFO_FRAGMENT } from './fragments/_citadelInfrastructure';

export const CITADEL_INFRASTRUCTURES_QUERY = gql`
  query citadelInfrastructures {
    citadelInfrastructures
  }
`;

export const CREATE_CITADEL_INFRASTRUCTURE_MUTATION = gql`
  mutation createCitadelInfrastructure(
    $data: CitadelInfrastructureCreateInput!
  ) {
    createCitadelInfrastructure(data: $data) {
      ...CitadelInfrastructureInfo
    }
  }
  ${CITADEL_INFRASTRUCTURE_INFO_FRAGMENT}
`;

export const UPDATE_CITADEL_INFRASTRUCTURE_MUTATION = gql`
  mutation updateCitadelInfrastructure(
    $where: CitadelInfrastructureWhereUniqueInput!
    $data: CitadelInfrastructureUpdateInput!
  ) {
    updateCitadelInfrastructure(where: $where, data: $data) {
      ...CitadelInfrastructureInfo
    }
  }
  ${CITADEL_INFRASTRUCTURE_INFO_FRAGMENT}
`;

export const ARCHIVE_CITADEL_INFRASTRUCTURE_MUTATION = gql`
  mutation archiveCitadelInfrastructure(
    $where: CitadelInfrastructureWhereUniqueInput!
  ) {
    archiveCitadelInfrastructure(where: $where) {
      asset_id
    }
  }
`;

export const DELETE_CITADEL_INFRASTRUCTURE_MUTATION = gql`
  mutation deleteCitadelInfrastructure(
    $where: CitadelInfrastructureWhereUniqueInput!
  ) {
    deleteCitadelInfrastructure(where: $where) {
      asset_id
    }
  }
`;

export const CITADEL_INFRASTRUCTURE_SUBSCRIPTION = gql`
  subscription citadelInfrastructureSubscription {
    citadelInfrastructureSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
