import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService, AuthStore } from '@sparte/api';
import { SparteModulesService } from '@sparte/ui';
import { ModuleInterface } from '@sparte/utils';
import { makeObservable } from 'mobx';
import { action, computed, observable } from 'mobx-angular';
import { Subscription } from 'rxjs';
import { modulesMap } from './app.module';
import { appRoutes, defaultRoute } from './app.routing';
import changes from './changelog.json';
import { ChangelogDialogComponent } from './components/changelog-dialog/changelog-dialog.component';
import { ElectronStockService } from './services/electron-stock.service';
import { StockInventoryService } from './services/stock-inventory.service';
import { StockUsersService } from './services/stock-users.service';

@Component({
  selector: 'sparte-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  @observable public isAuthenticated = false;
  @observable public version: string;
  @observable public online: boolean;
  @observable public citadelConnected: boolean = true;
  private onlineSub: Subscription;
  private citadelSub: Subscription;
  private authSub: Subscription;
  constructor(
    private authStore: AuthStore, // DONT REMOVE NEEDED FOR INIT
    private stockInventoryService: StockInventoryService, // DONT REMOVE NEEDED FOR INIT
    private stockUsersService: StockUsersService, // DONT REMOVE NEEDED FOR INIT
    private api: ApiService,
    private electronStockService: ElectronStockService,
    private sparteModulesService: SparteModulesService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) {
    makeObservable(this);
    this.setVersion([...(changes.versions)].sort((verA, verB) => {
      return verA.id > verB.id ? -1 : 1;
    })[0].tag);
  }

  ngOnInit() {
    this.setOnline(this.api.online);
    this.api.renewTokens();
    this.authSub = this.api.authChanged.subscribe(auth => {
      if (this.isAuthenticated && !auth) {
        if (this.electronStockService.isInElectron) {
          this.electronStockService.sendMessage('logout');
        }
      }
      this.setAuthenticated(auth);
      if (!auth) {
        this.sparteModulesService.clearModules();
        appRoutes[0].redirectTo = defaultRoute;
        appRoutes[appRoutes.length - 1].redirectTo = defaultRoute;
        this.router.resetConfig(appRoutes);
      }
      if (!this.api.currentUser) return;
      this.updateAppModules();
    });
    this.onlineSub = this.api.onlineChanged.subscribe(isOnline => {
      this.setOnline(isOnline);
    });
    this.citadelSub = this.api.citadelConnected.subscribe(connected => {
      this.setCitadelConnected(connected);
    });
  }

  ngOnDestroy() {
    this.onlineSub?.unsubscribe();
    this.authSub?.unsubscribe();
    this.citadelSub?.unsubscribe();
  }

  @computed get citadelConnection(): boolean {
    return this.isAuthenticated ? this.citadelConnected : true;
  }

  @action private setAuthenticated = (auth: boolean) => {
    this.isAuthenticated = auth;
  }

  @action private setOnline = (isOnline: boolean) => {
    this.online = isOnline;
  }

  @action private setCitadelConnected = (connected: boolean) => {
    this.citadelConnected = connected;
  }

  @action private setVersion = (version: string) => {
    this.version = version;
  }

  showChangeLog = () => {
    this.dialog.open(ChangelogDialogComponent, {
      maxHeight: 800, width: '800px'
    });
  }

  updateAppModules = () => {
    const modules: ModuleInterface[] = [];
    let fallbackRoute;
    modulesMap.forEach((module, name) => {
      const navLinks = module.navLinks.reduce((links, navLink) => {
        links.push(navLink);
        navLink.children?.forEach(child => {
          links.push({
            permission_id: child.permission_id,
            link: `${navLink.link}/${child.link}`
          });
        });
        return links;
      }, []);
      const navLink = navLinks.find(navLink => this.api.currentUser.hasPermission(navLink.permission_id));
      if (navLink) {
        modules.push(module);
        if (!fallbackRoute) {
          if (this.api.currentUser.hasPermission(module.fallbackRoute)) fallbackRoute = module.fallbackRoute;
          else fallbackRoute = `${module.link}/${navLink.link}`
        };
        if (!this.api.currentUser.hasPermission(module.fallbackRoute)) module.fallbackRoute = `${module.link}/${navLink.link}`;
      }
    });
    this.sparteModulesService.initSparteModules(modules);
    if (fallbackRoute) {
      appRoutes[0].redirectTo = fallbackRoute;
      appRoutes[appRoutes.length - 1].redirectTo = fallbackRoute;
      this.router.resetConfig(appRoutes);
    }
    this.cdRef.detectChanges();
  }

  @computed get isUpToDate(): boolean {
    return this.electronStockService.upToDate;
  }
}
