import { StockUsersService } from 'apps/stocks/src/app/services/stock-users.service';
import { Deserializable } from '@sparte/utils';
import { StockInventoryService } from '../services/stock-inventory.service';
import { RequestedResource } from './requestedResource.model';

export enum RequestType {
  Supply = 'SUPPLY',
  Restock = 'RESTOCK',
  Shipment = 'SHIPMENT',
  Production = 'PRODUCTION'
}

export const getTypeName = (request_type: RequestType): string => {
  switch (request_type) {
    case RequestType.Supply:
      return 'Approvisionnement';
    case RequestType.Shipment:
      return 'Expédition';
    case RequestType.Restock:
      return 'Réapprovisionnement';
    case RequestType.Production:
      return 'Fabrication';
    default:
      return 'Aucun type'
  }
}

export enum RequestStatus {
  Draft = 'DRAFT',
  Done = 'DONE',
  Canceled = 'CANCELED'
}

export enum PurchaseStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export class StockRequest implements Deserializable {
  public id: string;
  public request_ref: string;
  public user_id: string;
  public business_ref: string;
  public request_type: RequestType;
  public request_status: RequestStatus;
  public purchase_status: PurchaseStatus;
  public request_date: Date;
  public created_at: Date;
  private updated_at: Date;
  private archived_at: Date;
  private _resources: RequestedResource[] = [];
  public readonly typeName = "StockNetwork";
  private snapshot: string;

  constructor(
    private stockInventoryService: StockInventoryService,
    private stockUsersService: StockUsersService
  ) { }

  deserialize(input: any): this {
    const { __typename, created_at, updated_at, request_date, archived_at, request_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = request_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    if (archived_at) this.archived_at = new Date(archived_at);
    if (request_date) this.request_date = new Date(request_date);
    this.snapshotObject();
    return this;
  }

  private snapshotObject() {
    this.snapshot = JSON.stringify(this.snapshotValue);
  }

  applySnapshot() {
    this.resources.forEach(resource => resource.applySnapshot());
    this.deserialize(JSON.parse(this.snapshot));
  }

  get label(): string {
    return this.request_ref;
  }

  get whereUniqueValue() {
    return {
      request_id: this.id
    }
  }

  get createValue() {
    const {
      id,
      snapshot,
      user_id,
      request_ref,
      created_at,
      updated_at,
      purchase_status,
      stockInventoryService,
      stockUsersService,
      typeName,
      _resources,
      ..._this
    } = this;
    return { ..._this, resources: _resources.map(resource => resource.createValue) };
  }

  get updateValue() {
    const {
      id,
      snapshot,
      request_ref,
      user_id,
      created_at,
      updated_at,
      purchase_status,
      stockInventoryService,
      stockUsersService,
      _resources,
      typeName,
      ..._this
    } = this;
    return { ..._this, resources: _resources.map(resource => resource.updateValue) };
  }

  get businessDes() {
    return this.stockInventoryService.getStockBusiness(this.business_ref)?.designation;
  }

  get snapshotValue() {
    const {
      id,
      snapshot,
      stockInventoryService,
      stockUsersService,
      _resources,
      typeName,
      ..._this
    } = this;
    return {
      request_id: id,
      ..._this
    };
  }

  get purchaseOrderUpdateValue() {
    return {
      purchase_status: this.purchase_status
    }
  }

  get searchTerm(): string {
    return `${this.request_ref} ${this.businessDes} ${this.business_ref} ${this.getUserFullName(this.user_id)} `.toLowerCase();
  }

  getUserFullName(user_id: string): string {
    return this.stockUsersService.getUserName(user_id);
  }

  setResources(resources: RequestedResource[]): void {
    this._resources = resources;
  }

  get resources(): RequestedResource[] {
    return this.stockInventoryService.getRequestResources(this.id);
  }
}