import { gql } from 'apollo-angular';

import { RESOURCE_LINK_INFO_FRAGMENT } from './fragments/_resourceLink';

export const RESOURCE_LINKS_QUERY = gql`
  query resourceLinks($where: CitadelTaskContextInput) {
    resourceLinks(where: $where)
  }
`;

export const RESOURCE_LINK_SUBSCRIPTION = gql`
  subscription resourceLinkSubscription($where: CitadelTaskWhereInput) {
    resourceLinkSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_RESOURCE_LINK_MUTATION = gql`
  mutation createResourceLink($data: ResourceLinkCreateInput!) {
    createResourceLink(data: $data) {
      ...ResourceLinkInfo
    }
  }
  ${RESOURCE_LINK_INFO_FRAGMENT}
`;

export const DELETE_RESOURCE_LINK_MUTATION = gql`
  mutation deleteResourceLink(
    $where: ResourceLinkWhereUniqueInput!
  ) {
    deleteResourceLink(where: $where) {
      link_id
    }
  }
`;