<section class="form-container mat-typography">
  <div class="flex items-center justify-between form-header">
    <div class="pl-0 header-text">{{formTitle}}</div>
    <button class="material-symbols-outlined"
            tabindex="-1"
            (click)="cancelDialog()">
      <span class="mr-3">close</span>
    </button>
  </div>
  <perfect-scrollbar class="flex-col items-start content-div justify-stretch">
    <ng-template sparteFormInfo></ng-template>
  </perfect-scrollbar>
  <div class="flex items-center justify-around gap-4 form-buttons">
    <sparte-button *ngIf="canDelete && !createModel"
            tabindex="-1"
            (click)="deleteDialog()"
            red
            roundedLg
            emphezise
            label="Supprimer"
            icon="delete">
    </sparte-button>
    <sparte-button tabindex="-1"
            (click)="cancelDialog()"
            gold
            roundedLg
            emphezise
            label="Annuler"
            icon="close">
    </sparte-button>
    <sparte-button [disabled]="!saveCondition()"
            tabindex="-1"
            green
            roundedLg
            emphezise
            icon="done"
            (click)="closeDialog()"
            label="Enregistrer">
    </sparte-button>
  </div>
</section>