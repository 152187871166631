import { Point } from 'pixi.js';
import { Deserializable, quantityPrettyfier } from '@sparte/utils';
import { ResourceInstance } from './resourceInstance.model';
import { TaskEvent } from './taskEvent.model';
import { ResourceForTask } from './resourceForTask.model';

export class CitadelTask implements Deserializable {
  public id: string;
  private owners_ids: string[];
  public parent_task_id: string;
  public context_asset_id: string;
  public name: string;
  public completion_time: number;
  public in_resources: ResourceForTask[];
  public out_resources: ResourceForTask[];
  public assets_ids: string[];
  public events: TaskEvent[];
  private x: number;
  private y: number;
  public created_by: string;
  public created_at: Date;
  public updated_at: Date;
  private simulated: boolean = false;
  public typeName = "CitadelTask";
  private _resourceInstances: ResourceInstance[];

  constructor(private citadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, task_id, events, created_at, updated_at, in_resources, out_resources, ..._input } = input;
    Object.assign(this, _input);
    this.id = task_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    this.in_resources = (in_resources || []).map(resource => new ResourceForTask(this.citadelCoreService).deserialize(resource));
    this.out_resources = (out_resources || []).map(resource => new ResourceForTask(this.citadelCoreService).deserialize(resource));
    this.events = (events || []).map(event => new TaskEvent().deserialize(event));
    this._resourceInstances = [];
    return this;
  }

  get searchTerm(): string {
    return this.name;
  }

  get label(): string {
    return `${this.name}${this.completion_time ? ` – ${quantityPrettyfier(this.completion_time)}h` : ''}`;
  }

  get whereUniqueValue(): any {
    return {
      parent_task_id: this.parent_task_id,
      task_id: this.id
    }
  }

  get resourceInstances(): ResourceInstance[] {
    //TODO ???
    return this._resourceInstances;
  }

  get createValue(): any {
    const { citadelCoreService, id, in_resources, out_resources, typeName, created_at, updated_at, created_by, events, owners_ids, _resourceInstances: _resourcesInstances, simulated, ..._this } = this;
    // const inResUpdVal = in_resources.map(res => res.updateValue);
    // const outResUpdVal = out_resources.map(res => res.updateValue);
    return {
      // in_resources: inResUpdVal,
      // out_resources: outResUpdVal,
      ..._this
    };
  }

  get updateValue(): any {
    const { citadelCoreService, id, in_resources, out_resources, typeName, created_at, updated_at, created_by, events, parent_task_id, owners_ids, _resourceInstances: _resourcesInstances, simulated, ..._this } = this;
    // const inResUpdVal = in_resources.map(res => res.updateValue);
    // const outResUpdVal = out_resources.map(res => res.updateValue);
    return {
      // in_resources: inResUpdVal,
      // out_resources: outResUpdVal,
      ..._this
    };
  }

  get position(): Point {
    return new Point(this.x, this.y);
  }

  /* get links(): ResourceLink[] {
    return flatMap([...this.in_resources, ...this.out_resources], resource => resource.links);
  } */


  get sortedEvents(): TaskEvent[] {
    return this.events.sort((eventA, eventB) => {
      return eventA.history_ts > eventB.history_ts ? 1 : -1;
    })
  }

  get lastEvent(): TaskEvent {
    return this.sortedEvents[this.events.length - 1];
  }

  /* addResourceHistory(resourceHistory: ResourceHistory) {
    const instance = this.resourceInstances.find(resInst => resInst.id === resourceHistory.instance_id);
    if (instance) {
      instance.initHistory(resourceHistory);
    }
  }

  resourceInstanceQuantity(resForTask: ResourceForTask): number {
    const selfResInstance = this.resourceInstances.find(resInstance => resInstance.resource_id === resForTask.resource_id);
    return selfResInstance?.instanceQuantity || 0;
  }

  availableResourceQuantity(resForTask: ResourceForTask): number {
    if (resForTask.inLinks.length > 0) {
      let qtyAvailable = resForTask.inLinks.reduce((sumQty, inLink) => {
        return sumQty + inLink.availableQuantity;
      }, this.resourceInstanceQuantity(resForTask));
      return qtyAvailable;
    }
    else return this.resourceInstanceQuantity(resForTask);
  }

  get conditionFulfilled(): boolean {
    return this.in_resources.every(resForTask => resForTask.quantity <= this.availableResourceQuantity(resForTask));
  } */

  setPosition(position: Point) {
    this.x = Math.round(position.x);
    this.y = Math.round(position.y);
  }

  /* async consumeResources() {
    if (this.conditionFulfilled) {
      const event = new TaskEvent().deserialize({ event_type: TaskEventTypeEnum.StartCycle });
      await this.citadelCoreService.simulateTaskEvent(this, event);
      await asyncForEach(this.in_resources, async (resourceForTask: ResourceForTask) => {
        await asyncForEach(resourceForTask.inLinks, async (resourceLink: ResourceLink) => {
          await asyncForEach(resourceLink.inResourceInstances, async (resourceInstance: ResourceInstance) => {
            const quantityNeeded = resourceForTask.quantity;
            const selfResourceInstance = this.resourceInstances.find(resInst => resInst.resource_id === resourceForTask.resource_id);
            if (selfResourceInstance) {
              const absorbQty = quantityNeeded - selfResourceInstance.quantity;
              resourceInstance.quantity -= absorbQty;
              selfResourceInstance.quantity = quantityNeeded;
              await this.citadelCoreService.updateResourceInstance(selfResourceInstance);
            }
            else {
              const newResInstance = new ResourceInstance(this.citadelCoreService).deserialize({ resource_id: resourceForTask.resource_id, stock_id: this.id, quantity: quantityNeeded });
              resourceInstance.quantity -= quantityNeeded;
              await this.citadelCoreService.createResourceInstance(newResInstance);
            }
            await this.citadelCoreService.updateResourceInstance(resourceInstance);
          });
        });
      });
    }
  }

  async endTaskCycle() {
    if (this.conditionFulfilled) {
      const event = new TaskEvent().deserialize({ event_type: TaskEventTypeEnum.EndCycle });
      await this.citadelCoreService.simulateTaskEvent(this, event);
      await asyncForEach(this.in_resources, async (resourceForTask: ResourceForTask) => {
        const selfInResourceInstance = this.resourceInstances.find(resInst => resInst.resource_id === resourceForTask.resource_id);
        if (selfInResourceInstance) {
          selfInResourceInstance.quantity -= resourceForTask.quantity;
          await this.citadelCoreService.updateResourceInstance(selfInResourceInstance);
        }
        else {
          console.log('This should not happen');
        }
      });
      await asyncForEach(this.out_resources, async (resourceForTask: ResourceForTask) => {
        await asyncForEach(resourceForTask.outLinks, async (resourceLink: ResourceLink) => {
          if (resourceLink.destination ? resourceLink.destination.typeName === "CitadelStock" : false) {
            const destinationResourceInstance = resourceLink.destination.resourceInstances.find(resInst => resInst.resource_id === resourceForTask.resource_id);
            if (destinationResourceInstance) {
              destinationResourceInstance.quantity += resourceForTask.quantity;
              await this.citadelCoreService.updateResourceInstance(destinationResourceInstance);
            }
            else {
              const newResInstance = new ResourceInstance(this.citadelCoreService).deserialize({ resource_id: resourceForTask.resource_id, stock_id: resourceLink.destination.id, quantity: resourceForTask.quantity });
              await this.citadelCoreService.createResourceInstance(newResInstance);
            }
          }
          else {
            const selfOutResourceInstance = this.resourceInstances.find(resInst => resInst.resource_id === resourceForTask.resource_id);
            if (selfOutResourceInstance) {
              selfOutResourceInstance.quantity += resourceForTask.quantity;
              await this.citadelCoreService.updateResourceInstance(selfOutResourceInstance);
            }
            else {
              const newResInstance = new ResourceInstance(this.citadelCoreService).deserialize({ resource_id: resourceForTask.resource_id, stock_id: this.id, quantity: resourceForTask.quantity });
              await this.citadelCoreService.createResourceInstance(newResInstance);
            }
          }
        })
      });
    }
  } */
}