import { Deserializable } from '@sparte/utils';
import { CitadelCoreService } from '../services/citadel-core.service';
import { NodeData } from '../utils/citadel-core.interface';
import { AssetZone } from './assetZone.model';
import { CitadelStock } from './citadelStock.model';
import { CitadelAsset } from './citadelAsset.model';


export class CitadelStorage implements Deserializable {
  id: string;
  stocks_ids: string[] = [];
  delivery: boolean;
  parent_zone_id: string;
  designation: string;
  tag: string;
  barcode: string;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
  typeName = 'Storage';
  visibility: boolean = false;
  protected nodeData: NodeData;

  constructor(private citadelCoreService: CitadelCoreService) { }

  deserialize(input: any): this {
    const { __typename, storage_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = storage_id;
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);
    if (!this.stocks_ids) this.stocks_ids = [];
    return this;
  }

  get whereUniqueValue(): any {
    return {
      storage_id: this.id
    };
  }

  get createValue(): any {
    const {
      id,
      typeName,
      citadelCoreService,
      created_at,
      updated_at,
      nodeData,
      visibility,
      ..._this
    } = this;
    return {
      ..._this
    };
  }

  get updateValue(): any {
    const {
      id,
      typeName,
      citadelCoreService,
      created_at,
      updated_at,
      nodeData,
      visibility,
      ..._this
    } = this;
    return {
      ..._this
    };
  }

  get updateStocksValue(): any {
    return {
      storage_id: this.id,
      stocks_ids: this.stocks_ids
    }
  }

  get parent_asset_id(): string {
    return this.parent?.parent_asset_id;
  }

  get type(): string {
    return 'Storage';
  }

  get parent(): AssetZone {
    return this.citadelCoreService.getAssetZone(this.parent_zone_id);
  }

  get label(): string {
    return this.designation;
  }

  get fullLabel(): string {
    return `${this.fullTag} - ${this.designation}`;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.fullTag} ${this.barcode}`.toLowerCase();
  }

  get stocks(): CitadelStock[] {
    return this.stocks_ids.map(stock_id => this.citadelCoreService.getCitadelStock(stock_id)) || [];
  }

  get prefix(): string {
    return "B";
  }

  get parentAssetIds(): string[] {
    if (this.parent_zone_id) {
      return this.citadelCoreService.getAssetZone(this.parent_zone_id).parentAssetIds;
    }
    else if (this.parent_asset_id) return this.citadelCoreService.getCitadelAsset(this.parent_asset_id).parentAssetIds.concat(this.parent_asset_id)
    else return [];
  }

  get parentZoneIds(): string[] {
    if (!this.parent) return [];
    return this.parent.parentZoneIds.concat(this.parent_zone_id);
  }

  get fullSortOrder(): string {
    if (!this.parent) return this.sort_order?.toString().padStart(3, "0") || '000';
    return `${this.parent.fullSortOrder}${this.sort_order?.toString().padStart(3, "0") || '000'}`;
  }

  get fullTag(): string {
    if (!this.parent) return this.tag;
    return `${this.parent.fullTag}${this.tag}`;
  }

  get directChildren(): (CitadelAsset | AssetZone | CitadelStorage)[] {
    return [];
  }
}