import { gql } from "apollo-angular";

export const DASHBOARD_STATS_QUERY = gql`
  query dashboardStats {
    dashboardStats
  }
`;

export const RUNNER_STATS_QUERY = gql`
  query runnerStats {
    runnerStats
  }
`;