import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CitadelCoreApiService } from './services/citadel-core-api.service';
import { UserApiService } from './services/user-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    CitadelCoreApiService,
    UserApiService
  ],
})
export class CitadelCoreModule { }
