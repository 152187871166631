import { gql } from 'apollo-angular';

export const RESOURCE_LINK_INFO_FRAGMENT = gql`
  fragment ResourceLinkInfo on ResourceLink {
    link_id
    task_id
    resource_id
    link_from
    link_to
    created_at
    updated_at
  }
`;
