import { gql } from 'apollo-angular';

import { ASSET_CATEGORY_INFO_FRAGMENT } from './fragments/_assetCategory';

export const ASSET_CATEGORIES_QUERY = gql`
  query assetCategories {
    assetCategories
  }
`;

export const CREATE_ASSET_CATEGORY_MUTATION = gql`
  mutation createAssetCategory($data: AssetCategoryCreateInput!) {
    createAssetCategory(data: $data) {
      ...AssetCategoryInfo
    }
  }
  ${ASSET_CATEGORY_INFO_FRAGMENT}
`;

export const UPDATE_ASSET_CATEGORY_MUTATION = gql`
  mutation updateAssetCategory(
    $where: AssetCategoryWhereUniqueInput!
    $data: AssetCategoryUpdateInput!
  ) {
    updateAssetCategory(where: $where, data: $data) {
      ...AssetCategoryInfo
    }
  }
  ${ASSET_CATEGORY_INFO_FRAGMENT}
`;

export const DELETE_ASSET_CATEGORY_MUTATION = gql`
  mutation deleteAssetCategory($where: AssetCategoryWhereUniqueInput!) {
    deleteAssetCategory(where: $where) {
      category_id
    }
  }
`;

export const ASSET_CATEGORY_SUBSCRIPTION = gql`
  subscription assetCategorySubscription {
    assetCategorySubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
