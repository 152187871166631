
import { gql } from 'apollo-angular';

export const CARTO_RESOURCE_INFO_FRAGMENT = gql`
  fragment CartoResourceInfo on ProcessResource {
    resource_id
    label
    output{
      id
      label
      type
    }
    location
  }
`;
