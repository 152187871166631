import { StockInventoryService } from '../services/stock-inventory.service';
import { Deserializable } from '@sparte/utils';

export class StockNetwork implements Deserializable {
  public id: string;
  public designation: string;
  public from_sap: boolean;
  private created_at: Date;
  private updated_at: Date;
  public readonly typeName = "StockNetwork";

  constructor(private stockInventoryService: StockInventoryService) { }

  deserialize(input: any): this {
    const { __typename, created_at, updated_at, network_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = network_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    return this;
  }

  get whereUniqueValue() {
    return {
      network_id: this.id
    }
  }

  get createValue() {
    const {
      id,
      typeName,
      from_sap,
      created_at,
      updated_at,
      stockInventoryService,
      ..._this
    } = this;
    return _this;
  }

  get updateValue() {
    const {
      id,
      typeName,
      from_sap,
      created_at,
      updated_at,
      stockInventoryService,
      ..._this
    } = this;
    return _this;
  }

  get label(): string {
    return this.designation;
  }

  get default(): boolean {
    return this.designation === 'SANS RESEAU';
  }

  get searchTerm(): string {
    return this.designation?.toLowerCase() || '';
  }
}