import { Subscription } from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService, FileService, SparteFile } from '@sparte/api';
import { SubscriptionEvent } from '@sparte/utils';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';

import { CitadelPermission } from '../models/citadelPermission.model';
import { CitadelUser } from '../models/citadelUser.model';
import { Role } from '../models/role.model';
import { UserGroup } from '../models/userGroup.model';
import { UserNode } from './../models/userNode.model';
import { UserApiService } from './user-api.service';
import { AvatarByUser } from '../utils/citadel-core.interface';

@Injectable()
export abstract class UserService {
  public subscriptionRefresh: EventEmitter<SubscriptionEvent>;
  public companyChange: EventEmitter<UserGroup>;
  @observable public storeInitialized: boolean = false;
  protected citadelUsers: Map<string, CitadelUser>;
  protected roles: Map<string, Role>;
  protected userGroups: Map<string, UserGroup>;
  protected userNodes: Map<string, UserNode>;
  protected citadelPermissions: Map<string, CitadelPermission>;
  public currentCompanyId: string;
  public updateCompanyData: Function;
  protected userNodeSub: Subscription;

  constructor(
    protected userApi: UserApiService,
    protected fileService: FileService,
    protected api: ApiService
  ) {
    makeObservable(this);
    this.companyChange = new EventEmitter();
    this.subscriptionRefresh = new EventEmitter();
    this.citadelUsers = new Map<string, CitadelUser>();
    this.roles = new Map<string, Role>();
    this.userGroups = new Map<string, UserGroup>();
    this.userNodes = new Map<string, UserNode>();
    this.citadelPermissions = new Map<string, CitadelPermission>();
  }

  @action protected setStoreInitialized() {
    this.storeInitialized = true;
  }

  get currentCompany(): UserGroup {
    return this.userGroups.get(this.currentCompanyId);
  }

  public getUserNode(node_id): UserNode {
    return this.userNodes.get(node_id);
  }

  public get getUserNodes(): UserNode[] {
    return [...this.userNodes.values()];
  }

  public get getCitadelUsers(): CitadelUser[] {
    return [...this.citadelUsers.values()];
  }

  public getCitadelUser(user_id): CitadelUser {
    return this.citadelUsers.get(user_id);
  }

  public get getUserGroups(): UserGroup[] {
    return [...this.userGroups.values()];
  }

  public getUserGroup(group_id): UserGroup {
    return this.userGroups.get(group_id);
  }

  public get getRoles(): Role[] {
    return [...this.roles.values()];
  }

  public getRole(role_id): Role {
    return this.roles.get(role_id);
  }

  public get currentUserId(): string {
    return this.currentUser?.id;
  }

  public get currentUser(): CitadelUser {
    if (this.api?.currentUser) return this.getCitadelUser(this.api.currentUser.id);
  }

  public get getCitadelPermissions(): CitadelPermission[] {
    return [...this.citadelPermissions.values()]
  }

  public getCitadelPermission(permission_id: string): CitadelPermission {
    return this.citadelPermissions.get(permission_id);
  }

  public getFile = async (file_id: string): Promise<SparteFile> => {
    return this.fileService.getFile(file_id, false);
  }

  public getCategoryPermissions(category: string): CitadelPermission[] {
    return this.getCitadelPermissions.filter(permission => permission.category === category).sort((permA, permB) => {
      return permA.zorder > permB.zorder ? 1 : -1;
    })
  }

  public getUserName = (user_id: string): string => {
    if (user_id) return this.getCitadelUser(user_id)?.label;
    return "Utilisateur invalide";
  }

  public getUsersAvatars (mail_in : string[]): Promise<AvatarByUser[]> {
    return new Promise<AvatarByUser[]> ((resolve, reject) => {
      this.userApi.getUsersAvatars(mail_in).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  public checkUserPin(mail: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.userApi.checkUserPin(mail).subscribe({
        next: resolve,
        error: reject
      })
    })
  }


  /**
   * add user to store
   * @param {any} citadelUser user to add
   * @param {function} callback function to call when it is done
   */
  protected addCitadelUser(citadelUser: any, callback?: Function) {
    if (!this.citadelUsers.has(citadelUser.user_id)) {
      this.citadelUsers.set(citadelUser.user_id, new CitadelUser(this).deserialize(citadelUser));
    }
    else {
      this.citadelUsers.get(citadelUser.user_id).deserialize(citadelUser);
    }
    if (callback) callback(this.citadelUsers.get(citadelUser.user_id));
  }

  /**
   * remove citadel user from map
   * @param {string} userId user id
   * @param {function} callback function to call when it is done
   */
  protected removeCitadelUser(userId, callback?: Function): void {
    this.citadelUsers.delete(userId);
    if (callback) callback('deleted');
  }

  protected async fetchCitadelUsers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userApi.getCitadelUsers().subscribe({
        next: citadelUsers => {
          citadelUsers.forEach(citadelUser => {
            this.addCitadelUser(citadelUser);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create citadelUser in database
   * @param {any} newUser user to create
   * @returns {Promise<CitadelUser>} created CitadelUser
   */
  public async createCitadelUser(newUser: any): Promise<CitadelUser> {
    return new Promise<CitadelUser>((resolve, reject) => {
      this.userApi.createCitadelUser(newUser).subscribe({
        next: citadelUserRes => {
          this.addCitadelUser(citadelUserRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update citadelUser in database
   * @param {CitadelUser} user user to update
   * @returns {Promise<CitadelUser>} updated CitadelUser
   */
  public async updateCitadelUser(user: CitadelUser): Promise<CitadelUser> {
    return new Promise<CitadelUser>((resolve, reject) => {
      this.userApi.updateCitadelUser(user).subscribe({
        next: citadelUserRes => {
          this.addCitadelUser(citadelUserRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update self avatar
   * @param {string} avatarId: id of file for avatar
   * @returns  {Promise<CitadelUser>} updated citadel user
   */
  public async updateSelfAvatar(avatarId: string): Promise<CitadelUser> {
    return new Promise<CitadelUser>((resolve, reject) => {
      this.userApi.updateSelfAvatar(avatarId).subscribe({
        next: citadelUserRes => {
          this.addCitadelUser(citadelUserRes, resolve);
        },
        error: reject
      });
    });
  }

  public archiveCitadelUser(citadelUserId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userApi.archiveCitadelUser(citadelUserId).subscribe({
        next: citadelUserRes => {
          this.removeCitadelUser(citadelUserRes, resolve);
        },
        error: reject
      });
    });
  }

  /* deleteCitadelUser(citadelUserId: string, callback?: Function) {
    if (citadelUserId) {
      this.userApi.deleteCitadelUser(citadelUserId).subscribe(citadelUserIdRes => {
        this.removeCitadelUser(citadelUserIdRes);
        if (callback) callback('deleted');
      });
    }
  } */

  protected addUserNode(userNode: any, callback?: Function) {
    if (!userNode) return;
    if (!this.userNodes.has(userNode.node_id)) {
      this.userNodes.set(userNode.node_id, new UserNode().deserialize(userNode));
    }
    else {
      this.userNodes.get(userNode.node_id).deserialize(userNode);
    }
    if (callback) callback(this.userNodes.get(userNode.node_id));
  }

  /**
   * remove user node from map
   * @param {string} nodeId node id
   * @param {function} callback function to call when it is done
   */
  protected removeUserNode(nodeId, callback?: Function): void {
    this.userNodes.delete(nodeId);
    if (callback) callback('deleted');
  }

  /**
   * add user to store
   * @param {any} userGroup user to add
   * @param {function} callback function to call when it is done
   */
  protected addUserGroup(userGroup: any, callback?: Function) {
    if (!this.userGroups.has(userGroup.group_id)) {
      this.userGroups.set(userGroup.group_id, new UserGroup(this).deserialize(userGroup));
    }
    else {
      this.userGroups.get(userGroup.group_id).deserialize(userGroup);
    }
    if (callback) callback(this.userGroups.get(userGroup.group_id));
  }

  /**
   * remove user group from map
   * @param {string} groupId group id
   * @param {function} callback function to call when it is done
   */
  protected removeUserGroup(groupId, callback?: Function): void {
    this.userGroups.delete(groupId);
    if (callback) callback('deleted');
  }

  protected async fetchUserGroups(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userApi.getUserGroups().subscribe({
        next: userGroups => {
          userGroups.forEach(userGroup => {
            this.addUserGroup(userGroup);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  /**
   * create userGroup in database
   * @param {any} newGroup user to create
   * @returns {Promise<UserGroup>} created UserGroup
   */
  public async createUserGroup(newGroup: any): Promise<UserGroup> {
    return new Promise<UserGroup>((resolve, reject) => {
      this.userApi.createUserGroup(newGroup).subscribe({
        next: userGroupRes => {
          this.addUserGroup(userGroupRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update userGroup in database
   * @param {UserGroup} group user to update
   * @returns {Promise<UserGroup>} updated UserGroup
   */
  public async updateUserGroup(group: UserGroup): Promise<UserGroup> {
    return new Promise<UserGroup>((resolve, reject) => {
      this.userApi.updateUserGroup(group).subscribe({
        next: userGroupRes => {
          this.addUserGroup(userGroupRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete userGroup in database
   * @param {UserGroup} userGroup user to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteUserGroup(userGroup: UserGroup): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userApi.deleteUserGroup(userGroup.id).subscribe({
        next: userGroupIdRes => {
          this.removeUserGroup(userGroupIdRes, resolve)
        },
        error: reject
      });
    });
  }



  /**
   * add user to store
   * @param {any} role user to add
   * @param {function} callback function to call when it is done
   */
  protected addRole(role: any, callback?: Function) {
    if (!this.roles.has(role.role_id)) {
      this.roles.set(role.role_id, new Role(this).deserialize(role));
    }
    else {
      this.roles.get(role.role_id).deserialize(role);
    }
    if (callback) callback(this.roles.get(role.role_id));
  }

  /**
   * remove citadel user from map
   * @param {string} userId user id
   * @param {function} callback function to call when it is done
   */
  protected removeRole(userId, callback?: Function): void {
    this.roles.delete(userId);
    if (callback) callback('deleted');
  }

  /**
   * create role in database
   * @param {any} newUser user to create
   * @returns {Promise<Role>} created Role
   */
  public async createRole(newUser: any): Promise<Role> {
    return new Promise<Role>((resolve, reject) => {
      this.userApi.createRole(newUser).subscribe({
        next: roleRes => {
          this.addRole(roleRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * update role in database
   * @param {Role} role role to update
   * @returns {Promise<Role>} updated Role
   */
  public async updateRole(role: Role): Promise<Role> {
    return new Promise<Role>((resolve, reject) => {
      this.userApi.updateRole(role).subscribe({
        next: roleRes => {
          this.addRole(roleRes, resolve);
        },
        error: reject
      });
    });
  }

  /**
   * delete role in database
   * @param {Role} role user to delete
   * @returns {Promise<string>} returns 'deleted' when function ended
   */
  public async deleteRole(role: Role): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userApi.deleteRole(role).subscribe({
        next: roleIdRes => {
          this.removeRole(roleIdRes, resolve)
        },
        error: reject
      });
    });
  }

  protected addCitadelPermission(data: any, callback?: Function) {
    if (data) {
      if (this.citadelPermissions.has(data.permission_id)) {
        this.citadelPermissions.get(data.permission_id).deserialize(data);
      }
      else {
        this.citadelPermissions.set(data.permission_id, new CitadelPermission().deserialize(data));
      }
      if (callback) callback(this.citadelPermissions.get(data.permission_id));
    }
  }

  protected async fetchCitadelPermissions(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userApi.getCitadelPermissions().subscribe({
        next: citadelPermissions => {
          citadelPermissions.forEach(citadelPermission => {
            this.addCitadelPermission(citadelPermission);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  protected async fetchRolesForGroups(group_id_in: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userApi.getRoles(group_id_in).subscribe({
        next: rolesRes => {
          rolesRes.forEach(role => {
            this.addRole(role);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  public async fetchUserNodes(groupIds: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userNodes.clear();
      this.userApi.getUserNodes(groupIds).subscribe({
        next: userNodes => {
          userNodes.forEach(userNode => {
            this.addUserNode(userNode);
          });
          resolve();
        },
        error: reject
      });
    });
  }

  public async createUserNode(userNode: UserNode): Promise<UserNode> {
    return new Promise<UserNode>((resolve, reject) => {
      this.userApi.createUserNode(userNode).subscribe({
        next: userNodeRes => {
          this.addUserNode(userNodeRes, resolve);
        },
        error: reject
      });
    });
  }

  public async updateUserNode(userNode: UserNode): Promise<UserNode> {
    return new Promise<UserNode>((resolve, reject) => {
      this.userApi.updateUserNode(userNode).subscribe({
        next: userNodeRes => {
          this.addUserNode(userNodeRes, resolve);
        },
        error: reject
      });
    });
  }

  public setCurrentCompany(companyId?: string, callback?: Function) {
    if (companyId && companyId !== this.currentCompanyId) {
      if (this.userGroups.has(companyId)) {
        this.currentCompanyId = companyId;
        this.companyChange.emit(this.currentCompany);
        if (callback) callback();
      }
    }
    else {
      if (!companyId) {
        this.currentCompanyId = null;
      }
      this.companyChange.emit(this.currentCompany);
      if (callback) callback();
    }
  }

  public updateUserNodeSubscription(groups_ids: string[]) {
    this.userNodeSub?.unsubscribe();
    this.userNodeSub = this.userApi.userNodeSubscription(groups_ids).subscribe(data => {
      if (!data) return;
      const { mutationType, objectId, objectJson } = data;
      switch (mutationType) {
        case "CREATED":
        case "UPDATED":
          this.addUserNode(JSON.parse(objectJson));
          break;
        case "DELETED":
          this.removeUserNode(objectId);
          break;
        default:
          console.log('untracked mutation type', data);
          break;
      }
      this.subscriptionRefresh.emit({ objectId, mutationType, objectType: 'UserNode' });
    });
  }
}
