import { gql } from "apollo-angular";

export const TRACK_N_VIEW_SUBSCRIPTION = gql`
  subscription trackNViewSubscription {
    trackNViewSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const GET_ITEMS_STATUS_HISTORY_QUERY = gql`
  query getItemsStatusHistory($where: TrackItemWhereInput!) {
    getItemsStatusHistory(where: $where)
  }
`;

export const OGA_DATA_QUERY = gql`
  query getOGAData {
    getOGAData
  }
`;

export const ERROR_REPORT_SUBSCRIPTION = gql`
  subscription errorReportSubscription {
    errorReportSubscription {
      message
      stack
    }
  }
`;

export const GET_CONWIP_HISTORY_BY_POSTS_QUERY = gql`
  query getConwipHistoryByPosts($where: PostWhereInput!) {
    getConwipHistoryByPosts(where: $where)
  }
`;