import { CartoProcess } from './../../../../../apps/citadel/src/app/modules/production/cartography/pixi-models/carto-process.model';
import { ResourceCatalog } from './../models/resourceCatalog.model';
import { Injectable } from '@angular/core';
import {
  ApiService,
  ASSET_CATEGORIES_QUERY,
  ASSET_CATEGORY_SUBSCRIPTION,
  ASSET_MODELS_QUERY,
  ASSET_MODEL_SUBSCRIPTION,
  ASSET_ZONES_QUERY,
  ASSET_ZONE_SUBSCRIPTION,
  CITADEL_INFRASTRUCTURES_QUERY,
  CITADEL_INFRASTRUCTURE_SUBSCRIPTION,
  CITADEL_ITEMS_QUERY,
  CITADEL_ITEM_SUBSCRIPTION,
  CITADEL_STOCKS_QUERY,
  CITADEL_STOCK_SUBSCRIPTION,
  CITADEL_TASKS_QUERY,
  CITADEL_TASK_SUBSCRIPTION,
  CREATE_ASSET_CATEGORY_MUTATION,
  CREATE_ASSET_MODEL_MUTATION,
  CREATE_ASSET_ZONE_MUTATION,
  CREATE_CITADEL_INFRASTRUCTURE_MUTATION,
  CREATE_CITADEL_ITEM_MUTATION,
  CREATE_CITADEL_STOCK_MUTATION,
  CREATE_CITADEL_TASK_MUTATION,
  CREATE_ITEM_GROUP_MUTATION,
  CREATE_PARAMETER_SET_MUTATION,
  CREATE_RESOURCE_LINK_MUTATION,
  CREATE_RESOURCE_MUTATION,
  CREATE_RESOURCE_BY_STOCK_MUTATION,
  CREATE_STOCK_NODE_MUTATION,
  CREATE_STORAGE_MUTATION,
  DELETE_ASSET_CATEGORY_MUTATION,
  DELETE_ASSET_MODEL_MUTATION,
  DELETE_ASSET_ZONE_MUTATION,
  DELETE_CITADEL_INFRASTRUCTURE_MUTATION,
  DELETE_CITADEL_ITEM_MUTATION,
  DELETE_CITADEL_STOCK_MUTATION,
  DELETE_CITADEL_TASK_MUTATION,
  DELETE_ITEM_GROUP_MUTATION,
  DELETE_PARAMETER_SET_MUTATION,
  DELETE_RESOURCE_LINK_MUTATION,
  DELETE_RESOURCE_MUTATION,
  DELETE_RESOURCE_BY_STOCK_MUTATION,
  DELETE_STOCK_NODE_MUTATION,
  DELETE_STORAGE_MUTATION,
  ITEM_GROUPS_QUERY,
  ITEM_GROUP_SUBSCRIPTION,
  PARAMETER_SETS_QUERY,
  PARAMETER_SET_SUBSCRIPTION,
  RESOURCES_QUERY,
  RESOURCES_BY_STOCK_QUERY,
  RESOURCE_LINKS_QUERY,
  RESOURCE_LINK_SUBSCRIPTION,
  RESOURCE_SUBSCRIPTION,
  RESOURCE_BY_STOCK_SUBSCRIPTION,
  SIMULATE_TASK_EVENT_MUTATION,
  STOCK_NODES_QUERY,
  STOCK_NODE_SUBSCRIPTION,
  STORAGES_QUERY,
  STORAGE_SUBSCRIPTION,
  UPDATE_ASSET_CATEGORY_MUTATION,
  UPDATE_ASSET_MODEL_MUTATION,
  UPDATE_ASSET_ZONE_MUTATION,
  UPDATE_CITADEL_INFRASTRUCTURE_MUTATION,
  UPDATE_CITADEL_ITEM_MUTATION,
  UPDATE_CITADEL_STOCK_MUTATION,
  UPDATE_CITADEL_TASK_MUTATION,
  UPDATE_ITEM_GROUP_MUTATION,
  UPDATE_PARAMETER_SET_MUTATION,
  UPDATE_RESOURCE_MUTATION,
  UPDATE_RESOURCE_BY_STOCK_MUTATION,
  UPDATE_STOCK_NODE_MUTATION,
  UPDATE_STORAGE_MUTATION,
  UPDATE_STORAGE_STOCK_IDS_MUTATION,
  UPDATE_RESOURCE_CATALOG_MUTATION,
  CREATE_RESOURCE_CATALOG_MUTATION,
  DELETE_RESOURCE_CATALOG_MUTATION,
  RESOURCE_CATALOG_SUBSCRIPTION,
  RESOURCE_CATALOGS_QUERY,
  UPDATE_RESOURCE_LIBRARY_MUTATION,
  CREATE_RESOURCE_LIBRARY_MUTATION,
  DELETE_RESOURCE_LIBRARY_MUTATION,
  RESOURCE_LIBRARY_SUBSCRIPTION,
  RESOURCE_LIBRARIES_QUERY,
  RESOURCE_QUANTITY_BY_STOCK_QUERY,
  RESOURCE_QUANTITY_BY_STOCK_SUBSCRIPTION,
  CHANGE_RESOURCES_CATALOG_MUTATION,
  RESOURCE_ORDERED_QUANTITY_BY_STOCK_SUBSCRIPTION,
  IMPORT_CONFIGS_FOR_DATASET_QUERY,
  CREATE_IMPORT_CONFIG_MUTATION,
  UPDATE_IMPORT_CONFIG_MUTATION,
  DELETE_IMPORT_CONFIG_MUTATION
} from '@sparte/api';
import { Point } from 'electron';
import { makeObservable, when } from 'mobx';
import { action, observable } from 'mobx-angular';
import { map, Observable } from 'rxjs';
import { ImportConfig, CitadelStorage } from '../models';
import { AssetCategory } from '../models/assetCategory.model';
import { AssetModel } from '../models/assetModel.model';
import { AssetZone } from '../models/assetZone.model';
import { CitadelInfrastructure } from '../models/citadelInfrastructure.model';
import { CitadelItem } from '../models/citadelItem.model';
import { CitadelStock } from '../models/citadelStock.model';
import { CitadelTask } from '../models/citadelTask.model';
import { ItemGroup } from '../models/itemGroup.model';
import { ParameterSet } from '../models/parameterSet.model';
import { Resource } from '../models/resource.model';
import { ResourceLink } from '../models/resourceLink.model';
import { StockNode } from '../models/stockNode.model';
import { TaskEvent } from '../models/taskEvent.model';
import { ResourceByStock } from '../models/resourceByStock.model';
import { ResourceLibrary } from '../models/resourceLibrary.model';

@Injectable({
  providedIn: 'root'
})
export class CitadelCoreApiService {
  @observable public apiReady: boolean = false;
  constructor(private api: ApiService) {
    makeObservable(this);
    when(() => this.api.apolloReady, () => {
      this.setApiReady();
    });
  }

  @action private setApiReady = () => {
    this.apiReady = true;
  }

  // CITADEL INFRASTRUCTURES

  citadelInfrastructureSubscription(): Observable<any> {
    return this.api.subscription(CITADEL_INFRASTRUCTURE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['citadelInfrastructureSubscription'];
        })
      )
  }

  /**
   * récupérer les citadelInfrastructures
   * @returns {Observable} array de citadelInfrastructures
   */
  getCitadelInfrastructures(): Observable<any[]> {
    return this.api.watchQuery(CITADEL_INFRASTRUCTURES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['citadelInfrastructures']);
      })
    );
  }

  /**
   * Create citadelInfrastructure
   * @param citadelInfrastructure: data du citadelInfrastructure à créer
   * @returns {Observable} citadelInfrastructure créé
   */
  createCitadelInfrastructure(citadelInfrastructure: CitadelInfrastructure): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_INFRASTRUCTURE_MUTATION, {
        data: citadelInfrastructure.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCitadelInfrastructure']
        })
      );
  }

  /**
   * Update citadelInfrastructure
   * @param citadelInfrastructure: citadelInfrastructure à modifier
   * @returns {Observable} citadelInfrastructure modifié
   */
  updateCitadelInfrastructure(citadelInfrastructure: CitadelInfrastructure): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_INFRASTRUCTURE_MUTATION, {
        where: citadelInfrastructure.whereUniqueValue,
        data: citadelInfrastructure.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelInfrastructure'];
        })
      );
  }

  /**
   * Delete citadelInfrastructure
   * @param infrastructure_id: id du citadelInfrastructure à supprimer
   * @returns {Observable} citadelInfrastructure supprimé
   */
  deleteCitadelInfrastructure(citadelInfrastructure: CitadelInfrastructure): Observable<string> {
    return this.api
      .mutation(DELETE_CITADEL_INFRASTRUCTURE_MUTATION, { where: citadelInfrastructure.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteCitadelInfrastructure'].asset_id
        })
      );
  }

  // CITADEL ITEMS

  /**
   * Items Subscription
   * @returns {Observable} citadelItemSubscription
   */
  citadelItemSubscription(): Observable<any> {
    return this.api.subscription(CITADEL_ITEM_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['citadelItemSubscription'];
        })
      )
  }

  /**
   * récupérer les citadelItems
   * @returns {Observable} array de citadelItems
   */
  getCitadelItems(): Observable<any[]> {
    return this.api.watchQuery(CITADEL_ITEMS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['citadelItems']);
      })
    );
  }

  /**
   * Create citadelItem
   * @param citadelItem: data du citadelItem à créer
   * @returns {Observable} citadelItem créé
   */
  createCitadelItem(citadelItem: CitadelItem): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_ITEM_MUTATION, {
        data: citadelItem.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCitadelItem']
        })
      );
  }

  /**
   * Update citadelItem
   * @param citadelItem: citadelItem à modifier
   * @returns {Observable} citadelItem modifié
   */
  updateCitadelItem(citadelItem: CitadelItem): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_ITEM_MUTATION, {
        where: citadelItem.whereUniqueValue,
        data: citadelItem.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelItem'];
        })
      );
  }

  /**
   * Delete citadelItem
   * @param item_id: id du citadelItem à supprimer
   * @returns {Observable} citadelItem supprimé
   */
  deleteCitadelItem(citadelItem: CitadelItem): Observable<string> {
    return this.api
      .mutation(DELETE_CITADEL_ITEM_MUTATION, { where: citadelItem.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteCitadelItem'].asset_id
        })
      );
  }

  // ITEM GROUPS

  /**
   * Item Groups Subscription
   * @returns {Observable} citadelItemGroupSubscription
   */
  itemGroupSubscription(): Observable<any> {
    return this.api.subscription(ITEM_GROUP_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          if (result ? result.data : false) {
            return result.data['itemGroupSubscription'];
          }
          else return;
        })
      )
  }

  /**
   * Fetch Item Groups from database
   * @returns {Observable} array of itemGroups
   */
  getItemGroups(): Observable<any[]> {
    return this.api.watchQuery(ITEM_GROUPS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['itemGroups']);
      })
    );
  }

  /**
   * Create an itemGroup
   * @param itemGroup: itemGroup to create
   * @returns {Observable} created itemGroup
   */
  createItemGroup(itemGroup: ItemGroup): Observable<any> {
    return this.api.mutation(CREATE_ITEM_GROUP_MUTATION, {
      data: itemGroup.createValue
    }).pipe(
      map((result: any) => {
        return result.data['createItemGroup']
      })
    );
  }

  /**
   * Update an itemGroup
   * @param itemGroup: itemGroup to update
   * @returns {Observable} updated itemGroup
   */
  updateItemGroup(itemGroup: ItemGroup): Observable<any> {
    return this.api.mutation(UPDATE_ITEM_GROUP_MUTATION, {
      where: itemGroup.whereUniqueValue,
      data: itemGroup.updateValue
    }).pipe(
      map((result: any) => {
        return result.data['updateItemGroup'];
      })
    );
  }

  /**
   * Delete an itemGroup
   * @param itemGroup: itemGroup to delete
   * @returns {Observable} deleted itemGroup id
   */
  deleteItemGroup(itemGroup: ItemGroup): Observable<string> {
    return this.api.mutation(DELETE_ITEM_GROUP_MUTATION, { where: itemGroup.whereUniqueValue }).pipe(
      map((result: any) => {
        return result.data['deleteItemGroup'].group_id
      })
    );
  }


  // CITADEL STOCKS

  citadelStockSubscription(): Observable<any> {
    return this.api.subscription(CITADEL_STOCK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['citadelStockSubscription'];
        })
      )
  }

  /**
   * récupérer les citadelStocks
   * @returns {Observable} array de citadelStocks
   */
  getCitadelStocks(): Observable<any[]> {
    return this.api.watchQuery(CITADEL_STOCKS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['citadelStocks']);
      })
    );
  }

  /**
   * Create citadelStock
   * @param citadelStock: data du citadelStock à créer
   * @returns {Observable} citadelStock créé
   */
  createCitadelStock(citadelStock: CitadelStock): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_STOCK_MUTATION, {
        data: citadelStock.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCitadelStock']
        })
      );
  }

  /**
   * Update citadelStock
   * @param citadelStock: citadelStock à modifier
   * @returns {Observable} citadelStock modifié
   */
  updateCitadelStock(citadelStock: CitadelStock): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_STOCK_MUTATION, {
        where: citadelStock.whereUniqueValue,
        data: citadelStock.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelStock'];
        })
      );
  }

  /**
   * Delete citadelStock
   * @param stock_id: id du citadelStock à supprimer
   * @returns {Observable} citadelStock supprimé
   */
  deleteCitadelStock(citadelStock: CitadelStock): Observable<string> {
    return this.api
      .mutation(DELETE_CITADEL_STOCK_MUTATION, { where: citadelStock.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteCitadelStock'].stock_id
        })
      );
  }

  // ASSET CATEGORIES

  assetCategorysubscription(): Observable<any> {
    return this.api.subscription(ASSET_CATEGORY_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['assetCategorySubscription'];
        })
      )
  }

  /**
   * récupérer les assetCategories
   * @returns {Observable} array de assetCategories
   */
  getAssetCategories(): Observable<any[]> {
    return this.api.watchQuery(ASSET_CATEGORIES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['assetCategories']);
      })
    );
  }

  /**
   * Create assetCategory
   * @param assetCategory: data du assetCategory à créer
   * @returns {Observable} assetCategory créé
   */
  createAssetCategory(assetCategory: AssetCategory): Observable<any> {
    return this.api
      .mutation(CREATE_ASSET_CATEGORY_MUTATION, {
        data: assetCategory.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createAssetCategory']
        })
      );
  }

  /**
   * Update assetCategory
   * @param assetCategory: assetCategory à modifier
   * @returns {Observable} assetCategory modifié
   */
  updateAssetCategory(assetCategory: AssetCategory): Observable<any> {
    return this.api
      .mutation(UPDATE_ASSET_CATEGORY_MUTATION, {
        where: assetCategory.whereUniqueValue,
        data: assetCategory.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateAssetCategory'];
        })
      );
  }

  /**
   * Delete assetCategory
   * @param category_id: id du assetCategory à supprimer
   * @returns {Observable} assetCategory supprimé
   */
  deleteAssetCategory(assetCategory: AssetCategory): Observable<string> {
    return this.api
      .mutation(DELETE_ASSET_CATEGORY_MUTATION, { where: assetCategory.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteAssetCategory'].category_id
        })
      );
  }

  // ASSET MODELS

  assetModelSubscription(): Observable<any> {
    return this.api.subscription(ASSET_MODEL_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['assetModelSubscription'];
        })
      )
  }

  /**
   * récupérer les assetModels
   * @returns {Observable} array de assetModels
   */
  getAssetModels(): Observable<any[]> {
    return this.api.watchQuery(ASSET_MODELS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['assetModels']);
      })
    );
  }

  /**
   * Create assetModel
   * @param assetModel: data du assetModel à créer
   * @returns {Observable} assetModel créé
   */
  createAssetModel(assetModel: AssetModel): Observable<any> {
    return this.api
      .mutation(CREATE_ASSET_MODEL_MUTATION, {
        data: assetModel.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createAssetModel']
        })
      );
  }

  /**
   * Update assetModel
   * @param assetModel: assetModel à modifier
   * @returns {Observable} assetModel modifié
   */
  updateAssetModel(assetModel: AssetModel): Observable<any> {
    return this.api
      .mutation(UPDATE_ASSET_MODEL_MUTATION, {
        where: assetModel.whereUniqueValue,
        data: assetModel.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateAssetModel'];
        })
      );
  }

  /**
   * Delete assetModel
   * @param model_id: id du assetModel à supprimer
   * @returns {Observable} assetModel supprimé
   */
  deleteAssetModel(assetModel: AssetModel): Observable<string> {
    return this.api
      .mutation(DELETE_ASSET_MODEL_MUTATION, { where: assetModel.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteAssetModel'].model_id
        })
      );
  }

  // PARAMETER SETS

  parameterSetSubscription(): Observable<any> {
    return this.api.subscription(PARAMETER_SET_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['parameterSetSubscription'];
        })
      )
  }

  /**
   * récupérer les parameterSets
   * @returns Promise : array de parameterSets
   */
  getParameterSets(): Observable<any> {
    return this.api.watchQuery(PARAMETER_SETS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['parameterSets']);
      })
    );
  }

  /**
   * Create parameterSet
   * @param parameterSet data du parameterSet à créer
   * @returns Promise : parameterSet créé
   */
  createParameterSet(parameterSet: any): Observable<any> {
    const { id, variableModels, ..._parameterSet } = parameterSet;
    let variableModelsCreate = [];
    if (variableModels) {
      variableModelsCreate = variableModels.map(varMod => varMod.updateValue);
    }
    return this.api
      .mutation(CREATE_PARAMETER_SET_MUTATION, {
        data: {
          variable_models: variableModelsCreate,
          ..._parameterSet
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['createParameterSet']
        })
      );
  }

  /**
   * Update parameterSet
   * @param parameterSet parameterSet à modifier
   * @returns Promise : parameterSet modifié
   */
  updateParameterSet(parameterSet: ParameterSet): Observable<any> {
    return this.api
      .mutation(UPDATE_PARAMETER_SET_MUTATION, {
        where: {
          set_id: parameterSet.id
        },
        data: parameterSet.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateParameterSet'];
        })
      );
  }

  /**
   * Delete parameterSet
   * @param set_id id du parameterSet à supprimer
   * @returns Promise : parameterSet supprimé
   */
  deleteParameterSet(set_id: string): Observable<string> {
    return this.api
      .mutation(DELETE_PARAMETER_SET_MUTATION, { where: { set_id } }).pipe(
        map((result: any) => {
          return result.data['deleteParameterSet'].set_id
        })
      );
  }

  // ASSET ZONES

  assetZoneSubscription(): Observable<any> {
    return this.api.subscription(ASSET_ZONE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['assetZoneSubscription'];
        })
      )
  }

  /**
   * récupérer les assetZones
   * @returns {Observable} array de assetZones
   */
  getAssetZones(): Observable<any[]> {
    return this.api.watchQuery(ASSET_ZONES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['assetZones']);
      })
    );
  }

  /**
   * Create assetZone
   * @param assetZone: data du assetZone à créer
   * @returns {Observable} assetZone créé
   */
  createAssetZone(assetZone: AssetZone): Observable<any> {
    return this.api
      .mutation(CREATE_ASSET_ZONE_MUTATION, {
        data: assetZone.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createAssetZone']
        })
      );
  }

  /**
   * Update assetZone
   * @param assetZone: assetZone à modifier
   * @returns {Observable} assetZone modifié
   */
  updateAssetZone(assetZone: AssetZone): Observable<any> {
    return this.api
      .mutation(UPDATE_ASSET_ZONE_MUTATION, {
        where: assetZone.whereUniqueValue,
        data: assetZone.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateAssetZone'];
        })
      );
  }

  /**
   * Delete assetZone
   * @param zone_id: id du assetZone à supprimer
   * @returns {Observable} assetZone supprimé
   */
  deleteAssetZone(assetZone: AssetZone): Observable<string> {
    return this.api
      .mutation(DELETE_ASSET_ZONE_MUTATION, { where: assetZone.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteAssetZone'].zone_id
        })
      );
  }

  // NEW CARTO PROCESS

  cartoProcessSubscription(): Observable<any> {
    return this.api.subscription(CITADEL_TASK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['cartoProcessSubscription'];
        })
      )
  }

  /**
   * récupérer les cartoProcesss
   * @returns {Observable} array de cartoProcesss
   */
  getCartoProcesses(): Observable<any[]> {
    return this.api.watchQuery(CITADEL_TASKS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['cartoProcesss']);
      })
    );
  }

  /**
   * Create cartoProcess
   * @param cartoProcess: data du cartoProcess à créer
   * @returns {Observable} cartoProcess créé
   */
  createCartoProcess(cartoProcess: CartoProcess): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_TASK_MUTATION, {
        data: cartoProcess.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCartoProcess']
        })
      );
  }

  /**
   * Update cartoProcess
   * @param cartoProcess: cartoProcess à modifier
   * @returns {Observable} cartoProcess modifié
   */
  updateCartoProcess(cartoProcess: CartoProcess): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_TASK_MUTATION, {
        where: cartoProcess.whereUniqueValue,
        data: cartoProcess.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCartoProcess'];
        })
      );
  }

  /**
   * Mettre à jour la position d'une citadel task
   * @param {CartoProcess} cartoProcess task à mettre à jour
   * @param {Point} position x, y : nouvelle position
   * @returns {Observable} cartoProcess modifié
   */
  updateCartoProcessPosition(cartoProcess: CartoProcess, position: Number[]): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_TASK_MUTATION, {
        where: cartoProcess.whereUniqueValue,
        data: {
          x: position[0],
          y: position[1],
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCartoProcess'];
        })
      );
  }

  /**
   * Delete cartoProcess
   * @param task_id: id du cartoProcess à supprimer
   * @returns {Observable} cartoProcess supprimé
   */
  deleteCartoProcess(cartoProcess: CartoProcess): Observable<string> {
    return this.api
      .mutation(DELETE_CITADEL_TASK_MUTATION, { where: cartoProcess.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteCartoProcess'].process_id
        })
      );
  }



  // CITADEL TASKS

  citadelTaskSubscription(parent_task_id_in: string[]): Observable<any> {
    return this.api.subscription(CITADEL_TASK_SUBSCRIPTION, { where: { parent_task_id_in } })
      .pipe(
        map((result: any) => {
          return result.data['citadelTaskSubscription'];
        })
      )
  }

  /**
   * récupérer les citadelTasks
   * @returns {Observable} array de citadelTasks
   */
  getCitadelTasks(parent_task_id: string): Observable<any[]> {
    return this.api.watchQuery(CITADEL_TASKS_QUERY, { where: { parent_task_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['citadelTasks']);
      })
    );
  }

  /**
   * Create citadelTask
   * @param citadelTask: data du citadelTask à créer
   * @returns {Observable} citadelTask créé
   */
  createCitadelTask(citadelTask: CitadelTask): Observable<any> {
    return this.api
      .mutation(CREATE_CITADEL_TASK_MUTATION, {
        data: citadelTask.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createCitadelTask']
        })
      );
  }

  /**
   * Update citadelTask
   * @param citadelTask: citadelTask à modifier
   * @returns {Observable} citadelTask modifié
   */
  updateCitadelTask(citadelTask: CitadelTask): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_TASK_MUTATION, {
        where: citadelTask.whereUniqueValue,
        data: citadelTask.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelTask'];
        })
      );
  }

  /**
   * simulate task event
   * @param citadelTask : citadelTask à modifier
   * @param event : event
   */
  simulateTaskEvent(citadelTask: CitadelTask, event: TaskEvent): Observable<any> {
    return this.api
      .mutation(SIMULATE_TASK_EVENT_MUTATION, {
        where: citadelTask.whereUniqueValue,
        data: event.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['simulateTaskEvent'];
        })
      );
  }

  /**
   * Mettre à jour la position d'une citadel task
   * @param {CitadelTask} citadelTask task à mettre à jour
   * @param {Point} position x, y : nouvelle position
   * @returns {Observable} citadelTask modifié
   */
  updateCitadelTaskPosition(citadelTask: CitadelTask, position: Point): Observable<any> {
    return this.api
      .mutation(UPDATE_CITADEL_TASK_MUTATION, {
        where: citadelTask.whereUniqueValue,
        data: {
          x: Math.round(position.x),
          y: Math.round(position.y)
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['updateCitadelTask'];
        })
      );
  }

  /**
   * Delete citadelTask
   * @param task_id: id du citadelTask à supprimer
   * @returns {Observable} citadelTask supprimé
   */
  deleteCitadelTask(citadelTask: CitadelTask): Observable<string> {
    return this.api
      .mutation(DELETE_CITADEL_TASK_MUTATION, { where: citadelTask.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteCitadelTask'].task_id
        })
      );
  }

  // STOCK NODES

  stockNodeSubscription(task_id_in: string[]): Observable<any> {
    return this.api.subscription(STOCK_NODE_SUBSCRIPTION, { where: { task_id_in } })
      .pipe(
        map((result: any) => {
          return result.data['stockNodeSubscription'];
        })
      )
  }

  /**
   * récupérer les stockNodes
   * @returns {Observable} array de stockNodes
   */
  getStockNodes(task_id: string): Observable<any[]> {
    return this.api.watchQuery(STOCK_NODES_QUERY, { where: { task_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['stockNodes']);
      })
    );
  }

  /**
   * Create stockNode
   * @param {StockNode} stockNode: stockNode à créer
   * @returns {Observable} stockNode créé
   */
  createStockNode(stockNode: StockNode): Observable<any> {
    return this.api
      .mutation(CREATE_STOCK_NODE_MUTATION, {
        data: stockNode.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStockNode']
        })
      );
  }

  /**
   * Update stockNode
   * @param {StockNode} node: id du stockNode à modifier
   * @returns {Observable} stockNode modifié
   */
  updateStockNode(node: StockNode): Observable<string> {
    return this.api
      .mutation(UPDATE_STOCK_NODE_MUTATION, {
        where: node.whereUniqueValue,
        data: node.updateValue
      }).pipe(
        map((result: any) => {
          return result.data['updateStockNode']
        })
      );
  }

  /**
   * Mettre à jour la position d'une stocknode
   * @param {StockNode} stockNode stock node à mettre à jour
   * @param {Point} position x, y : nouvelle position
   * @returns {Observable} stockNode modifié
   */
  updateStockNodePosition(stockNode: StockNode, position: Point): Observable<any> {
    return this.api
      .mutation(UPDATE_STOCK_NODE_MUTATION, {
        where: stockNode.whereUniqueValue,
        data: {
          x: Math.trunc(position.x),
          y: Math.trunc(position.y)
        }
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStockNode'];
        })
      );
  }

  /**
   * Delete stockNode
   * @param {StockNode} link: id du stockNode à supprimer
   * @returns {Observable} stockNode supprimé
   */
  deleteStockNode(link: StockNode): Observable<string> {
    return this.api
      .mutation(DELETE_STOCK_NODE_MUTATION, { where: link.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteStockNode'].node_id
        })
      );
  }

  // RESOURCE LINKS

  resourceLinkSubscription(task_id_in: string[]): Observable<any> {
    return this.api.subscription(RESOURCE_LINK_SUBSCRIPTION, { where: { task_id_in } })
      .pipe(
        map((result: any) => {
          if (result ? result.data : false) {
            return result.data['resourceLinkSubscription'];
          }
          else return;
        })
      )
  }

  /**
   * récupérer les resourceLinks
   * @returns {Observable} array de resourceLinks
   */
  getResourceLinks(task_id: string): Observable<any[]> {
    return this.api.watchQuery(RESOURCE_LINKS_QUERY, { where: { task_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resourceLinks']);
      })
    );
  }

  /**
   * Create resourceLink
   * @param resourceLink: data du resourceLink à créer
   * @returns {Observable} resourceLink créé
   */
  createResourceLink(resourceLink: ResourceLink): Observable<any> {
    return this.api
      .mutation(CREATE_RESOURCE_LINK_MUTATION, {
        data: resourceLink.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createResourceLink']
        })
      );
  }

  /**
   * Delete resourceLink
   * @param {ResourceLink} link: id du resourceLink à supprimer
   * @returns {Observable} resourceLink supprimé
   */
  deleteResourceLink(link: ResourceLink): Observable<string> {
    return this.api
      .mutation(DELETE_RESOURCE_LINK_MUTATION, { where: link.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteResourceLink'].link_id
        })
      );
  }

  // RESOURCE CATALOGS

  /**
   * Get resource catalogs in real time
   * @returns resource catalog subscription
   */
  resourceCatalogSubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_CATALOG_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceCatalogSubscription'];
        })
      );
  }

  /**
   * Fetch resource catalogs from database
   * @returns resource catalogs array
   */
  getResourceCatalogs(): Observable<any[]> {
    return this.api.watchQuery(RESOURCE_CATALOGS_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resourceCatalogs']);
      })
    );
  }

  /**
   * Create resource catalog
   * @param resourceCatalog: resource catalog created
   * @returns created resource catalog
   */
  createResourceCatalog(resourceCatalog: ResourceCatalog): Observable<any> {
    return this.api.mutation(CREATE_RESOURCE_CATALOG_MUTATION, {
      data: resourceCatalog.createValue
    }).pipe(
      map((result: any) => {
        return result.data['createResourceCatalog']
      })
    );
  }

  /**
   * Update resource catalog
   * @param resourceCatalog: resource catalog updated
   * @returns updated resource catalog
   */
  updateResourceCatalog(resourceCatalog: ResourceCatalog): Observable<any> {
    return this.api.mutation(UPDATE_RESOURCE_CATALOG_MUTATION, {
      where: resourceCatalog.whereUniqueValue,
      data: resourceCatalog.updateValue
    }).pipe(
      map((result: any) => {
        return result.data['updateResourceCatalog']
      })
    );
  }

  /**
   * Delete resource catalog
   * @param resourceCatalog: resource catalog deleted
   * @returns deleted resource catalog
   */
  deleteResourceCatalog(resourceCatalog: ResourceCatalog): Observable<any> {
    return this.api.mutation(DELETE_RESOURCE_CATALOG_MUTATION, {
      where: resourceCatalog.whereUniqueValue
    }).pipe(
      map((result: any) => {
        return result.data['deleteResourceCatalog'].catalog_id;
      })
    );
  }

  // RESOURCE LIBRARIES

  /**
   * Get resource libraries in real time
   * @returns resource catalog subscription
   */
  resourceLibrarySubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_LIBRARY_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceLibrarySubscription'];
        })
      );
  }

  /**
   * Fetch resource libraries from database
   * @returns resource libraries array
   */
  getResourceLibraries(): Observable<any[]> {
    return this.api.watchQuery(RESOURCE_LIBRARIES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resourceLibraries']);
      })
    );
  }

  /**
   * Create resource library
   * @param resourceLibrary: resource library created
   * @returns created resource library
   */
  createResourceLibrary(resourceLibrary: ResourceLibrary): Observable<any> {
    return this.api.mutation(CREATE_RESOURCE_LIBRARY_MUTATION, {
      data: resourceLibrary.createValue
    }).pipe(
      map((result: any) => {
        return result.data['createResourceLibrary']
      })
    );
  }

  /**
   * Update resource library
   * @param resourceLibrary: resource library updated
   * @returns updated resource library
   */
  updateResourceLibrary(resourceLibrary: ResourceLibrary): Observable<any> {
    return this.api.mutation(UPDATE_RESOURCE_LIBRARY_MUTATION, {
      where: resourceLibrary.whereUniqueValue,
      data: resourceLibrary.updateValue
    }).pipe(
      map((result: any) => {
        return result.data['updateResourceLibrary']
      })
    );
  }

  /**
   * Delete resource library
   * @param resourceLibrary: resource library deleted
   * @returns deleted resource library
   */
  deleteResourceLibrary(resourceLibrary: ResourceLibrary): Observable<any> {
    return this.api.mutation(DELETE_RESOURCE_LIBRARY_MUTATION, {
      where: resourceLibrary.whereUniqueValue
    }).pipe(
      map((result: any) => {
        return result.data['deleteResourceLibrary'].library_id;
      })
    );
  }

  // RESOURCES

  /**
   * Get resources in real time
   * @returns resource subscription
   */
  resourceSubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceSubscription'];
        })
      )
  }

  /**
   * Fetch resources
   * @returns {Observable} resources array
   */
  getResources(): Observable<any[]> {
    return this.api.watchQuery(RESOURCES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resources']);
      })
    );
  }

  /**
   * Create resource
   * @param resource: data du resource à créer
   * @returns {Observable} resource créé
   */
  createResource(resource: Resource): Observable<any> {
    return this.api
      .mutation(CREATE_RESOURCE_MUTATION, {
        data: resource.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createResource']
        })
      );
  }

  /**
   * Update resource
   * @param resource: resource à modifier
   * @returns {Observable} resource modifié
   */
  updateResource(resource: Resource): Observable<any> {
    return this.api
      .mutation(UPDATE_RESOURCE_MUTATION, {
        where: resource.whereUniqueValue,
        data: resource.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateResource'];
        })
      );
  }

  /**
   * Change resources catalog
   * @param {string[]} resource_id_in: ids des resources à modifier
   * @param {string} catalog_id: id du nouveau catalog
   * @returns {Observable} resources modifiés
   */

  changeResourcesCatalog(resource_id_in: string[], catalog_id: string): Observable<any[]> {
    return this.api
      .mutation(CHANGE_RESOURCES_CATALOG_MUTATION, {
        where: { resource_id_in },
        data: { catalog_id }
      })
      .pipe(
        map((result: any) => {
          return result.data['changeResourcesCatalog'];
        })
      );
  }

  /**
   * Delete resource
   * @param resource_id: id du resource à supprimer
   * @returns {Observable} resource supprimé
   */
  deleteResource(resource_id: string): Observable<string> {
    return this.api
      .mutation(DELETE_RESOURCE_MUTATION, { where: { resource_id } }).pipe(
        map((result: any) => {
          return result.data['deleteResource'].resource_id
        })
      );
  }


  // STORAGES

  /**
   * Fetch all storages
   * @returns {Observable} array of storages
   */
  getStorages(): Observable<any[]> {
    return this.api.watchQuery(STORAGES_QUERY).pipe(
      map((result: any) => {
        return JSON.parse(result.data['storages']);
      })
    );
  }

  /**
   * Storages subscription
   * @returns {Observable} subscription
   */
  storageSubscription(): Observable<any> {
    return this.api.subscription(STORAGE_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['storageSubscription'];
        })
      )
  }

  /**
   * Create a storage
   * @param storage: storage to create
   * @returns {Observable} created storage
   */
  createStorage(storage: CitadelStorage): Observable<any> {
    return this.api
      .mutation(CREATE_STORAGE_MUTATION, {
        data: storage.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createStorage']
        })
      );
  }

  /**
   * Update a storage
   * @param storage: storage to update
   * @returns {Observable} updated storage
   */
  updateStorage(storage: CitadelStorage): Observable<any> {
    return this.api
      .mutation(UPDATE_STORAGE_MUTATION, {
        where: storage.whereUniqueValue,
        data: storage.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateStorage'];
        })
      );
  }

  /**
   * Update multiple storage stocks ids at once
   * @param {CitadelStorage} storages: storages to update
   * @returns {Observable} updated storages
   */
  updateStorageStockIds(storages: CitadelStorage[]): Observable<any[]> {
    return this.api
      .mutation(UPDATE_STORAGE_STOCK_IDS_MUTATION, { data: storages.map(storage => storage.updateStocksValue) })
      .pipe(
        map((result: any) => {
          return result.data['updateStorageStockIds'];
        })
      );
  }

  /**
   * Delete a storage
   * @param storage_id: id of the storage to delete
   * @returns {Observable} deleted storage
   */
  deleteStorage(storage: CitadelStorage): Observable<string> {
    return this.api
      .mutation(DELETE_STORAGE_MUTATION, { where: storage.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteStorage'].storage_id
        })
      );
  }

  // ResourceByStock

  /**
   * Fetch all resourcesByStock
   * @returns {Observable} array of resourcesByStock
   */
  getResourcesByStock(stock_id: string): Observable<any[]> {
    return this.api.watchQuery(RESOURCES_BY_STOCK_QUERY, { where: { stock_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resourcesByStock']);
      })
    );
  }

  /**
   * ResourceByStocks subscription
   * @returns {Observable} subscription
   */
  resourceByStockSubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_BY_STOCK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceByStockSubscription'];
        })
      )
  }

  /**
   * Create a resourceByStock
   * @param resourceByStock: resourceByStock to create
   * @returns {Observable} created resourceByStock
   */
  createResourceByStock(resourceByStock: ResourceByStock): Observable<any> {
    return this.api
      .mutation(CREATE_RESOURCE_BY_STOCK_MUTATION, {
        data: resourceByStock.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createResourceByStock']
        })
      );
  }

  /**
   * Update a resourceByStock
   * @param resourceByStock: resourceByStock to update
   * @returns {Observable} updated resourceByStock
   */
  updateResourceByStock(resourceByStock: ResourceByStock): Observable<any> {
    return this.api
      .mutation(UPDATE_RESOURCE_BY_STOCK_MUTATION, {
        where: resourceByStock.whereUniqueValue,
        data: resourceByStock.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateResourceByStock'];
        })
      );
  }

  /**
   * Delete a resourceByStock
   * @param resource_id: id of the resourceByStock to delete
   * @returns {Observable} deleted resourceByStock
   */
  deleteResourceByStock(resourceByStock: ResourceByStock): Observable<string> {
    return this.api
      .mutation(DELETE_RESOURCE_BY_STOCK_MUTATION, { where: resourceByStock.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteResourceByStock'].resource_id
        })
      );
  }

  /**
 * récupérer les resourcesQuantityByStock
 * @returns {Observable} array de resourcesQuantityByStock
 */
  getResourceQuantityByStock(stock_id: string): Observable<any[]> {
    return this.api.watchQuery(RESOURCE_QUANTITY_BY_STOCK_QUERY, { where: { stock_id } }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['resourceQuantityByStock']);
      })
    );
  }

  /**
   * Fetch resourcesQuantities by stock in real time
   * @returns {Observable} resourcesQuantities by stock subscription
   */
  resourceQuantityByStockSubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_QUANTITY_BY_STOCK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceQuantityByStockSubscription'];
        })
      )
  }

  /**
   * Fetch resourcesOrderedQuantities by stock in real time
   * @returns {Observable} resourcesOrderedQuantities by stock subscription
   */
  resourceOrderedQuantityByStockSubscription(): Observable<any> {
    return this.api.subscription(RESOURCE_ORDERED_QUANTITY_BY_STOCK_SUBSCRIPTION)
      .pipe(
        map((result: any) => {
          return result.data['resourceOrderedQuantityByStockSubscription'];
        })
      )
  }

  // IMPORT CONFIGS

  /**
   * Fetch all importConfigs from dataset
   * @param {string} dataset: dataset for which to fetch importConfigs
   * @returns {Observable} array of importConfigs
   */
  getImportConfigsForDataset(dataset: string): Observable<any[]> {
    return this.api.watchQuery(IMPORT_CONFIGS_FOR_DATASET_QUERY, { dataset }).pipe(
      map((result: any) => {
        return JSON.parse(result.data['importConfigsForDataset']);
      })
    );
  }

  /**
   * Creates an importConfig
   * @param {ImportConfig} importConfig: importConfig to create
   * @returns {Observable} created importConfig
   */
  createImportConfig(importConfig: ImportConfig): Observable<any> {
    return this.api
      .mutation(CREATE_IMPORT_CONFIG_MUTATION, {
        data: importConfig.createValue
      })
      .pipe(
        map((result: any) => {
          return result.data['createImportConfig']
        })
      );
  }

  /**
   * Updates an importConfig
   * @param {ImportConfig} importConfig: importConfig to update
   * @returns {Observable} updated importConfig
   */
  updateImportConfig(importConfig: ImportConfig): Observable<any> {
    return this.api
      .mutation(UPDATE_IMPORT_CONFIG_MUTATION, {
        where: importConfig.whereUniqueValue,
        data: importConfig.updateValue
      })
      .pipe(
        map((result: any) => {
          return result.data['updateImportConfig'];
        })
      );
  }

  /**
   * Deletes an importConfig
   * @param {ImportConfig} importConfig: importConfig to delete
   * @returns {Observable} deleted importConfig
   */
  deleteImportConfig(importConfig: ImportConfig): Observable<string> {
    return this.api
      .mutation(DELETE_IMPORT_CONFIG_MUTATION, { where: importConfig.whereUniqueValue }).pipe(
        map((result: any) => {
          return result.data['deleteImportConfig'].config_id
        })
      );
  }
}
