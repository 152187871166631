import { gql } from 'apollo-angular';

import { PERSPECTIVE_INFO_FRAGMENT } from './fragments/_perspective';

export const PERSPECTIVES_QUERY = gql`
  query perspectives($where: PerspectivesWhereInput!) {
    perspectives(where: $where)
  }
`;

export const CREATE_PERSPECTIVE_MUTATION = gql`
  mutation createPerspective($data: PerspectiveCreateInput!) {
    createPerspective(data: $data) {
      ...PerspectiveInfo
    }
  }
  ${PERSPECTIVE_INFO_FRAGMENT}
`;

export const DELETE_PERSPECTIVE_MUTATION = gql`
  mutation deletePerspective($where: PerspectiveWhereUniqueInput!) {
    deletePerspective(where: $where) {
      pers_id
    }
  }
`;

export const POST_COMMENT_MUTATION = gql`
  mutation postComment(
    $where: CommentWhereUniqueInput!
    $data: CommentUpdateInput!
  ) {
    postComment(where: $where, data: $data) {
      ...PerspectiveInfo
    }
  }
  ${PERSPECTIVE_INFO_FRAGMENT}
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($where: CommentWhereUniqueInput!) {
    deleteComment(where: $where) {
      ...PerspectiveInfo
    }
  }
  ${PERSPECTIVE_INFO_FRAGMENT}
`;

export const PERSPECTIVE_SUBSCRIPTION = gql`
  subscription perspectiveSubscription($where: ShareFolderWhereInput!) {
    perspectiveSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;