import { getEnv, types, Instance } from 'mobx-state-tree';

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
export const User = types
  .model({
    id: types.optional(types.string, ''),
    name: types.maybeNull(types.optional(types.string, '')),
    email: types.maybeNull(types.optional(types.string, '')),
    permissions: types.maybeNull(types.array(types.string)),
    groups_ids: types.maybeNull(types.array(types.string)),
    companies_ids: types.maybeNull(types.array(types.string)),
    editMode: types.optional(types.boolean, false),
    superAdminMode: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isSuperAdmin(): boolean {
      if (self.permissions) {
        return self.permissions.includes('sparte:admin');
      }
    },
    get canEdit(): boolean {
      if (self.permissions) {
        return (
          (self as any).isSuperAdmin ||
          self.permissions.some(
            (perm) =>
              perm.includes('create') ||
              perm.includes('update') ||
              perm.includes('destroy')
          )
        );
      }
    },
    get getName(): string {
      return self.name;
    },
    hasPermission(permission: string, checkAdmin = true): boolean {
      if (!permission) return false;
      if (self.permissions) {
        return (
          ((self as any).isSuperAdmin && checkAdmin) || self.permissions.includes(permission)
        );
      }
    },
  }))
  .actions((self) => ({
    set(data) {
      const { ..._data } = data;
      Object.assign(self, _data);
    },
    setPermissions(permissions) {
      self.permissions = permissions;
    },
  }));

export type IUser = Instance<typeof User>;

const RootStore = types
  .model({
    storeUser: types.maybeNull(User),
    _accessToken: types.maybeNull(types.string),
  })
  .views((self) => ({
    get currentUser(): IUser {
      return self.storeUser;
    },
    get accessToken(): string {
      return self._accessToken;
    }
  }))
  .actions((self) => ({
    setAccessToken(accessToken: string) {
      self._accessToken = accessToken;
    },
    setCurrentUser(user: any) {
      self.storeUser = User.create();
      self.storeUser.set(user);
    },
    unsetAccessToken() {
      self._accessToken = null;
    },
    unsetCurrentUser() {
      self.storeUser = null;
    },
    showMessage(message: string, severity: string, summary: string) {
      getEnv(self).messageService.add({
        key: 'toast',
        severity: severity,
        summary: summary,
        detail: message,
        closable: true,
      });
    },
  }));
export type IApiStore = Instance<typeof RootStore>;

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  store: IApiStore;
  constructor(
    private apiService: ApiService
  ) {
    this.store = RootStore.create(
      {
        storeUser: null,
        _accessToken: null,
      },
      { api: this.apiService }
    );
    this.apiService.auth.setApiStore(this.store);
  }
}
