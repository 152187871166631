import * as moment from 'moment';
moment.locale('fr');

const concat = (x: any, y: any): any[] => {
  return x.concat(y)
}

/**
 * Replace .flatMap() for arrays since it's not yet implemented in some browsers
 * Same thing as a map but flattens the output
 * @param srcArray array to flat map
 * @param mapFunction function for mapping
 * @returns flattened map from array
 * @type any[]
 */
export const flatMap = (srcArray: any[], mapFunction: any): any[] => {
  if (srcArray ? srcArray.length > 0 : false) {
    return srcArray.map(mapFunction).reduce(concat, []) as any[];
  }
  else return [];
}

/**
 * allows for synchronous for each to make sure every step can be waited for
 * @param array the array to iterate throuh
 * @param callback each item of the array
 */
export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * synchronous for iteration
 * @param max the total number of iterations
 * @param callback the index to wait for
 */
export const asyncForRequest = async (max: number, callback: Function, min = 0) => {
  for (let index = min; index < max; index++) {
    await callback(index);
  }
}

/**
 * returns a pretty string to qhow a quantity
 * @param {number} quantity quantity to prettify
 * @returns pretty string 🥰
 */
export const quantityPrettyfier = (quantity: number): string => {
  if (!!quantity) {
    if (Number.isInteger(quantity)) {
      return quantity.toString();
    }
    else return quantity.toFixed(2);
  }
  else return "0";
}

/**
 * returns date in localized formatted string (exemple : vendredi 7 Avril 1989 13:37)
 * @param date date to prettify
 * @returns pretty string 🥰
 */
export const datePrettyfier = (date: Date, format = "LLLL"): string => {
  return !!date ? moment(date).format(format) : "";
}

/**
 * compares two arrays and returns equals if they contains the same items
 * even if they are not in the same order
 * @param {any[]} arr1: array to compare
 * @param {any[]} arr2 : array to compare
 * @returns true if equals
 */
export const arraysEquals = (arr1: any[], arr2: any[]): boolean => {
  if ((arr1 && arr2) ? (arr1.length === arr2.length) : false) {
    return JSON.stringify(arr1.slice().sort()) === JSON.stringify(arr2.slice().sort());
  }
  else return false;
}

/**
 * Checks if an error from apollo is an array or a single error and returns a string
 * @param error error to check
 * @returns stringified error
 */
export const getApolloError = (error: any): string => {
  let errorMessage;
  if (Array.isArray(error)) {
    errorMessage = error.map(err => err.message).join(', ')
  }
  else if (error.message) {
    errorMessage = error.message;
  }
  else {
    errorMessage = JSON.stringify(error);
  }
  return errorMessage;
}

export const filterSearch = (searchTerm: string, search: string): boolean => {
  if (!searchTerm) return true;
  if (!search) return false;
  return searchTerm.toLowerCase().split(' ').every(term => search.toLowerCase().includes(term));
}

export const unitValue = (value: number): string => {
  if (value < 0.0001) {
    return `0€`;
  }
  if (value < 0.001) {
    return `<0.001€`;
  }
  else if (value * 200 % 2 < 0.0001) {
    return `${value.toFixed(2)}€`;
  }
  else {
    return `${value.toFixed(3)}€`;
  }
}