import { gql } from 'apollo-angular';

export const ASSET_MODEL_INFO_FRAGMENT = gql`
  fragment AssetModelInfo on AssetModel {
    model_id
    category_id
    name
    desc_file_id
    distribution
    possible_parent_models_ids
    icon
    parameters_sets_ids
  }
`;
