import { gql } from "apollo-angular";

export const WORK_ORDERS_QUERY = gql`
  query workOrders($where: WorkOrderWhereInput!) {
    workOrders(where: $where)
  }
`;

export const WORK_ORDER_SUBSCRIPTION = gql`
  subscription workOrderSubscription {
    workOrderSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;