<section class="relative block w-full h-full mat-typography stock-app"
         *mobxAutorun="{ dontDetach: true }">
  <section [hidden]="!online || !citadelConnection"
           class="w-full h-full">
    <div *ngIf="!isUpToDate"
         class="flex flex-col items-center justify-center w-full h-full">
      <sparte-spinner size="large" />
      <span class="mt-16 text-4xl font-medium tracking-widest uppercase text-sparteWhite-500">MISE à JOUR DU LAUNCHER EN COURS</span>
    </div>
    <ng-container *ngIf="isUpToDate">
      <sparte-auth *ngIf="!isAuthenticated" />
      <sparte-menu *ngIf="isAuthenticated">
        <ng-template #logo>
          <img src="assets/images/Citadel-logo-only.svg"
               class="w-full h-full">
        </ng-template>
        <ng-template #logoText>
          <img src="assets/images/Citadel-only.svg"
               class="w-full h-full">
        </ng-template>
        <ng-template #changeLog>
          <button (click)="showChangeLog()"
                  class="ml-2 font-medium text-sparteBlue"> {{version}} </button>
        </ng-template>
        <ng-template #routerOutlet>
          <router-outlet />
        </ng-template>
      </sparte-menu>
    </ng-container>
  </section>
  <div *ngIf="!online || !citadelConnection"
       class="flex flex-col items-center justify-center home-content no-article">
    <mat-icon class="mb-8 big-icon">signal_wifi_connected_no_internet_4</mat-icon>
    <span *ngIf="!online">Vous avez perdu votre connexion à internet.</span>
    <span *ngIf="!citadelConnection">Vous avez perdu votre connexion au serveur Citadel.</span>
  </div>
</section>
<sparte-print-page></sparte-print-page>