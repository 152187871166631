import { gql } from 'apollo-angular';

export const STOCK_STORAGE_ENTITY_INFO_FRAGMENT = gql`
  fragment StockStorageEntityInfo on StockStorageEntity {
    entity_id
    order_id
    business_ref
    network_id
    shipment_ref
    barcode
    storage_id
    zorder
    parent_entity_id
    trash
    weight
    dimensions
    locked
    received
    created_at
    updated_at
  }
`;
