<section class="flex items-center justify-center w-full h-full"
         *mobxAutorun="{dontDetach: true}">
  <form class="flex flex-col justify-center w-auto">
    <!-- Header logo -->
    <img class="self-center w-24 h-24 mb-8 mt-14"
         *ngIf="formMode !== 'PIN'"
         src="assets\images\Citadel-logo-only.svg"
         alt="logo">
    <img class="self-center w-64 mb-16"
         *ngIf="formMode !== 'PIN'"
         src="assets\images\Citadel-only.svg"
         alt="logo">
    <!-- PIN Header -->
    <div *ngIf="formMode === 'PIN'">
      <div class="mt-40">
        <img *ngIf="selectedProfile.avatar_url"
             [src]="selectedProfile.avatar_url"
             alt=""
             class="flex rounded-[4rem] w-[112px] h-[115px] m-auto">
        <div class="flex rounded-[4rem] w-[112px] h-[115px] m-auto text-8xl"
             *ngIf="!selectedProfile.avatar_url">
          <mat-icon class="runner-icon gray-content w-[100%] h-[100%] cursor-pointer hover:shadow-2xl rounded-[4rem] hover:scale-110"
                    style="width: 100%; height:100%; font-size: 90px">person</mat-icon>
        </div>
        <p class="flex justify-center pt-4 pb-12 m-auto text-lg font-medium uppercase text-sparteBlue">{{selectedProfile.full_name}}</p>
      </div>
      <div class="inputs mb-8 w-[392px] h-[52px]">
        <span class="pl-3 material-icons-outlined">password</span>
        <input type="password"
               (input)="updatePin($event.target.value)"
               [ngModel]="pin"
               name="pin"
               id="pinCode"
               required
               maxlength=4
               placeholder="****"
               class="ml-3 form-input">
      </div>
      <p *ngIf="!!pinError"
         class="input-nok">{{pinError}}</p>
      <!-- Input Numbers -->
      <div class="grid grid-cols-3 w-[392px]">
        <div *ngFor="let number of numbers"
             class=" bg-sparteWhite w-[120px] h-[56px] gap-y-[60px] gap-x-20 inputs mb-4 text-sparteBlue font-medium text-lg uppercase m-auto"
             (click)="setPinValue(number)">
          <input type="button"
                 name="pin"
                 [id]="number"
                 [value]="number"
                 [placeholder]="number"
                 class="w-full h-full m-auto cursor-pointer hover:bg-blue-200 active:text-sparteWhite active:bg-sparteBlue">
        </div>
        <div class="w-[120px] h-[56px] gap-y-[60px] gap-x-20 inputs text-sparteBlue font-medium text-lg uppercase m-auto cursor-pointer mb-8"
             (click)="handleClear()">
            <div class="flex items-center justify-center w-full h-full m-auto cursor-pointer hover:bg-blue-200 active:text-sparteWhite active:bg-sparteBlue">
              <span class="material-icons-outlined">clear</span>
            </div>
        </div>
        <div class="w-[120px] h-[56px] gap-y-[60px] gap-x-20 inputs text-sparteBlue font-medium text-lg uppercase m-auto cursor-pointer mb-8">
          <input type="button"
                 name="pin"
                 (click)="setPinValue(0)"
                 id="0"
                 value="0"
                 placeholder="0"
                 class="w-full h-full m-auto cursor-pointer hover:bg-blue-200 active:text-sparteWhite active:bg-sparteBlue">
        </div>
        <div class="w-[120px] h-[56px] gap-y-[60px] gap-x-20 inputs text-sparteBlue font-medium text-lg uppercase m-auto cursor-pointer mb-8"
             (click)="erase()">
            <div class="flex items-center justify-center w-full h-full m-auto cursor-pointer hover:bg-blue-200 active:text-sparteWhite active:bg-sparteBlue">
              <span class="material-icons-outlined">backspace</span>
            </div>
        </div>
      </div>
    </div>
    <sparte-connexion *ngIf="formMode === 'SELECT'"
                      [onProfileSelected]="onProfileSelected"
                      class="" />
    <ng-container *ngIf="formMode !== 'SELECT'">
      <!-- Input Names -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'SIGNUP'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">person</span>
          <input type="text"
                 (input)="updateFirstName($event.target.value)"
                 [ngModel]="firstName"
                 name="firstName"
                 id="firstName"
                 class="form-input"
                 placeholder="Prénom">
        </div>
        <p *ngIf="!firstNameValid"
           class="input-nok">Veuillez renseigner un prénom</p>
      </section>
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'SIGNUP'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">person</span>
          <input type="text"
                 (input)="updateLastName($event.target.value)"
                 [ngModel]="lastName"
                 name="lastName"
                 id="lastName"
                 class="form-input"
                 placeholder="Nom">
        </div>
        <p *ngIf="!lastNameValid"
           class="input-nok">Veuillez renseigner un nom</p>
      </section>
      <!-- Input Mail -->
      <section class="mx-16 mb-4"
               *ngIf="formMode !== 'PIN'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">person</span>
          <input type="email"
                 [ngModel]="email"
                 (input)="updateMail($event.target.value)"
                 (blur)="checkEmail()"
                 [disabled]="formMode === 'RESET' || formMode === 'VERIFY' || formMode === 'UPDATEPASS'"
                 email
                 name="email"
                 id="email"
                 class="ml-3 form-input"
                 placeholder="Mail">
        </div>
        <p *ngIf="!!emailError"
           class="input-nok">{{emailError}}</p>
      </section>
      <!-- Input Password -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'LOGIN' || formMode === 'UPDATEPASS' || formMode === 'UPDATEPIN'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 (input)="updatePassword($event.target.value)"
                 [ngModel]="password"
                 name="password"
                 id="password"
                 placeholder="Mot de passe"
                 class="ml-3 form-input">
        </div>
        <p *ngIf="!!passwordError"
           class="input-nok">{{passwordError}}</p>
      </section>
      <!-- Code -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'RESET' || formMode === 'VERIFY'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 (input)="updateCode($event.target.value)"
                 [ngModel]="code"
                 name="code"
                 id="code"
                 placeholder="Code"
                 class="form-input">
        </div>
        <p *ngIf="!!codeError"
           class="input-nok">{{codeError}}</p>
      </section>
      <!-- New password -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'UPDATEPASS' || formMode === 'RESET' || formMode === 'SIGNUP'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 [ngModel]="newPassword"
                 (input)="updateNewPassword($event.target.value)"
                 (blur)="checkNewPassword()"
                 name="newPassword"
                 id="newPassword"
                 placeholder="Nouveau mot de passe"
                 class="form-input">
        </div>
        <p *ngIf="!!newPasswordError"
           class="input-nok">{{newPasswordError}}</p>
      </section>
      <!-- Confirm password -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'SIGNUP' || formMode === 'UPDATEPASS' || formMode === 'RESET'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 [ngModel]="confirmPassword"
                 (input)="updateConfirmPassword($event.target.value)"
                 name="confirmPassword"
                 id="confirmPassword"
                 placeholder="Confirmer le mot de passe"
                 class="form-input">
        </div>
        <p *ngIf="!!confirmPassword && !passwordMatch"
           class="input-nok">Les mots de passes ne correspondent pas</p>
      </section>
      <!-- New Pin -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'UPDATEPIN' || formMode === 'RESET' || formMode === 'SIGNUP'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 maxlength="4"
                 [ngModel]="newPin"
                 (input)="updateNewPin($event.target.value)"
                 (blur)="checkNewPin()"
                 name="newPin"
                 id="newPin"
                 placeholder="Nouveau Pin"
                 class="form-input">
        </div>
        <p *ngIf="!!newPinError"
           class="input-nok">{{newPinError}}</p>
      </section>
      <!-- Confirm Pin -->
      <section class="mx-16 mb-4"
               *ngIf="formMode === 'SIGNUP' || formMode === 'UPDATEPIN' || formMode === 'RESET'">
        <div class="inputs">
          <span class="pl-3 material-icons-outlined">password</span>
          <input type="password"
                 maxlength="4"
                 [ngModel]="confirmPin"
                 (input)="updateConfirmPin($event.target.value)"
                 name="confirmPin"
                 id="confirmPin"
                 placeholder="Confirmer le pin"
                 class="form-input">
        </div>
        <p *ngIf="!!confirmPin && !pinMatch"
           class="input-nok">Les pin ne correspondent pas</p>
      </section>
      <!-- Stay connected -->
      <section class="flex items-center justify-start mx-16 mb-12 uppercase"
               *ngIf="formMode === 'LOGIN'">
        <input id="keepMeLoggedIn"
               [(ngModel)]="keepMeLoggedIn"
               name="keepMeLoggedIn"
               aria-describedby="remember"
               type="checkbox"
               class="w-4 h-4 border-2 border-gray-500 rounded cursor-pointer focus:ring-3 focus:ring-blue-300 text-sparteBlue">
        <label for="keepMeLoggedIn"
               class="pl-2 text-xs tracking-wide text-gray-500">Rester connecté</label>
      </section>
      <!-- Submit -->
      <button type="submit"
              [disabled]="!canSubmit"
              (click)="submitForm()"
              class="w-60 font-medium text-sm tracking-wider px-5 py-2.5 justify-center gap-4 mb-8">
        <ng-container *ngIf="!fetching">
          <span class="material-icons-outlined">{{buttonLogo}}</span>
          <span>{{buttonTitle}}</span>
        </ng-container>
        <sparte-spinner *ngIf="fetching"
                        size="small"></sparte-spinner>
      </button>
      <!-- Create Account -->
      <button type="submit"
              (click)="signup()"
              *ngIf="formMode !== 'SIGNUP' && 'PIN'"
              class="sub-inputs">
        <span class="material-icons-outlined">person_add_alt</span>
        <span>créer un compte</span>
      </button>
      <!-- Return to login -->
      <button type="submit"
              (click)="login()"
              *ngIf="formMode !== 'LOGIN' && !'PIN'"
              class="sub-inputs">
        <span class="material-icons-outlined">account_circle</span>
        <span>Déjà un compte ?</span>
      </button>
      <!-- Password forgotten -->
      <button type="submit"
              (click)="resetPassword()"
              *ngIf="formMode !== 'FORGOT'"
              class="sub-inputs">
        <span class="material-icons-outlined">support</span>
        <span>{{ formMode === 'PIN' ? 'Code oublié?' : 'Mot de passe oublié ?' }}</span>
      </button>
      <!-- Retour -->
      <button type="submit"
              (click)="back()"
              style="width: auto;"
              *ngIf="showBackButton"
              class="sub-inputs">
        <span class="material-icons-outlined">arrow_back</span>
        <span>Retour</span>
      </button>
    </ng-container>
  </form>
</section>