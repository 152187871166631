import { Deserializable } from '@sparte/utils';

export class DashboardStat implements Deserializable {
  public stat_name: string;
  public stat_ts: Date;
  public stat_value: number;
  typeName = "DashboardStat";
  searchTerm: string;
  label: string;
  updateValue: any;

  deserialize(input: any): this {
    const { __typename, stat_ts, ..._input } = input;
    Object.assign(this, _input);
    if (stat_ts) this.stat_ts = new Date(stat_ts);
    else this.stat_ts = new Date();
    return this;
  }
}