import { gql } from 'apollo-angular';
import { SPQDC_EVENT_INFO_FRAGMENT } from './fragments/_spqdcEvent';


export const SPQDC_EVENTS_BY_POSTS_QUERY = gql`
  query spqdcEventsByPosts($where: PostWhereInput!) {
    spqdcEventsByPosts(where: $where)
  }
`;

export const CREATE_SPQDC_EVENT_MUTATION = gql`
  mutation createSpqdcEvent($data: SPQDCEventCreateInput!) {
    createSPQDCEvent(data: $data) {
      ...SPQDCEventInfo
    }
  }
  ${SPQDC_EVENT_INFO_FRAGMENT}
`;

export const SPQDC_EVENT_SUBSCRIPTION = gql`
  subscription spqdcEventSubscription($where: PostWhereInput!) {
    spqdcEventSubscription(where: $where) {
      mutationType
      objectId
      objectJson
    }
  }
`;
