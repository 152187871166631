import { gql } from 'apollo-angular';


export const POST_MAINTENANCE_INFO_FRAGMENT = gql`
  fragment PostMaintenanceInfo on PostMaintenance {
    post
    time_id
    start_date
    end_date
    description
  }
`;