import { gql } from 'apollo-angular';

export const SHIPMENT_INFO_FRAGMENT = gql`
  fragment ShipmentInfo on Shipment {
    shipment_ref
    status
    user_id
    description
    deadline
    company
    address {
      street
      zip_code
      city
      country
    }
    shipment_type
    destination_user {
      full_name
      phone
      mail
    }
    business_ref
    comment
    added_articles {
      entity_id
      barcode
      instance_id
      time_id
      designation
      reference
      quantity
    }
    entity_details {
      entity_id
      barcode
      packaged_at
      weight
      dimensions
    }
    created_at
    updated_at
  }
`;

export const DELIVERY_SLIP_INFO_FRAGMENT = gql`
  fragment DeliverySlipInfo on DeliverySlip {
    shipment_ref
    entity_id
    barcode
    articles {
      entity_id
      reference
      designation
      quantity
    }
    added_articles {
      entity_id
      reference
      designation
      quantity
    }
    weight
    dimensions
    shipped_at
  }
`;