<div class="confirmation-container">
  <h3 class="form-title"
      [innerHTML]="data.title || default.title"></h3>
  <div class="flex-row confirmation-dialog-content"
       *ngIf="data.content">
    <mat-icon *ngIf="data.contentIcon"
              class="content-icon"
              mat-icon>{{data.contentIcon}}</mat-icon>
    <div [innerHTML]="data.content"></div>
  </div>
  <div class="flex items-center justify-around gap-6 form-buttons">
    <sparte-button gold
                   emphezise
                   roundedLg
                   tabindex="-1"
                   label="{{data.cancel || default.cancel}}"
                   [ngClass]="data.cancelClass"
                   (click)="closeDialog('cancel')"
                   icon="close">
    </sparte-button>
    <sparte-button red
                   emphezise
                   roundedLg
                   tabindex="-1"
                   label="{{data.confirm || default.confirm}}"
                   [ngClass]="data.confirmClass"
                   (click)="closeDialog('ok')"
                   icon="done">
    </sparte-button>
  </div>
</div>
