import { gql } from "apollo-angular";
import { DELIVERY_SLIP_INFO_FRAGMENT, SHIPMENT_INFO_FRAGMENT } from './fragments/_shipment';

export const SHIPMENTS_QUERY = gql`
  query shipments {
    shipments
  }
`;
export const SHIPMENT_SUBSCRIPTION = gql`
  subscription shipmentSubscription {
    shipmentSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_SHIPMENT_MUTATION = gql`
  mutation createShipment($data: ShipmentCreateInput!) {
    createShipment(data: $data) {
      ...ShipmentInfo
    }
  }
  ${SHIPMENT_INFO_FRAGMENT}
`;

export const UPDATE_SHIPMENT_MUTATION = gql`
  mutation updateShipment(
    $data: ShipmentUpdateInput!
    $where: ShipmentWhereUniqueInput!
  ) {
    updateShipment(data: $data, where: $where) {
      ...ShipmentInfo
    }
  }
  ${SHIPMENT_INFO_FRAGMENT}
`;

export const UPDATE_SHIPMENT_STATUS_MUTATION = gql`
  mutation updateShipmentStatus($where: ShipmentWhereUniqueInput!) {
    updateShipmentStatus(where: $where) {
      ...ShipmentInfo
    }
  }
  ${SHIPMENT_INFO_FRAGMENT}
`;

export const REMOVE_ARTICLES_FROM_SHIPMENT_MUTATION = gql`
  mutation removeArticlesFromShipment(
    $where: ShipmentWhereUniqueInput!
    $data: UpdateArticleSelectionInput!
  ) {
    removeArticlesFromShipment(
      data: $data
      where: $where
    ) {
      ...ShipmentInfo
    }
  }
  ${SHIPMENT_INFO_FRAGMENT}
`;

export const SHIP_SHIPMENT_ENTITY_MUTATION = gql`
  mutation shipShipmentEntity($where: ShipmentEntityWhereUniqueInput!) {
    shipShipmentEntity(where: $where) {
      ...DeliverySlipInfo
    }
  }
  ${DELIVERY_SLIP_INFO_FRAGMENT}
`;

export const DELIVERY_SLIPS_QUERY = gql`
  query deliverySlips($where: ShipmentWhereUniqueInput!) {
    deliverySlips(where: $where)
  }
`;

export const DELIVERY_SLIP_SUBSCRIPTION = gql`
  subscription deliverySlipSubscription {
    deliverySlipSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;