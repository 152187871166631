import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewChecked, ChangeDetectorRef, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { slideAnimation } from './slide.animation';

// import * as d3 from 'd3';

@Component({
  selector: 'sparte-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss'],
  animations: [slideAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryViewerComponent implements OnInit, AfterViewChecked {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
    suppressScrollY: true,
  };
  @ViewChild('galleryScroll', { static: false }) componentScrollBar?: CdkVirtualScrollViewport;
  @ViewChild('scrollContent', { static: false }) scrollbarContent: ElementRef;
  @ViewChild('scrollElementMin', { static: false }) componentScrollBarMin?: PerfectScrollbarComponent;
  @ViewChild('scrollContentMin', { static: false }) scrollbarContentMin: ElementRef;
  @ViewChild('drawingCanvas', { static: false }) public drawingCanvas: ElementRef;
  // @ViewChild('drawingCanvas') public drawingCanvas: ElementRef;
  public fileList: any[]; //SparteFile
  public currentIndex: number;
  private scrollOffset: number;
  private scrollOffsetMin: number;
  // private canvasWidth: number;
  // private canvasHeight: number;
  private hoveredId: number;
  // public undo: Function;
  // public reset: Function;
  public drawEnabled: boolean;
  public showImageTitle: boolean;
  public isDrawingActive = false;
  constructor(
    private dialogRef: MatDialogRef<GalleryViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private cdRef: ChangeDetectorRef
  ) {
    if (data) {
      this.showImageTitle = data.showImageTitle;
      this.fileList = data.files.filter((f) => !!f);
      if (data.index < this.fileList.length) {
        this.currentIndex = data.index;
      } else {
        this.currentIndex = this.fileList.length - 1;
      }
      this.updateThumbnailPosition();
    } else {
      this.closeDialog();
    }
  }

  @HostListener('window:resize') onWindowResize() {
    if (this.multiFiles) {
      this.scrollOffset = this.scrollbarContent.nativeElement.clientWidth / 2;
      this.scrollOffsetMin =
        this.scrollbarContentMin.nativeElement.clientWidth / 2;
      // this.canvasWidth = this.drawingCanvas.nativeElement.clientWidth;
      // this.canvasHeight = this.drawingCanvas.nativeElement.clientHeight;
      // const canvasEl: HTMLCanvasElement = this.drawingCanvas.nativeElement;
      // canvasEl.width = this.canvasWidth;
      // canvasEl.height = this.canvasHeight;
      this.updateThumbnailPosition();
    }
  }

  get multiFiles(): boolean {
    if (this.fileList) {
      return this.fileList.length > 1;
    }
  }

  get imageTitle(): string {
    if (this.fileList) {
      return this.fileList[this.currentIndex].imageTitle
        ? this.fileList[this.currentIndex].imageTitle
        : ' ';
    }
  }

  get fileClass(): string {
    if (this.fileList) {
      return `${this.multiFiles ? 'multi-file' : 'solo-file'}-${this.showImageTitle ? 'show-title' : 'no-title'
        }`;
    }
  }

  get isCurrentPdf(): boolean {
    if (this.fileList) {
      return this.isPdf(this.fileList[this.currentIndex]);
    }
  }

  get getWidgetSize(): number {
    return Math.max(Math.floor(this.scrollOffset / 4.5), 202);
  }

  /* @computed get isDrawingActive(): boolean {
    if (this.fileList) {
      return this.isCurrentPdf && this.drawEnabled;
    }
  } */

  isImage(file: any): boolean {
    return file ? file.isImage : false;
  }

  isPdf(file: any): boolean {
    return file ? file.isPdf : false;
  }

  ngOnInit() {
    // PRELOAD IMAGE
    this.fileList.forEach((slide, index) => {
      if (this.isImage(slide)) {
        new Image().src = slide.url;
      }
    });
    this.cdRef.detectChanges();
  }

  /* ngOnDestroy() { }

  ngAfterViewInit() {
    // CANVAS DRAWING
    this.canvasWidth = this.drawingCanvas.nativeElement.clientWidth;
    this.canvasHeight = this.drawingCanvas.nativeElement.clientHeight;
    const canvasEl: HTMLCanvasElement = this.drawingCanvas.nativeElement;

    const drawing_data = {
      lines: []
    };

    const canvas = d3.select(canvasEl);

    const render_line = d3.line().x(d => d[0]).y(d => d[1]).curve(d3.curveBasis);

    let active_line = null;
    const active_color = "#C1092B";

    const lines_layer = canvas.append('g');

    function selectToolOnToolbar() {
      canvas.call(toolDragger(active_color));
      d3.select("[data-brush='pen']");
      return d3.select(this).classed('active', true);
    }

    function redraw(specific_line?) {
      const lines = lines_layer.selectAll('.line').data(drawing_data.lines);
      lines.enter().append('path')
        .attr("class", 'line')
        .attr("stroke", d => d.color)
        .each(function (d: any) {
          return d.elem = d3.select(this);
        });
      if (specific_line) {
        specific_line.elem.attr("d", d => render_line(d.points)).attr("data-brush", d => "pen");
      } else {
        lines.enter().each((line: any) => line.elem.attr('d', d => render_line(d.points)).attr("data-brush", d => "pen"));
      }
      return lines.exit().remove();
    }

    redraw();
    selectToolOnToolbar();

    function toolDragger(activecolor) {
      const drag = d3.drag();
      drag.on('start', function () {
        active_line = {
          points: [],
          brush: "pen",
          color: activecolor
        };
        drawing_data.lines.push(active_line);
        return redraw(active_line);
      });

      drag.on('drag', function () {
        active_line.points.push(d3.mouse(this as d3.ContainerElement));
        return redraw(active_line);
      });

      drag.on('end', function () {
        if (active_line.points.length === 0) {
          drawing_data.lines.pop();
          redraw();
        }
        active_line = null;
        return;
      });

      return drag;
    }

    this.undo = function () {
      drawing_data.lines.pop();
      redraw();
    }
    this.reset = function () {
      drawing_data.lines = [];
      redraw();
    }
  } */

  ngAfterViewChecked() {
    if (this.multiFiles) {
      this.scrollOffset =
        this.scrollbarContent.nativeElement.clientWidth / 2 - 128;
      this.scrollOffsetMin =
        this.scrollbarContentMin.nativeElement.clientWidth / 2 - 6;
      this.cdRef.detectChanges();
    }
  }

  updateThumbnailPosition() {
    if (this.multiFiles) {
      setTimeout(() => {
        this.componentScrollBar.scrollToOffset(
          this.currentIndex * 200 - this.scrollOffset,
          'smooth'
        );
        this.componentScrollBarMin.directiveRef.scrollToElement(
          `#${this.fileList[this.currentIndex].id}`,
          -this.scrollOffsetMin,
          250
        );
        this.cdRef.detectChanges();
      }, 250);
    }
  }

  isFileSelected(index: number): boolean {
    return this.currentIndex === index;
  }

  setCurrentIndex(i: any) {
    // this.reset();
    this.currentIndex = i;
    this.updateThumbnailPosition();
  }

  prevFile() {
    // this.reset();
    if (this.currentIndex > 0) {
      this.currentIndex = this.currentIndex - 1;
    } else {
      this.currentIndex = this.fileList.length - 1;
    }
    this.updateThumbnailPosition();
  }

  nextFile() {
    // this.reset();
    if (this.currentIndex < this.fileList.length - 1) {
      this.currentIndex = this.currentIndex + 1;
    } else {
      this.currentIndex = 0;
    }
    this.updateThumbnailPosition();
  }
  closeDialog() {
    this.dialogRef.close();
  }

  setHoveredFile(index?) {
    if (index) {
      this.hoveredId = index;
    } else {
      this.hoveredId = null;
    }
  }

  isFileHovered(index): boolean {
    return this.hoveredId === index;
  }
}
