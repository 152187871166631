import { gql } from 'apollo-angular';

export const CITADEL_INFRASTRUCTURE_INFO_FRAGMENT = gql`
  fragment CitadelInfrastructureInfo on CitadelInfrastructure {
    asset_id
    parent_asset_id
    designation
    model_id
    parent_zone_id
    sort_order
    parent_systems_ids
    asset_instances {
      variables {
        values
        variable_model_id
      }
    }
    variables {
      values
      variable_model_id
    }
    created_at
    updated_at
    address {
      label
      lat
      lng
    }
  }
`;
