import { Deserializable } from '@sparte/utils';
import { Resource } from './resource.model';
import { ResourceInstance } from './resourceInstance.model';

export class CitadelStock implements Deserializable {
  id: string;
  designation: string;
  tag: string;
  business_ref: string;
  locked: boolean;
  reservation: boolean;
  self_service: boolean
  accounting: boolean
  restock_users: string[];
  created_at: Date;
  updated_at: Date;

  public x: number;
  public y: number;
  public typeName = "CitadelStock";

  deserialize(input: any): this {
    const { __typename, stock_id, created_at, updated_at, ..._input } = input;
    Object.assign(this, _input);
    this.id = stock_id;
    this.created_at = new Date(created_at);
    this.updated_at = new Date(updated_at);
    if(!this.restock_users) this.restock_users = [];
    return this
  }

  get whereUniqueValue(): any {
    return {
      stock_id: this.id
    }
  }

  get createValue(): any {
    const {
      id,
      x,
      y,
      business_ref,
      locked,
      typeName,
      created_at,
      updated_at,
      ..._this
    } = this;
    return _this;
  }

  get updateValue(): any {
    const {
      id,
      x,
      y,
      typeName,
      created_at,
      updated_at,
      ..._this
    } = this;
    return _this;
  }

  get label(): string {
    return this.designation;
  }

  get searchTerm(): string {
    return `${this.designation} ${this.tag}`.toLowerCase();
  }

  //TODO : implement gets
  get events(): any[] {
    return [];
  }

  get resourceInstances(): ResourceInstance[] {
    return [];
  }

  get resources(): Resource[] {
    return [];
  }
}