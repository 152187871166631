import { gql } from "apollo-angular";

export const AUTH_PAYLOAD_FRAGMENT = gql `
  fragment AuthPayload on AuthPayload {
    token
    user {
      user_id
      mail
      first_name
      last_name
      permissions
      groups_ids
      companies_ids
    }
  }
`;