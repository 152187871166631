import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataPrintService, PrintInterface, PrintModeEnum } from '@sparte/ui';

@Component({
  selector: 'sparte-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintPageComponent implements OnInit, OnDestroy {
  public printOrder: PrintInterface;
  private readonly defaultPrintOrder: PrintInterface = {
    mode: PrintModeEnum.EntityLabel,
    barcodeData: null
  }
  private printSub: Subscription;

  constructor(
    private dataPrintService: DataPrintService,
    private cdRef: ChangeDetectorRef
  ) {
    this.printOrder = this.defaultPrintOrder;
  }

  ngOnInit(): void {
    this.printSub = this.dataPrintService.printEvent.subscribe(printOrder => {
      this.printOrder = printOrder;
      this.cdRef.detectChanges();
      window.print();
      this.printOrder = this.defaultPrintOrder;
      this.cdRef.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.printSub?.unsubscribe();
  }
}
