import { gql } from "apollo-angular";


export const ITEM_GROUP_INFO_FRAGMENT = gql`
  fragment ItemGroupInfo on ItemGroup {
    group_id
    designation
    created_at
    updated_at
  }
`;