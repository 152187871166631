import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private apiService: ApiService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const token = this.apiService._accessToken;
    if (this.apiService.routeCheck(state.url)) return true;
    if (!token) {
      if(state.url === '/login') return true;
      this.apiService.redirectToLogin();
      return false
    }
    if (this.apiService.currentUser) {
      console.log('can activate false : ', state.url);
      this.apiService.redirectToDefault()
      return false;
    };
    return new Observable(observer => {
      this.apiService.authChanged.subscribe(auth => {
        if (!auth) {
          observer.next(false);
          observer.complete();
        };
        const routeCheck = this.apiService.routeCheck(state.url);
        if (!routeCheck) {
          console.log('can activate false : ', state.url);
          this.apiService.redirectToDefault();
        }
        observer.next(routeCheck);
        observer.complete();
      });
    });
  }
}

export const AuthGardActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> => {
  return inject(AuthGuard).canActivate(route, state);
}