import { Deserializable } from '@sparte/utils';
import { CitadelVariableModel } from './citadelVariableModel.model';
import { AssetCategory } from './assetCategory.model';
import { AssetModel } from './assetModel.model';
import { CitadelCoreService } from '../services/citadel-core.service';

export class ParameterSet implements Deserializable {
  id: string;
  name: string;
  variableModels: CitadelVariableModel[];
  newModels: CitadelVariableModel[];
  typeName = "ParameterSet";
  constructor(private citadelCoreService: CitadelCoreService) { }
  deserialize(input: any): this {
    const { __typename, variable_models, set_id, ..._input } = input;
    Object.assign(this, _input);
    this.id = set_id;
    this.newModels = [];
    if (!this.variableModels) {
      this.variableModels = (variable_models || []).map(varMod => new CitadelVariableModel().deserialize(varMod));
    }
    else {
      const variableModels = (variable_models || []);
      for (let i = 0; i < variableModels.length; i++) {
        const varMod = variableModels[i];
        const variableModel = this.variableModels.find(model => model.variable_model_id === varMod.variable_model_id)
        if (!variableModel) {
          this.variableModels.push(new CitadelVariableModel().deserialize(varMod));
        }
        else {
          variableModel.deserialize(varMod);
        }
      }
    }
    return this;
  }

  get updateValue() {
    const { id, typeName, citadelCoreService, variableModels, newModels, ..._this } = this;
    let variableModelsUpdate = [];
    if (variableModels || newModels) {
      variableModelsUpdate = variableModels.map(varMod => varMod.updateValue).concat(newModels.map(varMod => varMod.updateValue));
    }
    return {
      ..._this,
      variable_models: variableModelsUpdate
    }
  }

  get searchTerm(): string {
    return this.name
  }

  get label(): string {
    return this.name
  }

  get models(): AssetModel[] {
    return this.citadelCoreService.getAssetModels.filter(model => model.parameters_sets_ids.includes(this.id));
  }

  get categories(): AssetCategory[] {
    return this.citadelCoreService.getAssetCategories.filter(category => category.parameters_sets_ids.includes(this.id));
  }
}