import { gql } from 'apollo-angular';

import { ITEM_GROUP_INFO_FRAGMENT } from './fragments/_itemGroup';

export const ITEM_GROUPS_QUERY = gql`
  query itemGroups {
    itemGroups
  }
`;

export const CREATE_ITEM_GROUP_MUTATION = gql`
  mutation createItemGroup($data: ItemGroupCreateInput!) {
    createItemGroup(data: $data) {
      ...ItemGroupInfo
    }
  }
  ${ITEM_GROUP_INFO_FRAGMENT}
`;

export const UPDATE_ITEM_GROUP_MUTATION = gql`
  mutation updateItemGroup(
    $where: ItemGroupWhereUniqueInput!
    $data: ItemGroupUpdateInput!
  ) {
    updateItemGroup(where: $where, data: $data) {
      ...ItemGroupInfo
    }
  }
  ${ITEM_GROUP_INFO_FRAGMENT}
`;

export const DELETE_ITEM_GROUP_MUTATION = gql`
  mutation deleteItemGroup($where: ItemGroupWhereUniqueInput!) {
    deleteItemGroup(where: $where) {
      asset_id
    }
  }
`;

export const ITEM_GROUP_SUBSCRIPTION = gql`
  subscription itemGroupSubscription {
    itemGroupSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;
