import { gql } from 'apollo-angular';

export const STOCK_REQUEST_INFO_FRAGMENT = gql`
  fragment StockRequestInfo on StockRequest {
    request_id
    request_ref
    user_id
    business_ref
    request_type
    request_status
    purchase_status
    request_date
    created_at
    updated_at
    archived_at
  }
`;
