import { datePrettyfier, Deserializable, quantityPrettyfier } from '@sparte/utils';

import { HistoryTypeEnum } from '../utils/stock-interfaces';


export class ArticleHistory implements Deserializable {
  public instance_id: string;
  public time_id: string;
  public history_type: HistoryTypeEnum
  public information: string;
  public comment: string;
  public origin: string;
  public destination: string;
  public user: string;
  public new_history: boolean;
  public quantity: number;
  public action_ts: Date;
  public typeName = "ArticleHistory";

  constructor(private defaultLabel: string) { }

  deserialize(input: any, new_history = false,): this {
    const { __typename, action_ts, ..._input } = input;
    Object.assign(this, _input);
    this.new_history = new_history;
    if (action_ts) this.action_ts = new Date(action_ts);
    return this;
  }

  cloneHistory(history: ArticleHistory): this {
    const { typeName, defaultLabel, ..._history } = history;
    Object.assign(this, _history);
    return this;
  }

  get createValue() {
    const { typeName, defaultLabel, new_history, ..._this } = this;
    return {
      ..._this
    };
  }

  get updateValue() {
    const { typeName, defaultLabel, ..._this } = this;
    return {
      ..._this
    };
  }

  get searchTerm(): string {
    return `${this.user} ${this.information} ${this.comment}`.toLowerCase();
  }

  get label(): string {
    return;
  }

  get prettyDay(): string {
    return datePrettyfier(this.action_ts, 'L');
  }

  get prettyHour() {
    return datePrettyfier(this.action_ts, 'LT');
  }

  get originLabel(): string {
    return this.origin === 'default' ? this.defaultLabel : this.origin;
  }

  get destinationLabel(): string {
    return this.destination;
  }

  get prettyQuantity(): string {
    const quantity = quantityPrettyfier(this.quantity);
    switch (this.history_type) {
      case HistoryTypeEnum.Added:
        return `+ ${quantity}`;
      case HistoryTypeEnum.UpdatedArticle:
        if (this.quantity > 0) return `(+ ${quantity})`;
        else if (quantity === '0') return '';
        else return `(${quantity})`;
      case HistoryTypeEnum.OutOfStock:
      case HistoryTypeEnum.Shipped:
      case HistoryTypeEnum.ArticleWithdrawal:
        return `- ${quantity}`;
      default:
        return quantity;
    }
  }
}