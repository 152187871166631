import { gql } from 'apollo-angular';

export const STOCK_OPERATION_INFO_FRAGMENT = gql`
  fragment StockOperationInfo on StockOperation {
    operation_id
    entity_id
    article_instance_id
    shipment_ref
    destination_id
    operation_type
    hpriority
    user_created
    status
    user_id
    created_at
    updated_at
  }
`;
