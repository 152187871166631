import { gql } from 'apollo-angular';

import { CARTO_OUTPUT_INFO_FRAGMENT } from './fragments/_cartoOutput';

export const CARTO_OUTPUTS_QUERY = gql`
  query cartoOutputs {
    processOutputs
  }
`;

export const CARTO_OUTPUT_SUBSCRIPTION = gql`
  subscription cartoOutputSubscription {
    processOutputSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;

export const CREATE_CARTO_OUTPUT_MUTATION = gql`
  mutation createCartoOutput($data: ProcessOutputCreateInput!) {
    createProcessOutput(data: $data) {
      ...CartoOutputInfo
    }
  }
  ${CARTO_OUTPUT_INFO_FRAGMENT}
`;

export const UPDATE_CARTO_OUTPUT_MUTATION = gql`
  mutation updateCartoOutput(
    $where: ProcessOutputWhereUniqueInput!
    $data: ProcessOutputUpdateInput!
  ) {
    updateProcessOutput(where: $where, data: $data) {
      ...CartoOutputInfo
    }
  }
  ${CARTO_OUTPUT_INFO_FRAGMENT}
`;

export const DELETE_CARTO_OUTPUT_MUTATION = gql`
  mutation deleteCartoOutput(
    $where: ProcessOutputWhereUniqueInput!
  ) {
    deleteProcessOutput(where: $where) {
      output_id
    }
  }
`;