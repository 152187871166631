import { datePrettyfier, Deserializable } from '@sparte/utils';
import { StockInventoryService } from '../services/stock-inventory.service';
import { StockBusiness } from './stockBusiness.model';

export class Address {
  street: string;
  zip_code: string;
  city: string;
  country: string;
  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }
}

export class AddressLabel {
  label: string;
  address: Address;
  deserialize(input: any): this {
    const { __typename, address, ..._input } = input;
    Object.assign(this, _input);
    this.address = new Address().deserialize(address);
    return this;
  }
}

export class ShipmentUser {
  full_name: String
  mail: String
  phone: String
  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }
}

export class ShipmentArticle {
  entity_id: string;
  barcode: string;
  time_id: string;
  instance_id: string;
  reference: string;
  designation: string;
  quantity: number;

  deserialize(input: any): this {
    const { __typename, ..._input } = input;
    Object.assign(this, _input);
    return this;
  }

  get id(): string {
    return this.instance_id;
  }

  get updateValue(): any {
    return {
      entity_id: this.entity_id,
      reference: this.reference,
      designation: this.designation,
      quantity: this.quantity
    };
  }
}

export class ShipmentEntityDetail {
  entity_id: string;
  barcode: string;
  packaged_at: Date;
  weight: number;
  dimensions: string;

  deserialize(input: any): this {
    const { __typename, packaged_at, ..._input } = input;
    Object.assign(this, _input);
    if (packaged_at) this.packaged_at = new Date(packaged_at);
    return this;
  }
}

export class DeliverySlip implements Deserializable {
  shipment_ref: string;
  entity_id: string;
  barcode: string;
  articles: ShipmentArticle[];
  added_articles: ShipmentArticle[];
  weight: number;
  dimensions: string;
  shipped_at: Date;

  typeName: string = 'DeliverySlip';
  createValue?: any;
  whereUniqueValue?: any;
  updateValue: any;

  deserialize(input: any): this {
    const { __typename, articles, added_articles, shipped_at, ..._input } = input;
    Object.assign(this, _input);
    if (shipped_at) this.shipped_at = new Date(shipped_at);
    this.articles = (articles || []).map((article: any) => new ShipmentArticle().deserialize(article));
    this.added_articles = (added_articles || []).map((article: any) => new ShipmentArticle().deserialize(article));
    return this;
  }

  get id(): string {
    return this.entity_id;
  }

  get label(): string {
    return this.barcode;
  }

  get searchTerm(): string {
    return `${this.shipment_ref} ${this.barcode}`.toLowerCase();
  }
}

export enum ShipmentStatusEnum {
  PREP = 'PREP',
  PENDING = 'PENDING',
  DONE = 'DONE',
  PACKAGING = 'PACKAGING'
}

export class Shipment implements Deserializable {

  public shipment_ref: string;
  public status: ShipmentStatusEnum;
  public user_id: string;
  public description: string;
  public deadline: Date;
  public destination_user: ShipmentUser;
  public company: string;
  public address: Address;
  public comment: string;
  public business_ref: string;
  public added_articles: ShipmentArticle[];
  public entity_details: ShipmentEntityDetail[];
  public shipment_type: 'REGULAR' | 'PICKUP';
  public created_at: Date;
  public updated_at: Date;
  public typeName = 'Shipment';

  constructor(private stockInventoryService: StockInventoryService) { }
  deserialize(input: any): this {
    const { __typename, address, weight, destination_user, deadline, entity_details, created_at, updated_at, added_articles, ..._input } = input;
    Object.assign(this, _input);
    if (deadline) this.deadline = new Date(deadline);
    if (created_at) this.created_at = new Date(created_at);
    if (updated_at) this.updated_at = new Date(updated_at);

    if (address) {
      this.address = new Address().deserialize(address);
    } else {
      this.address = new Address().deserialize({
        street: '',
        zip_code: '',
        city: '',
        country: ''
      });
    }

    if (destination_user) {
      this.destination_user = new ShipmentUser().deserialize(destination_user);
    } else {
      this.destination_user = new ShipmentUser().deserialize({
        full_name: '',
        mail: '',
        phone: ''
      });
    }

    this.added_articles = (added_articles || []).map((article: any) => new ShipmentArticle().deserialize(article));
    this.entity_details = (entity_details || []).map((entity_detail: any) => new ShipmentEntityDetail().deserialize(entity_detail));

    return this;
  }

  get id(): string {
    return this.shipment_ref;
  }

  get createValue() {
    const {
      typeName,
      user_id,
      entity_details,
      shipment_ref,
      created_at,
      updated_at,
      added_articles,
      stockInventoryService,
      status,
      ..._this
    } = this;
    return _this;
  }

  get searchTerm(): string {
    return `${this.shipment_ref} ${this.description} ${this.destination_user.full_name} ${this.company} ${this.address.street} ${this.address.zip_code} ${this.address.city} ${this.address.country} ${this.business?.searchTerm}`.toLowerCase();
  }

  get label(): string {
    return `${this.shipment_ref} - ${this.description}`;
  }

  get whereUniqueValue(): any {
    return { shipment_ref: this.shipment_ref };
  }

  get updateValue(): any {
    return {
      added_articles: this.added_articles.map(article => article.updateValue)
    };
  }

  get getStatus(): string {
    switch (this.status) {
      case 'PREP':
        return 'En préparation';
      case 'PACKAGING':
        return 'En cours de conditionnement';
      case 'PENDING':
        return 'En attente d’expédition';
      case 'DONE':
        return 'Expédié';
      default:
        return 'En préparation';
    }
  }

  get getTextColor(): string {
    switch (this.status) {
      case 'PREP':
        return 'text-sparteOrange';
      case 'PACKAGING':
        return 'text-sparteGold';
      case 'PENDING':
        return 'text-sparteGreen';
      case 'DONE':
        return 'text-sparteWhite-500';
      default:
        return 'text-sparteWhite-500';
    }
  }

  get getIcon(): string {
    switch (this.status) {
      case 'PREP':
        return 'trolley';
      case 'PACKAGING':
        return 'inventory_2';
      case 'PENDING':
        return 'inventory';
      case 'DONE':
        return 'local_shipping';
      default:
        return '';
    }
  }

  get business(): StockBusiness {
    return this.stockInventoryService.getStockBusiness(this.business_ref);
  }

  get weight(): number {
    return this.entity_details.reduce((acc, entity_detail) => acc + (entity_detail.weight || 0), 0);
  }

  get dimensions(): string[] {
    return this.entity_details.map(entity_detail => `${entity_detail.barcode}: ${entity_detail.dimensions}`);
  }


  get prettyCreatedAt(): string {
    return datePrettyfier(this.created_at, "LL");
  }

  get prettyDeadline(): string {
    return datePrettyfier(this.deadline, "LL");
  }
}