import { gql } from "apollo-angular";

export const DASSAULT_COMPONENTS_QUERY = gql`
  query dassaultComponents($where: DassaultComponentWhereInput!) {
    dassaultComponents(where: $where)
  }
`;

export const DASSAULT_COMPONENT_SUBSCRIPTION = gql`
  subscription dassaultComponentSubscription {
    dassaultComponentSubscription {
      mutationType
      objectId
      objectJson
    }
  }
`;