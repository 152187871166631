import { ApiService } from '@sparte/api';
import { LinkInterface, ModuleInterface } from '@sparte/utils';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { action, observable } from 'mobx-angular';
import { makeObservable } from 'mobx';

@Injectable({
  providedIn: 'root'
})
export class SparteModulesService {
  @observable public sparteModules: ModuleInterface[] = [];
  @observable public currentModule: ModuleInterface;
  public moduleChangeEvent: EventEmitter<ModuleInterface>;
  constructor(private api: ApiService) {
    makeObservable(this);
    this.moduleChangeEvent = new EventEmitter<ModuleInterface>();
  }

  @action public clearModules() {
    this.sparteModules = [];
    this.currentModule = null;
    this.moduleChangeEvent.emit(null);
  }

  @action public initSparteModules(modules: ModuleInterface[]) {
    this.sparteModules = modules;
  }

  @action setModuleByName(name: string): void {
    if (this.currentModule ? this.currentModule.name !== name : true) {
      this.currentModule = this.sparteModules.find(module => module.name === name);
    }
    if (this.currentModule) {
      let navLinks: LinkInterface[] = [];
      const moduleNavLinks = this.api.modulesMap.get(name).navLinks;
      moduleNavLinks.forEach(link => {
        if (this.api.currentUser?.hasPermission(link.permission_id)) {
          navLinks.push(link);
        }
        else if (link.children?.length > 0) {
          const children = link.children.filter(child => this.api.currentUser?.hasPermission(child.permission_id));
          if (children.length > 0) {
            link.children = children;
            navLinks.push(link);
          }
        }
      });
      this.currentModule.navLinks = navLinks;
    }
    this.moduleChangeEvent.emit(this.currentModule);
  }

  @action updateNavLinksCounts(countFunctions: Map<string, Function>) {
    if (this.currentModule) {
      this.currentModule.navLinks.forEach(link => {
        if (countFunctions.has(link.id)) {
          link.count = countFunctions.get(link.id)();
        }
      });
      this.moduleChangeEvent.emit(this.currentModule);
    }
  }
}
